export const register = {
	Gender: 'M',
	FirstName: '',
	LastName: '',
	FullName: '',
	UserNote: 'requestMsg',
	ExternalUser: true,
	Photo: null,
	Address1: null,
	Address2: null,
	City: null,
	Region: null,
	Zip: null,
	Country: null,
	PhoneNo: null,
	Active: 1,
	JobTitleID: 5,
	UserStatusID: 112,
	TempUserName: '',
	TempPassword: '',
	CustomerID: null,
	TempEmailID: '',
	isRequestAccount: true,
	AddByAdmin: false,
	Portal: '',
};
