import classNames from 'classnames';
import types from 'prop-types';
import React from 'react';

// Components

import { Tool } from '../../../../components';

// Data

import data from './data';

// Static

import { matType as matTypeInfo } from '../../../../static/constants';

// Styles

import './styles.scss';

// ----------------

// Type of props

MatAside.propTypes = {
	requireColors: types.bool,
	activeTool: types.string,
	imageCount: types.number,
	className: types.string,
	matType: types.string,
	mobile: types.bool,
};

const activeToolData = {
	border: ['border'],
	colors: ['colors', 'size'],
	image: ['image'],
	text: ['text'],
	size: ['colors', 'size'],
};

const defaultData = ['colors', 'size'];

// ----------------

export default function MatAside(props) {
	const {
		activeObjectId,
		requireColors,
		className,
		mobile,
		activeTool,
		imageCount,
		matType,
		isTutorialOpen,
	} = props;

	function isOpen(tool) {
		if (mobile) {
			return tool === activeTool;
		}

		if (activeTool) {
			return activeToolData[activeTool].some((toolName) => toolName === tool);
		} else {
			return defaultData.some((toolName) => toolName === tool);
		}
	}

	function isReady(tool) {
		if (tool.children) {
			switch (tool.name) {
				case 'text':
				case 'image': {
					if (!activeObjectId) {
						return (
							<div className="mat-aside__body-empty-text">{`Select or add ${tool.name}`}</div>
						);
					}

					return <tool.children />;
				}

				default: {
					return <tool.children />;
				}
			}
		}

		return null;
	}

	function addButton(tool) {
		if (tool.name !== 'image') return tool.onAddClick;

		return imageCount < 2 ? tool.onAddClick : undefined;
	}

	function isHideMode(tool) {
		return tool.name === 'image' || tool.name === 'text';
	}

	function showRequiredIcon(tool) {
		return tool.name === 'colors' && requireColors;
	}

	// Modify styles

	const modify = classNames({
		' mat-aside__body--center': matType === matTypeInfo.LYL.name,
	});

	// Render

	return (
		<aside
			className={classNames(
				className,
				isTutorialOpen ? 'mat-aside mat-aside--no-visible' : 'mat-aside'
			)}
		>
			<div className={`mat-aside__body${modify}`}>
				{data().map((tool) =>
					tool.access ? (
						<Tool
							requiredIcon={showRequiredIcon(tool)}
							onAddClick={addButton(tool)}
							hideMode={isHideMode(tool) && mobile}
							onClose={tool.onClose}
							onClick={tool.onClick}
							title={tool.title}
							open={isOpen(tool.name)}
							icon={tool.icon}
							key={tool.name}
							id={tool.id}
						>
							{isReady(tool)}
						</Tool>
					) : null
				)}
			</div>
		</aside>
	);
}
