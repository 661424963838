import types from 'prop-types';
import React from 'react';

// Components

import { Button, RadioButton, LogoMatTypeCard } from '../../../../components';

// Static

import { matType } from '../../../../static/constants';

// Data

import { logoMatTypeDes } from './data';

// Styles

import './styles.scss';

// ----------------

// Type of props

LogoMatTypeForm.propTypes = {
	onSubmit: types.func.isRequired,
	onClose: types.func.isRequired,
	fields: types.object.isRequired,
};

// Default value for props

LogoMatTypeForm.defaultProps = {
	onSubmit: () => null,
	onClose: () => null,
};

// ----------------

export default function LogoMatTypeForm(props) {
	const { onSubmit, onClose, fields } = props;

	return (
		<form className="logo-mat-type-form" onSubmit={onSubmit}>
			<fieldset className="logo-mat-type-form__fields">
				<LogoMatTypeCard
					active={fields.logoMatType.value === 'traditional'}
					label="Traditional"
				>
					<RadioButton
						checked={fields.logoMatType.value === matType.TRADITIONAL.name}
						onChange={fields.logoMatType.onChange}
						value={matType.TRADITIONAL.name}
						name="logoMatType"
					/>
				</LogoMatTypeCard>
				<LogoMatTypeCard active={fields.logoMatType.value === 'photo'} label="Photo">
					<RadioButton
						onChange={fields.logoMatType.onChange}
						checked={fields.logoMatType.value === matType.PHOTO.name}
						value={matType.PHOTO.name}
						name="logoMatType"
					/>
				</LogoMatTypeCard>
				<LogoMatTypeCard
					active={fields.logoMatType.value === 'logo'}
					label="Lay your Logo"
				>
					<RadioButton
						onChange={fields.logoMatType.onChange}
						checked={fields.logoMatType.value === matType.LYL.name}
						value={matType.LYL.name}
						name="logoMatType"
					/>
				</LogoMatTypeCard>
			</fieldset>
			<p className="logo-mat-type-form__subtitle">
				{logoMatTypeDes[fields.logoMatType.value].label}
			</p>
			<div className="form__two-buttons-grid">
				<Button reverseType fluid onClick={onClose} id="logo-mat-type-form-cancel-button">
					Cancel
				</Button>
				<Button
					onClick={onSubmit}
					theme="secondary"
					fluid
					type="submit"
					id="logo-mat-type-form-submit-button"
				>
					Create
				</Button>
			</div>
		</form>
	);
}
