import React, { useState, useEffect } from 'react';
import classNames from 'classnames';
import types from 'prop-types';

// Hooks

import { useWindowWidth } from '../../../../hooks';

// Components

import {
	SearchPageHeader,
	SearchAside,
	Container,
	FontIcon,
	Dropdown,
	ButtonUp,
	Button,
	List,
	Bool,
} from '../../../../components';

// Action types

import { GET_SIZES_$NETWORK } from '../../../../redux/types/mat';

// Styles

import './styles.scss';

// Formating card view mode

const matViewFormat = {
	block: 'block',
	list: 'row',
};

// ----------------

// Type of props

SearchPageStruct.propTypes = {
	loadMoreNetworkProcess: types.bool,
	getNetworkProcess: types.bool,
	showLoadMore: types.bool,
	sortActions: types.array,
	searchAside: types.node,
	onLoadMore: types.func.isRequired,
	asideOpen: types.bool,
	onSearch: types.func,
	list: types.array.isRequired,
	card: types.func,
};

// Default value for props

SearchPageStruct.defaultProps = {
	withPagination: false,
};

// ----------------

export default function SearchPageStruct(props) {
	const {
		loadMoreNetworkProcess,
		getNetworkProcess,
		showLoadMore,
		sortActions,
		onLoadMore,
		asideOpen,
		onSearch,
		toggle,
		list,
		card: Card,
	} = props;

	// State

	const [isFiltersButtonShow, setFiltersShow] = useState(false);
	const [activeGridMode, setMode] = useState('block');

	// Preparation

	const currentWindowWidth = useWindowWidth();

	useEffect(() => {
		currentWindowWidth <= 1024 ? setFiltersShow(true) : setFiltersShow(false);
	}, [currentWindowWidth]);

	// Modify styles

	const modifyBackdrop = (open) =>
		classNames({
			' search-page-struct__aside-backdrop--open': open,
		});

	const modifyViewGrid = classNames({
		[` search-page-struct__mats-grid--${activeGridMode}`]: activeGridMode,
	});

	// Render

	return (
		<div className="search-page-struct">
			<Container
				withRouter
				mapState={(state) => ({
					getSizesNetworkProcess: state.network[GET_SIZES_$NETWORK],
					asideOpen: state.ui.active.searchAside,
					matSizes: state.mat.matSizes,
				})}
				render={({ getSizesNetworkProcess, asideOpen, matSizes }) => (
					<>
						<div
							className={`search-page-struct__aside-backdrop${modifyBackdrop(asideOpen)}`}
							onClick={() => (asideOpen ? toggle('searchAside') : null)}
						/>
						<SearchAside
							getSizesNetworkProcess={getSizesNetworkProcess}
							filterSizes={matSizes}
							onSearch={onSearch}
							toggle={toggle}
							open={asideOpen}
						/>
					</>
				)}
			/>
			<div className="search-page-struct__header-wrapper">
				<SearchPageHeader
					isFiltersButtonShow={isFiltersButtonShow}
					asideOpen={asideOpen}
					onSearch={onSearch}
					toggle={toggle}
				/>
			</div>
			<div className="search-page-struct__grid-header grid-header">
				<div>
					<span className="text-middle grid-header__title">Results</span>
				</div>
				<div className="grid-header__actions-wrapper">
					{/* Sort actions dropdown */}

					{!!sortActions && (
						<Bool
							render={({ isTrue, handleChange }) => (
								<Dropdown
									handleToggle={handleChange}
									isShowing={isTrue}
									actions={sortActions}
									button={
										<div
											className="dropdown__show-button search-page-struct__dropdown-btn"
											onClick={handleChange}
										>
											<FontIcon size="xl" icon="sort" />
										</div>
									}
								/>
							)}
						/>
					)}

					{/* View actions dropdown */}

					<Bool
						render={({ isTrue, handleChange }) => (
							<Dropdown
								handleToggle={handleChange}
								isShowing={isTrue}
								actions={[
									{
										onClick: () => setMode('block'),
										active: activeGridMode === 'block',
										label: 'Grid View',
										icon: 'view_module',
										id: 'mats-view-block-button',
									},
									{
										onClick: () => setMode('list'),
										active: activeGridMode === 'list',
										label: 'List View',
										icon: 'view_stream',
										id: 'mats-view-list-button',
									},
								]}
								button={
									<div
										className="dropdown__show-button search-page-struct__dropdown-btn"
										onClick={handleChange}
									>
										<FontIcon size="xl" icon="more_vert" />
									</div>
								}
							/>
						)}
					/>
				</div>
			</div>
			<List
				listContentClassName={`search-page-struct__mats-grid${modifyViewGrid} show-info`}
				networkProcess={getNetworkProcess}
				listItem={(props) => {
					return <Card viewMode={matViewFormat[activeGridMode]} {...props.data} />;
				}}
				list={list}
			/>
			{showLoadMore && !!list.length && list.length >= 12 && !getNetworkProcess && (
				<div className="search-page-struct__load-more-button">
					<Button
						loaderColor="white"
						isLoading={loadMoreNetworkProcess}
						onClick={onLoadMore}
						size="xl"
						fluid
						id="load-more-mats-list"
					>
						Load More
					</Button>
				</div>
			)}
			<div className="search-page-struct__button-up-wrapper">
				<ButtonUp />
			</div>
		</div>
	);
}
