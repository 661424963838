import { Route, Switch, Redirect } from 'react-router-dom';
import ComponentWrapper from './ComponentWrapper';
import React from 'react';

// Utils

import isPlainObject from './utils/isPlainObject';

// ----------------

/**
 * @param {object} config
 * @param {object} setting
 *
 * @returns {Function} Router that are ready for use.
 */

function createRouter(config, setting) {
	let router;

	// Create an array of routes

	const routes = config.routes.map((route) => {
		let component;

		if (route.redirect) {
			component = () => {
				return <Redirect to={route.redirect} />;
			};
		} else {
			component = (props) => (
				<ComponentWrapper {...route} baseTitle={setting.title} historyProps={props} />
			);
		}

		return (
			<Route
				component={component}
				exact={!route.notExact}
				path={route.path}
				key={route.path}
			/>
		);
	});

	if (config.notFoundRedirect) {
		routes.push(
			<Route
				component={() => <Redirect to={config.notFoundRedirect} />}
				key="notFoundRedirect"
			/>
		);
	}

	router = () => <Switch>{routes}</Switch>;

	router.displayName = `${
		config.name[0].toUpperCase() + config.name.slice(1, config.name.length)
	}Router`;

	const RouterWrapper = () => (
		<ComponentWrapper
			component={router}
			onUnMount={config.onUnMount}
			onMount={config.onMount}
		/>
	);

	RouterWrapper.displayName = 'RouterWrapper';

	return RouterWrapper;
}

/**
 * @param {object|object[]} routerConfig
 * @param {object} [setting]
 *
 * @returns {Function|object}
 */

export default function createRouterHelper(routerConfig, setting) {
	if (!isPlainObject(routerConfig) && !routerConfig instanceof Array) {
		throw new Error('Expected the routerConfig to be a plain object or Array.');
	}

	if (typeof setting !== 'undefined' && !isPlainObject(setting)) {
		throw new Error('Expected the setting to be a plain object');
	}

	let router;

	if (routerConfig instanceof Array) {
		router = {};

		routerConfig.forEach((config) => {
			if (config.name) router[config.name] = createRouter(config, setting);
			else
				throw new Error('Expected the routerConfig object, must have a name property.');
		});
	} else {
		router = createRouter(routerConfig, setting);
	}

	return router;
}
