import classNames from 'classnames';
import types from 'prop-types';
import React from 'react';

// Styles

import './styles.scss';

// ----------------

// Type of props

Label.propTypes = {
	children: types.node.isRequired,
	alignTop: types.bool,
	fullWidthMobile: types.bool,
	position: types.oneOf(['left', 'right', 'top']),
	theme: types.oneOf(['onPrimary', 'onPrimaryDark', 'onSecondary', 'onFourth']),
	title: types.string.isRequired,
};

// Default value for props

Label.defaultProps = {
	position: 'right',
	theme: 'onPrimary',
};

// ----------------

export default function Label(props) {
	const { children, alignTop, fullWidthMobile, position, theme, title } = props;

	// Modify styles

	const modify = classNames({
		[` label--position-${position}`]: position,
		' label--align-top': alignTop,
		' label--full-width-mobile': fullWidthMobile,
	});

	const modifyTitle = classNames({
		[` label__title--theme-${theme}`]: theme,
	});

	// Render

	return (
		<div className={`label${modify}`}>
			<span className={`label__title${modifyTitle}`}>{title}</span>
			<div className="label__children">{children}</div>
		</div>
	);
}
