import * as base_types from '../types/base';
import { matType } from '../../static/constants';
import * as types from '../types/mat';
import $ from '../../utils/createActionType';

// -------- Default state of the branch --------

const defaultState = {
	activeObjectId: null,
	activeToolHeight: 0,
	additionalTools: false,
	activeTool: null,
	editTextId: null,
	nextZIndex: 1,
	matIsReady: false,
	lastAction: {
		type: '',
	},

	matOrientation: 'Portrait',
	submitIsOpen: false,
	dragPosition: { x: 0, y: 0 },
	dragMode: false,
	matType: matType.TRADITIONAL.name,
	matInfo: { sketchName: 'New draft' },
	tempBgImage: null,
	bgImageJSON: {},
	bgImage: null,
	bgColor: null,

	// Switch between color/image background option

	bgOption: 'color',

	// ----------------

	objects: {},
	border: {
		isShow: false,
		color: '108',
	},
	toggleSuggestedColors: false,
	extractedColors: [],
	selectedColors: [],
	colorsCount: 0,
	imageCount: 0,
	lylImages: [],
	tutorial: false,
	colors: { standart: [], pms: [] },
	nextId: 1,
	modal: '',
	saved: true,
	zoom: window.innerWidth > 768 ? 1 : 0.6,

	selectedSizeId: 4,
	matSizes: [],

	historyPosition: 0,
	history: [
		{
			selectedSizeId: 4,
			matOrientation: 'Portrait',
			objects: {},
			bgColor: null,
			bgImage: null,
			bgImageJSON: {},
			selectedColors: [],
			border: {
				isShow: false,
				color: '108',
			},
		},
	],

	// Edit Background Image modal

	modalType: 'addImageToMat',
	editBg: null,
	editBgObjectId: null,
	restoreEditBg: false,
};

// -------- Reducer --------

export default function (state, action) {
	if (!state) return defaultState;

	switch (action.type) {
		// MAT_READY

		case types.MAT_READY: {
			return {
				...state,
				lastAction: { type: action.type, id: null },
				matIsReady: true,
				saved: false,
			};
		}

		// ADD_OBJECT

		case types.ADD_OBJECT: {
			const { id, zIndex } = action.payload;

			return {
				...state,
				activeObjectId: id,
				lastAction: { type: action.type, id, save: action.payload.save },
				objects: {
					...state.objects,
					[id]: { ...action.payload },
				},
				nextZIndex: zIndex + 1,
				nextId: id + 1,
				saved: false,
				...(action.payload.objectType === 'image'
					? { imageCount: state.imageCount + 1 }
					: {}),
			};
		}

		// SYNC_OBJECT | EDIT_OBJECT

		case types.SYNC_OBJECT:
		case types.EDIT_OBJECT: {
			const { id } = action.payload;

			return {
				...state,
				lastAction: { type: action.type, id, save: action.payload.save },
				objects: {
					...state.objects,
					[id]: { ...state.objects[id], ...action.payload },
				},
				saved: false,
			};
		}

		// SYNC_OBJECTS

		case types.SYNC_OBJECTS: {
			return {
				...state,
				lastAction: { type: action.type, id: null },
				objects: {
					...state.objects,
					...action.payload,
				},
				saved: false,
			};
		}

		// DEL_OBJECT

		case types.DEL_OBJECT: {
			const newObjects = { ...state.objects };
			const { id } = action.payload;
			const type = newObjects[id].objectType;

			delete newObjects[id];

			return {
				...state,
				lastAction: { type: action.type, id, save: action.payload.save },
				objects: newObjects,
				saved: false,
				...(type === 'image' ? { imageCount: state.imageCount - 1 } : {}),
			};
		}

		// SET_BG_OPTION

		case types.SET_BG_OPTION: {
			const { option, saved } = action.payload;

			let bgImage, tempBgImage;

			if (option === 'color') {
				bgImage = null;
				tempBgImage = state.bgImage;
			} else {
				bgImage = state.tempBgImage;
				tempBgImage = null;
			}

			return {
				...state,
				lastAction: { type: action.type, id: null },
				bgOption: option,
				tempBgImage,
				bgImage,
				saved,
			};
		}

		// SET_BG_COLOR

		case types.SET_BG_COLOR: {
			return {
				...state,
				colorsCount: state.bgColor ? state.colorsCount : state.colorsCount + 1,
				lastAction: { type: action.type, id: null },
				bgColor: action.payload.color,
				saved: false,
			};
		}

		// DEL_BG_COLOR

		case types.DEL_BG_COLOR: {
			return {
				...state,
				colorsCount: state.colorsCount - 1,
				lastAction: { type: action.type, id: null },
				bgColor: null,
				saved: false,
			};
		}

		// SET_TOOL

		case types.SET_TOOL: {
			return {
				...state,
				activeObjectId: null,
				lastAction: { type: action.type, id: null },
				activeTool:
					state.activeTool === action.payload.toolName ? null : action.payload.toolName,
			};
		}

		// SET_TOOL_HEIGHT

		case types.SET_TOOL_HEIGHT: {
			return {
				...state,
				lastAction: { type: action.type, id: null },
				activeToolHeight: action.payload.height,
			};
		}

		// TOGGLE_ADDITIONAL_TOOLS

		case types.TOGGLE_ADDITIONAL_TOOLS: {
			return {
				...state,
				lastAction: { type: action.type, id: null },
				additionalTools: !state.additionalTools,
			};
		}

		// SET_ACTIVE_OBJECT

		case types.SET_ACTIVE_OBJECT: {
			return {
				...state,
				activeObjectId: action.payload.id,
				activeTool: action.payload.type,
				lastAction: { type: action.type, id: action.payload.id },
			};
		}

		// TOGGLE_MODAL

		case types.TOGGLE_MODAL: {
			return {
				...state,
				lastAction: { type: action.type, id: null },
				modal: state.modal === action.payload.name ? '' : action.payload.name,
			};
		}

		// SET_MODAL_TYPE

		case types.SET_MODAL_TYPE: {
			return {
				...state,
				modalType: action.payload.name,
			};
		}

		// SET_EDIT_TEXT_ID

		case types.SET_EDIT_TEXT_ID: {
			return {
				...state,
				lastAction: { type: action.type, id: null },
				editTextId: action.payload.id,
			};
		}

		// TOGGLE_BORDER

		case types.TOGGLE_BORDER: {
			return {
				...state,
				lastAction: { type: action.type, id: null },
				border: { ...state.border, isShow: !state.border.isShow },
				saved: false,
			};
		}

		// SET_BORDER_COLOR

		case types.SET_BORDER_COLOR: {
			return {
				...state,
				lastAction: { type: action.type, id: null },
				border: { ...state.border, color: action.payload.color },
				saved: false,
			};
		}

		// ZOOM

		case types.ZOOM: {
			return {
				...state,
				lastAction: { type: action.type, id: null },
				zoom: action.payload.zoom,
			};
		}

		// TOGGLE_DRAG_MODE

		case types.TOGGLE_DRAG_MODE: {
			return {
				...state,
				lastAction: { type: action.type, id: null },
				dragMode: !state.dragMode,
			};
		}

		// SET_DRAG_POSITION

		case types.SET_DRAG_POSITION: {
			return {
				...state,
				lastAction: { type: action.type, id: null },
				dragPosition: action.payload,
			};
		}

		// SET_DRAG_DEF_POSITION

		case types.SET_DRAG_DEF_POSITION: {
			return {
				...state,
				lastAction: { type: action.type, id: null },
				dragPosition: { x: 0, y: 0 },
			};
		}

		// SET_Z_INDEX

		case types.SET_Z_INDEX: {
			return {
				...state,
				lastAction: { type: action.type, id: null },
				objects: action.payload,
				saved: false,
			};
		}

		// SET_COLORS

		case types.SET_COLORS: {
			return {
				...state,
				lastAction: { type: action.type, id: null },
				colors: action.payload.colors,
			};
		}

		// SELECT_COLOR

		case types.SELECT_COLOR: {
			return {
				...state,
				selectedColors: [...state.selectedColors, action.payload.color],
				colorsCount: state.colorsCount + 1,
				lastAction: { type: action.type, id: null },
				saved: false,
			};
		}

		// SELECT_MULTIPLY_COLORS

		case types.SELECT_MULTIPLY_COLORS: {
			return {
				...state,
				selectedColors: [...state.selectedColors, ...action.payload.colors],
				colorsCount: state.colorsCount + action.payload.colors.length,
				lastAction: { type: action.type, id: null },
				saved: false,
			};
		}

		// DESELECT_COLOR

		case types.DESELECT_COLOR: {
			return {
				...state,
				selectedColors: state.selectedColors.filter(
					(color) => color !== action.payload.color
				),
				colorsCount: state.colorsCount - 1,
				lastAction: { type: action.type, id: null },
				saved: false,
			};
		}

		// CLEAR_COLORS

		case types.CLEAR_COLORS: {
			return {
				...state,
				selectedColors: [],
				colorsCount: 0,
				lastAction: { type: action.type, id: null },
				bgColor: null,
				saved: false,
			};
		}

		// TOGGLE_SUGGESTED_COLORS

		case types.TOGGLE_SUGGESTED_COLORS: {
			return {
				...state,
				lastAction: { type: action.type, id: null },
				toggleSuggestedColors: !state.toggleSuggestedColors,
			};
		}

		// OPEN_SUBMIT

		case types.OPEN_SUBMIT: {
			return {
				...state,
				submitIsOpen: true,
				lastAction: { type: action.type, id: null },
			};
		}

		// CLOSE_SUBMIT

		case types.CLOSE_SUBMIT: {
			return {
				...state,
				submitIsOpen: false,
				lastAction: { type: action.type, id: null },
			};
		}

		// SET_MAT_INFO

		case types.SET_MAT_INFO: {
			return {
				...state,
				lastAction: { type: action.type, id: null },
				matInfo: { ...state.matInfo, ...action.payload },
				saved: false,
			};
		}

		// RESTORE_MAT

		case types.RESTORE_MAT:
		case types.RESTORE_MAT_HISTORY: {
			return {
				...state,
				...action.payload,
				...(action.payload.selectedColors && {
					colorsCount:
						action.payload.selectedColors.length + (action.payload.bgColor ? 1 : 0),
				}),
				lastAction: { type: action.type, id: null },
			};
		}

		// TOGGLE_SUBMIT

		case types.SHOW_TUTORIAL: {
			return {
				...state,
				tutorial: !state.tutorial,
				lastAction: { type: action.type, id: null },
			};
		}

		// SET_SIZE

		case types.SET_SIZE: {
			return {
				...state,
				selectedSizeId: action.payload.id,
				lastAction: { type: action.type, id: null },
				saved: false,
			};
		}

		// SET_ORIENTATION_L

		case types.SET_ORIENTATION_L: {
			return {
				...state,
				matOrientation: 'Landscape',
				lastAction: { type: action.type, id: null },
				saved: false,
			};
		}

		// SET_ORIENTATION_P

		case types.SET_ORIENTATION_P: {
			return {
				...state,
				matOrientation: 'Portrait',
				lastAction: { type: action.type, id: null },
				saved: false,
			};
		}

		// SET_MAT_TYPE

		case types.SET_MAT_TYPE: {
			return {
				...state,
				lastAction: { type: action.type, id: null },
				matType: action.payload.type,
			};
		}

		// SET_MAT_NAME

		case types.SET_MAT_NAME: {
			return {
				...state,
				lastAction: { type: action.type, id: null },
				matInfo: { ...state.matInfo, sketchName: action.payload.name },
			};
		}

		// SAVE_DRAFT_$NETWORK

		case $(types.SAVE_DRAFT_$NETWORK):
		case $(types.UPDATE_DRAFT_$NETWORK): {
			return {
				...state,
				lastAction: { type: action.type, id: null },
				saved: true,
			};
		}

		// ADD_SNAPSHOTS_TO_HIS

		case types.ADD_SNAPSHOTS_TO_HIS: {
			return {
				...state,
				historyPosition: action.payload.position,
				lastAction: { type: action.type, id: null },
				history: action.payload.snapshots,
			};
		}

		// RELOAD_HIS_AFTER_SAVE

		case types.RELOAD_HIS_AFTER_SAVE: {
			return {
				...state,
				lastAction: { type: action.type, id: null },
				historyPosition: 0,
				history: [state.history[state.historyPosition]],
			};
		}

		// UNDO

		case types.UNDO: {
			return {
				...state,
				historyPosition: state.historyPosition - 1,
				lastAction: { type: action.type, id: null },
			};
		}

		// REDO

		case types.REDO: {
			return {
				...state,
				historyPosition: state.historyPosition + 1,
				lastAction: { type: action.type, id: null },
			};
		}

		// SET_SIZES

		case types.SET_SIZES: {
			return {
				...state,
				lastAction: { type: action.type, id: null },
				matSizes: action.payload,
			};
		}

		// SET_LYL_IMAGES

		case types.SET_LYL_IMAGES: {
			return {
				...state,
				lastAction: { type: action.type, id: null },
				lylImages: action.payload,
			};
		}

		// SET_BG_IMAGE

		case types.SET_BG_IMAGE: {
			return {
				...state,
				lastAction: { type: action.type, id: null },
				tempBgImage: null,
				bgOption: 'image',
				bgImage: action.payload.image,
				bgImageJSON: action.payload.json,
				saved: false,
			};
		}

		// DEL_BG_IMAGE

		case types.DEL_BG_IMAGE: {
			return {
				...state,
				lastAction: { type: action.type, id: null },
				tempBgImage: null,
				bgOption: 'image',
				bgImage: null,
				bgImageJSON: {},
				saved: false,
			};
		}

		// SET_EDIT_BG

		case types.SET_EDIT_BG: {
			return {
				...state,
				lastAction: { type: action.type, id: null },
				editBg: action.payload.image,
				editBgObjectId: action.payload.objectId,
				restoreEditBg: action.payload.restore,
			};
		}

		// DEL_EDIT_BG

		case types.DEL_EDIT_BG: {
			return {
				...state,
				lastAction: { type: action.type, id: null },
				editBg: null,
				editBgObjectId: null,
				restoreEditBg: false,
			};
		}

		// SET_EXTRACTED_COLORS

		case types.SET_EXTRACTED_COLORS: {
			return {
				...state,
				extractedColors: [...action.payload.colors],
				lastAction: { type: action.type, id: null },
			};
		}

		// RESTORE_BRANCH | RESTORE_DEFAULT_STATE

		case types.RESTORE_BRANCH:
		case base_types.RESTORE_DEFAULT_STATE: {
			return defaultState;
		}

		default: {
			return state;
		}
	}
}
