import types from 'prop-types';
import React from 'react';

// Components

import VisibilityIcon from '../../../../components/ui/VisibilityIcon';

import InputField from '../../../../components/ui/InputField';

import ErrorField from '../../../../components/block/ErrorField';

import Button from '../../../../components/ui/Button';

import Switch from '../../../../components/ui/Switch';

import Bool from '../../../../components/rp/Bool';

// Styles

import './styles.scss';

// ----------------

// Type of props

SigninForm.propTypes = {
	networkProcess: types.bool,
	extraState: types.object.isRequired,
	onSubmit: types.func.isRequired,
	fields: types.object.isRequired,
};

// Default value for props

SigninForm.defaultProps = {
	onSubmit: () => null,
};

// ----------------

export default function SigninForm(props) {
	const { networkProcess, extraState, onSubmit, fields } = props;

	return (
		<form className="sign-in-form" onSubmit={onSubmit}>
			<fieldset className="sign-in-form__fields">
				<InputField
					{...fields.userName}
					placeholder="Username"
					theme="secondaryDark"
					fluid
				/>
				<Bool
					render={({ isTrue, handleChange }) => (
						<InputField
							{...fields.password}
							customSideBlock={
								<VisibilityIcon onClick={handleChange} isVisible={isTrue} />
							}
							placeholder="Password"
							theme="secondaryDark"
							type={isTrue ? 'text' : 'password'}
							fluid
						/>
					)}
				/>
				{extraState.notValidData && (
					<div className="sign-in-form__error">
						<ErrorField fluid error="The username or password specified is not valid." />
					</div>
				)}
				<div className="sign-in-form__switch">
					<Switch
						onChange={fields.rememberMe.onChange}
						active={fields.rememberMe.value}
						theme="onSecondary"
					/>
					<span className="sign-in-form__switch-title">Remember Me</span>
				</div>
			</fieldset>
			<div className="sign-in-form__buttons">
				<Button
					borderWeight="bold"
					loaderColor="white"
					isLoading={networkProcess}
					onClick={onSubmit}
					theme="onPrimary"
					type="submit"
					size="lg"
					id="signin-form-submit-button"
					fluid
				>
					Sign In
				</Button>
				<Button
					borderWeight="bold"
					theme="onPrimary"
					fluid
					size="lg"
					link="/signup"
					id="signin-form-tosignup-button"
				>
					Sign Up
				</Button>
				<Button
					borderWeight="bold"
					reverseType
					fluid
					theme="onPrimary"
					size="lg"
					link="/reset-password"
					id="signin-form-reset-button"
				>
					Reset Password
				</Button>
			</div>
		</form>
	);
}
