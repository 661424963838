import classNames from 'classnames';
import types from 'prop-types';
import React from 'react';

// Hooks

import { useModal } from '../../../../hooks';

// Components

import AddSignatureModal from '../../../../components/block/modals/AddSignatureModal';
import UIFieldWrapper from '../../../../components/block/UIFieldWrapper';
import InputField from '../../../../components/ui/InputField';
import Button from '../../../../components/ui/Button';

// Styles

import './styles.scss';

// ----------------

// Type of props

CustomerApprovalForm.propTypes = {
	onDownload: types.func.isRequired,
	onSubmit: types.func.isRequired,
	onClose: types.func.isRequired,
	fields: types.object.isRequired,
};

// Default value for props

CustomerApprovalForm.defaultProps = {
	onDownload: () => null,
	onSubmit: () => null,
	onClose: () => null,
};

// ----------------

export default function CustomerApprovalForm(props) {
	const { onDownload, onSubmit, fields } = props;

	// State

	const { modal, handleModalToggle } = useModal();

	// Modify styles

	const error = classNames({
		' customer-approval-form__signature--error': fields.signature.status.error,
	});

	// Render

	return (
		<form className="customer-approval-form" onSubmit={onSubmit}>
			<div className="customer-approval-form__fields">
				<InputField
					{...fields.customerName}
					placeholder="Enter name"
					theme="onPrimaryDark"
					size="md"
					title="Customer Name"
					fluid
				/>
				<InputField
					{...fields.email}
					placeholder="Enter your email"
					theme="onPrimaryDark"
					title="Email Address"
					size="md"
					fluid
				/>
			</div>
			<UIFieldWrapper
				withPadding
				status={fields.signature.status}
				title="Approval Signature"
				theme="onPrimaryDark"
				fluid
			>
				<div
					className={`customer-approval-form__signature${error}`}
					onClick={() => handleModalToggle('addSignatureModal')}
				>
					{fields.signature.value && fields.signature.value.base64Url ? (
						<img src={fields.signature.value.base64Url} alt="" />
					) : (
						<p>Place Approval Signature in here</p>
					)}
				</div>
			</UIFieldWrapper>
			<div className="customer-approval-form__buttons">
				<Button
					reverseType
					onClick={onDownload}
					fluid
					id="customer-approval-form-download-button"
				>
					Download PDF Spec
				</Button>
				<Button
					onClick={onSubmit}
					fluid
					type="submit"
					id="customer-approval-form-send-button"
				>
					Send to a Customer
				</Button>
			</div>

			<AddSignatureModal
				onClose={() => {
					handleModalToggle(null);
				}}
				onSaveData={fields.signature.onChange}
				data={fields.signature.value}
				open={modal === 'addSignatureModal'}
			/>
		</form>
	);
}
