import React, { memo } from 'react';
import classNames from 'classnames';
import ReactSVG from 'react-svg';
import types from 'prop-types';

// Styles

import './styles.scss';

// ----------------

// Type of props

SvgIcon.propTypes = {
	size: types.oneOf(['xs', 'sm', 'md', 'lg', 'xl', 'xxl', 'xxxl', 'auto']),
	url: types.string.isRequired,
};

// Default value for props

SvgIcon.defaultProps = {
	size: 'md',
};

// ----------------

function SvgIcon(props) {
	const { size, url } = props;

	// Modify styles

	const modify = classNames({
		[` svg-icon--${size}`]: size,
	});

	// Render

	return (
		<div className={`svg-icon${modify}`}>
			<ReactSVG src={url} />
		</div>
	);
}

export default memo(SvgIcon);
