import types from 'prop-types';
import React from 'react';

// Components

import FontIcon from '../../../components/block/FontIcon';
import Button from '../../../components/ui/Button';

// Styles

import './styles.scss';

// ----------------

// Type of props

TutorialStep.propTypes = {
	tooltipProps: types.object,
	primaryProps: types.object,
	backProps: types.object,
	skipProps: types.object,
	index: types.oneOfType([types.string, types.number]),
	step: types.object,
	size: types.oneOfType([types.string, types.number]),
};

// Default value for props

TutorialStep.defaultProps = {};

// ----------------

export default function TutorialStep(props) {
	const { tooltipProps, primaryProps, backProps, skipProps, index, step, size } = props;

	return (
		<div className="tutorial-step" {...tooltipProps}>
			<div className="tutorial-step__close-btn" {...skipProps}>
				<FontIcon icon="close" />
			</div>
			<div className="tutorial-step__steps">
				{index + 1}/{size}
			</div>
			<div className="tutorial-step__title">{step.title}</div>
			<div className="tutorial-step__content">{step.content}</div>
			<div className="tutorial-step__buttons-grid">
				<div className="tutorial-step__button">
					<Button
						fluid
						theme="secondary"
						size="sm"
						id="tutorial-step__next-button"
						{...primaryProps}
					>
						{index + 1 < size ? `Next` : 'Finish'}
					</Button>
				</div>
				{index > 0 ? (
					<div className="tutorial-step__button">
						<Button
							reverseType
							theme="secondary"
							fluid
							size="sm"
							id="tutorial-step__previous-button"
							{...backProps}
						>
							Previous
						</Button>
					</div>
				) : (
					<div className="tutorial-step__button">
						<Button
							reverseType
							theme="secondary"
							fluid
							size="sm"
							id="tutorial-step__skip-button"
							{...skipProps}
						>
							Skip
						</Button>
					</div>
				)}
			</div>
		</div>
	);
}
