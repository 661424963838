import classNames from 'classnames';
import types from 'prop-types';
import React from 'react';

// Components

import FontIcon from '../../../components/block/FontIcon';

// Styles

import './styles.scss';

// ----------------

// Type of props

Dropdown.propTypes = {
	handleToggle: types.func.isRequired,
	isShowing: types.bool.isRequired,
	actions: types.arrayOf(
		types.exact({
			onClick: types.func.isRequired,
			active: types.bool,
			label: types.string.isRequired,
			icon: types.string.isRequired,
			id: types.string.isRequired,
		})
	),
	button: types.node.isRequired,
};

// Default value for props

Dropdown.defaultProps = { isShowing: false };

// ----------------

export default function Dropdown(props) {
	const { handleToggle, isShowing, actions, button } = props;

	// Modify styles

	const modify = classNames({
		' dropdown__wrapper--is-showing': isShowing,
	});

	// Render

	return (
		<div className={`dropdown__wrapper${modify}`}>
			{button}
			{isShowing && (
				<>
					<ul className="dropdown">
						{actions.map(({ onClick, active, label, icon, id }) => {
							const modifyItem = classNames({
								' dropdown__action--active': active,
							});

							return (
								<li
									className={`dropdown__action${modifyItem}`}
									onClick={async () => {
										await handleToggle();
										await onClick();
									}}
									key={id}
								>
									<FontIcon size="xl" icon={icon} />
									{!!label && <p className="action__label">{label}</p>}
								</li>
							);
						})}
					</ul>
					<div className="dropdown__backdrop" onClick={handleToggle} />
				</>
			)}
		</div>
	);
}
