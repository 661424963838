import React from 'react';

// Utils

import { getCustomSketchData } from '../../../../utils';

// Components

import { MatPreviewHeader, MatPreviewAside, Container } from '../../../../components';

// Static

import browserHistory from '../../../../static/global';

// Action creators

import { setActiveSketchPreview } from '../../../../redux/actionCreators/sketch';
import { setMatName } from '../../../../redux/actionCreators/mat';
import { toggle } from '../../../../redux/actionCreators/ui';

// Styles

import './styles.scss';

// ----------------

export default function MatPreviewStruct(props) {
	const { history } = browserHistory;

	return (
		<>
			<div className="mat-preview-struct">
				<Container
					mapDispatch={{ setActiveSketchPreview, setMatName, toggle }}
					mapState={(state) => ({
						sketchName: getCustomSketchData(state.sketch.sketchData.sketchRequestList[0])
							.sketchName,
						sketchID: getCustomSketchData(state.sketch.sketchData.sketchRequestList[0])
							.sketchID,
					})}
					render={({
						setActiveSketchPreview,
						sketchName,
						setMatName,
						sketchID,
						toggle,
						...cProps
					}) => (
						<MatPreviewHeader
							className="mat-preview-struct__header"
							onVariation={() => {
								setActiveSketchPreview(sketchID);
								setMatName(sketchName);
								toggle('variationSketchModal');
							}}
							onApprove={() => {
								setActiveSketchPreview(sketchID);
								toggle('customerApprovalModal');
							}}
							onBack={() => history.goBack()}
							{...cProps}
						/>
					)}
				/>
				<Container
					mapState={(state) => ({
						sketch: getCustomSketchData(state.sketch.sketchData.sketchRequestList[0]),
					})}
					render={({
						sketch: {
							colorListRgb,
							variationOf,
							sketchName,
							matDetails,
							sketchRef,
							image,
							bgRgb,
						},
					}) => (
						<>
							<MatPreviewAside
								sketchReference={sketchRef}
								colorsList={colorListRgb}
								sketchName={sketchName}
								variation={variationOf === 0 ? '-' : variationOf}
								className="mat-preview-struct__aside"
								details={matDetails}
								bgColor={bgRgb}
							/>

							<main className="mat-preview-struct__content">
								<div className="preview-image__container">
									<div className="preview-image__image-wrapper">
										<img className="img-cover" src={image} alt="sketch preview" />
									</div>
								</div>
							</main>
						</>
					)}
				/>
			</div>
		</>
	);
}
