export default function getDesignAdditions(mat) {
	const { border, objects } = mat;
	const info = [];

	if (border.isShow) info.push(`Border color "${border.color}"`);

	const objectKeys = Object.keys(objects);

	if (objectKeys.length) {
		objectKeys.forEach((key) => {
			const object = objects[key];

			if (object.objectType === 'text') {
				// Text

				info.push(`Text: "${object.text}"`);
				info.push(`Font family: "${object.fontFamily.replace(/_/g, ' ')}"`);

				// Text style

				if (object.bold || object.italic) {
					info.push(
						`Font style: "${object.bold ? 'Bold' : ''}${
							object.bold && object.italic ? ' Italic' : object.italic ? 'Italic' : ''
						}"`
					);
				}

				// Text color
				info.push(`Font color: "${object.fontColor}"`);
				object.fontBGColor && info.push(`Background color: "${object.fontBGColor}"`);
			}
		});
	}

	return info;
}
