import classNames from 'classnames';
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';

// Actions

import { undoRedo } from '../../../../redux/actionCreators/mat';

// Hooks

import { useWindowWidth } from '../../../../hooks';

// Components

import FontIcon from '../../../../components/block/FontIcon';
import TooltipHoverView from '../../../../components/block/TooltipHoverView';

// Data

import data from './data';

// Styles

import './styles.scss';

// ----------------

export default function MatAdditionalTools(props) {
	const dispatch = useDispatch();

	const { toggleAdditionalTools, additionalTools, dragMode } = props;
	const arr = data(props);

	useEffect(() => {
		document.addEventListener('keydown', handleKeyPress);

		return () => {
			document.removeEventListener('keydown', handleKeyPress);
		};
	});

	const handleKeyPress = (event) => {
		if (event.keyCode === 90 && event.ctrlKey) {
			dispatch(undoRedo('undo'));
		}
	};

	// Preparation

	const currentWindowWidth = useWindowWidth();
	const deviceSizeXS = currentWindowWidth <= 576;

	// Modify styles

	const modifyTools = classNames({
		' mat-additional-tools--open': additionalTools,
	});

	return (
		<div className={`mat-additional-tools${modifyTools}`}>
			{((deviceSizeXS && additionalTools) || !deviceSizeXS) &&
				arr.map((item, index) => {
					let group = null;
					if (item) {
						group = item.map((additionalTool) => {
							const modifyIcon = classNames({
								' mat-additional-tools__icon--big-margin':
									!deviceSizeXS && additionalTool.bigMargin,
								' mat-additional-tools__icon--disabled': additionalTool.disabled,
								' mat-additional-tools__icon--active': additionalTool.drag && dragMode,
							});

							return (
								<TooltipHoverView
									placement={deviceSizeXS ? 'top' : 'auto-start'}
									mobileTop={additionalTool.mobileTop}
									render={additionalTool.tooltip}
									key={additionalTool.id}
								>
									<div
										className={`mat-additional-tools__icon${modifyIcon}`}
										id={additionalTool.id}
										{...(!additionalTool.disabled && { onClick: additionalTool.onClick })}
									>
										<FontIcon
											rotate45={additionalTool.rotate45}
											rotate90={additionalTool.rotate90}
											icon={additionalTool.icon}
											size="xl"
										/>
									</div>
								</TooltipHoverView>
							);
						});
					}

					if (!deviceSizeXS && arr.length !== index + 1 && group)
						group.push(<div className="mat-additional-tools__line" key={index} />);

					return group;
				})}

			{deviceSizeXS && (
				<div
					className="mat-additional-tools__icon"
					onClick={() => toggleAdditionalTools()}
				>
					<FontIcon icon={additionalTools ? 'close' : 'more_horiz'} size="xl" />
				</div>
			)}
		</div>
	);
}
