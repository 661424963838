/**
 * Define system constants.
 */

export const GOOGLE_CAPTCHA_SITEKEY = '6Lf6JGEUAAAAAM-pIMrklc5oHK4NIW5xGPtjWZ-t'
export const ENV                    = process.env.REACT_APP_HOST_ENV || "development";
export const NS                     = "@@virtuoso-x"; // Name Space
export const maxMobileSize          = 1024;
export const cookie                 = {
  TOKEN: "token"
};
export const limit                  = {
  IMAGE_ACCEPT: [
    {format: "image/jpeg", displayName: "jpeg"},
    {format: "image/png", displayName: "png"},
    {format: "image/tiff", displayName: "tiff"},
    {format: "application/pdf", displayName: "pdf"}
  ],
  IMAGE_SIZE: (1024 * 1024) * 2, // 2 MB in bytes
  COLORS: 25,
  IMAGES: 2,
};

// Colors types

export const PMS_COLOR      = 202;
export const STANDART_COLOR = 201;

// Mat types

export const matType = {
   PHOTO       : { code: 116, name: "PHOTO", displayName: "Photorealistic" },
   TRADITIONAL : { code: 101, name: "TRADITIONAL", displayName: "Traditional" },
   LYL         : { code: 205, name: "LYL", displayName: "Lay your logo" }
};

// Mat types by "productGroupID"

export const productGroupData = {
	114: 'UltraGuard Logo',
	205: 'Lay Your Logo',
  101: 'Traditional',
  113: "Message Mats",
	115: 'Motif',
	116: 'Photo',
	24: 'First Step Logo Scraper'
};

// Status data

export const statusData = {
	draft: {
		label: 'Draft',
		color: 'for-draft'
	},
	inProcessDraft: {
		label: 'In Process',
		color: 'for-in-process'
	},
	inProcess: {
		id: 249,
		label: 'In Process',
		color: 'for-in-process'
	},
	rejected: {
		id: 226,
		label: 'Rejected',
		color: 'for-rejected'
	},
	interactiveLogo: {
		label: 'Interactive Logo',
		color: 'for-interactive-logo'
	},
	complete: {
		id: 205,
		label: 'Complete',
		color: 'for-complete'
	}
};

// Replace/Remove color settings (Threshold, Blur, Simplification) 

export const DEFAULT_REPLACE_COLOR = 'rgb(255, 255, 255)';
export const IMAGE_MAGIC_SPOT_THRESHOLD = 15;
export const SIMPLIFY_TOLERANT = 0;
export const SIMPLIFY_COUNT = 100;
export const IMAGE_BLUR = 1;
