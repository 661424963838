import { NS } from "../../static/constants";

// ----------------

const TYPE_NAME = "MATS";

export const DELETE_SKETCH_FROM_FAVOURITE_BY_ID       = `${NS}/${TYPE_NAME}/DELETE_SKETCH_FROM_FAVOURITE_BY_ID`;
export const REMOVE_SKETCH_FAVOURITE_FLAG_BY_ID       = `${NS}/${TYPE_NAME}/REMOVE_SKETCH_FAVOURITE_FLAG_BY_ID`;
export const ADD_SKETCH_FAVOURITE_FLAG_BY_ID          = `${NS}/${TYPE_NAME}/ADD_SKETCH_FAVOURITE_FLAG_BY_ID`;
export const INCREMENT_FAVOURITE_QUANTITY             = `${NS}/${TYPE_NAME}/INCREMENT_FAVOURITE_QUANTITY`;
export const DECREMENT_FAVOURITE_QUANTITY             = `${NS}/${TYPE_NAME}/DECREMENT_FAVOURITE_QUANTITY`;
export const SHOW_LOAD_MORE_BUTTON                    = `${NS}/${TYPE_NAME}/SHOW_LOAD_MORE_BUTTON`;
export const DELETE_DRAFT_BY_ID                       = `${NS}/${TYPE_NAME}/DELETE_DRAFT_BY_ID`;
export const RESTORE_BRANCH                           = `${NS}/${TYPE_NAME}/RESTORE_BRANCH`;

// Network

export const GET_FAVOURITE_SKETCHES_QUANTITY_$NETWORK = `${NS}/${TYPE_NAME}/GET_FAVOURITE_SKETCHES_QUANTITY_$NETWORK`;
export const DELETE_SKETCH_FROM_FAVOURITE_$NETWORK    = `${NS}/${TYPE_NAME}/DELETE_SKETCH_FROM_FAVOURITE_$NETWORK`;
export const GET_MORE_FAVOURITE_SKETCHES_$NETWORK     = `${NS}/${TYPE_NAME}/GET_MORE_FAVOURITE_SKETCHES_$NETWORK`;
export const GET_MORE_LOCATION_SKETCHES_$NETWORK      = `${NS}/${TYPE_NAME}/GET_MORE_LOCATION_SKETCHES_$NETWORK`;
export const ADD_SKETCH_TO_FAVOURITE_$NETWORK         = `${NS}/${TYPE_NAME}/ADD_SKETCH_TO_FAVOURITE_$NETWORK`;
export const GET_FAVOURITE_SKETCHES_$NETWORK          = `${NS}/${TYPE_NAME}/GET_FAVOURITE_SKETCHES_$NETWORK`;
export const GET_LOCATION_SKETCHES_$NETWORK           = `${NS}/${TYPE_NAME}/GET_LOCATION_SKETCHES_$NETWORK`;
export const GET_MORE_SEARCH_MATS_$NETWORK            = `${NS}/${TYPE_NAME}/GET_MORE_SEARCH_MATS_$NETWORK`;
export const GET_MORE_SKETCHES_$NETWORK               = `${NS}/${TYPE_NAME}/GET_MORE_SKETCHES_$NETWORK`;
export const GET_SEARCH_MATS_$NETWORK                 = `${NS}/${TYPE_NAME}/GET_SEARCH_MATS_$NETWORK`;
export const GET_MORE_DRAFTS_$NETWORK                 = `${NS}/${TYPE_NAME}/GET_MORE_DRAFTS_$NETWORK`;
export const GET_SKETCHES_$NETWORK                    = `${NS}/${TYPE_NAME}/GET_SKETCHES_$NETWORK`;
export const GET_DRAFTS_$NETWORK                      = `${NS}/${TYPE_NAME}/GET_DRAFTS_$NETWORK`;