import types from 'prop-types';
import React from 'react';

// Components

import FontIcon from '../../../../components/block/FontIcon';

// Styles

import './styles.scss';

// ----------------

// Type of props

MatPreviewHeader.propTypes = {
	onVariation: types.func.isRequired,
	onApprove: types.func.isRequired,
	className: types.string,
	onBack: types.func.isRequired,
};

// Default value for props

MatPreviewHeader.defaultProps = {
	onVariation: () => null,
	onApprove: () => null,
	onBack: () => null,
};

// ----------------

export default function MatPreviewHeader(props) {
	const { onVariation, onApprove, className, onBack } = props;

	return (
		<header className={`${className} mat-preview-header`}>
			<div className="mat-preview-header__inner-struct-left">
				<div className="mat-preview-header__title">
					<div className="mat-preview-header__back-icon" onClick={onBack}>
						<FontIcon icon="chevron_left" size="xxxxl" />
					</div>
					<div className="mat-preview-header__title-inner-struct-right">
						<h4 className="mat-preview-header__name">Sketch Preview</h4>
						<div className="mat-preview-header__status text-small">Complete</div>
					</div>
				</div>
			</div>
			<div className="mat-preview-header__inner-struct-right">
				<button className="mat-preview-header__button" onClick={onApprove}>
					<FontIcon icon="verified_user" size="xxl" />
					<span>Customer Approval</span>
				</button>
				<button
					className="mat-preview-header__button mat-preview-header__button--active"
					onClick={onVariation}
				>
					<FontIcon icon="content_copy" size="xxl" />
					<span>Variation</span>
				</button>
			</div>
		</header>
	);
}
