import classNames from 'classnames';
import React from 'react';
import types from 'prop-types';

// Components

import FontIcon from '../../../components/block/FontIcon';

// Assets

import transparentDark from '../../../assets/icons/transparent-color.svg';

// Styles

import './styles.scss';

// ----------------

// Type of props

SelectImage.propTypes = {
	onCrossClick: types.func,
	imageUrl: types.string,
	onClick: types.func,
	label: types.string.isRequired,
	size: types.oneOf(['sm', 'xl']),
	id: types.string.isRequired,
};

// ----------------

export default function SelectImage(props) {
	const { onCrossClick, imageUrl, onClick, label, size, id } = props;

	// Handlers

	function handleCrossClick(e) {
		e.stopPropagation();
		onCrossClick(imageUrl);
	}

	// Modify styles

	const modify = classNames({
		[` select-image--size-${size}`]: size,
		' select-image--selected': imageUrl,
	});

	const modifyName = classNames({
		' select-image__name--selected': imageUrl,
	});

	// Render

	return (
		<div
			onClick={onClick}
			className={`select-image${modify}`}
			id={id}
			style={{
				background: imageUrl ? 'rgb(255, 255, 255)' : `url(${transparentDark})`,
			}}
		>
			{imageUrl && onCrossClick && (
				<div className="select-image__icon-btn" onClick={handleCrossClick}>
					<FontIcon size="xs" icon="close" />
				</div>
			)}
			{imageUrl && (
				<div
					className="select-image__icon"
					style={{ backgroundImage: `url(${imageUrl})` }}
				/>
			)}
			<div className={`select-image__name${modifyName}`}>
				{imageUrl ? 'Replace image' : label}
			</div>
		</div>
	);
}
