import arrayMove from 'array-move';
import * as types from '../types/mat';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';

// Static

import { limit, PMS_COLOR, STANDART_COLOR, matType } from '../../static/constants';
import { draft, submit as submitPayload } from '../../static/payloads/mats';
import notification from '../../static/data/notification';
import endpoints from '../../static/endpoints/mat';
import browserHistory from '../../static/global';

// Selectors

import getDesignAdditions from '../selectors/getDesignAdditions';
import getMatDataForSave from '../selectors/getMatDataForSave';
import getSelectedId from '../selectors/getSelectedId';
import getById from '../selectors/getById';
import {
	getMat,
	getMatObjects,
	getMatObjectsById,
	getEditTextId,
} from '../selectors/mat';

import { getUser } from '../selectors/user';

// Action creators

import { startLoading, endLoading } from './system';
import { deleteDraftById } from './mats';
import { successMsgs, errorMsgs } from './systemNotifications';

// Utils

import toBase64 from '../../utils/toBase64';
import color from '../../utils/color';

// ----------------

const zoomStep = 0.1;
const zoomMax = 2.7;
const zoomMin = 0.7;

// -------- Classic action creators --------

// Mat ready

export const matReady = () => ({
	type: types.MAT_READY,
});

// Add object

export const addObject = (payload) => ({
	type: types.ADD_OBJECT,
	payload: { ...payload },
});

// Edit object

export const _editObject = (payload) => ({
	type: types.EDIT_OBJECT,
	payload,
});

// Sync object

export const syncObject = (payload) => ({
	type: types.SYNC_OBJECT,
	payload,
});

// Sync objects

export const syncObjects = (payload) => ({
	type: types.SYNC_OBJECTS,
	payload,
});

// Delete object

export const delObject = (id, save = true) => ({
	type: types.DEL_OBJECT,
	payload: { id, save },
});

// Set color/image background option

export const setBGOption = (option, saved = false) => ({
	type: types.SET_BG_OPTION,
	payload: { option, saved },
});

// Set bg color

export const setBGColor = (color) => ({
	type: types.SET_BG_COLOR,
	payload: { color },
});

// Del bg color

export const delBGColor = () => ({
	type: types.DEL_BG_COLOR,
});

// Set tool

export const setTool = (toolName) => ({
	type: types.SET_TOOL,
	payload: { toolName },
});

// Set tool height

export const setToolHeight = (height) => ({
	type: types.SET_TOOL_HEIGHT,
	payload: { height },
});

// Toggle additional tools

export const toggleAdditionalTools = () => ({
	type: types.TOGGLE_ADDITIONAL_TOOLS,
});

// Set active object

export const setActiveObject = (id = null, type = null) => ({
	type: types.SET_ACTIVE_OBJECT,
	payload: { id, type },
});

// Set modal type

export const setModalType = (name) => ({
	type: types.SET_MODAL_TYPE,
	payload: { name },
});

// Toggle modal

export const toggleModal = (name) => ({
	type: types.TOGGLE_MODAL,
	payload: { name },
});

// Set edit text id

export const setEditTextId = (id) => ({
	type: types.SET_EDIT_TEXT_ID,
	payload: { id },
});

// Toggle border

export const toggleBorder = () => ({
	type: types.TOGGLE_BORDER,
});

// Set border color

export const setBorderColor = (color) => ({
	type: types.SET_BORDER_COLOR,
	payload: { color },
});

// Zoom

export const zoom = (zoom) => ({
	type: types.ZOOM,
	payload: { zoom },
});

// Toggle drag mode

export const toggleDragMode = () => ({
	type: types.TOGGLE_DRAG_MODE,
});

// Set drag position

export const setDragPosition = (x, y) => ({
	type: types.SET_DRAG_POSITION,
	payload: { x, y },
});

// Set drag default position

export const setDragDefPosition = () => ({
	type: types.SET_DRAG_DEF_POSITION,
});

// Set colors

export const setColors = (colors) => ({
	type: types.SET_COLORS,
	payload: { colors },
});

// Select color

export const selectColor = (color) => ({
	type: types.SELECT_COLOR,
	payload: { color },
});

// Select multiply colors

export const selectMultiplyColors = (colors) => ({
	type: types.SELECT_MULTIPLY_COLORS,
	payload: { colors },
});

// Deselect color

export const deselectColor = (color) => ({
	type: types.DESELECT_COLOR,
	payload: { color },
});

// Clear colors

export const clearColors = () => ({
	type: types.CLEAR_COLORS,
});

// Toggle Suggested Colors

export const toggleSuggestedColors = () => ({
	type: types.TOGGLE_SUGGESTED_COLORS,
});

// Close submit

export const closeSubmit = () => ({
	type: types.CLOSE_SUBMIT,
});

// Set mat info

export const setMatInfo = (matInfo) => ({
	type: types.SET_MAT_INFO,
	payload: matInfo,
});

// Set restore mat

export const setRestoreMat = (payload) => ({
	type: types.RESTORE_MAT,
	payload,
});

// Restore mat history

export const restoreMatHistory = (payload) => ({
	type: types.RESTORE_MAT_HISTORY,
	payload,
});

// Toggle image filters

export const setImageFiltersType = (filtersType) => ({
	type: types.TOGGLE_IMAGE_FILTERS,
	payload: { filtersType },
});

// Show Mat Tutorial

export const toggleTutorial = () => ({
	type: types.SHOW_TUTORIAL,
});

// Set size

export const setSize = (id) => ({
	type: types.SET_SIZE,
	payload: { id },
});

// Set landscape orientation

export const setLandscapeOrientation = () => ({
	type: types.SET_ORIENTATION_L,
});

// Set portrait orientation

export const setPortraitOrientation = () => ({
	type: types.SET_ORIENTATION_P,
});

// Set mat name

export const setMatName = (name) => ({
	type: types.SET_MAT_NAME,
	payload: { name },
});

// Set mat type

export const setMatType = (type) => ({
	type: types.SET_MAT_TYPE,
	payload: { type },
});

// Restore branch

export const restoreBranch = (type) => ({
	type: types.RESTORE_BRANCH,
});

// Add snapshots to history

export const addSnapshotsToHistory = (snapshots, position) => ({
	type: types.ADD_SNAPSHOTS_TO_HIS,
	payload: { snapshots, position },
});

// Reload history after save

export const reloadHistoryAfterSave = () => ({
	type: types.RELOAD_HIS_AFTER_SAVE,
});

// Set sizes

export const setSizes = (payload) => ({
	type: types.SET_SIZES,
	payload,
});

// Set LYL backgrounds

export const setLYLBackgrounds = (payload) => ({
	type: types.SET_LYL_IMAGES,
	payload,
});

// Set background image

export const setBGImage = (image, json) => ({
	type: types.SET_BG_IMAGE,
	payload: { image, json },
});

// Delete background image

export const delBGImage = () => ({
	type: types.DEL_BG_IMAGE,
});

// Set extracted colors

export const setExtractedColors = (colors) => ({
	type: types.SET_EXTRACTED_COLORS,
	payload: { colors },
});

// Set temporary background image for editing

export const setEditBG = (image, restore = false, objectId = null) => ({
	type: types.SET_EDIT_BG,
	payload: { image, restore, objectId },
});

// Delete temporary background image

export const delEditBG = () => ({
	type: types.DEL_EDIT_BG,
});

// -------- Async action creators --------

// Add image object

export const addImageObject = (
	image,
	params = { visible: true },
	closeModal = true
) => async (store) => {
	if (/^data:image/.test(image)) {
		callBack(image);
	} else {
		toBase64(image, callBack, store.dispatch);
	}

	async function callBack(url) {
		const {
			extractedColors,
			nextZIndex: zIndex,
			matType: currentMatType,
			colors: { standart: standartColors },
			nextId: id,
		} = getMat(store.getState());

		const fabricImageObj = await store
			.getState()
			.matCanvas.canvas.createImageFabricObject({ url, ...params, id }, !params.visible);

		const newParams = getParamsFromFabricObject(fabricImageObj);

		store.getState().matCanvas.canvas.canvas.add(fabricImageObj);

		store.dispatch(addObject({ ...newParams, url, zIndex }));

		// New image added to a canvas should be always placed under text layers
		const newObjects = getMatObjects(store.getState());

		const data = Object.keys(newObjects)
			.map((key) => ({
				zIndex: newObjects[key].zIndex,
				type: newObjects[key].objectType,
				id: newObjects[key].id,
			}))
			.sort((a, b) => (a.zIndex > b.zIndex ? -1 : 1));
		const newIndex =
			data[data.length - 1].type === 'image' ? data.length - 2 : data.length - 1;
		const newSequence = arrayMove(data, 0, newIndex).map((obj) => obj.id);
		store.dispatch(setZIndex(newSequence));

		closeModal && setTimeout(() => store.dispatch(toggleModal('image')), 200);

		// Extracted colors

		if (currentMatType === matType.TRADITIONAL.name) {
			setTimeout(async () => {
				const imageColors = await color.getImageColors(url);

				const newExtractedColors = color.getColorsInRange(imageColors, standartColors);

				store.dispatch(
					setExtractedColors(new Set([...extractedColors, ...newExtractedColors]))
				);
			}, 400);
		}
	}
};

// Add new image to Edit Background Image modal

export const addEditBG = (data) => async (store) => {
	const { modalType } = getMat(store.getState());
	const isImageFromMat = typeof data === 'object' && data.hasOwnProperty('url');
	let isTransparent;

	if (typeof data === 'string') {
		isTransparent = await color.isImageTransparent(
			'https://cors-anywhere.herokuapp.com/' + data
		);
	} else if (isImageFromMat) {
		isTransparent = await color.isImageTransparent(data.url);
	} else {
		// Get image data from File

		function readFile(data) {
			return new Promise((resolve) => {
				var reader = new FileReader();
				reader.onload = () => {
					resolve(color.isImageTransparent(reader.result));
				};
				reader.readAsDataURL(data);
			});
		}

		isTransparent = await readFile(data);
	}

	if (isTransparent) {
		store.dispatch(
			errorMsgs({
				message: notification.selectTransparentImageAsBgError,
			})
		);

		return;
	}

	function handleModal() {
		store.dispatch(toggleModal('editBackgroundImage'));
		if (modalType !== 'addImageToMat') {
			store.dispatch(setModalType('addImageToMat'));
		}
	}

	if (isImageFromMat) {
		store.dispatch(setEditBG(data.url, false, data.id));
		handleModal();
	} else {
		toBase64(
			data,
			(base64) => {
				store.dispatch(setEditBG(base64));
				handleModal();
			},
			store.dispatch
		);
	}
};

// Add current background to Edit Background Image modal

export const addEditBGFromCurrentBg = (data) => (store) => {
	const objects = getMatObjects(store.getState());

	const selectedId = getSelectedId(objects);
	store.dispatch(setEditBG(data, true, selectedId));
	store.dispatch(toggleModal('editBackgroundImage'));
};

// Add text object

export const addTextObject = (text, params = {}) => async (store) => {
	const { nextId: id, nextZIndex: zIndex } = getMat(store.getState());

	const fabricTextObj = await store.getState().matCanvas.canvas.createTextFabricObject({
		fontFamily: 'Roboto',
		fontColor: '108',
		text,
		...params,
		id,
	});

	const newParams = getParamsFromFabricObject(fabricTextObj);
	store.getState().matCanvas.canvas.canvas.add(fabricTextObj);

	store.dispatch(addObject({ ...newParams, zIndex }));
	store.dispatch(matSnapshots());
};

// Edit object

export const editObject = (params, save = true) => async (store) => {
	const matCanvas = store.getState().matCanvas.canvas;

	const fabricObj = matCanvas.objects[params.id];
	const obj = getMatObjectsById(store.getState(), params.id);

	//it is necessary that the text does not go out on its background color if we enabled italic
	const text = get(obj, ['text'], '');
	const lastSymbol = isEmpty(text) ? '' : obj.text[text.length - 1];
	const isItalic = get(obj, ['italic'], false);

	if ((params.italic || isItalic) && lastSymbol !== ' ') {
		obj.text += ' ';
	} else if (!isEmpty(obj.text) && params.italic === false) {
		obj.text = obj.text.trim();
	}

	const newParams = { ...obj, ...params };
	await matCanvas.setParamsForFabricObject(fabricObj, newParams);

	fabricObj.setCoords();
	matCanvas.canvas.renderAll(fabricObj);

	store.dispatch(_editObject(newParams));
	save && store.dispatch(matSnapshots());
};

// Sync object params

export const syncObjectParams = (object) => (store) => {
	const params = getParamsFromFabricObject(object);

	store.dispatch(syncObject(params));
	store.dispatch(matSnapshots());
};

// Sync objects params

export const syncObjectsParams = (array) => (store) => {
	const objects = getMatObjects(store.getState());
	const newObjects = {};

	array.forEach((object) => {
		const params = getParamsFromFabricObject(object);
		newObjects[params.id] = { ...objects[params.id], ...params };
	});

	store.dispatch(syncObjects(newObjects));
	store.dispatch(matSnapshots());
};

// Delete active object

export const deleteActiveObject = () => (store) => {
	const { activeObjectId, objects, matType: currentMatType } = getMat(store.getState());

	const { objectType } = objects[activeObjectId];

	if (activeObjectId) {
		store.dispatch(delObject(activeObjectId));

		if (objectType === 'image' && currentMatType === matType.TRADITIONAL.name) {
			setTimeout(async () => {
				store.dispatch(updateExtractedColors());
			}, 400);
		}
	}
};

// Copy active object

export const copyActiveObject = () => (store) => {
	const mat = getMat(store.getState());

	if (mat.activeObjectId) {
		const object = mat.objects[mat.activeObjectId];

		if (object.objectType === 'image' && mat.imageCount === limit.IMAGES) {
			store.dispatch(toggleModal('attention-images-limit'));
			return;
		}

		const copy = {
			...object,
			left: object.left + 40,
			top: object.top + 40,
		};

		if (object.objectType === 'text') store.dispatch(addTextObject(copy.text, copy));
		else store.dispatch(addImageObject(copy.url, copy, false));
	}
};

// Rotate active object

export const rotateActiveObject = (direction = 'left') => (store) => {
	const mat = getMat(store.getState());
	const activeObjectId = mat.activeObjectId;

	if (activeObjectId) {
		const angle = mat.objects[activeObjectId].angle;

		store.dispatch(
			editObject({
				angle: direction === 'left' ? angle - 90 : angle + 90,
				id: activeObjectId,
			})
		);
	}
};

// Align active object

export const alignActiveObject = (to = 'center') => (store) => {
	const { activeObjectId } = getMat(store.getState());

	if (activeObjectId) {
		const fabricObject = store.getState().matCanvas.canvas.objects[activeObjectId];

		switch (to) {
			case 'center': {
				store.getState().matCanvas.canvas.canvas.viewportCenterObject(fabricObject);
				break;
			}

			case 'centerV': {
				store.getState().matCanvas.canvas.canvas.viewportCenterObjectV(fabricObject);
				break;
			}

			case 'centerH': {
				store.getState().matCanvas.canvas.canvas.viewportCenterObjectH(fabricObject);
				break;
			}

			default:
		}

		store.getState().matCanvas.canvas.canvas.renderAll();

		store.dispatch(syncObjectParams(fabricObject));
	}
};

// Default drag

export const defaultDrag = () => (store) => {
	const { canvas } = store.getState().matCanvas.canvas;

	canvas.viewportTransform[4] = 0;
	canvas.viewportTransform[5] = 0;
	canvas.renderAll();

	for (let key in store.getState().matCanvas.canvas.objects) {
		store.getState().matCanvas.canvas.objects[key].setCoords();
	}
};

// Set z-index

export const setZIndex = (newSequence) => (store) => {
	const { objects, nextZIndex } = getMat(store.getState());

	const newObjects = { ...objects };
	let zIndex = nextZIndex - 1;

	newSequence.forEach((id) => (newObjects[id].zIndex = zIndex--));

	store.dispatch({ type: types.SET_Z_INDEX, payload: newObjects });
};

// Edit text

export const editText = (text = '') => (store) => {
	const editTextId = getEditTextId(store.getState());

	if (editTextId) {
		const fabricObject = store.getState().matCanvas.canvas.objects[editTextId];

		fabricObject.set({ text });
		store.getState().matCanvas.canvas.canvas.renderAll();

		store.dispatch(syncObjectParams(fabricObject));
	}
};

// Select color by limit

export const selectColorByLimit = (payload) => (store) => {
	const { colorsCount } = getMat(store.getState());

	if (colorsCount < limit.COLORS) {
		store.dispatch(selectColor(payload));
	}
};

// Set BG color by limit

export const setBGColorByLimit = (payload) => (store) => {
	const { colorsCount, bgColor } = getMat(store.getState());

	if (colorsCount < limit.COLORS || bgColor) {
		store.dispatch(setBGColor(payload));
	}
};

// Set font color

export const setFontColor = (fontColor) => (store) => {
	const { activeObjectId } = getMat(store.getState());

	store.dispatch(editObject({ fontColor, id: activeObjectId }));
};

// Set font bg color

export const setFontBGColor = (fontBGColor) => (store) => {
	const { activeObjectId } = getMat(store.getState());
	store.dispatch(editObject({ fontBGColor, id: activeObjectId }));
};

// Toggle font bold

export const toggleFontBold = () => (store) => {
	const { activeObjectId, objects } = getMat(store.getState());

	store.dispatch(editObject({ bold: !objects[activeObjectId].bold, id: activeObjectId }));
};

// Toggle font italic

export const toggleFontItalic = () => (store) => {
	const { activeObjectId, objects } = getMat(store.getState());

	store.dispatch(
		editObject({ italic: !objects[activeObjectId].italic, id: activeObjectId })
	);
};

// Set font family

export const setFontFamily = (fontFamily) => (store) => {
	const { activeObjectId } = getMat(store.getState());

	store.dispatch(editObject({ fontFamily, id: activeObjectId }));
};

// Save draft

export const saveDraft = (action = true) => async (store) => {
	const user = getUser(store.getState());
	const mat = getMat(store.getState());

	const { pathname } = browserHistory.history.location;
	const { canvas } = store.getState().matCanvas.canvas;

	store.dispatch(startLoading());

	let res, fabricBorder;
	const id = pathname.split('/')[2];

	// Create canvas data

	const jsonData = JSON.stringify(getMatDataForSave(mat));

	// Set zoom

	store.getState().matCanvas.canvas.zoom(3);

	// Create fabric border for preview image

	if (mat.border.isShow) {
		fabricBorder = store.getState().matCanvas.canvas.createFabricBorder();

		canvas.add(fabricBorder);
		canvas.discardActiveObject();
	}

	// Create request body

	const body = {
		...draft,
		sketchImage: store
			.getState()
			.matCanvas.canvas.canvas.toDataURL({
				format: 'png',
			})
			.replace('data:image/png;base64,', ''),
		logoMatType: matType[mat.matType].code,
		placedbyId: user.userData.UserID,
		locationId: user.userData.CustomerID,
		jsonData,
		realName: mat.matInfo.sketchName,
		sizeId: getById(mat.matSizes, 'id', mat.selectedSizeId).value,
	};

	// Remove fabric border for preview image

	if (mat.border.isShow) {
		canvas.remove(fabricBorder);
	}

	// Set current zoom

	store.getState().matCanvas.canvas.zoom(mat.zoom);

	// Request

	if (id) {
		res = await store.dispatch(updateDraft$network(body, id));
	} else {
		res = await store.dispatch(saveDraft$network(body));
	}

	store.dispatch(reloadHistoryAfterSave());

	setTimeout(() => {
		store.dispatch(endLoading());
	}, 1000);

	setTimeout(() => {
		store.dispatch(
			successMsgs({
				message: notification.saveDraftSuccess,
				...(action
					? {
							action: {
								label: 'Return to dashboard',
								callback: () => store.dispatch(leaveMat(false)),
							},
					  }
					: {}),
			})
		);
	}, 1500);

	return res;
};

// Get colors

export const getColors = () => async (store) => {
	if (!store.getState().mat.colors.standart.length) {
		if (!localStorage.getItem('colors')) {
			const colors = { pms: [], standart: [] };
			const res = await store.dispatch(getColors$network());

			res.result.data.lstcolormaster.forEach((color) => {
				const colorObj = {
					name: color.colorCode,
					green: +color.green,
					blue: +color.blue,
					red: +color.red,
				};

				if (color.mcColorTypeID === PMS_COLOR) colors.pms.push(colorObj);
				else if (color.mcColorTypeID === STANDART_COLOR) colors.standart.push(colorObj);
			});

			store.dispatch(setColors(colors));
			localStorage.setItem('colors', JSON.stringify(colors));
		} else {
			store.dispatch(setColors(JSON.parse(localStorage.getItem('colors'))));
		}
	}
};

// Leave mat

export const leaveMat = (save = true) => async (store) => {
	save && (await store.dispatch(saveDraft(false)));

	browserHistory.history.push('/dashboard');
};

// Mat snapshots

export const matSnapshots = (restore) => (store) => {
	const mat = getMat(store.getState());
	const { historyPosition } = mat;
	const newObjects = {};
	const maxLength = 20;

	Object.keys(mat.objects).forEach((key) => {
		newObjects[mat.objects[key].id] = { ...mat.objects[key] };
	});

	const newSnapshot = {
		selectedSizeId: mat.selectedSizeId,
		matOrientation: mat.matOrientation,
		selectedColors: mat.selectedColors,
		tempBgImage: mat.tempBgImage,
		bgImageJSON: mat.bgImageJSON,
		imageCount: mat.imageCount,
		bgOption: mat.bgOption,
		objects: newObjects,
		bgColor: mat.bgColor,
		bgImage: mat.bgImage,
		border: mat.border,
	};

	let newHistoryPosition;
	let newSnapshots;

	if (!restore) {
		let start;

		if (mat.history.length === maxLength) {
			start = 1;
			newHistoryPosition = historyPosition;
		} else {
			start = 0;
			newHistoryPosition = historyPosition + 1;
		}

		newSnapshots = [...mat.history.splice(start, newHistoryPosition), newSnapshot];
	} else {
		newHistoryPosition = 0;
		newSnapshots = [newSnapshot];
	}

	store.dispatch(addSnapshotsToHistory(newSnapshots, newHistoryPosition));
};

// Undo - Redo

export const undoRedo = (type) => (store) => {
	const { canvas } = store.getState().matCanvas.canvas;
	const mat = getMat(store.getState());

	canvas.discardActiveObject();
	canvas.renderAll();

	if (type === 'undo') {
		if (mat.historyPosition > 0) {
			const snapshot = mat.history[mat.historyPosition - 1];

			store.dispatch({ type: types.UNDO });
			store.dispatch(restoreMatHistory(snapshot));
		}
	} else if (mat.history.length !== mat.historyPosition + 1) {
		const snapshot = mat.history[mat.historyPosition + 1];

		store.dispatch({ type: types.REDO });
		store.dispatch(restoreMatHistory(snapshot));
	}
};

// Delete draft

export const deleteDraft = () => async (store) => {
	const id = browserHistory.history.location.pathname.split('/')[2];

	store.dispatch(startLoading());

	await store.dispatch(deleteDraft$network(id));

	setTimeout(() => {
		store.dispatch(endLoading());
		browserHistory.history.push('/dashboard');
	}, 1000);
};

// Submit

export const submit = (data) => async (store) => {
	const mat = getMat(store.getState());
	const user = getUser(store.getState());

	store.dispatch(startLoading());
	let draftId = browserHistory.history.location.pathname.split('/')[2];
	let fabricBorder;

	if (!draftId) {
		const jsonData = JSON.stringify(getMatDataForSave(mat));
		const body = {
			...draft,
			sketchImage: store
				.getState()
				.matCanvas.canvas.toDataURL({
					format: 'png',
				})
				.replace('data:image/png;base64,', ''),
			logoMatType: matType[mat.matType].code,
			placedbyId: user.userData.UserID,
			locationId: user.userData.CustomerID,
			jsonData,
			realName: mat.matInfo.sketchName,
			sizeId: getById(mat.matSizes, 'id', mat.selectedSizeId).value,
		};

		// Save

		const res = await store.dispatch(saveDraft$network(body));

		draftId = res.result.data.draftId;
	}

	const allColors = [...mat.colors.standart, ...mat.colors.pms];
	const matSize = getById(mat.matSizes, 'id', mat.selectedSizeId).value;

	// Set  zoom

	store.getState().matCanvas.canvas.zoom(3);

	// Create fabric border for preview image

	if (mat.border.isShow) {
		fabricBorder = store.getState().matCanvas.canvas.createFabricBorder();

		store.getState().matCanvas.canvas.canvas.add(fabricBorder);
		store.getState().matCanvas.canvas.canvas.discardActiveObject();
	}

	const body = {
		...submitPayload,
		SketchRequest: {
			...submitPayload.SketchRequest,
			ProductGroupID: matType[mat.matType].code, // Mat type code
			LocationID: user.userData.CustomerID,
			SellerID: user.userData.UserID,
			PlacedByID: user.userData.UserID,
			BackGroundColor: mat.bgImage ? null : mat.bgColor,
			AdditionalInfo: getDesignAdditions(mat).join(', '),
			Instructions: mat.matInfo.instructions,
			CompanyName: data.companyName,
			Orientation: mat.matOrientation,
			NoOfColors: mat.colorsCount,
			DesignName: data.sketchName,
			UserName: user.userData.UserName,
			Size: matSize,
		},
		SketchAttributes: [
			// X

			{
				EntitySubPropertyID: null,
				AttributeValue: matSize.slice(0, matSize.indexOf('x')),
				AttributeName: matSize,
				AttributeType: 15,
				EntityID: 17,
			},

			// Y

			{
				EntitySubPropertyID: null,
				AttributeValue: matSize.slice(matSize.indexOf('x') + 1),
				AttributeName: matSize,
				AttributeType: 15,
				EntityID: 16,
			},

			// Orientation

			{
				EntitySubPropertyID: mat.matOrientation === 'Landscape' ? 27 : 26,
				AttributeValue: mat.matOrientation === 'Landscape' ? 'Landscape' : 'Portrait',
				AttributeName: mat.matOrientation === 'Landscape' ? 'Landscape' : 'Portrait',
				AttributeType: 58,
				EntityID: 58,
			},
		],
		Base64File: store
			.getState()
			.matCanvas.canvas.canvas.toDataURL({
				format: 'png',
			})
			.replace('data:image/png;base64,', ''),
		SignatureImage: data.signature,
		OriginalImages: [],
		DraftId: browserHistory.history.location.pathname.split('/')[2],
	};

	// Remove fabric border for preview image

	if (mat.border.isShow) {
		store.getState().matCanvas.canvas.canvas.remove(fabricBorder);
	}

	// Set current zoom

	store.getState().matCanvas.canvas.zoom(mat.zoom);

	function componentToHex(c) {
		const hex = c.toString(16);
		return hex.length === 1 ? '0' + hex : hex;
	}

	function rgbToHex(r, g, b) {
		return '#' + componentToHex(r) + componentToHex(g) + componentToHex(b);
	}

	// BG color

	if (mat.bgColor && !mat.bgImage) {
		const bgColor = getById(allColors, 'name', mat.bgColor);

		body.SketchAttributes.push({
			EntitySubPropertyID: 29,
			AttributeName: rgbToHex(bgColor.red, bgColor.green, bgColor.blue),
			AttributeValue: mat.bgColor,
			AttributeType: 9,
			EntityID: 18,
		});
	}

	// Colors

	if (mat.selectedColors) {
		mat.selectedColors.forEach((name) => {
			const color = getById(allColors, 'name', name);

			body.SketchAttributes.push({
				EntitySubPropertyID: 30,
				AttributeName: rgbToHex(color.red, color.green, color.blue),
				AttributeValue: name,
				AttributeType: 9,
				EntityID: 18,
			});
		});
	}

	// Add original images

	Object.keys(mat.objects).forEach((key) => {
		if (mat.objects[key].objectType === 'image') {
			body.OriginalImages.push(
				mat.objects[key].url.replace(
					/data:image\/png;base64,|data:image\/jpeg;base64,/gi,
					''
				)
			);
		}
	});

	// Submit

	await store.dispatch(submit$network(body));

	setTimeout(() => {
		store.dispatch(endLoading());
		browserHistory.history.push('/dashboard');
		document.body.removeAttribute('style');
	}, 1000);
};

// Open submit

export const openSubmit = () => (store) => {
	const mat = getMat(store.getState());
	let draftIsReady = false;

	switch (matType[mat.matType].name) {
		case matType.TRADITIONAL.name: {
			const { bgColor, selectedColors } = mat;

			if (bgColor && selectedColors.length) draftIsReady = true;
			else store.dispatch(toggleModal('attention-traditional'));

			break;
		}

		case matType.PHOTO.name: {
			const { bgColor, bgImage } = mat;

			if (bgColor || bgImage) draftIsReady = true;
			else store.dispatch(toggleModal('attention-photo'));

			break;
		}

		case matType.LYL.name: {
			draftIsReady = true;

			break;
		}

		default:
	}

	if (draftIsReady) store.dispatch({ type: types.OPEN_SUBMIT });
};

// Simple delete draft

export const simpleDeleteDraft = (id) => async (store) => {
	store.dispatch(startLoading());

	await store.dispatch(deleteDraft$network(id));
	store.dispatch(deleteDraftById(id));

	setTimeout(() => {
		store.dispatch(endLoading());
	}, 1000);
};

// Get sizes

export const getSizes = () => async (store) => {
	const res = await store.dispatch(getSizes$network());

	const sizes = [];

	res.result.data.forEach((obj, id) => {
		sizes.push({ value: obj.sizeData, id: id + 1 });
	});

	store.dispatch(setSizes(sizes));
};

// Get LYL images

export const getLYLImages = () => async (store) => {
	const res = await store.dispatch(getLYLImages$network());
	const bg = [];

	res.result.data.forEach((obj, id) => {
		bg.push({ url: obj.sketchURL, size: obj.attributeName, id: id + 1 });
	});

	store.dispatch(setLYLBackgrounds(bg));
};

// Zoom in

export const zoomIn = () => (store) => {
	const { zoom: zoomValue } = getMat(store.getState());

	if (zoomValue < zoomMax) {
		store.dispatch(zoom(zoomValue + zoomStep));
	}
};

// Zoom out

export const zoomOut = () => (store) => {
	const { zoom: zoomValue } = getMat(store.getState());

	if (zoomValue > zoomMin) {
		store.dispatch(zoom(zoomValue - zoomStep));
	}
};

// Zoom default

export const zoomDefault = () => (store) => {
	const { zoom: zoomValue } = getMat(store.getState());

	if (zoomValue !== 1) {
		store.dispatch(zoom(1));
	}
};

// Toggle layers modal

export const toggleLayersModal = () => (store) => {
	const { canvas } = store.getState().matCanvas.canvas;

	canvas.discardActiveObject();
	canvas.renderAll();

	store.dispatch(toggleModal('layers'));
};

// Restore mat

export const restoreMat = (data) => (store) => {
	let imageCount = 0;

	Object.keys(data.objects).forEach((key) => {
		if (data.objects[key].objectType === 'image') imageCount++;
	});

	store.dispatch(setRestoreMat({ ...data, imageCount }));
};

// Set LYL BG image

export const setLYLBGImage = (data) => (store) => {
	store.dispatch(setLandscapeOrientation());
	setTimeout(() => {
		store.dispatch(setBGImage(data.url));
	}, 0);
	setTimeout(() => {
		store.dispatch(toggleModal('image'));
	}, 0);
};

// Set BG image from object

export const setBGImageFromObject = (image, json) => async (store) => {
	const { bgImage, editBg, editBgObjectId, objects } = getMat(store.getState());

	const isTransparent = await color.isImageTransparent(image);
	if (isTransparent) {
		store.dispatch(
			errorMsgs({
				message: notification.saveTransparentImageAsBgError,
			})
		);

		return false;
	} else {
		setTimeout(() => {
			const selectedId = getSelectedId(objects);

			if (editBgObjectId && editBgObjectId !== selectedId) {
				store.dispatch(editObject({ id: editBgObjectId, visible: false }));
				store.dispatch(matSnapshots());

				if (bgImage) {
					store.dispatch(editObject({ id: selectedId, visible: true }));
				}
			}

			if (!editBgObjectId) {
				store.dispatch(addImageObject(editBg, { visible: false }, false));
				store.dispatch(matSnapshots());
			}

			store.dispatch(setBGImage(image, json));

			// Take a mat snapshot only if background image is set and relevant image object is hidden

			if (bgImage) {
				store.dispatch(matSnapshots());
			}
		}, 0);

		return true;
	}
};

// Add image object from BG

export const addImageObjectFromBg = () => (store) => {
	const objects = getMatObjects(store.getState());
	const selectedId = getSelectedId(objects);
	store.dispatch(delBGImage());
	store.dispatch(editObject({ id: selectedId, visible: true }));
};

// Generate sketch pdf

export const generateSketchPdf = (id) => async (store) => {
	store.dispatch(startLoading());

	const res = await store.dispatch(generateSketchPdf$network(id));

	store.dispatch(endLoading());

	window.open(res.Result.Data);
};

// Update extracted colors

export const updateExtractedColors = () => async (store) => {
	const objects = getMatObjects(store.getState());
	const {
		colors: { standart: standartColors },
	} = getMat(store.getState());

	const keys = Object.keys(objects);
	let urls = keys
		.filter((key) => {
			return objects[key].objectType === 'image';
		})
		.map((key) => objects[key].url);

	if (!urls.length) store.dispatch(setExtractedColors([]));
	else {
		let newExtractedColors = [];

		for (const url of urls) {
			const imageColors = await color.getImageColors(url);

			newExtractedColors = [
				...newExtractedColors,
				...color.getColorsInRange(imageColors, standartColors),
			];
		}

		store.dispatch(setExtractedColors(new Set(newExtractedColors)));
	}
};

// Convert PDF to image

export const convertImage = (file) => async (store) => {
	store.dispatch(startLoading());
	const res = await store.dispatch(convertImage$network(file));
	store.dispatch(endLoading());

	if (res.result && res.result.data) {
		return res.result.data;
	} else {
		store.dispatch(
			errorMsgs({
				message: notification.addImageFromPdfError,
			})
		);

		return null;
	}
};

// -------- Async network action creator --------

// Convert PDF to image

export const convertImage$network = (file) => ({
	endpoint: `${endpoints.CONVERT_IMAGE}/pdf`,
	method: 'post',
	payload: file,
	type: types.CONVERT_IMAGE_$NETWORK,
});

//  Get colors

export const getColors$network = () => ({
	endpoint: endpoints.COLORS,
	type: types.GET_COLORS_$NETWORK,
});

// Get sizes

export const getSizes$network = () => ({
	endpoint: endpoints.SIZES,
	type: types.GET_SIZES_$NETWORK,
});

//  Save draft

export const saveDraft$network = (payload) => ({
	endpoint: endpoints.SAVE_DRAFT,
	payload,
	method: 'POST',
	type: types.SAVE_DRAFT_$NETWORK,
});

//  Update draft

export const updateDraft$network = (payload, id) => ({
	endpoint: `${endpoints.UPDATE_DRAFT}/${id}`,
	payload,
	method: 'PUT',
	type: types.UPDATE_DRAFT_$NETWORK,
});

//  Get draft

export const getDraft$network = (id) => ({
	endpoint: `${endpoints.GET_DRAFT}/${id}`,
	type: types.GET_DRAFT_$NETWORK,
});

//	Delete draft

export const deleteDraft$network = (id) => ({
	endpoint: `${endpoints.DEL_DRAFT}/${id}`,
	method: 'DELETE',
	type: types.DEL_DRAFT_$NETWORK,
});

//	Submit

export const submit$network = (payload) => ({
	endpoint: endpoints.SUBMIT,
	payload,
	method: 'POST',
	type: types.SUBMIT_$NETWORK,
});

// getLYLImages$network

export const getLYLImages$network = (payload) => ({
	endpoint: endpoints.GET_LYL_IMAGES,
	payload: {
		IsSketchLibrary: true,
		SketchRequest: { ProductGroupID: matType.LYL.code },
		IsCompleted: true,
	},
	method: 'POST',
	type: types.GET_LYL_IMAGES_$NETWORK,
});

// generateSketchPdf$network

export const generateSketchPdf$network = (id) => ({
	endpoint: `${endpoints.GENERATE_SKETCH_PDF}/${id}`,
	type: types.GENERATE_SKETCH_PDF_$NETWORK,
});

// -------- Helpers --------

function getParamsFromFabricObject(fabricObject) {
	const height = fabricObject.height * fabricObject.scaleY;
	const width = fabricObject.width * fabricObject.scaleX;

	const params = {
		fontBGColor: fabricObject.fontBGColor,
		objectType: fabricObject.objectType,
		fontColor: fabricObject.fontColor,
		italic: fabricObject.fontStyle === 'italic',
		height,
		width,
		angle: fabricObject.angle,
		left: fabricObject.left - width / 2,
		bold: fabricObject.fontWeight === 'bold',
		top: fabricObject.top - height / 2,
		id: fabricObject.objectId,
		...(fabricObject.objectType === 'image' ? { visible: fabricObject.visible } : {}),
		...(fabricObject.text
			? { text: fabricObject.text, fontFamily: fabricObject.fontFamily }
			: {}),
	};

	return params;
}
