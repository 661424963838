import types from 'prop-types';
import React from 'react';

// Components

import TooltipDefaultView from '../../../../components/block/TooltipDefaultView';
import SizeList from '../../../../components/block/SizeList';

// ----------------

// Type of props

SizeTooltip.propTypes = {
	onSizeClick: types.func,
	selectedId: types.oneOfType([types.number, types.string]),
	children: types.node.isRequired,
	list: types.array,
};

// ----------------

export default function SizeTooltip(props) {
	const { onSizeClick, selectedId, children, list } = props;

	return (
		<TooltipDefaultView
			maxWidth="350px"
			render={<SizeList list={list} selectedId={selectedId} onSizeClick={onSizeClick} />}
			title="Mat Size"
		>
			{children}
		</TooltipDefaultView>
	);
}
