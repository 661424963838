import { NS } from "../../static/constants";

// ----------------

const TYPE_NAME = "SKETCH";

export const SET_ACTIVE_SKETCH_PREVIEW       = `${NS}/${TYPE_NAME}/SET_ACTIVE_SKETCH_PREVIEW`;
export const SET_SKETCH_DATA_BY_ID           = `${NS}/${TYPE_NAME}/SET_SKETCH_DATA_BY_ID`;
export const RESTORE_BRANCH                  = `${NS}/${TYPE_NAME}/RESTORE_BRANCH`;

// Network

export const UPDATE_SKETCH_DOCUMENT_$NETWORK = `${NS}/${TYPE_NAME}/UPDATE_SKETCH_DOCUMENT_$NETWORK`;
export const GET_SKETCH_DATA_BY_ID_$NETWORK  = `${NS}/${TYPE_NAME}/GET_SKETCH_DATA_BY_ID_$NETWORK`;
