import React, { useRef, useState, useEffect } from 'react';
import classNames from 'classnames';
import types from 'prop-types';

// Hooks

import { useWindowWidth } from '../../../hooks';
import { useDispatch } from 'react-redux';

// Components

import { FontIcon, ToolDesktop, ToolMobile } from '../../../components';

// Action creators

import { setToolHeight } from '../../../redux/actionCreators/mat';

// Styles

import './styles.scss';

// ----------------

// Type of props

Tool.propTypes = {
	requiredIcon: types.bool,
	onAddClick: types.func,
	children: types.node,
	hideMode: types.bool,
	onClick: types.func,
	onClose: types.func,
	title: types.string.isRequired,
	icon: types.string.isRequired,
	open: types.bool,
	id: types.string.isRequired,
};

// Default value for props

Tool.defaultProps = {
	title: 'title',
	icon: 'crop_din',
};

// ----------------

export default function Tool(props) {
	const {
		requiredIcon,
		onAddClick,
		children,
		hideMode,
		onClick,
		onClose,
		title,
		icon,
		open,
		id,
	} = props;

	const dispatch = useDispatch();
	const [mobileHideIsClose, mobileHideToggle] = useState(false);

	function handleMobileHideClick() {
		mobileHideToggle(!mobileHideIsClose);
	}

	// Preparation

	const currentWindowWidth = useWindowWidth();
	const deviceSizeXS = currentWindowWidth <= 576;

	const ref = useRef(null);

	useEffect(() => {
		if (deviceSizeXS) {
			dispatch(setToolHeight(open ? ref.current.offsetHeight - 48 : 0));
		}
	}, [ref, open, deviceSizeXS]); // eslint-disable-line

	// Modify styles

	const modify = classNames({
		' tool--open': open,
		' tool--hide': mobileHideIsClose && hideMode,
	});

	// Render

	return (
		<div className={`tool${modify}`} id={id}>
			<div className="tool__header" onClick={onClick}>
				<div className="tool__icon">
					<FontIcon icon={icon} size={deviceSizeXS ? 'lg' : 'xxl'} />
					{requiredIcon && (
						<div className="tool__required-icon">
							<FontIcon size={deviceSizeXS ? 'xxs' : 'xs'} icon="priority_high" />
						</div>
					)}
				</div>
				<ToolDesktop onAddClick={onAddClick} title={title} />
			</div>
			{children && open && (
				<div className="tool__body" ref={ref}>
					<ToolMobile
						handleMobileHideClick={handleMobileHideClick}
						deviceSizeXS={deviceSizeXS}
						onAddClick={onAddClick}
						hideMode={hideMode}
						onClose={onClose}
						title={title}
					/>
					<div className="tool__wrapper">
						{(!mobileHideIsClose || !hideMode) && children}
					</div>
				</div>
			)}
		</div>
	);
}
