import classNames from 'classnames';
import types from 'prop-types';
import React from 'react';

// Styles

import './styles.scss';

// ----------------

// Type of props

Size.propTypes = {
	children: types.node.isRequired,
	onClick: types.func,
	active: types.bool,
};

// Default value for props

Size.defaultProps = {
	onClick: () => null,
};

// ----------------

export default function Size(props) {
	const { children, onClick, active } = props;

	// Modify styles

	const modify = classNames({ ' size--active': active });

	// Render

	return (
		<div className={`size${modify}`} onClick={onClick}>
			{children}
		</div>
	);
}
