const getMatFavouriteStatus = (mat) => {
	if ('isFavourite' in mat) {
		switch (true) {
			case mat.isFavourite === true:
				return 1;

			case mat.isFavourite === 1:
				return 1;

			case mat.isFavourite === false:
				return 0;

			default: {
				return null;
			}
		}
	} else {
		return null;
	}
};

export default getMatFavouriteStatus;
