import types from 'prop-types';
import React from 'react';

// Hooks

import { useWindowWidth } from '../../../../hooks';

// Components

import Switch from '../../../../components/ui/Switch';
import Label from '../../../../components/block/Label';
import SelectColor from '../../../../components/block/SelectColor';

// Action creators

import { setBorderColor } from '../../../../redux/actionCreators/mat';

// Styles

import './styles.scss';

// ----------------

// Type of props

BorderTool.propTypes = {
	onSwitchChange: types.func.isRequired,
	isSwitchActive: types.bool.isRequired,
	color: types.object.isRequired,
};

// ----------------

export default function BorderTool(props) {
	const { onSwitchChange, isSwitchActive, color } = props;

	// Preparation

	const currentWindowWidth = useWindowWidth();
	const deviceSizeXS = currentWindowWidth <= 576;

	return (
		<div className="border-tool">
			<Label title="Use border" theme="onFourth">
				<Switch
					onChange={onSwitchChange}
					active={isSwitchActive}
					{...(deviceSizeXS ? { size: 'sm' } : {})}
					theme={deviceSizeXS ? 'secondaryDark' : 'onFourth'}
				/>
			</Label>
			{isSwitchActive && (
				<div className="border-tool__color">
					<SelectColor
						onSelect={setBorderColor}
						{...(!deviceSizeXS ? { label: 'Color' } : {})}
						size={deviceSizeXS ? 'xl' : 'sm'}
						name={color.name}
						rgb={{ r: color.red, g: color.green, b: color.blue }}
						withBorderRadius
						sketchColors
					/>
				</div>
			)}
		</div>
	);
}
