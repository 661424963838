import React, { useState, useEffect } from 'react';
import classNames from 'classnames';
import Swiper from 'react-id-swiper';
import types from 'prop-types';

// Components

import { FontIcon } from '../../../components';

// Styles

import './styles.scss';

// ----------------

// Type of props

ToolSlider.propTypes = {
	withColorSelect: types.bool,
	initialSlide: types.number,
	children: types.node.isRequired,
	list: types.array.isRequired,
};

// Default value for props

ToolSlider.defaultProps = {
	initialSlide: 0,
};

// ----------------

export default function ToolSlider(props) {
	const { withColorSelect, initialSlide, children, list } = props;

	const params = {
		slidesPerView: 'auto',
		spaceBetween: 3,
		initialSlide,
		freeMode: true,
		observer: true,
		init: false,
	};

	// State

	const [swiper, updateSwiper] = useState(null);
	const [state, updateState] = useState({
		isBeginning: true,
		isEnd: true,
	});

	// Preparation

	useEffect(() => {
		if (swiper !== null) {
			swiper.on('init reachBeginning slideChange reachEnd observerUpdate', () =>
				updateState({ isBeginning: swiper.isBeginning, isEnd: swiper.isEnd })
			);
			swiper.init();
		}
	}, [swiper]);

	// Handlers

	const slideToFirst = () => {
		if (swiper !== null) {
			swiper.slideTo(0);
		}
	};

	const slideToLast = () => {
		if (swiper !== null) {
			swiper.slideTo(list.length - 1);
		}
	};

	// Modify styles

	const modify = classNames({
		' tool-slider--withColorSelect': withColorSelect,
	});

	// Render

	return (
		<div className={`tool-slider ${modify}`}>
			<Swiper getSwiper={updateSwiper} {...params}>
				{children}
			</Swiper>

			{!state.isBeginning && (
				<button
					className="tool-slider__button tool-slider__button--first"
					onClick={slideToFirst}
				>
					<FontIcon icon="chevron_left" size="xl" />
				</button>
			)}
			{!state.isEnd && (
				<button
					className="tool-slider__button tool-slider__button--last"
					onClick={slideToLast}
				>
					<FontIcon icon="chevron_right" size="xl" />
				</button>
			)}
		</div>
	);
}
