import types from 'prop-types';
import React from 'react';

// Components

import FontIcon from '../../../../components/block/FontIcon';
import Button from '../../../../components/ui/Button';
import Modal from '../../../../components/block/Modal';

// Styles

import './styles.scss';

// ----------------

// Type of props

AttentionModal.propTypes = {
	secondaryButtonText: types.string,
	primaryButtonText: types.string,
	onSecondaryClick: types.func,
	onPrimaryClick: types.func,
	subtitle: types.string.isRequired,
	onClose: types.func.isRequired,
	title: types.string,
	open: types.bool.isRequired,
};

// ----------------

export default function AttentionModal(props) {
	const {
		secondaryButtonText,
		primaryButtonText,
		onSecondaryClick,
		onPrimaryClick,
		subtitle,
		onClose,
		title,
		open,
	} = props;

	// Render

	return (
		<Modal onClose={onClose} title={title} open={open} bodyAccent>
			<div className="modal__icon-wrapper attention-modal__icon-wrapper">
				<FontIcon icon="warning" />
			</div>
			<p className="modal__subtitle attention-modal__subtitle">{subtitle}</p>
			{(secondaryButtonText || primaryButtonText) && (
				<div className="modal__two-buttons-grid attention-modal__buttons">
					{secondaryButtonText && (
						<Button
							reverseType
							onClick={onSecondaryClick}
							{...(!onSecondaryClick && { disabled: true })}
							fluid
							id="attention-modal-secondary-button"
						>
							{secondaryButtonText}
						</Button>
					)}
					{primaryButtonText && (
						<Button
							onClick={onPrimaryClick}
							theme="secondary"
							fluid
							type="submit"
							id="attention-modal-primary-button"
						>
							{primaryButtonText}
						</Button>
					)}
				</div>
			)}
		</Modal>
	);
}
