export default function (objects) {
	const selectedObject = Object.keys(objects)
		.map((key) => ({
			visible: objects[key].visible,
			id: objects[key].id,
		}))
		.find((item) => item.visible === false);

	const selectedId = selectedObject ? selectedObject.id : false;

	return +selectedId;
}
