import { NS } from "../../static/constants";

// ----------------

const TYPE_NAME = "SYSTEM";

export const RESTORE_BRANCH       = `${NS}/${TYPE_NAME}/RESTORE_BRANCH`;
export const SET_MOBILE_VERSION   = `${NS}/${TYPE_NAME}/SET_MOBILE_VERSION`;
export const SET_DESKTOP_VERSION  = `${NS}/${TYPE_NAME}/SET_DESKTOP_VERSION`;
export const START_LOADING        = `${NS}/${TYPE_NAME}/START_LOADING`;
export const END_LOADING          = `${NS}/${TYPE_NAME}/END_LOADING`;
