import React from 'react';

// Actions
import { maxMobileSize } from './static/constants';

// Styles

import './styles/fonts.scss';
import './styles/index.scss';

// Modules

import AppRouter from './routes';
import { useMediaQuery } from './hooks/useMediaQuery';

// ----------------

export default function Root() {
	useMediaQuery(maxMobileSize);

	return (
		<div className="root">
			<AppRouter />
		</div>
	);
}
