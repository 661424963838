import classNames from 'classnames';
import types from 'prop-types';
import React from 'react';

// Styles

import './styles.scss';

// ----------------

// Type of props

RadioButton.propTypes = {
	onChange: types.func.isRequired,
	checked: types.bool.isRequired,
	theme: types.oneOf(['onPrimary', 'onSecondary']),
	value: types.string.isRequired,
	name: types.string.isRequired,
};

// Default value for props

RadioButton.defaultProps = {
	theme: 'onPrimary',
};

// ----------------

export default function RadioButton(props) {
	const { onChange, checked, theme, value, name } = props;

	// Modify styles

	const modify = classNames({
		[` radio-button__field--theme-${theme}`]: theme,
	});

	// Render

	return (
		<div className="radio-button">
			<input
				className={`radio-button__field${modify}`}
				onChange={onChange}
				checked={checked}
				value={value}
				type="radio"
				name={name}
			/>
		</div>
	);
}
