import React, { memo } from 'react';
import classNames from 'classnames';
import types from 'prop-types';

// Styles

import './styles.scss';

// ----------------

// Type of props

LogoMatTypeCard.propTypes = {
	children: types.node,
	active: types.bool,
	theme: types.oneOf(['onPrimaryDark']),
	label: types.string.isRequired,
};

// Default value for props

LogoMatTypeCard.defaultProps = {
	theme: 'onPrimaryDark',
};

// ----------------

function LogoMatTypeCard(props) {
	const { children, active, theme, label } = props;

	// Modify styles

	const modify = classNames({
		[` logo-mat-type-card--theme-${theme}`]: theme,
		' logo-mat-type-card--active': active,
	});

	// Render

	return (
		<div className={`logo-mat-type-card${modify}`}>
			<div className="logo-mat-type-card__children">{children}</div>
			<span className="logo-mat-type-card__label">{label}</span>
		</div>
	);
}

export default memo(LogoMatTypeCard);
