import React from 'react';
import types from 'prop-types';

// Components

import FontIcon from '../../../components/block/FontIcon';

// ----------------

// Type of props

ToolDesktop.propTypes = {
	onAddClick: types.func,
	title: types.string.isRequired,
};

// ----------------

export default function ToolDesktop(props) {
	const { onAddClick, title } = props;

	// Render

	return (
		<div className="tool__desktop">
			<span className="tool__title">{title}</span>
			{onAddClick && (
				<button className="tool__add" onClick={onAddClick}>
					<FontIcon icon="add" size="md" />
					<span> Add </span>
				</button>
			)}
		</div>
	);
}
