export const mats = {
	sketchRequestID: 0,
	sketchRef: null,
	mcSketchStatusID: null,
	designGroupID: null,
	requestDate: '0001-01-01T00:00:00',
	companyName: null,
	designName: '',
	locationID: null,
	sellerID: null,
	placedByID: null,
	createdDate: '0001-01-01T00:00:00',
	pageNumber: 1,
	recordCount: 12,
	isSearchByUserID: false,
	columnName: null,
	searchOrder: 'desc',
	dateRange: null,
	SketchAttributes: null,
	productGroupID: ['101', '116', '205'],
	fromdate: '2016-01-01T00:00:00',
	todate: '',
	ColorRange: 0,
	SearchString: '',
	SortByDate: true,
	parentCustomerID: null,
	websiteId: '4',
	isCustomerApproved: '-1',
	SketchStatusID: ['205', '249', '226'],
};

// Draft

export const draft = {
	username: '', //  From User/GetUserCustomerDetailsForLogin (UserName)
	locationId: 0, // From User/GetUserCustomerDetailsForLogin (CustomerID)
	placedbyId: 0, // From User/GetUserCustomerDetailsForLogin (UserID)
	companyname: '',
	ponumber: '1',
	sizeId: '', // Sketch size
	styleId: '1',
	colors: '',
	instructions: '',
	sketchImage: '', // Draft preview
	previewImage: '',
	jsonData: {}, // Sketch data
	realName: '', // Sketch name
	parentId: '',
	extractedColors: '',
	currentActivityId: 1,
	isPending: 1,
	sketchRef: '',
	defaultDate: '',
	logoMatType: 0, // Mat type
	customerName: '',
	customerEmail: '',
	variationOf: '',
};

// Submit

export const submit = {
	SketchRequest: {
		ProductGroupID: 0, // Mat type
		MCSketchStatusID: 207, // Sketch in progress | static
		DesignGroupID: null,
		ImageName: '',
		CompanyName: 'Name', // Company name
		DesignName: 'Name', // Sketch name
		LocationID: 0, // Customer id
		SellerID: 1648,
		PlacedByID: 1648,
		VariationOf: null,
		AdditionalInfo: 'Info',
		Instructions: 'Info',
		Orientation: 'Portrait',
		Size: '4x6',
		NoOfColors: 2,
		ColorRange: 'PHOTOREALISTIC',
		Location: 'CINTAS 001',
		Seller: 'mkozachok mkozachok',
		BackGroundColor: '115',
		Colors: '',
		MatType: 'PHOTO',
		EmailCC: '',
		IsSendMailConfn: false,
		UserName: 'mkozachok@s-pro.io',
		Source: 'cintas',
		UserEmailID: 'websupport@millinfo.net',
		SizeUOMID: '22',
	},
	SketchAttributes: [],
	SketchDocuments: null,
	OriginalImages: [],
	SignatureImage: '',
	isCopyColors: false,
	Base64File: '',
	DraftId: '',
};
