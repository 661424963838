import types from 'prop-types';
import React from 'react';

// Components

import Modal from '../../../../components/block/Modal';
import AddTextForm from '../../../../components/block/forms/AddTextForm';
import Form from '../../../../modules/form-x';

// ----------------

// Type of props

AddTextModal.propTypes = {
	maxLength: types.number,
	onSubmit: types.func.isRequired,
	onClose: types.func.isRequired,
	text: types.string,
	open: types.bool.isRequired,
};

// Default props

AddTextModal.defaultProps = {
	maxLength: 20,
};

// ----------------

export default function AddTextModal(props) {
	const { maxLength, onSubmit, onClose, text, open } = props;

	return (
		<Modal onClose={onClose} title={text ? 'Edit text' : 'Add text'} open={open}>
			<Form
				onSubmit={(data) => {
					onSubmit(data);
					setTimeout(onClose, 100);
				}}
				fields={{
					text: {
						handler: 'limitLength',
						messages: {
							required: 'Please enter your text',
							max: `Max: ${maxLength} symbols`,
						},
						validate: { min: 1, max: maxLength, required: true },
						value: text || '',
					},
				}}
				render={(formProps) => (
					<AddTextForm
						{...formProps}
						primaryBtnText={text ? 'Save' : 'Create'}
						maxLength={maxLength}
						onClose={onClose}
					/>
				)}
			/>
		</Modal>
	);
}
