import types from 'prop-types';
import React from 'react';

// Assets

import { virtuosoLogo, cintasLogo } from '../../../../assets/images';

// Styles

import './styles.scss';

// ----------------

// Type of props

AuthStruct.propTypes = {
	children: types.node.isRequired,
	bg: types.string,
};

// ----------------

export default function AuthStruct(props) {
	const { children, bg } = props;

	return (
		<div
			className="auth-struct scroll"
			style={{ ...(bg && { backgroundImage: `url(${bg})` }) }}
		>
			<div className="auth-struct__content">
				<div className="auth-struct__header">
					<div className="auth-struct__logo">
						<img className="img-contain" src={`${virtuosoLogo}`} alt="Virtuoso Logo" />
					</div>
					<h4 className="auth-struct__subtitle">Custom Logo Mat Designer</h4>
				</div>
				<div className="auth-struct__body">{children}</div>
				<div className="auth-struct__footer">
					<div className="auth-struct__cintas-logo">
						<img className="img-contain" src={`${cintasLogo}`} alt="Virtuoso Logo" />
					</div>
					<span className="auth-struct__copyright">
						Powered by Virtuoso. All Rights Reserved
					</span>
				</div>
			</div>
		</div>
	);
}
