import * as types from '../types/system';

// -------- Classic action creators --------

// Set desktop version

export const setDesktopVersion = () => ({
	type: types.SET_DESKTOP_VERSION,
});

// Set mobile version

export const setMobileVersion = () => ({
	type: types.SET_MOBILE_VERSION,
});

// Start loading

export const startLoading = (opacity = true) => ({
	type: types.START_LOADING,
	payload: { opacity },
});

// End loading

export const endLoading = () => ({
	type: types.END_LOADING,
});
