import imageFromUrl2base64 from 'image-to-base64';
import UTIF from 'utif';

// Action creators

import { startLoading, endLoading } from '../redux/actionCreators/system';
import { convertImage } from '../redux/actionCreators/mat';
import { errorMsgs } from '../redux/actionCreators/systemNotifications';

// Static

import browserHistory from '../static/global';
import notification from '../static/data/notification';

/**
 * @param {File|string} data
 * @param {function}    callBack
 *
 * @return {string} base 64 string
 */

export default function toBase64(data, callBack, dispatch) {
	if (typeof data === 'string') {
		// Base 64 from url

		dispatch(startLoading());

		imageFromUrl2base64('https://cors-anywhere.herokuapp.com/' + data)
			.then((response) => {
				// Store PNG file signature as Base64 and check if response string starts with it
				const pngSignature = 'iVBORw0KGgo';

				if (response.indexOf(pngSignature) === 0) {
					callBack(`data:image/png;base64,${response}`);

					setTimeout(() => dispatch(endLoading()), 1200);
				} else {
					setTimeout(() => dispatch(endLoading()), 0);
					dispatch(errorMsgs({ message: notification.addImageFromGoogleSearchError }));
				}
			})
			.catch((error) => {
				setTimeout(() => browserHistory.store.dispatch(endLoading()), 0);
				dispatch(errorMsgs({ message: notification.addImageFromGoogleSearchError }));
			});
	} else {
		// Base 64 from File

		const reader = new FileReader();

		if (data.type === 'image/tiff') {
			reader.onloadend = () => {
				const ifds = UTIF.decode(reader.result);
				UTIF.decodeImage(reader.result, ifds[0], ifds);

				//  Uint8Array of the image in RGBA format, 8 bits per channel

				const RGBA = UTIF.toRGBA8(ifds[0]);

				// Convert to Uint8ClampedArray

				const clampedRGBA = new Uint8ClampedArray(RGBA.buffer);

				// Draw an ArrayBuffer into a canvas

				const canvas = document.createElement('canvas');
				const ctx = canvas.getContext('2d');
				const canvasWidth = ifds[0].width;
				const canvasHeight = ifds[0].height;
				canvas.width = canvasWidth;
				canvas.height = canvasHeight;

				const imageData = new ImageData(clampedRGBA, canvasWidth, canvasHeight);
				ctx.putImageData(imageData, 0, 0);

				// Get base64-encoded image data in PNG format

				callBack(canvas.toDataURL());
			};

			reader.readAsArrayBuffer(data);
		} else if (data.type === 'application/pdf') {
			reader.onloadend = async () => {
				const pdfBase64 = reader.result.replace('data:application/pdf;base64,', '');

				// Convert PDF to image

				const imageUrl = await dispatch(convertImage(pdfBase64));

				// Draw a converted image into a canvas

				if (imageUrl) {
					const image = new Image();

					image.onload = () => {
						const canvas = document.createElement('canvas');
						const ctx = canvas.getContext('2d');
						canvas.width = image.width;
						canvas.height = image.height;
						ctx.drawImage(image, 0, 0);

						// Get base64-encoded image data in PNG format

						callBack(canvas.toDataURL());
					};

					image.onerror = () => {
						dispatch(
							errorMsgs({
								message: notification.addConvertedImageToCanvasError,
							})
						);
					};

					image.crossOrigin = 'Anonymous';
					image.src = imageUrl;
				}
			};

			reader.readAsDataURL(data);
		} else {
			reader.onloadend = () => {
				callBack(reader.result);
			};

			reader.readAsDataURL(data);
		}
	}
}
