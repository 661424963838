import React, { useEffect } from 'react';
import types from 'prop-types';

// Components

import TextareaField from '../../../../components/ui/TextareaField';
import FontIcon from '../../../../components/block/FontIcon';
import Button from '../../../../components/ui/Button';
import Loader from '../../../../components/block/loaders/Loader';

// Styles

import './styles.scss';

// ----------------

// Type of props

FeedbackForm.propTypes = {
	postNetworkProcess: types.bool,
	extraState: types.object.isRequired,
	onSubmit: types.func.isRequired,
	onClose: types.func.isRequired,
	fields: types.object.isRequired,
};

// Default value for props

FeedbackForm.defaultProps = {
	onSubmit: () => null,
	onClose: () => null,
};

// ----------------

export default function FeedbackForm(props) {
	const { postNetworkProcess, extraState, onSubmit, onClose, fields } = props;

	// Preparation

	useEffect(() => {
		document
			.getElementsByClassName('feedback-form')[0]
			.getElementsByTagName('textarea')[0]
			.focus();
	}, []);

	// Render

	return (
		<form className="feedback-form" onSubmit={onSubmit}>
			{!extraState.isRequestSend ? (
				<>
					{postNetworkProcess ? (
						<div className="feedback-form__loader-wrapper">
							<Loader />
						</div>
					) : (
						<>
							<p className="form__subtitle feedback-form__subtitle">
								New ideas? Issues? Let us know about your VIRTUOSO experience!
							</p>
							<fieldset className="feedback-form__fields">
								<TextareaField
									{...fields.message}
									placeholder="Please write your feedback here..."
									areaHeight="200px"
									theme="onPrimaryDark"
									title="Feedback"
									fluid
								/>
							</fieldset>
							<div className="form__two-buttons-grid">
								<Button
									reverseType
									onClick={onClose}
									fluid
									id="feedback-form-cancel-button"
								>
									Cancel
								</Button>
								<Button
									onClick={onSubmit}
									fluid
									type="submit"
									id="feedback-form-submit-button"
								>
									Send
								</Button>
							</div>
						</>
					)}
				</>
			) : (
				<>
					<div className="modal__icon-wrapper">
						<FontIcon icon="done" />
					</div>
					<p className="form__subtitle feedback-form__subtitle">
						Your feedback has been sent successfully!
					</p>
					<div className="feedback-form__cancel-button">
						<Button reverseType onClick={onClose} fluid id="feedback-form-cancel-button">
							Close
						</Button>
					</div>
				</>
			)}
		</form>
	);
}
