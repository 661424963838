import { animateScroll } from 'react-scroll';
import classNames from 'classnames';
import types from 'prop-types';
import React from 'react';

// Hooks

import { useCurrentPosition } from './../../../hooks';

// Components

import FontIcon from './../../../components/block/FontIcon';

// Styles

import './styles.scss';

// ----------------

// Type of props

ButtonUp.propTypes = {
	visiblePosition: types.number,
	className: types.string,
};

// Default props

ButtonUp.defaultProps = {
	visiblePosition: 200,
	className: '',
};

// ----------------
export default function ButtonUp(props) {
	const { visiblePosition, className } = props;

	// Preparation

	const currentPosition = useCurrentPosition();

	// Handlers

	const scrollTo = () =>
		animateScroll.scrollToTop({
			duration: 800,
			smooth: 'easeInOutQuart',
		});

	// Modify styles

	const modify = classNames({
		' button-up--visible': currentPosition >= visiblePosition,
	});

	// Render

	return (
		<div className={`button-up${modify} ${className}`} onClick={() => scrollTo()}>
			<FontIcon icon="arrow_upward" />
		</div>
	);
}
