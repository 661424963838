import React, { memo } from 'react';
import classNames from 'classnames';
import types from 'prop-types';

// Components

import FontIcon from '../../../../components/block/FontIcon';

// Styles

import './styles.scss';

// ----------------

// Type of props

ImageCard.propTypes = {
	selected: types.bool.isRequired,
	disabled: types.bool.isRequired,
	onClick: types.func.isRequired,
	image: types.string.isRequired,
	label: types.string,
	size: types.string,
};

// Default value for props

ImageCard.defaultProps = {
	selected: false,
	disabled: false,
	onClick: () => null,
	image: '-',
	label: '-',
	size: '-',
};

// ----------------

function ImageCard(props) {
	const { selected, disabled, onClick, label, image, size } = props;

	// Modify styles

	const modify = classNames({
		' image-card--selected': selected,
		' image-card--disabled': disabled,
	});

	// Render

	return (
		<div className={`image-card${modify}`} onClick={onClick}>
			<div className="image-card__image-wrapper show-info">
				<img className="img-contain" src={image} alt="Flyer preview img" />
			</div>
			<p className="text-small image-card__label show-info" title={label}>
				{label}
			</p>
			<p className="text-mini image-card__size show-info">{size}</p>
			{selected && (
				<div className="image-card__selected-icon">
					<FontIcon icon="check" size="sm" />
				</div>
			)}
		</div>
	);
}

export default memo(ImageCard);
