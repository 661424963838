import classNames from 'classnames';
import types from 'prop-types';
import React from 'react';

// Components

import Loader from '../../../components/block/loaders/Loader';

// Styles

import './styles.scss';

// ----------------

// Type of props

List.propTypes = {
	listContentClassName: types.string,
	networkProcess: types.bool,
	staticProps: types.object,
	listItem: types.func.isRequired,
	keyName: types.string,
	height: types.string,
	style: types.object,
	empty: types.func,
	list: types.array.isRequired,
};

// Default value for props

List.defaultProps = {
	staticProps: {},
	height: '200px',
	empty: () => (
		<div className="list__empty-default-content">
			<span>No Results Found</span>
		</div>
	),
};

// ----------------

export default function List(props) {
	const {
		listContentClassName,
		networkProcess,
		staticProps,
		listItem: ListItem,
		keyName,
		onClick,
		height,
		empty: Empty,
		style,
		list,
	} = props;

	// Modify styles

	const modifyContent = classNames({
		' list__content--loaded': !networkProcess,
	});

	// Render

	return (
		<div className="list" style={style}>
			{networkProcess ? (
				<div className="list__empty" style={{ minHeight: height }}>
					<Loader />
				</div>
			) : list.length ? (
				<div className={`list__content ${listContentClassName}${modifyContent}`}>
					{list.map((item, index) => (
						<ListItem
							onClick={onClick}
							data={item}
							key={item[keyName || 'id'] || index}
							{...staticProps}
						/>
					))}
				</div>
			) : (
				<div className="list__empty" style={{ minHeight: height }}>
					<Empty />
				</div>
			)}
		</div>
	);
}
