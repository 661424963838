import * as base_types from '../types/base';
import * as types from '../types/google';
import $ from '../../utils/createActionType';

// -------- Default state of the branch --------

const defaultState = {
	imagesNextPage: {},
	images: [],
};

// -------- Reducer --------

export default function (state, action) {
	if (!state) return defaultState;

	switch (action.type) {
		case $(types.GET_IMAGES_$NETWORK): {
			return {
				...state,
				images: action.payload.items || [],
				imagesNextPage: {
					searchValue: action.payload.queries.request[0].searchTerms,
					start: action.payload.queries.nextPage
						? action.payload.queries.nextPage[0].startIndex
						: null,
				},
			};
		}

		case $(types.GET_MORE_IMAGES_$NETWORK): {
			return {
				...state,
				images: [...state.images, ...action.payload.items],
				imagesNextPage: {
					searchValue: action.payload.queries.request[0].searchTerms,
					start: action.payload.queries.nextPage[0].startIndex,
				},
			};
		}

		case types.RESTORE_BRANCH:
		case base_types.RESTORE_DEFAULT_STATE: {
			return defaultState;
		}

		default: {
			return state;
		}
	}
}
