import onClickOutside from 'react-onclickoutside';
import { Component } from 'react';
import types from 'prop-types';

// ----------------

class Outside extends Component {
	// Type of props

	static propTypes = {
		onOutsideClick: types.func.isRequired,
		children: types.object.isRequired,
	};

	// Handlers

	outsideClickHandler() {
		this.props.onOutsideClick();
	}

	// Render

	render() {
		return this.props.children;
	}
}

// Module config

const config = {
	handleClickOutside: (instance) => instance.outsideClickHandler,
};

export default onClickOutside(Outside, config);
