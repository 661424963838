import types from 'prop-types';
import React from 'react';

// Components

import Modal from '../../../../components/block/Modal';
import Button from '../../../../components/ui/Button';

// Styles

import './styles.scss';

// ----------------

// Type of props

BackgroundModal.propTypes = {
	onImageClick: types.func.isRequired,
	imageList: types.arrayOf(types.object).isRequired,
	onClear: types.func,
};

// ----------------

export default function BackgroundModal(props) {
	const {
		onImageClick,
		shadowAccent,
		bodyHeight,
		imageList,
		closeIcon,
		maxWidth,
		onClose,
		onClear,
		open,
	} = props;

	// Render

	return (
		<Modal
			shadowAccent={shadowAccent}
			bodyHeight={bodyHeight}
			closeIcon={closeIcon}
			maxWidth={maxWidth}
			onClose={onClose}
			title="Select your background"
			open={open}
			withoutPadding
			bodyAccent
		>
			{onClear && (
				<div className="background-modal__button">
					<Button id="clear-bg-button" fluid size="lg" onClick={onClear}>
						Clear background
					</Button>
				</div>
			)}
			<div className="background-modal__list-wrapper">
				<div
					className="background-modal__list"
					style={{
						gridTemplateColumns: `repeat(auto-fill, minmax(300px, 1fr))`,
					}}
				>
					{imageList.map((image, index) => (
						<div
							className={`background-modal__image-container${
								image.id === 'bg' ? ' background-modal__image-container--active' : ''
							}`}
							onClick={() => (image.id !== 'bg' ? onImageClick(image) : null)}
							key={index}
						>
							<img className="background-modal__image" src={image.url} alt="Background" />
						</div>
					))}
				</div>
			</div>
		</Modal>
	);
}
