import React, { memo } from 'react';
import classNames from 'classnames';
import types from 'prop-types';

// Styles

import './styles.scss';

// ----------------

// Type of props

Textarea.propTypes = {
	placeholder: types.string,
	areaHeight: types.string,
	onChange: types.func.isRequired,
	onFocus: types.func,
	onBlur: types.func,
	status: types.shape({
		error: types.bool,
	}),
	theme: types.oneOf(['onPrimary', 'secondary', 'secondaryDark', 'onPrimaryDark']),
	fluid: types.bool,
	value: types.string,
	name: types.string.isRequired,
	id: types.string,
};

// Default value for props

Textarea.defaultProps = {
	theme: 'onPrimary',
};

// ----------------

function Textarea(props) {
	const {
		placeholder,
		areaHeight,
		onChange,
		onFocus,
		onBlur,
		status: { error },
		theme,
		fluid,
		value,
		name,
		id,
		...rest
	} = props;

	// Modify styles

	const modify = classNames({ ' textarea--fluid': fluid });

	const modifyTextareaField = classNames({
		[` textarea__field--theme-${theme}`]: theme,
		' textarea__field--error': error,
	});

	// Render

	return (
		<div className={`textarea${modify}`}>
			<textarea
				placeholder={placeholder}
				className={`textarea__field${modifyTextareaField}`}
				onChange={onChange}
				onFocus={onFocus}
				onBlur={onBlur}
				value={value}
				style={{ minHeight: areaHeight }}
				name={name}
				id={id}
				{...rest}
			/>
		</div>
	);
}

export default memo(Textarea);
