export default [
	{
		label: 'Flyers',
		icon: 'picture_as_pdf',
		to: '/marketing',
		id: 'flyers',
	},
	{
		label: 'Videos',
		icon: 'video_library',
		to: '/marketing/videos',
		id: 'videos',
	},
];
