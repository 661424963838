import * as base_types from '../types/base';
import * as types from '../types/auth';
import Cookies from 'js-cookie';

// Static

import { register as registerInitialPayload } from '../../static/payloads/auth';
import { cookie } from '../../static/constants';
import endpoints from '../../static/endpoints/auth';
import browserHistory from '../../static/global';

// Import action creators

import { getUser$network } from '../../redux/actionCreators/user';

// -------- Async action creator --------

// signin

export const signin = (payload) => async (store) => {
	const data = await store.dispatch(signin$network(payload));

	Cookies.set(cookie.TOKEN, data.Result.Data.access_token, { expires: 30 });

	await store.dispatch(getUser$network());

	browserHistory.history.replace('/dashboard');
};

// signup

export const signup = (payload) => async (store) => {
	const sendPayload = { ...registerInitialPayload, ...payload };

	await store.dispatch(signup$network(sendPayload));
};

// resetPassword

export const resetPassword = (payload) => async (store) => {
	const res = await store.dispatch(resetPassword$network(payload));

	return res;
};

// logout

export const logout = (reload) => (store) => {
	Cookies.remove(cookie.TOKEN);

	if (reload) {
		window.location.href = '/signin';
	} else {
		browserHistory.history.replace('/signin');
		store.dispatch({ type: base_types.RESTORE_DEFAULT_STATE });
	}
};

// -------- Async network action creator --------

// signin$network

export const signin$network = (payload) => ({
	type: types.SIGNIN_$NETWORK,
	endpoint: endpoints.SIGNIN,
	payload,
	method: 'POST',
	URLE: true,
});

// signup$network

export const signup$network = (payload) => ({
	type: types.SIGNUP_$NETWORK,
	endpoint: endpoints.SIGNUP,
	payload,
	method: 'POST',
});

// resetPassword$network

export const resetPassword$network = (payload) => ({
	type: types.RESET_PASSWORD_$NETWORK,
	endpoint: endpoints.RESET_PASSWORD,
	payload,
	method: 'POST',
	URLE: true,
});
