import DatePicker from 'react-datepicker';
import classNames from 'classnames';
import types from 'prop-types';
import React from 'react';

// Components

import SvgIcon from '../../../components/block/SvgIcon';

// Assets

import { calendarIcon } from '../../../assets/icons';

// Styles

import 'react-datepicker/dist/react-datepicker.css';
import './styles.scss';

// ----------------

// Type of props

Datepicker.propTypes = {
	placeholder: types.string,
	onChange: types.func.isRequired,
	status: types.shape({
		error: types.bool,
	}),
	theme: types.oneOf(['fourth']),
	fluid: types.bool,
	size: types.oneOf(['xs', 'sm', 'md', 'lg']),
};

// Default value for props

Datepicker.defaultProps = {
	theme: 'fourth',
	size: 'lg',
};

// ----------------

export default function Datepicker(props) {
	const {
		placeholder,
		selected,
		onChange,
		status: { error },
		theme,
		fluid,
		size,
		...rest
	} = props;

	// Modify styles

	const modify = classNames({
		[` datepicker--theme-${theme}`]: theme,
		[` datepicker--size-${size}`]: size,
		' datepicker--fluid': fluid,
		' datepicker--error': error,
	});

	const modifyDatepickerIcon = classNames({
		[` datepicker__icon--theme-${theme}`]: theme,
	});

	// Render

	return (
		<div className={`datepicker${modify}`}>
			<DatePicker
				placeholderText={placeholder}
				popperModifiers={{
					preventOverflow: {
						escapeWithReference: false,
						boundariesElement: 'viewport',
						enabled: true,
					},
				}}
				autoComplete="off"
				dropdownMode="select"
				dateFormat="MM-dd-yyyy"
				selected={selected}
				onChange={onChange}
				fixedHeight
				withPortal
				{...rest}
			/>
			{!selected && (
				<div className={`datepicker__icon${modifyDatepickerIcon}`}>
					<SvgIcon size="sm" url={calendarIcon} />
				</div>
			)}
		</div>
	);
}
