import React from 'react';
import { useDispatch } from 'react-redux';

// Components

import {
	TriggerBackground,
	BorderTool,
	Container,
	ColorTool,
	ImageTool,
	SizeTool,
	FontTool,
} from '../../../../components';

// Static

import { limit } from '../../../../static/constants';
import _global from '../../../../static/global';

// Action creators

import {
	setLandscapeOrientation,
	setPortraitOrientation,
	toggleBorder,
	toggleModal,
	setTool,
	setSize,
} from '../../../../redux/actionCreators/mat';

// Selectors

import getById from '../../../../redux/selectors/getById';

// ----------------

const { getState } = _global.store;
const { flags, MASK, masksByTypes } = _global.pages.mat;

export default () => {
	const dispatch = useDispatch();
	return [
		{
			onAddClick: (e) => {
				e.stopPropagation();
				dispatch(toggleModal('text'));
			},
			children: () => <FontTool />,
			onClick: () => {
				if (getState().system.mobile) {
					dispatch(toggleModal('text'));
				}
			},
			onClose: () => {
				if (getState().system.mobile) {
					dispatch(setTool('text'));
				}
			},
			access: MASK & flags.TEXT && masksByTypes[getState().mat.matType] & flags.TEXT,
			title: 'text',
			icon: 'text_fields',
			name: 'text',
			id: 'mat-text-tool',
		},
		{
			onAddClick: (e) => {
				e.stopPropagation();
				dispatch(toggleModal('image'));
			},
			children: () => <ImageTool />,
			onClick: () => {
				if (getState().system.mobile) {
					const { mat } = getState();
					if (mat.imageCount >= limit.IMAGES) {
						dispatch(toggleModal('attention-images-limit'));
						return;
					}

					dispatch(toggleModal('image'));
				}
			},
			onClose: () => {
				if (getState().system.mobile) {
					dispatch(setTool('image'));
				}
			},
			access: MASK & flags.IMAGE && masksByTypes[getState().mat.matType] & flags.IMAGE,
			title: 'image',
			icon: 'collections',
			name: 'image',
			id: 'mat-image-tool',
		},
		{
			children: () => (
				<Container
					mapDispatch={{ onSwitchChange: toggleBorder }}
					mapState={(state) => ({
						isSwitchActive: state.mat.border.isShow,
						color:
							getById(
								[...state.mat.colors.standart, ...state.mat.colors.pms],
								'name',
								state.mat.border.color
							) || {},
					})}
					render={BorderTool}
				/>
			),
			onClick: () => dispatch(setTool('border')),
			onClose: () => {
				if (getState().system.mobile) {
					dispatch(setTool('border'));
				}
			},
			access: MASK & flags.BORDER && masksByTypes[getState().mat.matType] & flags.BORDER,
			title: 'border',
			icon: 'border_style',
			name: 'border',
			id: 'mat-border-tool',
		},
		{
			children: () =>
				masksByTypes[getState().mat.matType] & flags.BG_IMAGE ? (
					<TriggerBackground />
				) : (
					<ColorTool
						withClearAllColors
						palette={!!(masksByTypes[getState().mat.matType] & flags.PALETTE)}
					/>
				),
			onClick: () => dispatch(setTool('colors')),
			onClose: () => {
				if (getState().system.mobile) {
					dispatch(setTool('colors'));
				}
			},
			access: MASK & flags.COLORS && masksByTypes[getState().mat.matType] & flags.COLORS,
			title:
				masksByTypes[getState().mat.matType] & flags.BG_IMAGE ? 'background' : 'colors',
			icon: 'palette',
			name: 'colors',
			id: 'mat-colors-tool',
		},
		{
			children: () => (
				<Container
					mapDispatch={{
						onLandscapeClick: setLandscapeOrientation,
						onPortraitClick: setPortraitOrientation,
						onSizeClick: setSize,
					}}
					mapState={(state) => ({
						matOrientation: state.mat.matOrientation,
						selectedId: state.mat.selectedSizeId,
						list: state.mat.matSizes,
					})}
					render={SizeTool}
				/>
			),
			onClick: () => dispatch(setTool('size')),
			onClose: () => {
				if (getState().system.mobile) {
					dispatch(setTool('size'));
				}
			},
			access: MASK & flags.SIZE && masksByTypes[getState().mat.matType] & flags.SIZE,
			title: 'size & orientation',
			icon: 'zoom_out_map',
			name: 'size',
			id: 'mat-size-tool',
		},
	];
};
