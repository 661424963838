import types from 'prop-types';
import React from 'react';

// Components

import FeedbackForm from '../../../../components/block/forms/FeedbackForm';
import Container from '../../../../components/rp/Container';
import Modal from '../../../../components/block/Modal';
import Form from '../../../../modules/form-x';

// Action creators

import { postFeedback$network } from '../../../../redux/actionCreators/user';

// Action types

import { POST_FEEDBACK_$NETWORK } from '../../../../redux/types/user';

// ----------------

// Type of props

FeedbackModal.propTypes = {
	onClose: types.func.isRequired,
	open: types.bool.isRequired,
};

// ----------------

export default function FeedbackModal(props) {
	const { onClose, open } = props;

	// Render

	return (
		<Modal onClose={onClose} title="We want to hear from you" open={open} bodyAccent>
			<div className="feedback-modal">
				<Container
					mapDispatch={{ postFeedback$network }}
					mapState={(state) => ({
						postNetworkProcess: state.network[POST_FEEDBACK_$NETWORK],
					})}
					render={(cProps) => (
						<Form
							extraState={{ isRequestSend: false }}
							validate={{ required: true }}
							onSubmit={async (data, actions) => {
								try {
									const res = await cProps.postFeedback$network(data);

									if (res.result.data.feedbackId) {
										actions.setExtraState('isRequestSend', true);
									}
								} catch (err) {}
							}}
							fields={{
								message: {
									messages: {
										required: 'Please enter your feedback',
										min: 'To send a message, it should contain at least 10 characters',
									},
									validate: { min: 10 },
									value: '',
								},
							}}
							render={(formProps) => (
								<FeedbackForm
									{...formProps}
									postNetworkProcess={cProps.postNetworkProcess}
									onClose={onClose}
								/>
							)}
						/>
					)}
				/>
			</div>
		</Modal>
	);
}
