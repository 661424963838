import classNames from 'classnames';
import types from 'prop-types';
import React from 'react';

// Components

import Dropdown from '../../../../components/ui/Dropdown';
import FontIcon from '../../../../components/block/FontIcon';
import Bool from '../../../../components/rp/Bool';

// Styles

import './styles.scss';

// ----------------

// Type of props

MatsPagesHeader.propTypes = {
	handleGridModeToggle: types.func.isRequired,
	activeGridMode: types.string.isRequired,
	matsQuantity: types.number.isRequired,
	sortActions: types.array,
	titleIcon: types.string.isRequired,
	title: types.string.isRequired,
};

// Default props

MatsPagesHeader.defaultProps = {
	matsQuantity: 0,
};

// ----------------

export default function MatsPagesHeader(props) {
	const { handleGridModeToggle, activeGridMode, sortActions, titleIcon, title } = props;

	// Modify styles

	const modifyHeaderGrid = classNames({
		[` mats-header--with-sort-actions`]: !!sortActions,
	});

	// Render

	return (
		<header className={`mats-header${modifyHeaderGrid}`}>
			<div className="mats-header__counter">
				<FontIcon size="xl" icon={titleIcon} />
				<span className="text-middle mats-header__counter-title">{title}</span>
			</div>

			{/* Sort actions dropdown */}

			{!!sortActions && (
				<Bool
					render={({ isTrue, handleChange }) => (
						<Dropdown
							handleToggle={handleChange}
							isShowing={isTrue}
							actions={sortActions}
							button={
								<div
									className="dropdown__show-button mats-header__header-dropdown-btn"
									onClick={handleChange}
								>
									<FontIcon size="xl" icon="sort" />
								</div>
							}
						/>
					)}
				/>
			)}

			{/* View actions dropdown */}

			<Bool
				render={({ isTrue, handleChange }) => (
					<Dropdown
						handleToggle={handleChange}
						isShowing={isTrue}
						actions={[
							{
								onClick: () => handleGridModeToggle('block'),
								active: activeGridMode === 'block',
								label: 'Grid View',
								icon: 'view_module',
								id: 'mats-view-block-button',
							},
							{
								onClick: () => handleGridModeToggle('list'),
								active: activeGridMode === 'list',
								label: 'List View',
								icon: 'view_stream',
								id: 'mats-view-list-button',
							},
						]}
						button={
							<div
								className="dropdown__show-button mats-header__header-dropdown-btn"
								onClick={handleChange}
							>
								<FontIcon size="xl" icon="more_vert" />
							</div>
						}
					/>
				)}
			/>
		</header>
	);
}
