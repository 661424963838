import types from 'prop-types';
import React from 'react';

// Components

import Modal from '../../../../components/block/Modal';
import Button from '../../../../components/ui/Button';
import FontIcon from '../../../../components/block/FontIcon';

// Styles

import './styles.scss';

// ----------------

// Type of props

LogoutModal.propTypes = {
	onSubmit: types.func.isRequired,
	onClose: types.func.isRequired,
	open: types.bool.isRequired,
};

// ----------------

export default function LogoutModal(props) {
	const { onSubmit, onClose, open } = props;

	// Render

	return (
		<Modal onClose={onClose} title="Quit Virtuoso" open={open} bodyAccent>
			<div className="logout-modal">
				<div className="modal__icon-wrapper">
					<FontIcon icon="power_settings_new" />
				</div>
				<p className="modal__subtitle logout-modal__subtitle">
					Are you sure you want to log out of VIRTUOSO?
				</p>
				<div className="modal__two-buttons-grid">
					<Button reverseType onClick={onClose} fluid id="logout-modal-cancel-button">
						Cancel
					</Button>
					<Button
						textUppercase
						onClick={onSubmit}
						fluid
						type="submit"
						id="logout-modal-submit-button"
					>
						Confirm
					</Button>
				</div>
			</div>
		</Modal>
	);
}
