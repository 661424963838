import React, { memo } from 'react';
import classNames from 'classnames';
import types from 'prop-types';

// Styles

import './styles.scss';

// ----------------

// Type of props

Input.propTypes = {
	customSideBlock: types.node,
	placeholder: types.string,
	onChange: types.func.isRequired,
	onFocus: types.func,
	onBlur: types.func,
	status: types.shape({
		error: types.bool,
	}),
	theme: types.oneOf([
		'onPrimaryDark',
		'secondaryDark',
		'onPrimary',
		'secondary',
		'onFourth',
	]),
	fluid: types.bool,
	value: types.oneOfType([types.string, types.number]).isRequired,
	name: types.string.isRequired,
	size: types.oneOf(['xs', 'sm', 'md', 'lg']),
	type: types.string,
	id: types.string,
};

// Default value for props

Input.defaultProps = {
	theme: 'onPrimary',
	status: {},
	size: 'lg',
};

// ----------------

function Input(props) {
	const {
		customSideBlock,
		placeholder,
		onChange,
		onFocus,
		onBlur,
		status: { error },
		theme,
		fluid,
		value,
		name,
		type,
		size,
		id,
		...rest
	} = props;

	// Modify styles

	const modify = classNames({ ' input--fluid': fluid, [` input--size-${size}`]: size });

	const modifyInputField = classNames({
		[` input__field--theme-${theme}`]: theme,
		' input__field--withSideBlock': !!customSideBlock,
		' input__field--error': error,
	});

	// Render

	return (
		<div className={`input${modify}`}>
			<input
				autoComplete={type === 'password' ? 'new-password' : 'off'}
				placeholder={placeholder}
				className={`input__field${modifyInputField}`}
				onChange={onChange}
				onFocus={onFocus}
				onBlur={onBlur}
				value={value}
				name={name}
				type={type}
				id={id}
				{...rest}
			/>
			{customSideBlock && <div className="input__side-block">{customSideBlock}</div>}
		</div>
	);
}

export default memo(Input);
