import classNames from 'classnames';
import { Link } from 'react-router-dom';
import React from 'react';
import types from 'prop-types';

// Components

import Loader from '../../../components/block/loaders/Loader';

// Styles

import './styles.scss';

// ----------------

// Type of props

Button.propTypes = {
	withBorderRadius: types.bool,
	asSimpleLink: types.bool,
	borderWeight: types.oneOf(['thin', 'bold']),
	loaderColor: types.oneOf(['primary', 'onPrimary', 'secondary', 'white']),
	reverseType: types.bool,
	newTabLink: types.bool,
	loaderSize: types.oneOf(['sm', 'md', 'lg']),
	isLoading: types.bool,
	disabled: types.bool,
	children: types.node,
	onClick: types.func,
	fluid: types.bool,
	theme: types.oneOf([
		'secondary',
		'onPrimary',
		'onFourth',
		'primary',
		'fourth',
		'error',
	]),
	type: types.string,
	link: types.string,
	size: types.oneOf(['sm', 'md', 'lg', 'xl', 'xxl']),
	icon: types.node,
	id: types.string.isRequired,
};

// Default value for props

Button.defaultProps = {
	withBorderRadius: true,
	borderWeight: 'thin',
	reverseType: false,
	loaderColor: 'primary',
	theme: 'onPrimary',
	size: 'md',
};

// ----------------

export default function Button(props) {
	const {
		withBorderRadius,
		asSimpleLink,
		borderWeight,
		loaderColor,
		reverseType,
		newTabLink,
		loaderSize,
		className,
		isLoading,
		disabled,
		children,
		onClick,
		fluid,
		theme,
		type,
		link,
		icon,
		size,
		id,
	} = props;

	// Modify styles

	const modify = classNames({
		[` button--${borderWeight}`]: borderWeight,
		' button--withBorderRadius': withBorderRadius,
		[` button--theme-${theme}`]: theme,
		' button--reverseType': reverseType,
		[` button--${size}`]: size,
		' button--disabled': disabled,
		' button--asLink': !!link,
		' button--fluid': fluid,
	});

	// Render common content

	const renderContent = () => {
		return isLoading ? (
			<Loader color={loaderColor} size={loaderSize} />
		) : (
			<>
				{!!icon && <div className="button__inner-icon">{icon}</div>}
				<div className="button__text">{children}</div>
				{!!icon && <div className="button__inner-icon button__inner-icon--empty" />}
			</>
		);
	};

	// Customize render

	const renderButton = () => (
		<button
			className={`button${modify} ${className}`}
			onClick={isLoading ? null : onClick}
			type={type || 'button'}
			id={id}
		>
			{renderContent()}
		</button>
	);

	const renderLink = () => (
		<Link
			className={`button${modify} ${className}`}
			onClick={isLoading ? null : onClick}
			to={link}
		>
			{renderContent()}
		</Link>
	);

	const renderSimpleLink = () => (
		<a
			className={`button${modify} ${className}`}
			onClick={isLoading ? null : onClick}
			target={newTabLink ? '_blank' : undefined}
			href={link}
			rel="noopener"
		>
			{renderContent()}
		</a>
	);

	// Render

	return !!link && asSimpleLink
		? renderSimpleLink()
		: !!link
		? renderLink()
		: renderButton();
}
