import React from 'react';

// Components

import { AuthStruct } from '../../components';

// ----------------

export default function Auth(props) {
	return (
		<AuthStruct bg={props.setting.bg}>
			<props.subRouter />
		</AuthStruct>
	);
}
