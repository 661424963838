import Draggable from 'react-draggable';
import React from 'react';
import classNames from 'classnames';

// Hooks

import { useWindowWidth } from '../../../../hooks';
import { useDispatch, useSelector } from 'react-redux';
import get from 'lodash/get';

// Components

import {
	EditBackgroundImageModal,
	MatImageFiltersModal,
	MatAdditionalTools,
	AddNewImageModal,
	BackgroundModal,
	MatLayersModal,
	AttentionModal,
	ImageCropModal,
	AddTextModal,
	TutorialStep,
	SubmitSketch,
	MatHeader,
	Container,
	MatAside,
	Joyride,
	Canvas,
} from '../../../../components';

// Action creators

import { restoreBranch } from '../../../../redux/actionCreators/google';
import {
	updateExtractedColors,
	toggleAdditionalTools,
	setBGImageFromObject,
	addImageObjectFromBg,
	selectMultiplyColors,
	deleteActiveObject,
	syncObjectsParams,
	syncObjectParams,
	setActiveObject,
	setDragPosition,
	toggleTutorial,
	addTextObject,
	setLYLBGImage,
	setEditTextId,
	matSnapshots,
	setModalType,
	setBGOption,
	deleteDraft,
	clearColors,
	toggleModal,
	closeSubmit,
	editObject,
	openSubmit,
	delBGImage,
	addEditBG,
	delEditBG,
	saveDraft,
	setZIndex,
	editText,
	leaveMat,
	setTool,
} from '../../../../redux/actionCreators/mat';

// Static

import { getMatTutorial } from './../../../../static/data/mat';
import { matType, limit } from './../../../../static/constants';
import browserHistory from './../../../../static/global';

// Selectors

import getObjArrayByIds from './../../../../redux/selectors/getObjArrayByIds';
import getById from './../../../../redux/selectors/getById';

// Utils

import openColorTool from './../../../../utils/openColorTool';

// Styles

import './styles.scss';

// ----------------

export default function MatStruct(props) {
	const { flags, MASK, masksByTypes } = browserHistory.pages.mat;
	const dispatch = useDispatch();
	const state = useSelector((state) => state);
	const { match } = props;
	// Preparation

	const currentWindowWidth = useWindowWidth();
	const deviceSizeXS = currentWindowWidth <= 576;
	const isMobile = useWindowWidth() <= 1024;

	// Handlers

	function handleActiveToolClose(e) {
		if (e.target.classList.contains('upper-canvas')) {
			return;
		}

		if (state.mat.activeTool) {
			dispatch(setTool(null));
		}
	}

	function clearSpace(text) {
		return text ? text.trim() : null;
	}

	return (
		<>
			<div className="mat-struct">
				<Container
					mapDispatch={{ setTool }}
					mapState={(state) => {
						const allColors = [...state.mat.colors.standart, ...state.mat.colors.pms];

						return {
							activeObjectId: state.mat.activeObjectId,
							imageCount: state.mat.imageCount,
							activeTool: state.mat.activeTool,
							matType: state.mat.matType,
							mobile: state.system.mobile,
							isTutorialOpen: state.mat.tutorial,
							requireColors:
								state.system.mobile &&
								state.mat.matType === matType.TRADITIONAL.name &&
								(!getObjArrayByIds(allColors, state.mat.selectedColors, 'name').length ||
									!getById(allColors, 'name', state.mat.bgColor)),
						};
					}}
					render={(cProps) => {
						return <MatAside className="mat-struct__aside" {...cProps} />;
					}}
				/>

				<Container
					withRouter
					mapDispatch={{
						onBackButtonClick: leaveMat,
						onSubmit: openSubmit,
						onSave: saveDraft,
						toggle: toggleModal,
						setTool,
					}}
					mapState={(state) => ({
						matOrientation: state.mat.matOrientation,
						disableSave: state.mat.saved,
						sketchName: state.mat.matInfo.sketchName,
						matSize:
							getById(state.mat.matSizes, 'id', state.mat.selectedSizeId).value || '',
						zoom: state.mat.zoom,
					})}
					render={(cProps) => (
						<MatHeader
							className="mat-struct__header"
							{...cProps}
							onSave={async () => {
								const res = await cProps.onSave();

								if (res) browserHistory.history.push(`/draft/${res.result.data.draftId}`);
							}}
							onDelete={() => {
								if (browserHistory.history.location.pathname.split('/')[2]) {
									cProps.toggle('delete-draft');
								} else {
									cProps.toggle('delete-unsaved-sketch');
								}
							}}
							{...(MASK & flags.SIZE &&
								masksByTypes[state.mat.matType] & flags.SIZE && {
									onSizeClick: () => {
										cProps.setTool('size');
									},
								})}
						/>
					)}
				/>

				<main className="mat-struct__content" onClick={(e) => handleActiveToolClose(e)}>
					<Container
						mapDispatch={{
							updateExtractedColors,
							deleteActiveObject,
							syncObjectsParams,
							syncObjectParams,
							setActiveObject,
							setDragPosition,
							setEditTextId,
							matSnapshots,
							toggleModal,
							editObject,
						}}
						mapState={(state) => ({
							matOrientation: state.mat.matOrientation,
							activeObjectId: state.mat.activeObjectId,
							dragPosition: state.mat.dragPosition,
							tempBgImage: state.mat.tempBgImage,
							lastAction: state.mat.lastAction,
							bgOption: state.mat.bgOption,
							dragMode: state.mat.dragMode,
							objects: state.mat.objects,
							bgColor: state.mat.bgColor,
							matType: state.mat.matType,
							bgImage: state.mat.bgImage,
							matSize: getById(state.mat.matSizes, 'id', state.mat.selectedSizeId).value,
							mobile: state.system.mobile,
							colors: state.mat.colors,
							border: state.mat.border,
							zoom: state.mat.zoom,
						})}
						render={(props) => (
							<Draggable
								disabled={!props.dragMode}
								position={props.dragPosition}
								handle=".handle-canvas"
								onStop={(e, p) => {
									props.setDragPosition(p.lastX, p.lastY);
								}}
							>
								<div className="handle-canvas" id="handleCanvas">
									<Canvas {...props} restore={!!match.params.id} />
								</div>
							</Draggable>
						)}
					/>
				</main>
			</div>

			{/* Additional tools */}

			<Container
				mapDispatch={{ toggleAdditionalTools }}
				mapState={(state) => {
					return {
						activeToolHeight: state.mat.activeToolHeight,
						additionalTools: state.mat.additionalTools,
						isTutorialOpen: state.mat.tutorial,
						unduDisabled: state.mat.historyPosition === 0,
						reduDisabled:
							(get(state, 'mat.history.length', 0) || 1) ===
							state.mat.historyPosition + 1,
						bgDisabled: !state.mat.imageCount && !state.mat.bgImage,
						dragMode: state.mat.dragMode,
					};
				}}
				extra={{ deviceSizeXS }}
				render={(props) => (
					<div
						className={classNames('mat-struct__additional-tools-wrapper', {
							'mat-struct__additional-tools-wrapper--absolute': props.isTutorialOpen,
						})}
						{...(props.deviceSizeXS
							? {
									style: {
										transform: `translateY(-${props.activeToolHeight}px)`,
									},
							  }
							: {})}
					>
						<MatAdditionalTools {...props} />
					</div>
				)}
			/>

			{/* Modals */}

			<Container
				mapDispatch={{
					onImageModalUnmount: restoreBranch,
					addTextObject,
					setModalType,
					toggleModal,
					setBGOption,
					deleteDraft,
					clearColors,
					setZIndex,
					setTool,
				}}
				mapState={(state) => {
					const sketchImages = [];

					Object.keys(state.mat.objects).forEach((key) => {
						if (state.mat.objects[key].objectType === 'image') {
							const object = state.mat.objects[key];
							sketchImages.push({
								url: object.url,
								id: object.id,
							});
						}
					});

					return {
						activeTool: state.mat.activeTool,
						imageCount: state.mat.imageCount,
						modalType: state.mat.modalType,
						bgOption: state.mat.bgOption,
						matType: state.mat.matType,
						modal: state.mat.modal,
						sketchImages,
					};
				}}
				render={(cProps) => (
					<>
						<AttentionModal
							secondaryButtonText="Select image"
							primaryButtonText="Define color"
							onSecondaryClick={() => {
								if (cProps.bgOption !== 'image') cProps.setBGOption('image');
								setTimeout(() => {
									cProps.setModalType('addImageToBackground');
									cProps.toggleModal('image');
								}, 0);
							}}
							onPrimaryClick={() => {
								if (cProps.activeTool !== 'colors') cProps.setTool('colors');
								if (cProps.bgOption !== 'color') cProps.setBGOption('color');
								setTimeout(openColorTool, 120);
								cProps.toggleModal('attention-photo');
							}}
							subtitle="To complete your sketch request, you must define the background color. If you do
              not require a background color, the sketch will be designed as a Full PHOTO Mat
              using the image provided."
							onClose={() => cProps.toggleModal('attention-photo')}
							title="Select your background"
							open={cProps.modal === 'attention-photo'}
						/>
						<AttentionModal
							secondaryButtonText="Cancel"
							primaryButtonText="Define now"
							onSecondaryClick={() => cProps.toggleModal('attention-traditional')}
							onPrimaryClick={() => {
								if (cProps.activeTool !== 'colors') {
									cProps.setTool('colors');
								}
								setTimeout(openColorTool, 120);
								cProps.toggleModal('attention-traditional');
							}}
							subtitle="To complete your sketch request, you need to define your background and at least one sketch color."
							onClose={() => cProps.toggleModal('attention-traditional')}
							title="Select background & logo colors"
							open={cProps.modal === 'attention-traditional'}
						/>
						<AttentionModal
							subtitle={`You can only use a maximum of ${limit.IMAGES} images`}
							onClose={() => cProps.toggleModal('attention-images-limit')}
							open={cProps.modal === 'attention-images-limit'}
						/>
						<AttentionModal
							secondaryButtonText="Cancel"
							primaryButtonText="Delete"
							onSecondaryClick={() => cProps.toggleModal('delete-draft')}
							onPrimaryClick={() => {
								cProps.toggleModal('delete-draft');
								cProps.deleteDraft();
							}}
							subtitle="Are you sure you want to delete your sketch?"
							onClose={() => cProps.toggleModal('')}
							open={cProps.modal === 'delete-draft'}
						/>
						<AttentionModal
							secondaryButtonText="Cancel"
							primaryButtonText="Delete"
							onSecondaryClick={() => cProps.toggleModal('delete-unsaved-sketch')}
							onPrimaryClick={() => {
								cProps.toggleModal('delete-unsaved-sketch');
								browserHistory.history.push('/dashboard');
							}}
							subtitle="Are you sure you want to delete your sketch?"
							onClose={() => cProps.toggleModal('')}
							open={cProps.modal === 'delete-unsaved-sketch'}
						/>
						<AddTextModal
							onSubmit={(data) => cProps.addTextObject(data.text)}
							onClose={() => cProps.toggleModal('text')}
							open={cProps.modal === 'text'}
						/>
						<MatLayersModal
							onChange={cProps.setZIndex}
							onClose={() => cProps.toggleModal('layers')}
							open={cProps.modal === 'layers'}
						/>
						<AddNewImageModal
							onUnmount={cProps.onImageModalUnmount}
							onClose={() => {
								cProps.toggleModal('image');
								if (cProps.modalType !== 'addImageToMat') {
									cProps.setModalType('addImageToMat');
								}
							}}
							open={cProps.modal === 'image'}
							sketchImages={cProps.sketchImages}
							modalType={cProps.modalType}
							matType={cProps.matType}
						/>
						<AttentionModal
							secondaryButtonText="Cancel"
							primaryButtonText="Clear"
							onSecondaryClick={() => cProps.toggleModal('clear-colors')}
							onPrimaryClick={() => {
								cProps.toggleModal('clear-colors');
								cProps.clearColors();
							}}
							subtitle="Are you sure you want to clear all colors?"
							onClose={() => cProps.toggleModal('')}
							open={cProps.modal === 'clear-colors'}
						/>
					</>
				)}
			/>

			{/* LYL BG modal */}

			{state.mat.matType === matType.LYL.name && (
				<Container
					mapDispatch={{
						onImageClick: setLYLBGImage,
						toggleModal,
					}}
					mapState={(state) => ({
						modalIsOpen: state.mat.modal === 'LYLImage',
						list: state.mat.lylImages,
					})}
					render={(cProps) => (
						<BackgroundModal
							onImageClick={(obj) => {
								cProps.toggleModal('');
								cProps.onImageClick(obj);
							}}
							noOfColumns={3}
							bodyHeight="full"
							imageList={cProps.list}
							closeIcon={false}
							maxWidth="maxFull"
							onClose={() => cProps.toggleModal('')}
							open={cProps.modalIsOpen}
						/>
					)}
				/>
			)}

			{/* PHOTO mode. Backgrounds modal */}

			{masksByTypes[state.mat.matType] & flags.BG_TOOL ? (
				<Container
					mapDispatch={{
						setBGImageFromObject,
						addImageObjectFromBg,
						setModalType,
						toggleModal,
						delBGImage,
						addEditBG,
						delEditBG,
					}}
					mapState={(state) => {
						const bgImage = state.mat.bgImage;
						const images = [];

						if (state.mat.modal === 'background') {
							Object.keys(state.mat.objects).forEach((key) => {
								if (state.mat.objects[key].objectType === 'image') {
									const object = state.mat.objects[key];
									images.push({ url: object.url, id: object.id });
								}
							});

							if (bgImage) {
								images.push({ url: bgImage, id: 'bg' });
							}
						}

						return {
							matOrientation: state.mat.matOrientation,
							restoreEditBg: state.mat.restoreEditBg,
							bgImageJSON: state.mat.bgImageJSON,
							matSize: getById(state.mat.matSizes, 'id', state.mat.selectedSizeId).value,
							mobile: state.system.mobile,
							editBg: state.mat.editBg,
							modal: state.mat.modal,
							bgImage,
							images,
						};
					}}
					render={(cProps) => (
						<>
							{cProps.modal === 'background' && (
								<BackgroundModal
									onImageClick={(image) => {
										if (cProps.setBGImageFromObject(image)) {
											cProps.toggleModal('');
										}
									}}
									onClear={
										cProps.bgImage
											? () => {
													cProps.toggleModal('');
													cProps.addImageObjectFromBg();
											  }
											: null
									}
									imageList={cProps.images}
									onClose={() => cProps.toggleModal('')}
									open
								/>
							)}
							{cProps.modal === 'editBackgroundImage' && (
								<EditBackgroundImageModal
									onClose={() => {
										cProps.delEditBG();
										cProps.toggleModal('');
									}}
									onSave={async (image, json) => {
										const setImage = await cProps.setBGImageFromObject(image, json);
										if (setImage) {
											cProps.delEditBG();
											cProps.toggleModal('');
										}
									}}
									{...cProps}
									open
								/>
							)}
						</>
					)}
				/>
			) : null}

			{/* Image filters modal */}

			{MASK & flags.IMAGE && masksByTypes[state.mat.matType] & flags.IMAGE ? (
				<Container
					mapDispatch={{ toggleModal, editObject, selectMultiplyColors }}
					mapState={(state) => ({
						object: state.mat.objects[state.mat.activeObjectId],
						colors: state.mat.colors,
						modal: state.mat.modal,
						currentColors: [...state.mat.selectedColors, state.mat.bgColor],
					})}
					render={(cProps) => (
						<>
							{(cProps.modal === 'replaceColor' || cProps.modal === 'removeColor') && (
								<MatImageFiltersModal
									onClose={() => cProps.toggleModal('')}
									onSave={(base64, colors) => {
										cProps.toggleModal('');
										cProps.editObject({ id: cProps.object.id, url: base64 });
										// Add new sketch colors only if they aren't currently selected in Color Tool
										if (colors.length) {
											cProps.selectMultiplyColors(
												colors.filter((color) => !cProps.currentColors.includes(color))
											);
										}
									}}
									colors={cProps.colors}
									object={cProps.object}
									mode={cProps.modal === 'replaceColor' ? 'replace' : 'remove'}
									open
								/>
							)}
							{cProps.modal === 'cropImage' && (
								<ImageCropModal
									onClose={() => cProps.toggleModal('')}
									onSave={(base64, widthRatio, heightRatio) => {
										cProps.toggleModal('');
										cProps.editObject({
											id: cProps.object.id,
											url: base64,
											width: cProps.object.width * widthRatio,
											height: cProps.object.height * heightRatio,
										});
									}}
									object={cProps.object}
									open
								/>
							)}
						</>
					)}
				/>
			) : null}

			{/* Edit text modal */}

			{MASK & flags.TEXT && masksByTypes[state.mat.matType] & flags.TEXT ? (
				<Container
					mapDispatch={{ setEditTextId, editText }}
					mapState={(state) => ({
						text: state.mat.editTextId
							? state.mat.objects[state.mat.editTextId].text
							: null,
					})}
					render={(cProps) => (
						<>
							<AddTextModal
								onSubmit={(data) => cProps.editText(data.text)}
								onClose={() => cProps.setEditTextId(null)}
								open={!!cProps.text}
								text={clearSpace(cProps.text)} // Since I add space to the end of the line if the user uses an Italic font, then when editing this text I remove this space.
							/>
						</>
					)}
				/>
			) : null}

			{/* Submit sketch sidebar */}

			<Container
				mapDispatch={{ onClose: closeSubmit }}
				mapState={(state) => ({
					isOpen: state.mat.submitIsOpen,
				})}
				render={SubmitSketch}
			/>

			{/* Joyride */}

			{MASK & flags.TUTORIAL && masksByTypes[state.mat.matType] & flags.TUTORIAL ? (
				<div className="mat-struct__joyride">
					<Container
						mapDispatch={{ toggleTutorial }}
						mapState={(state) => ({
							isTutorialShow: state.mat.tutorial,
						})}
						render={(cProps) => (
							<Joyride
								tooltip={TutorialStep}
								onSkip={cProps.toggleTutorial}
								steps={
									state.mat.matType === matType.LYL.name
										? getMatTutorial(isMobile, state).steps.filter((step) => {
												return step.skipForLYLMatType !== true;
										  })
										: getMatTutorial(isMobile, state).steps
								}
								run={cProps.isTutorialShow}
							/>
						)}
					/>
				</div>
			) : null}
		</>
	);
}
