import { NavLink } from 'react-router-dom';
import types from 'prop-types';
import React from 'react';

// Components

import Dropdown from '../../../../components/ui/Dropdown';
import FontIcon from '../../../../components/block/FontIcon';
import Bool from '../../../../components/rp/Bool';

// Styles

import './styles.scss';

// ----------------

// Type of props

MarketingHeader.propTypes = {
	handleGridModeToggle: types.func.isRequired,
	activeGridMode: types.string.isRequired,
	navLinks: types.array.isRequired,
};

// ----------------

export default function MarketingHeader(props) {
	const { handleGridModeToggle, activeGridMode } = props;

	return (
		<header className="marketing-header">
			<div>
				{props.navLinks.map(({ label, icon, to, id }) => (
					<NavLink
						activeClassName="marketing-header__nav-link--active"
						className="marketing-header__nav-link"
						exact
						key={id}
						to={to || ''}
					>
						<FontIcon size="xl" icon={icon} />
						<span>{label}</span>
					</NavLink>
				))}
			</div>

			{/* Actions dropdown */}

			<Bool
				render={({ isTrue, handleChange }) => (
					<Dropdown
						handleToggle={handleChange}
						isShowing={isTrue}
						actions={[
							{
								onClick: () => handleGridModeToggle('block'),
								active: activeGridMode === 'block',
								label: 'Grid View',
								icon: 'view_module',
								id: 'mats-view-block-button',
							},
							{
								onClick: () => handleGridModeToggle('list'),
								active: activeGridMode === 'list',
								label: 'List View',
								icon: 'view_stream',
								id: 'mats-view-list-button',
							},
						]}
						button={
							<div
								className="dropdown__show-button marketing-header__header-dropdown-btn"
								onClick={handleChange}
							>
								<FontIcon size="xl" icon="more_vert" />
							</div>
						}
					/>
				)}
			/>
		</header>
	);
}
