import createActionType from '../../utils/createActionType';
import { logout } from '../actionCreators/auth';
import fetch from '../../utils/fetch';

// ----------------

export default (store) => (next) => (action) => {
	if (typeof action === 'function') {
		return action(store);
	}

	next(action);

	if (/.+_\$NETWORK$/.test(action.type)) {
		const { endpoint, headers, method, payload, type, URLE, withoutToken } = action;

		async function handler() {
			try {
				const result = await fetch(
					endpoint,
					{
						headers,
						method: method || 'GET',
						...(payload ? { body: payload } : {}),
					},
					URLE,
					withoutToken
				);

				store.dispatch({
					type: createActionType(type),
					payload: result,
				});

				return result;
			} catch (error) {
				if (error.type === 'NETWORK') {
					store.dispatch({
						type: createActionType(type, false),
					});

					switch (error.code) {
						case 400: {
							throw error;
						}

						case 401: {
							// Logout

							store.dispatch(logout(true));
							return;
						}

						case 404: {
							// 404

							break;
						}

						default:
							throw new Error();
					}
				} else {
					throw new Error('Unknown system error');
				}
			}
		}

		return handler();
	}
};
