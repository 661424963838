import React from 'react';

// Hooks

import { useModal } from '../../hooks';

// Components

import {
	NewSketchRequestModal,
	ManageDashboardModal,
	DashboardPageStruct,
	LogoMatTypeModal,
	MatPreviewModal,
	AttentionModal,
	MainStruct,
	MainHeader,
	IconButton,
	Container,
} from '../../components';

// Action creators

import { setActiveSketchPreview } from '../../redux/actionCreators/sketch';
import { toggle } from '../../redux/actionCreators/ui';
import {
	deleteSketchFromFavourite$network,
	addSketchToFavourite$network,
	toggleSketchForFavourite,
} from '../../redux/actionCreators/mats';
import {
	simpleDeleteDraft,
	setBGOption,
	setMatName,
	setMatType,
} from '../../redux/actionCreators/mat';

// Action types

import {
	GET_LOCATION_SKETCHES_$NETWORK,
	GET_SKETCHES_$NETWORK,
	GET_DRAFTS_$NETWORK,
} from '../../redux/types/mats';

// Selectors

import getById from '../../redux/selectors/getById';

// Static

import browserHistory from '../../static/global';
import { matType } from '../../static/constants';

// Utils

import { getMatFavouriteStatus, getMatStatus } from '../../utils';

// ----------------

export default function Dashboard(props) {
	// Preparation

	const { modal, handleModalToggle } = useModal();

	// Render

	return (
		<>
			<MainStruct
				header={
					<Container
						mapDispatch={{ toggle }}
						mapState={(state) => ({
							favouriteQuantity: state.mats.favouriteSketchesQty,
							sidebarOpen: state.ui.active.sidebar,
						})}
						render={(cProps) => (
							<MainHeader
								headerCustomButtons={[
									<IconButton
										handleClick={() => handleModalToggle('manageDashboardModal')}
										buttonSize="stretch"
										iconType="fontIcon"
										iconSize="xl"
										icon="settings"
										key={1}
										id="dashboard-manage-button"
									/>,
								]}
								{...cProps}
							/>
						)}
					/>
				}
			>
				<Container
					mapDispatch={{
						deleteSketchFromFavourite$network,
						addSketchToFavourite$network,
						setActiveSketchPreview,
						toggle,
					}}
					mapState={(state) => ({
						locationSketchesNetworkProcess: state.network[GET_LOCATION_SKETCHES_$NETWORK],
						sketchesNetworkProcess: state.network[GET_SKETCHES_$NETWORK],
						draftsNetworkProcess: state.network[GET_DRAFTS_$NETWORK],
						dashboardFilters: state.user.userSettings.dashboardFilters,
						locationSketches: state.mats.locationSketches,
						sketches: state.mats.sketches,
						drafts: state.mats.drafts,
					})}
					render={({
						dashboardFilters: {
							myLocationSketches,
							createSketch,
							searchLogo,
							mySketches,
							myDrafts,
						},
						...cProps
					}) => (
						<DashboardPageStruct
							deleteSketchFromFavourite={cProps.deleteSketchFromFavourite$network}
							addSketchToFavourite={cProps.addSketchToFavourite$network}
							manageMyDashboard={{
								viewMyLocationSketches: myLocationSketches,
								viewNewSketchRequest: createSketch,
								viewSearchDataBase: searchLogo,
								viewMySketches: mySketches,
								viewMyDrafts: myDrafts,
							}}
							myLocationSketches={cProps.locationSketches}
							mySketchesData={cProps.sketches}
							myDraftsData={cProps.drafts}
							{...cProps}
						/>
					)}
				/>
			</MainStruct>

			{/* Modals */}

			<Container
				mapDispatch={{
					toggleSketchForFavourite,
					simpleDeleteDraft,
					setMatName,
					toggle,
				}}
				mapState={(state) => ({
					attentionModalOpen: state.ui.active.attentionModal,
					matDetails:
						(state.sketch.activeSketchPreview &&
							getById(
								state.mats[state.sketch.activeSketchPreview.list],
								state.sketch.activeSketchPreview.status === 'draft'
									? 'draftId'
									: 'sketchRequestID',
								state.sketch.activeSketchPreview.id
							)) ||
						{},
					modalOpen: state.ui.active.matPreviewModal,
				})}
				render={({
					matDetails: {
						sketchRequestID,
						productGroupID,
						attributeName,
						companyName,
						requestDate,
						designName,
						imageName,
						sketchRef,
						sketchURL,
						created,
						draftId,
						scanURL,
						...matProps
					},
					...cProps
				}) => (
					<>
						<MatPreviewModal
							onVariation={() => {
								cProps.setMatName(designName);
								cProps.toggle('matPreviewModal');
								cProps.toggle('variationSketchModal');
							}}
							onApprove={() => {
								cProps.toggle('matPreviewModal');
								cProps.toggle('customerApprovalModal');
							}}
							onPreview={() => {
								cProps.toggle('matPreviewModal');
								browserHistory.history.push(`/preview/${sketchRequestID}`);
							}}
							onDelete={() => {
								cProps.toggle('matPreviewModal');
								cProps.toggle('attentionModal');
							}}
							onClose={() => cProps.toggle('matPreviewModal')}
							onEdit={() => {
								cProps.toggle('matPreviewModal');
								browserHistory.history.push(`/draft/${draftId}`);
							}}
							open={cProps.modalOpen || false}
							data={{
								withFavouriteIcon: true,
								onFavouriteClick: (id, favourite) => {
									const sketchList =
										getMatStatus(matProps) === 'inProcess'
											? 'sketches'
											: 'locationSketches';
									favourite === 1
										? cProps.toggleSketchForFavourite(id, 'delete', sketchList)
										: cProps.toggleSketchForFavourite(id, 'add', sketchList);
								},
								companyName: companyName,
								imageName: imageName,
								sketchRef: sketchRef,
								favourite: getMatFavouriteStatus(matProps),
								status: getMatStatus(matProps),
								image: getMatStatus(matProps) === 'inProcess' ? scanURL : sketchURL,
								type: productGroupID || undefined,
								size: attributeName,
								name: designName,
								date: created || requestDate,
								id: draftId || sketchRequestID,
							}}
							{...cProps}
						/>

						<AttentionModal
							secondaryButtonText="Cancel"
							primaryButtonText="Delete"
							onSecondaryClick={() => cProps.toggle('attentionModal')}
							onPrimaryClick={() => {
								cProps.simpleDeleteDraft(draftId);
								cProps.toggle('attentionModal');
							}}
							subtitle="Are you sure you want to delete your draft?"
							onClose={() => cProps.toggle('attentionModal')}
							open={cProps.attentionModalOpen || false}
						/>
					</>
				)}
			/>

			<ManageDashboardModal
				onClose={() => {
					handleModalToggle(null);
				}}
				open={modal === 'manageDashboardModal'}
			/>

			<Container
				mapDispatch={{ toggle, setMatName, setMatType, setBGOption }}
				mapState={(state) => ({
					manageDashboard: state.ui.active.manageDashboardModal,
					name: state.ui.active.newSketchRequestModal,
					type: state.ui.active.logoMatTypeModal,
				})}
				render={(cProps) => (
					<>
						<NewSketchRequestModal
							onSubmit={(data) => {
								cProps.setMatName(data);
								cProps.toggle('logoMatTypeModal');
							}}
							onClose={() => {
								cProps.toggle('newSketchRequestModal');
							}}
							title="New sketch request"
							open={!!cProps.name}
						/>

						<ManageDashboardModal
							onClose={() => {
								cProps.toggle('manageDashboardModal');
							}}
							open={!!cProps.manageDashboard}
						/>

						<LogoMatTypeModal
							onClose={() => {
								cProps.toggle('logoMatTypeModal');
							}}
							onSubmit={(data) => {
								cProps.setMatType(data);

								const option = data === matType.LYL.name ? 'image' : 'color';
								cProps.setBGOption(option, true);

								browserHistory.history.push('/draft');
								cProps.toggle('logoMatTypeModal');
							}}
							open={!!cProps.type}
						/>
					</>
				)}
			/>
		</>
	);
}
