import * as types from '../types/google';

// Static

import endpoints from '../../static/endpoints/google';

//Selectors

import { getImagesNextPage, getImages } from '../selectors/google';
// -------- Async action creator --------

export const getMoreImages = (payload) => async (store) => {
	const { searchValue, start } = getImagesNextPage(store.getState());

	await store.dispatch(getMoreImages$network(searchValue, start));
};

export const restoreBranch = () => (store) => {
	const images = getImages(store.getState());

	if (images.length) {
		store.dispatch({ type: types.RESTORE_BRANCH });
	}
};

// -------- Async network action creator --------

export const getImages$network = (searchValue) => ({
	withoutToken: true,
	endpoint: `${endpoints.IMAGES}&imgSize=xlarge&q=${searchValue}&num=9&start=1&fileType=png`,
	type: types.GET_IMAGES_$NETWORK,
});

export const getMoreImages$network = (searchValue, start) => ({
	withoutToken: true,
	endpoint: `${endpoints.IMAGES}&imgSize=xlarge&q=${searchValue}&num=9&start=${start}&fileType=png`,
	type: types.GET_MORE_IMAGES_$NETWORK,
});
