import React, { useEffect } from 'react';

// ----------------

export default function ComponentWrapper(props) {
	const {
		historyProps,
		baseTitle,
		onUnMount,
		component: Component,
		onMount,
		title,
	} = props;

	useEffect(() => {
		if (title) document.title = (baseTitle || '') + (title || '');

		if (onMount) onMount(historyProps);

		if (onUnMount) return onUnMount;
	}, []); // eslint-disable-line

	return <Component {...(historyProps ? historyProps : {})} />;
}
