import React, { useState } from 'react';
import classNames from 'classnames';
import types from 'prop-types';

// Components

import MarketingHeader from '../../../../components/block/headers/MarketingHeader';
import ButtonUp from '../../../../components/ui/ButtonUp';
import List from '../../../../components/block/List';

// Styles

import './styles.scss';

// Data

import marketingLinks from './data';

// Formating card view mode

const matViewFormat = {
	block: 'block',
	list: 'row',
};

// ----------------

// Type of props

MarketingStruct.propTypes = {
	getNetworkProcess: types.bool,
	listItemKeyName: types.string,
	defaultGridMode: types.oneOf(['block', 'list']),
	materials: types.array,
	card: types.oneOfType([types.node, types.func]),
};

// ----------------

export default function MarketingStruct(props) {
	const {
		getNetworkProcess,
		listItemKeyName,
		defaultGridMode,
		materials,
		card: Card,
	} = props;

	// State

	const [activeGridMode, setMode] = useState(defaultGridMode);

	// Modify styles

	const modifyViewGrid = classNames({
		[` marketing-struct__mats-grid--${activeGridMode}`]: activeGridMode,
	});

	// Render

	return (
		<div className="marketing-struct">
			<div className="marketing-struct__header-wrapper">
				<MarketingHeader
					handleGridModeToggle={setMode}
					activeGridMode={activeGridMode}
					navLinks={marketingLinks}
				/>
			</div>
			<div className="marketing-struct__mats-grid-wrapper">
				<List
					listContentClassName={`marketing-struct__mats-grid${modifyViewGrid} show-info`}
					networkProcess={getNetworkProcess}
					listItem={(props) => {
						return <Card viewMode={matViewFormat[activeGridMode]} {...props.data} />;
					}}
					keyName={listItemKeyName}
					list={materials}
				/>
			</div>
			<div className="marketing-struct__button-up-wrapper">
				<ButtonUp />
			</div>
		</div>
	);
}
