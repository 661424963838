import React, { memo } from 'react';
import classNames from 'classnames';
import filesize from 'filesize';
import types from 'prop-types';

// Components

import SvgIcon from '../../../../components/block/SvgIcon';
import Button from '../../../../components/ui/Button';

// Assets

import { pdfIcon } from '../../../../assets/icons';

// Styles

import './styles.scss';

// ----------------

// Type of props

FlyerCard.propTypes = {
	fileExtension: types.string,
	viewMode: types.string.isRequired,
	onClick: types.func,
	title: types.string,
	size: types.oneOfType([types.string, types.number]),
	url: types.string.isRequired,
	id: types.oneOfType([types.string, types.number]),
};

// Default value for props

FlyerCard.defaultProps = {
	fileName: '-',
	onClick: () => null,
	title: '-',
	size: '-',
};

// ----------------

function FlyerCard(props) {
	const { fileExtension, viewMode, onClick, title, size, url, id } = props;

	// Modify styles

	const modify = classNames({
		[` flyer-card--view-${viewMode}`]: viewMode,
	});

	// Render

	return (
		<div className={`flyer-card${modify}`} onClick={() => onClick(id)}>
			<div className="flyer-card__icon-wrapper">
				<SvgIcon size="xxxl" url={pdfIcon} />
			</div>
			<div className="flyer-card__label">
				<p>{title}</p>
			</div>
			<div className="flyer-card__size">
				<span className="text-small">{fileExtension}</span>
				{!!size && <span className="text-small">, {filesize(size)}</span>}
			</div>
			<div className="flyer-card__button-wrapper">
				<Button
					textUppercase
					asSimpleLink
					newTabLink
					link={url}
					id="mat-modal-submit-button"
					fluid
				>
					View
				</Button>
			</div>
		</div>
	);
}

export default memo(FlyerCard);
