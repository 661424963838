// Static

import { useDispatch } from 'react-redux';

// Action creators

import {
	deleteActiveObject,
	rotateActiveObject,
	alignActiveObject,
	copyActiveObject,
} from '../../../redux/actionCreators/mat';

// ----------------

export default () => {
	const dispatch = useDispatch();
	return [
		// Rotate

		{
			onClick: () => {
				dispatch(rotateActiveObject('left'));
			},
			icon: 'rotate_left',
			id: 'tool-standard-actions-rotate-left',
		},
		{
			onClick: () => {
				dispatch(rotateActiveObject('right'));
			},
			icon: 'rotate_right',
			id: 'tool-standard-actions-rotate-right',
		},

		// Copy

		{
			onClick: () => {
				dispatch(copyActiveObject());
			},
			icon: 'filter_none',
			id: 'tool-standard-actions-copy',
		},

		// Align

		{
			onClick: () => {
				dispatch(alignActiveObject('centerV'));
			},
			icon: 'vertical_align_center',
			id: 'tool-standard-actions-vertical-align-center-v',
		},
		{
			onClick: () => {
				dispatch(alignActiveObject('centerH'));
			},
			rotate90: true,
			icon: 'vertical_align_center',
			id: 'tool-standard-actions-vertical-align-bottom-h',
		},
		{
			onClick: () => {
				dispatch(alignActiveObject());
			},
			rotate45: true,
			icon: 'fullscreen_exit',
			id: 'tool-standard-actions-vertical-align-center',
		},

		// Delete

		{
			onClick: () => {
				dispatch(deleteActiveObject());
			},
			danger: true,
			icon: 'delete_forever',
			id: 'tool-standard-actions-delete',
		},
	];
};
