import { fabric } from 'fabric-with-gestures';

export default function initCenteringGuidelines(canvas) {
	var centerLineMargin = 1,
		centerLineColor = '#ff006b',
		centerLineWidth = 1,
		ctx = canvas.getSelectionContext(),
		scaleX,
		scaleY;

	function getMap(dimensionVolume) {
		let canvasMap = {};

		for (
			var i = dimensionVolume - centerLineMargin,
				len = dimensionVolume + centerLineMargin;
			i <= len;
			i++
		) {
			canvasMap[Math.round(i)] = true;
		}

		return canvasMap;
	}

	function showGuideline(x1, y1, x2, y2) {
		ctx.save();
		ctx.strokeStyle = centerLineColor;
		ctx.lineWidth = centerLineWidth;
		ctx.setLineDash([10, 10]);
		ctx.beginPath();
		ctx.moveTo(x1, y1);
		ctx.lineTo(x2, y2);
		ctx.stroke();
		ctx.restore();
	}

	var isInVerticalCenter,
		isInHorizontalCenter,
		isTopEdge,
		isBottomEdge,
		isLeftEdge,
		isRightEdge,
		isTopInHorizontalCenter,
		isBottomInHorizontalCenter,
		isLeftInVerticalCenter,
		isRightInVerticalCenter;

	canvas.on('mouse:down', function () {
		scaleX = canvas.viewportTransform[0];
		scaleY = canvas.viewportTransform[3];
	});

	canvas.on('object:moving', function (e) {
		var object = e.target,
			objectCenter = object.getCenterPoint(),
			transform = canvas._currentTransform;

		if (!transform) return;

		const objectWidth = object.width * object.scaleX;
		const objectHeight = object.height * object.scaleY;

		// Forced to use directly 'canvas.width' and 'canvas.height' without assigning them to variables,
		// otherwise width/height will not be updated when canvas size/orientation are changed

		const canvasWidthCenterMap = getMap(Math.round(canvas.width / scaleX / 2));
		const canvasHeightCenterMap = getMap(Math.round(canvas.height / scaleY / 2));
		const topLeftEdgeMap = getMap(0);

		isInVerticalCenter = Math.round(objectCenter.x) in canvasWidthCenterMap;
		isInHorizontalCenter = Math.round(objectCenter.y) in canvasHeightCenterMap;

		isTopEdge = Math.round(objectCenter.y - objectHeight / 2) in topLeftEdgeMap;
		isBottomEdge =
			Math.round(objectCenter.y + objectHeight / 2) in
			getMap(Math.round(canvas.height / scaleY));
		isLeftEdge = Math.round(objectCenter.x - objectWidth / 2) in topLeftEdgeMap;
		isRightEdge =
			Math.round(objectCenter.x + objectWidth / 2) in
			getMap(Math.round(canvas.width / scaleX));

		isTopInHorizontalCenter =
			Math.round(objectCenter.y - objectHeight / 2) in canvasHeightCenterMap;
		isBottomInHorizontalCenter =
			Math.round(objectCenter.y + objectHeight / 2) in canvasHeightCenterMap;
		isLeftInVerticalCenter =
			Math.round(objectCenter.x - objectWidth / 2) in canvasWidthCenterMap;
		isRightInVerticalCenter =
			Math.round(objectCenter.x + objectWidth / 2) in canvasWidthCenterMap;

		if (isInHorizontalCenter || isInVerticalCenter) {
			object.setPositionByOrigin(
				new fabric.Point(
					isInVerticalCenter ? Math.round(canvas.width / scaleX / 2) : objectCenter.x,
					isInHorizontalCenter ? Math.round(canvas.height / scaleY / 2) : objectCenter.y
				),
				'center',
				'center'
			);
		}
	});

	canvas.on('before:render', function () {
		canvas.clearContext(canvas.contextTop);
	});

	canvas.on('after:render', function () {
		if (isInVerticalCenter || isLeftInVerticalCenter || isRightInVerticalCenter) {
			showGuideline(
				Math.round(canvas.width / 2) + 0.5,
				0,
				Math.round(canvas.width / 2) + 0.5,
				Math.round(canvas.height)
			);
		}
		if (isInHorizontalCenter || isTopInHorizontalCenter || isBottomInHorizontalCenter) {
			showGuideline(
				0,
				Math.round(canvas.height / 2) + 0.5,
				Math.round(canvas.width),
				Math.round(canvas.height / 2) + 0.5
			);
		}
		if (isTopEdge) {
			showGuideline(0, 1, Math.round(canvas.width), 1);
		}
		if (isBottomEdge) {
			showGuideline(
				0,
				Math.round(canvas.height) - 1.1,
				Math.round(canvas.width),
				Math.round(canvas.height) - 1.1
			);
		}
		if (isLeftEdge) {
			showGuideline(1, 0, 1, Math.round(canvas.height));
		}
		if (isRightEdge) {
			showGuideline(
				Math.round(canvas.width) - 1.1,
				0,
				Math.round(canvas.width) - 1.1,
				Math.round(canvas.height)
			);
		}
	});

	canvas.on('mouse:up', function () {
		// clear these values, to stop drawing guidelines once mouse is up
		isInVerticalCenter = isInHorizontalCenter = isTopEdge = isBottomEdge = isLeftEdge = isRightEdge = isLeftInVerticalCenter = isRightInVerticalCenter = isTopInHorizontalCenter = isBottomInHorizontalCenter = null;
		canvas.renderAll();
	});
}
