import React, { memo } from 'react';
import classNames from 'classnames';
import types from 'prop-types';
import get from 'lodash/get';

// Styles

import './styles.scss';

// ----------------

// Type of props

UIFieldWrapper.propTypes = {
	withPadding: types.bool,
	titleSize: types.oneOf(['default', 'small']),
	children: types.node.isRequired,
	status: types.exact({
		success: types.bool,
		error: types.bool,
		des: types.string,
	}),
	theme: types.oneOf([
		'secondaryDark',
		'onPrimaryDark',
		'onPrimary',
		'secondary',
		'onFourth',
	]),
	title: types.string,
	fluid: types.bool,
};

// Default value for props

UIFieldWrapper.defaultProps = {
	withPadding: true,
	titleSize: 'default',
	theme: 'onPrimary',
};

// ----------------

function UIFieldWrapper(props) {
	const { withPadding, titleSize, children, theme, fluid, title } = props;

	const { error, success, des } = get(props, 'status', {});

	// Modify styles

	const modify = classNames({
		[` ui-field-wrapper--theme-${theme}`]: theme,
		' ui-field-wrapper--with-padding': withPadding,
		' ui-field-wrapper--fluid': fluid,
	});

	const modifyStatus = classNames({
		' ui-field-wrapper__status--success': success,
		' ui-field-wrapper__status--error': !!error,
	});

	const modifyTitle = classNames({
		[` ui-field-wrapper__title--size-${titleSize}`]: titleSize,
	});

	// Render

	return (
		<div className={`ui-field-wrapper${modify}`}>
			{title && <p className={`ui-field-wrapper__title${modifyTitle}`}>{title}</p>}
			{children}
			{des && withPadding && (
				<p className={`ui-field-wrapper__status${modifyStatus}`}>{des}</p>
			)}
		</div>
	);
}

export default memo(UIFieldWrapper);
