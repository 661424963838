import classNames from 'classnames';
import types from 'prop-types';
import React from 'react';

// Styles

import './styles.scss';

// ----------------

// Type of props

Loader.propTypes = {
	stretch: types.bool,
	color: types.oneOf([
		'onPrimaryDark',
		'secondaryDark',
		'onPrimary',
		'secondary',
		'fourth',
		'white',
	]),
	size: types.oneOf(['sm', 'md', 'lg']),
};

// Default value for props

Loader.defaultProps = {
	color: 'onPrimaryDark',
	size: 'md',
};

// ----------------

export default function Loader(props) {
	const { stretch, color, size } = props;

	// Modify styles

	const modify = classNames({
		[` loader--${props.color}`]: color,
		[` loader--${props.size}`]: size,
		' loader--stretch': stretch,
	});

	// Render

	return (
		<p className={`loader${modify}`}>
			<span />
			<span />
			<span />
		</p>
	);
}
