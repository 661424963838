import createRouter, { TopWrapper } from '../modules/react-router-x';
import Cookies from 'js-cookie';
import query from 'querystringify';
import React from 'react';

// Components

import {
	CustomerApprovalModal,
	NewSketchRequestModal,
	SystemNotification,
	FullScreenLoader,
	Container,
} from '../components';

// Static

import { matType, cookie } from '../static/constants';
import browserHistory from '../static/global';

// Selectors

import { getUserData } from '../redux/selectors/user';

// Top routes

import MyFavouriteSketches from './top/MyFavouriteSketches';
import MyLocationSketches from './top/MyLocationSketches';
import MySketches from './top/MySketches';
import MatPreview from './top/MatPreview';
import Dashboard from './top/Dashboard';
import Marketing from './top/Marketing';
import MyDrafts from './top/MyDrafts';
import Search from './top/Search';
import Auth from './top/Auth';
import Mat from './top/Mat';

// Sub routes

import MarketingVideos from './sub/MarketingVideos';
import MarketingFlyers from './sub/MarketingFlyers';
import ResetPassword from './sub/ResetPassword';
import Signup from './sub/Signup';
import Signin from './sub/Signin';

// Action creators

import { getMarketingMaterials } from '../redux/actionCreators/marketing';
import { getLocations$network } from '../redux/actionCreators/meta';
import { getUserSettings } from '../redux/actionCreators/user';
import { toggle } from '../redux/actionCreators/ui';
import {
	getFavouriteSketchesQuantity$network,
	getFavouriteSketches$network,
	getLocationSketches$network,
	getSearchSketches$network,
	getSketches$network,
	getDrafts$network,
} from '../redux/actionCreators/mats';
import {
	getSketchDataById$network,
	updateSketchDocument,
	setSketchVariation,
	setSketchDataById,
	restoreBranch as restoreSketchBranch,
} from '../redux/actionCreators/sketch';
import {
	generateSketchPdf,
	getDraft$network,
	restoreBranch,
	matSnapshots,
	getLYLImages,
	toggleModal,
	restoreMat,
	setMatName,
	getColors,
	getSizes,
	matReady,
} from '../redux/actionCreators/mat';
import { startLoading, endLoading } from '../redux/actionCreators/system';

// Utils

import addQuery from '../utils/addQuery';

// ----------------

const main = {
	notFoundRedirect: '/dashboard',
	name: 'main',
	routes: [
		{
			redirect: '/dashboard',
			path: '/',
		},
		{
			component: () => <Dashboard setting={browserHistory.pages.dashboard} />,
			title: 'Dashboard',
			path: '/dashboard',
			onMount: async () => {
				const { dispatch, getState } = browserHistory.store;
				const { CustomerID, UserID } = await getState().user.userData;

				dispatch(
					getLocationSketches$network({
						recordCount: 6,
						locationID: CustomerID,
						placedByID: UserID,
					})
				);

				dispatch(
					getSketches$network({
						recordCount: 6,
						placedByID: UserID,
					})
				);

				dispatch(
					getDrafts$network({
						recordCount: 6,
						pageNumber: 0,
						sortExpr: 'date',
						sortDir: 'desc',
					})
				);
			},
		},
		{
			component: () => <MyDrafts />,
			title: 'My Drafts',
			path: '/my-drafts',
			onMount: () => {
				const { history, store } = browserHistory;
				const q = query.parse(history.location.search);

				Object.keys(q).forEach((item) => addQuery({}, [item]));

				store.dispatch(
					getDrafts$network({
						recordCount: 12,
						pageNumber: 0,
						sortExpr: 'date',
						sortDir: 'desc',
					})
				);
			},
		},
		{
			component: () => <MySketches />,
			title: 'My Sketches',
			path: '/my-sketches',
			onMount: async () => {
				const { history, store } = browserHistory;
				const { UserID } = await getUserData(store.getState());
				const q = query.parse(history.location.search);

				Object.keys(q).forEach((item) => addQuery({}, [item]));

				store.dispatch(getSketches$network({ placedByID: UserID, searchOrder: 'desc' }));
			},
		},
		{
			component: () => <MyLocationSketches />,
			title: 'My Location Sketches',
			path: '/my-location-sketches',
			onMount: async () => {
				const { history, store } = browserHistory;
				const { CustomerID, UserID } = await getUserData(store.getState());
				const q = query.parse(history.location.search);

				Object.keys(q).forEach((item) => addQuery({}, [item]));

				store.dispatch(
					getLocationSketches$network({
						locationID: CustomerID,
						placedByID: UserID,
					})
				);
			},
		},
		{
			component: () => <MyFavouriteSketches />,
			title: 'My Favorite Sketches',
			path: '/my-favourite-sketches',
			onMount: () => {
				const { history, store } = browserHistory;
				const q = query.parse(history.location.search);

				Object.keys(q).forEach((item) => addQuery({}, [item]));

				store.dispatch(
					getFavouriteSketches$network({
						recordCount: 12,
						pageNumber: 1,
					})
				);
			},
		},
		{
			component: () => <Marketing subRouter={routers.marketingSub} />,
			notExact: true,
			path: '/marketing',
			onMount: () => {
				const { dispatch } = browserHistory.store;

				dispatch(getMarketingMaterials());
			},
		},
		{
			component: () => <Search />,
			title: 'Search',
			path: '/search',
			onMount: () => {
				const {
					history,
					store: { dispatch },
				} = browserHistory;
				const q = query.parse(history.location.search);

				Object.keys(q).forEach((item) => addQuery({}, [item]));

				dispatch(getSearchSketches$network());

				// Get sizes for size filters

				dispatch(getSizes());
			},
			onUnMount: () => {
				const { dispatch, getState } = browserHistory.store;

				if (getState().ui.active.searchAside) {
					dispatch(toggle('searchAside'));
				}
			},
		},
		{
			component: (props) => <Mat setting={browserHistory.pages.mat} {...props} />,
			title: 'Draft',
			path: '/draft/:id?',
			onMount: async (history) => {
				const { store } = browserHistory;
				const {
					match: { params },
				} = history;

				store.dispatch(startLoading(false));

				// Get colors for draft | Get sizes for draft

				await Promise.all([store.dispatch(getColors()), store.dispatch(getSizes())]);

				// Restore draft

				if (params.id) {
					const draftData = await store.dispatch(getDraft$network(params.id));

					if (draftData.result.data) {
						store.dispatch(restoreMat(JSON.parse(draftData.result.data.jsonData)));
						store.dispatch(matSnapshots(true));
					} else {
						browserHistory.history.push('/dashboard');
					}
				} else {
					if (store.getState().mat.matType === matType.LYL.name) {
						store.dispatch(getLYLImages());

						setTimeout(() => {
							store.dispatch(toggleModal('LYLImage'));
						}, 2200);
					} else {
						setTimeout(() => {
							store.dispatch(toggleModal('image'));
						}, 2600);
					}
				}

				// Render mat

				store.dispatch(matReady());

				// Hide loader

				setTimeout(() => {
					store.dispatch(endLoading());
				}, 2000);
			},
			onUnMount: () => {
				const { dispatch } = browserHistory.store;

				dispatch(restoreBranch());
			},
		},
		{
			component: () => <MatPreview />,
			title: 'Mat preview',
			path: '/preview/:id?',
			onMount: async (history) => {
				const { dispatch, getState } = browserHistory.store;
				const {
					match: { params },
				} = history;

				dispatch(startLoading(false));

				// Restore sketch preview data

				if (params.id) {
					const sketchData = await dispatch(getSketchDataById$network(params.id));

					if (sketchData.result.data) {
						dispatch(setSketchDataById(sketchData.result.data));

						if (getState().sketch.sketchData) {
							setTimeout(() => {
								dispatch(endLoading());
							}, 2000);
						}
					} else {
						browserHistory.history.push('/dashboard');
					}
				}
			},
			onUnMount: () => {
				const { dispatch } = browserHistory.store;

				dispatch(restoreSketchBranch());
			},
		},
	],
	onMount: () => {
		const { dispatch } = browserHistory.store;

		dispatch(getFavouriteSketchesQuantity$network());
		dispatch(getUserSettings());
	},
};

const marketingSub = {
	notFoundRedirect: '/marketing',
	name: 'marketingSub',
	routes: [
		{
			component: MarketingVideos,
			title: 'Marketing Videos',
			path: '/marketing/videos',
		},
		{
			component: MarketingFlyers,
			title: 'Marketing Flyers',
			path: '/marketing',
		},
	],
};

const auth = {
	name: 'auth',
	routes: [
		{
			component: () => (
				<Auth subRouter={routers.authSub} setting={browserHistory.pages.auth} />
			),
			notExact: true,
			path: '/',
		},
	],
};

const authSub = {
	notFoundRedirect: '/signin',
	name: 'authSub',
	routes: [
		{
			component: Signin,
			title: 'Signin',
			path: '/signin',
		},
		{
			component: Signup,
			title: 'Signup',
			path: '/signup',
			onMount: () => {
				const { store } = browserHistory;
				if (!store.getState().meta.locations.length) {
					store.dispatch(getLocations$network());
				}
			},
		},
		{
			component: ResetPassword,
			title: 'Reset password',
			path: '/reset-password',
		},
	],
};

const routers = createRouter([main, auth, authSub, marketingSub], {
	title: 'Virtuoso | ',
});

function AppRouter() {
	const tokenIsExist = Cookies.get(cookie.TOKEN);

	return tokenIsExist ? (
		<>
			<routers.main />

			{/* Loader */}

			<Container
				mapState={(state) => ({
					loading: state.system.loading,
				})}
				render={({ loading }) =>
					loading ? <FullScreenLoader opacity={loading.opacity} /> : null
				}
			/>

			{/* Notifications */}

			<Container
				mapState={(state) => ({
					notifications: state.systemNotifications,
				})}
				render={SystemNotification}
			/>

			<Container
				mapDispatch={{
					updateSketchDocument,
					setSketchVariation,
					generateSketchPdf,
					setMatName,
					toggle,
				}}
				mapState={(state) => ({
					sketchName: state.mat.matInfo.sketchName,
					modalOpen: state.ui.active,
					sketchID:
						(state.sketch.activeSketchPreview && state.sketch.activeSketchPreview.id) ||
						null,
				})}
				render={({
					updateSketchDocument,
					setSketchVariation,
					generateSketchPdf,
					sketchName,
					modalOpen,
					sketchID,
					toggle,
				}) => (
					<>
						{/* Create new sketch variation */}

						<NewSketchRequestModal
							sketchName={sketchName}
							onSubmit={(data) => {
								setSketchVariation({ name: data, id: sketchID });
							}}
							onClose={() => toggle('variationSketchModal')}
							title="Duplicate as a new sketch"
							open={modalOpen.variationSketchModal || false}
						/>

						{/* Customer approval modal */}

						<CustomerApprovalModal
							onSubmitHandleClick={({ customerName, signature, email }) => {
								if (sketchID) {
									updateSketchDocument({
										customerName,
										signature,
										sketchID,
										email,
									});
								} else {
									toggle('customerApprovalModal');
								}
							}}
							onDownload={() => generateSketchPdf(sketchID)}
							onClose={() => toggle('customerApprovalModal')}
							open={modalOpen.customerApprovalModal || false}
						/>
					</>
				)}
			/>
		</>
	) : (
		<routers.auth />
	);
}

export default TopWrapper(AppRouter, browserHistory.history);
