import query from 'querystringify';
import React from 'react';

// Components

import {
	MatPreviewModal,
	MatsPagesStruct,
	MainStruct,
	MainHeader,
	MatPreview,
	Container,
} from '../../components';

// Static

import browserHistory from '../../static/global';

// Utils

import { getMatFavouriteStatus, getMatStatus } from '../../utils';
import addQuery from '../../utils/addQuery';

// Action creators

import { setActiveSketchPreview } from '../../redux/actionCreators/sketch';
import { setMatName } from '../../redux/actionCreators/mat';
import { toggle } from '../../redux/actionCreators/ui';
import {
	showLoadMoreButton as showLoadMoreButtonAction,
	toggleSketchForFavourite,
	getSketches$network,
	getMoreSketches,
} from '../../redux/actionCreators/mats';

// Action types

import {
	GET_MORE_SKETCHES_$NETWORK,
	GET_SKETCHES_$NETWORK,
} from '../../redux/types/mats';

// Selectors

import getById from '../../redux/selectors/getById';

// ----------------

export default function MySketches(props) {
	return (
		<>
			<MainStruct
				header={
					<Container
						mapDispatch={{ toggle }}
						mapState={(state) => ({
							favouriteQuantity: state.mats.favouriteSketchesQty,
							sidebarOpen: state.ui.active.sidebar,
						})}
						render={MainHeader}
					/>
				}
			>
				<Container
					mapDispatch={{
						showLoadMoreButtonAction,
						toggleSketchForFavourite,
						setActiveSketchPreview,
						getSketches$network,
						getMoreSketches,
						toggle,
					}}
					mapState={(state) => ({
						getMoreNetworkProcess: state.network[GET_MORE_SKETCHES_$NETWORK],
						showLoadMoreButton: state.mats.showLoadMoreButton,
						getNetworkProcess: state.network[GET_SKETCHES_$NETWORK],
						mySketches: state.mats.sketches,
					})}
					render={(cProps) => {
						const { history } = browserHistory;
						const q = query.parse(history.location.search);

						return (
							<MatsPagesStruct
								loadMoreNetworkProcess={cProps.getMoreNetworkProcess}
								getNetworkProcess={cProps.getNetworkProcess}
								headerSortActions={[
									{
										onClick: async () => {
											if (q.sortDir !== 'desc') {
												addQuery({ sortDir: 'desc' });
												addQuery({}, ['page']);

												await cProps.getSketches$network({ searchOrder: 'desc' });
												cProps.showLoadMoreButtonAction(true);
											}
										},
										active: q.sortDir ? q.sortDir === 'desc' : true,
										label: 'Date Decrease',
										icon: 'expand_more',
										id: 'sketches-date-decrease',
									},
									{
										onClick: async () => {
											if (q.sortDir !== 'asc') {
												addQuery({ sortDir: 'asc' });
												addQuery({}, ['page']);

												await cProps.getSketches$network({ searchOrder: 'asc' });
												cProps.showLoadMoreButtonAction(true);
											}
										},
										active: q.sortDir === 'asc',
										label: 'Date Increase',
										icon: 'expand_less',
										id: 'sketches-date-increase',
									},
								]}
								showLoadMore={cProps.showLoadMoreButton}
								onLoadMore={() => {
									addQuery({ page: q.page ? +q.page + 1 : 2 });
									cProps.getMoreSketches();
								}}
								titleIcon="image"
								title="My Sketches"
								mats={cProps.mySketches}
								card={({
									sketchRequestID,
									productGroupID,
									attributeName,
									companyName,
									requestDate,
									designName,
									sketchRef,
									sketchURL,
									imageName,
									viewMode,
									scanURL,
									...cardProps
								}) => (
									<MatPreview
										withFavouriteIcon
										onFavouriteClick={(id, favourite) =>
											favourite === 1
												? cProps.toggleSketchForFavourite(id, 'delete', 'sketches')
												: cProps.toggleSketchForFavourite(id, 'add', 'sketches')
										}
										withHoverEffect
										companyName={companyName}
										imageName={imageName || undefined}
										favourite={getMatFavouriteStatus(cardProps)}
										maxWidth="auto"
										viewMode={viewMode}
										onClick={() => {
											cProps.setActiveSketchPreview(
												sketchRequestID,
												'sketch',
												'sketches'
											);
											cProps.toggle('matPreviewModal');
										}}
										sketchRef={sketchRef}
										status={getMatStatus(cardProps)}
										image={getMatStatus(cardProps) === 'complete' ? sketchURL : scanURL}
										size={attributeName}
										name={designName}
										date={requestDate}
										type={productGroupID || undefined}
										id={sketchRequestID}
									/>
								)}
							/>
						);
					}}
				/>
			</MainStruct>

			<Container
				mapDispatch={{
					toggleSketchForFavourite,
					setMatName,
					toggle,
				}}
				mapState={(state) => ({
					matDetails:
						(state.sketch.activeSketchPreview &&
							getById(
								state.mats.sketches,
								'sketchRequestID',
								state.sketch.activeSketchPreview.id
							)) ||
						{},
					modalOpen: state.ui.active.matPreviewModal,
				})}
				render={({
					matDetails: {
						sketchRequestID,
						productGroupID,
						attributeName,
						companyName,
						requestDate,
						designName,
						sketchRef,
						imageName,
						sketchURL,
						scanURL,
						...matProps
					},
					...cProps
				}) => (
					<MatPreviewModal
						onVariation={() => {
							cProps.setMatName(designName);
							cProps.toggle('matPreviewModal');
							cProps.toggle('variationSketchModal');
						}}
						onApprove={() => {
							cProps.toggle('matPreviewModal');
							cProps.toggle('customerApprovalModal');
						}}
						onPreview={() => {
							cProps.toggle('matPreviewModal');
							browserHistory.history.push(`/preview/${sketchRequestID}`);
						}}
						onClose={() => cProps.toggle('matPreviewModal')}
						open={cProps.modalOpen || false}
						data={{
							withFavouriteIcon: true,
							onFavouriteClick: (id, favourite) =>
								favourite === 1
									? cProps.toggleSketchForFavourite(id, 'delete', 'sketches')
									: cProps.toggleSketchForFavourite(id, 'add', 'sketches'),
							companyName: companyName,
							imageName: imageName,
							sketchRef: sketchRef,
							favourite: getMatFavouriteStatus(matProps),
							status: getMatStatus(matProps),
							image: getMatStatus(matProps) === 'complete' ? sketchURL : scanURL,
							type: productGroupID || undefined,
							size: attributeName,
							name: designName,
							date: requestDate,
							id: sketchRequestID,
						}}
					/>
				)}
			/>
		</>
	);
}
