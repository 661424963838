import types from 'prop-types';
import React from 'react';

// Hooks

import { useWindowWidth } from '../../../../hooks';

// Components

import FontIcon from '../../../../components/block/FontIcon';

// Styles

import './styles.scss';

// ----------------

// Type of props

MatHeader.propTypes = {
	onBackButtonClick: types.func.isRequired,
	matOrientation: types.string.isRequired,
	disableDelete: types.bool,
	disableSave: types.bool,
	sketchName: types.string,
	className: types.string,
	onSubmit: types.func.isRequired,
	onDelete: types.func.isRequired,
	matSize: types.string.isRequired,
	onSave: types.func.isRequired,
	zoom: types.number.isRequired,
};

// ----------------

export default function MatHeader(props) {
	const {
		onBackButtonClick,
		matOrientation,
		disableDelete,
		sketchName,
		className,
		onDelete,
		onSubmit,
		matSize,
		onSave,
		zoom,
	} = props;

	// Preparation

	const currentWindowWidth = useWindowWidth();
	const deviceSizeXS = currentWindowWidth <= 576;

	return (
		<header className={`${className} mat-header`}>
			<div className="mat-header__info">
				<div className="mat-header__info-left">
					<div
						className="mat-header__back-icon"
						onClick={onBackButtonClick}
						id="mat-back-button"
					>
						<FontIcon icon="chevron_left" size={deviceSizeXS ? 'xl' : 'xxxxl'} />
					</div>
				</div>
				<div className="mat-header__info-right" id="mat-reference-title">
					<h4 className="mat-header__name">{sketchName}</h4>
					<div className="mat-header__description text-small">
						{matOrientation}, {matSize} size,{' '}
						<span> {Math.floor(100 * zoom)}% zoom </span>
					</div>
				</div>
			</div>
			<div className="mat-header__buttons">
				<button
					className="mat-header__button mat-header__button--red"
					disabled={disableDelete}
					onClick={onDelete}
					id="mat-delete-button"
				>
					<FontIcon icon="delete_forever" size={deviceSizeXS ? 'lg' : 'xxl'} />
				</button>
				<button className="mat-header__button" onClick={onSave} id="mat-save-button">
					<FontIcon icon="save" size={deviceSizeXS ? 'lg' : 'xxl'} />
					<span>Save</span>
				</button>
				<button
					className="mat-header__button mat-header__button--active"
					onClick={onSubmit}
					id="mat-submit-button"
				>
					<FontIcon icon="send" size={deviceSizeXS ? 'lg' : 'xxl'} />
					<span>Submit</span>
				</button>
			</div>
		</header>
	);
}
