import classNames from 'classnames';
import types from 'prop-types';
import React from 'react';

// Styles

import './styles.scss';

// ----------------

// Type of props

FontIcon.propTypes = {
	rotate180: types.bool,
	rotate45: types.bool,
	rotate90: types.bool,
	library: types.string.isRequired,
	icon: types.string.isRequired,
	size: types.oneOf([
		'xxs',
		'xs',
		'sm',
		'md',
		'lg',
		'xl',
		'xxl',
		'xxxl',
		'xxxxl',
		'xlarge',
		'max',
	]),
};

// Default value for props

FontIcon.defaultProps = {
	library: 'material-icons',
	size: 'md',
};

// ----------------

export default function FontIcon(props) {
	const { rotate180, rotate45, rotate90, library, icon, size } = props;

	// Modify styles

	const modify = classNames({
		[` font-icon--${size}`]: size,
		' font-icon--rotate180': rotate180,
		' font-icon--rotate45': rotate45,
		' font-icon--rotate90': rotate90,
	});

	// Render

	return <i className={`${library} font-icon${modify}`}>{icon}</i>;
}
