import classNames from 'classnames';
import ReactDOM from 'react-dom';
import types from 'prop-types';
import React from 'react';

// Styles

import './styles.scss';

// ----------------

// Type of props

OptionsFieldMobile.propTypes = {
	handleOptionsFieldClose: types.func.isRequired,
	handleOptionSelect: types.func.isRequired,
	optionsFieldOpen: types.bool.isRequired,
	withFontPreviews: types.bool,
	optionsVisible: types.number,
};

// ----------------

export default function OptionsFieldMobile(props) {
	const {
		handleOptionsFieldClose,
		handleOptionSelect,
		withFontPreviews,
		optionsFieldOpen,
		selectedOption,
		optionsVisible,
		options,
		theme,
		value,
		optionKey: key,
	} = props;

	// Modify styles

	const modify = classNames({
		[` options-field__backdrop--theme-${theme}`]: theme,
	});

	const modifyOptionsField = classNames({
		' options-field--open': optionsFieldOpen,
	});

	const modifyOption = (item) =>
		classNames({
			' options-field__option--selected': item[key] === selectedOption[key],
		});

	const optionHeight = '44px';

	// Render

	return ReactDOM.createPortal(
		<div className={`options-field__backdrop${modify}`} onClick={handleOptionsFieldClose}>
			<div className={`options-field${modifyOptionsField}`}>
				<ul
					className="options-field__content"
					style={{
						maxHeight: `calc(${optionsVisible} * ${optionHeight})`,
					}}
				>
					{!!options.length ? (
						options.map((item) => (
							<li
								className={`options-field__option${
									selectedOption ? modifyOption(item) : ''
								}`}
								onClick={() => handleOptionSelect(item)}
								key={item[key]}
							>
								<span
									{...(withFontPreviews && { style: { fontFamily: `${item[value]}` } })}
								>
									{item[value]}
								</span>
							</li>
						))
					) : (
						<p className="options-field__no-options">No options</p>
					)}
				</ul>
			</div>
		</div>,
		document.body
	);
}
