import { fabric } from 'fabric-with-gestures';
import React from 'react';
import types from 'prop-types';

// Components

import FontIcon from '../../../components/block/FontIcon';

// Styles

import './styles.scss';

// ----------------

export default class Paint extends React.Component {
	static propTypes = {
		onButtonClick: types.func,
		onMouseDown: types.func,
		onMouseUp: types.func.isRequired,
		data: types.object,
	};

	constructor(props) {
		super(props);

		this.canvas = null;

		// Binds

		this.setCanvasSize = this.setCanvasSize.bind(this);
		this.resetCanvas = this.resetCanvas.bind(this);
		this.handleMouseUp = this.handleMouseUp.bind(this);
	}

	// -------- Life cycle --------

	/**
	 * Did Mount
	 */

	componentDidMount() {
		this.canvas = new fabric.Canvas('canvas-paint', {
			isDrawingMode: true,
		});

		this.canvas.freeDrawingBrush.color = '#000000';
		this.canvas.freeDrawingBrush.width = 4;

		this.setCanvasSize();
		window.addEventListener('resize', this.resetCanvas);

		this.canvas.on('mouse:up', this.handleMouseUp);

		// Restore canvas from json

		if (this.props.data) {
			this.canvas.loadFromJSON(this.props.data);
		}
	}

	/**
	 * Did Update
	 */

	componentDidUpdate(prevProps) {
		if (prevProps.data && !this.props.data) {
			this.canvas.clear();
		}
	}

	/**
	 * Will Unmount
	 */

	componentWillUnmount() {
		window.removeEventListener('resize', this.resetCanvas);
	}

	// -------- Handlers --------

	/**
	 * Set Canvas Size
	 */

	setCanvasSize() {
		const canvasWidth = document.getElementById('paint').clientWidth;
		const canvasHeight = document.getElementById('paint').clientHeight;

		this.canvas.setDimensions({
			width: canvasWidth,
			height: canvasHeight,
		});
	}

	/**
	 * Reset Canvas
	 */

	resetCanvas() {
		this.setCanvasSize();
		this.props.onButtonClick();
	}

	/**
	 * Handle Mouse Up
	 */

	handleMouseUp() {
		this.props.onMouseUp({
			json: this.canvas.toJSON(),
			base64: this.canvas
				.toDataURL({
					format: 'png',
				})
				.replace('data:image/png;base64,', ''),
			base64Url: this.canvas.toDataURL({
				format: 'png',
			}),
		});
	}

	// -------- Render --------

	render() {
		const { onButtonClick, onMouseDown, data } = this.props;

		// Render

		return (
			<div className="paint" id="paint" onMouseDown={onMouseDown}>
				<canvas id="canvas-paint" />

				{onButtonClick && data && (
					<div className="paint__button" onClick={onButtonClick}>
						<FontIcon icon="sync" size="lg" />
					</div>
				)}

				{onMouseDown && !data && (
					<div className="paint__placeholder">
						<p>Place Approval Signature in here</p>
					</div>
				)}
			</div>
		);
	}
}
