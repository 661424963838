import { Link } from 'react-router-dom';
import Slider from 'react-slick';
import types from 'prop-types';
import React from 'react';

// Components

import FontIcon from '../../../components/block/FontIcon';
import Button from '../../../components/ui/Button';

// Styles

import './slick.scss';
import './styles.scss';

// ----------------

// Type of props

DashboardSlider.propTypes = {
	children: types.node,
	keyName: types.string,
	linkTo: types.string.isRequired,
	title: types.string.isRequired,
};

// Default value for props

DashboardSlider.defaultProps = {
	title: '-',
};

// ----------------

const NextArrow = (props) => {
	return (
		<div className="dashboard-slider__next-btn" onClick={props.onClick}>
			<FontIcon size="xxl" icon="chevron_right" />
		</div>
	);
};

const PrevArrow = (props) => {
	return (
		<div className="dashboard-slider__prev-btn" onClick={props.onClick}>
			<FontIcon size="xxl" icon="chevron_left" />
		</div>
	);
};

// ----------------

const sliderSettings = {
	slidesToScroll: 1,
	slidesToShow: 1,
	swipeToSlide: true,
	dotsClass: 'dashboard-slider__slick-dots',
	nextArrow: <NextArrow />,
	prevArrow: <PrevArrow />,
	infinite: false,
	speed: 400,
	dots: true,
};

// ----------------

export default function DashboardSlider(props) {
	const { children, keyName, linkTo, title } = props;

	return (
		<div className="dashboard-slider__wrapper">
			<Slider {...sliderSettings} className="dashboard-slider">
				{children.map((item, index) => (
					<div
						className="dashboard-slider__slide"
						key={item.props[keyName || 'id'] || index}
					>
						{item}
					</div>
				))}
				<div className="dashboard-slider__empty-slide">
					{children.length ? (
						<Link to={linkTo} className="dashboard-slider__header-link">
							See All {title}
						</Link>
					) : (
						<span className="dashboard-slider__no-slides-text">No Results Found</span>
					)}
				</div>
			</Slider>
			{!!children.length && (
				<Button
					reverseType
					fluid
					theme="onPrimary"
					link={linkTo}
					id="dashboard-slider-view-all"
				>
					View All
				</Button>
			)}
		</div>
	);
}
