import React, { memo } from 'react';
import classNames from 'classnames';
import types from 'prop-types';

// Components

import FontIcon from '../../../../components/block/FontIcon';

// Styles

import './styles.scss';

// ----------------

// Type of props

LibraryImageCard.propTypes = {
	selected: types.bool.isRequired,
	onDelete: types.func.isRequired,
	onClick: types.func.isRequired,
	image: types.string.isRequired,
	label: types.string,
};

// Default value for props

LibraryImageCard.defaultProps = {
	selected: false,
	onDelete: () => null,
	onClick: () => null,
	image: '-',
	label: '-',
};

// ----------------

function LibraryImageCard(props) {
	const { selected, onDelete, onClick, label, image } = props;

	// Handlers

	function handleDeleteClick(e) {
		e.stopPropagation();
		onDelete();
	}

	// Modify styles

	const modify = classNames({
		' library-image-card--selected': selected,
	});

	// Render

	return (
		<div className={`library-image-card${modify}`} onClick={onClick}>
			<div className="library-image-card__image-wrapper show-info">
				<img className="img-contain" src={image} alt="Flyer preview img" />
			</div>
			<div className="library-image-card__footer">
				<span className="text-mini library-image-card__label show-info">{label}</span>
				<div
					className="library-image-card__delete-icon-wrapper"
					onClick={handleDeleteClick}
				>
					<FontIcon size="lg" icon="delete_forever" />
				</div>
			</div>
			<div className="library-image-card__selected-icon">
				<FontIcon icon="check" />
			</div>
		</div>
	);
}

export default memo(LibraryImageCard);
