import env from '../env';

export default {
	GENERATE_SKETCH_PDF: `${env.API_ACCOUNT}Document/GenerateSketchPdf`,
	GET_LYL_IMAGES: `${env.API_VIEWER}SketchView/GetAllSketchRequest`,
	CONVERT_IMAGE: `${env.API_INVENTORY}Virtuoso/ConvertImage`,
	UPDATE_DRAFT: `${env.API_INVENTORY}Virtuoso/UpdateDraft`,
	SAVE_DRAFT: `${env.API_INVENTORY}Virtuoso/SaveDraft`,
	DEL_DRAFT: `${env.API_INVENTORY}Virtuoso/DeleteDraft`,
	GET_DRAFT: `${env.API_INVENTORY}Virtuoso/Drafts`,
	SUBMIT: `${env.API_INVENTORY}SketchRequest/SaveSketchRequestforVirtuoso`,
	COLORS: `${env.API_VIEWER}CatalogView/GetSketchRequestColorByCatalogID/9/205`, // Check params
	SIZES: `${env.API_VIEWER}CatalogView/GetPopularsizeByCatalogID/9/101`, // Check params
};
