import types from 'prop-types';
import React from 'react';

// Components

import FontIcon from '../../../components/block/FontIcon';

// Styles

import './styles.scss';

// ----------------

// Type of props

Layer.propTypes = {
	onSetActiveObject: types.func.isRequired,
	value: types.string.isRequired,
	type: types.string.isRequired,
};

// ----------------

export default function Layer(props) {
	const { onSetActiveObject, value, type } = props;

	return (
		<div className="layer">
			<div className="layer__drag-icon">
				<FontIcon icon="drag_handle" size="lg" />
			</div>

			<div className="layer__value">
				{type === 'image' ? (
					<div className="layer__image">
						<img src={value} alt="logo" />
					</div>
				) : (
					value
				)}
			</div>
			<div className="layer__button" onClick={onSetActiveObject}>
				<FontIcon icon="edit" size="lg" />
			</div>
		</div>
	);
}
