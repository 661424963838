import React from 'react';

// Components

import { ToolStandardActions, FontParams, Container } from '../../../../components';

// Action creators

import {
	toggleFontItalic,
	toggleFontBold,
	setFontBGColor,
	setFontFamily,
	setFontColor,
} from '../../../../redux/actionCreators/mat';

// Styles

import './styles.scss';

// ----------------

export default function FontTool(props) {
	return (
		<div className="font-tool">
			<div className="font-tool__section">
				<Container
					mapDispatch={{
						onChangeFontFamily: setFontFamily,
						onItalicClick: toggleFontItalic,
						onBoldClick: toggleFontBold,
						onClearBG: setFontBGColor,
					}}
					mapState={(state) => ({
						fontObject: state.mat.activeObjectId
							? state.mat.objects[state.mat.activeObjectId]
							: null,
						colors: state.mat.colors,
					})}
					render={(cProps) =>
						cProps.fontObject ? (
							<FontParams
								onSelectBGColor={setFontBGColor}
								onSelectColor={setFontColor}
								{...cProps}
							/>
						) : null
					}
				/>
			</div>

			{/* Font standard actions */}

			<ToolStandardActions />
		</div>
	);
}
