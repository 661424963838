import * as types from '../types/mats';
import query from 'querystringify';

// Static

import { mats as initialMatsPayload } from '../../static/payloads/mats';
import { statusData } from '../../static/constants';
import notification from '../../static/data/notification';
import endpoints from '../../static/endpoints/mats';
import browserHistory from '../../static/global';

// Action creators

import { successMsgs } from './systemNotifications';
import {
	startLoading as startSystemLoading,
	endLoading as endSystemLoading,
} from './system';

// -------- Preparation --------

// Search filters

function getSearchFilters() {
	const { history } = browserHistory;
	const q = query.parse(history.location.search);

	// Status

	const matStatusModified = q.matStatus
		? [q.matStatus]
		: initialMatsPayload.SketchStatusID;

	// Mat type

	const matTypeModified = q.matType ? [q.matType] : ['101', '116', '205'];

	// Orientation and size

	const sketchAttributesModified = [];

	if (q.size) {
		const fullSize = q.size;
		const length = q.size.split('')[2];
		const width = q.size.split('')[0];

		sketchAttributesModified.push(
			{
				EntityID: 17,
				EntitySubPropertyID: 0,
				AttributeName: fullSize,
				AttributeValue: width,
				AttributeType: 15,
			},
			{
				EntityID: 16,
				EntitySubPropertyID: 0,
				AttributeName: fullSize,
				AttributeValue: length,
				AttributeType: 15,
			}
		);
	}

	if (q.orientation === 'landscape') {
		sketchAttributesModified.push({
			EntitySubPropertyID: '27',
			AttributeValue: 'Landscape',
			AttributeName: 'Landscape',
			AttributeType: 58,
			EntityID: 58,
		});
	}

	if (q.orientation === 'portrait') {
		sketchAttributesModified.push({
			EntitySubPropertyID: '26',
			AttributeValue: 'Portrait',
			AttributeName: 'Portrait',
			AttributeType: 58,
			EntityID: 58,
		});
	}

	// Date

	const fromDate = q.startDate;
	const endDate = q.endDate;

	return {
		sketchAttributesModified,
		matStatusModified,
		matTypeModified,
		fromDate,
		endDate,
		q,
	};
}

// -------- Classic action creator --------

// showLoadMoreButton

export const showLoadMoreButton = (payload) => ({
	type: types.SHOW_LOAD_MORE_BUTTON,
	payload: payload,
});

// Delete draft by id

export const deleteDraftById = (id) => ({
	type: types.DELETE_DRAFT_BY_ID,
	payload: { id },
});

// Add Sketch Favourite Flag by id

export const addSketchFavouriteFlagById = (id, list) => ({
	type: types.ADD_SKETCH_FAVOURITE_FLAG_BY_ID,
	payload: { id, list },
});

// Remove Sketch Favourite Flag by id

export const removeSketchFavouriteFlagById = (id, list) => ({
	type: types.REMOVE_SKETCH_FAVOURITE_FLAG_BY_ID,
	payload: { id: id, list: list },
});

// Delete Sketch from Favourite by id

export const deleteSketchFromFavouriteById = (id) => ({
	type: types.DELETE_SKETCH_FROM_FAVOURITE_BY_ID,
	payload: { id },
});

// Increment favourite quantity

export const incrementFavouriteQuantity = () => ({
	type: types.INCREMENT_FAVOURITE_QUANTITY,
});

// Decrement favourite quantity

export const decrementFavouriteQuantity = () => ({
	type: types.DECREMENT_FAVOURITE_QUANTITY,
});

// -------- Async action creator --------

// getMoreDrafts

export const getMoreDrafts = (payload) => async (store) => {
	const { dispatch } = store;
	const { history } = browserHistory;
	const q = query.parse(history.location.search);

	const data = await dispatch(
		getMoreDrafts$network({
			recordCount: 12,
			pageNumber: q.page - 1 || 1,
			sortExpr: 'date',
			sortDir: q.sortDir || 'desc',
		})
	);

	if (!data.result.data) {
		dispatch(showLoadMoreButton(false));
	}
};

// getMoreSketches

export const getMoreSketches = (payload) => async (store) => {
	const { dispatch, getState } = store;
	const { history } = browserHistory;
	const q = query.parse(history.location.search);
	const { UserID } = await getState().user.userData;

	const data = await dispatch(
		getMoreSketches$network({
			searchOrder: q.sortDir,
			placedByID: UserID,
			pageNumber: q.page || 2,
			...payload,
		})
	);

	if (!data.result.data) {
		dispatch(showLoadMoreButton(false));
	}
};

// getMoreLocationSketches

export const getMoreLocationSketches = (payload) => async (store) => {
	const { dispatch, getState } = store;
	const { history } = browserHistory;
	const q = query.parse(history.location.search);
	const { CustomerID, UserID } = await getState().user.userData;

	const data = await dispatch(
		getMoreLocationSketches$network({
			searchOrder: q.sortDir,
			locationID: CustomerID,
			placedByID: UserID,
			pageNumber: +q.page || 2,
			...payload,
		})
	);

	if (!data.result.data) {
		dispatch(showLoadMoreButton(false));
	}
};

// getMoreFavouriteSketches

export const getMoreFavouriteSketches = (payload) => async (store) => {
	const { dispatch } = store;
	const { history } = browserHistory;
	const q = query.parse(history.location.search);

	const data = await dispatch(
		getMoreFavouriteSketches$network({
			recordCount: 12,
			pageNumber: +q.page || 2,
		})
	);

	if (!data.result.data.length) {
		dispatch(showLoadMoreButton(false));
	}
};

// getSearchSketches

export const getSearchSketches = (payload) => async (store) => {
	const { dispatch } = store;
	const {
		sketchAttributesModified,
		matStatusModified,
		matTypeModified,
		fromDate,
		endDate,
		q,
	} = getSearchFilters();

	dispatch(
		getSearchSketches$network({
			SketchAttributes: sketchAttributesModified.length ? sketchAttributesModified : null,
			SketchStatusID: matStatusModified,
			productGroupID: matTypeModified,
			SearchString: q.search,
			searchOrder: q.sortDir,
			pageNumber: +q.page || 1,
			fromdate: fromDate,
			todate: endDate,
			...payload,
		})
	);
};

// getMoreSearchSketches

export const getMoreSearchSketches = (payload) => async (store) => {
	const { dispatch } = store;
	const {
		sketchAttributesModified,
		matStatusModified,
		matTypeModified,
		fromDate,
		endDate,
		q,
	} = getSearchFilters();

	const data = await dispatch(
		getMoreSearchSketches$network({
			SketchAttributes: sketchAttributesModified.length ? sketchAttributesModified : null,
			SketchStatusID: matStatusModified,
			productGroupID: matTypeModified,
			SearchString: q.search,
			searchOrder: q.sortDir,
			pageNumber: +q.page || 2,
			fromdate: fromDate,
			todate: endDate,
			...payload,
		})
	);

	if (!data.result.data) {
		dispatch(showLoadMoreButton(false));
	}
};

// Toogle Sketch for Favourite

export const toggleSketchForFavourite = (id, action, list) => async (store) => {
	const { dispatch } = store;

	dispatch(startSystemLoading());

	if (action === 'add') {
		await dispatch(addSketchToFavourite$network({ id }));
		dispatch(incrementFavouriteQuantity());
		dispatch(addSketchFavouriteFlagById(id, list));
	}

	if (action === 'delete') {
		await dispatch(deleteSketchFromFavourite$network({ id }));
		dispatch(decrementFavouriteQuantity());

		if (list === 'favouriteSketches') {
			dispatch(deleteSketchFromFavouriteById(id));
		} else {
			dispatch(removeSketchFavouriteFlagById(id, list));
		}
	}

	dispatch(endSystemLoading());

	setTimeout(() => {
		dispatch(
			successMsgs({
				...(action === 'delete' && { message: notification.deleteToFavouriteSuccess }),
				...(action === 'add' && { message: notification.addToFavouriteSuccess }),
			})
		);
	}, 1000);
};

// -------- Async network action creator --------

// getDrafts$network

export const getDrafts$network = ({ recordCount, pageNumber, sortExpr, sortDir }) => ({
	type: types.GET_DRAFTS_$NETWORK,
	endpoint: `${endpoints.DRAFTS}/${sortExpr}/${sortDir}/${pageNumber}/${recordCount}`,
});

// getMoreDrafts$network

export const getMoreDrafts$network = ({
	recordCount,
	pageNumber,
	sortExpr,
	sortDir,
}) => ({
	type: types.GET_MORE_DRAFTS_$NETWORK,
	endpoint: `${endpoints.DRAFTS}/${sortExpr}/${sortDir}/${pageNumber}/${recordCount}`,
});

// getSketches$network

export const getSketches$network = (payload) => ({
	type: types.GET_SKETCHES_$NETWORK,
	endpoint: endpoints.SKETCHES,
	payload: {
		...initialMatsPayload,
		...payload,
	},
	method: 'POST',
});

// getMoreSketches$network

export const getMoreSketches$network = (payload) => ({
	type: types.GET_MORE_SKETCHES_$NETWORK,
	endpoint: endpoints.SKETCHES,
	payload: {
		...initialMatsPayload,
		...payload,
	},
	method: 'POST',
});

// getLocationSketches$network

export const getLocationSketches$network = (payload) => ({
	type: types.GET_LOCATION_SKETCHES_$NETWORK,
	endpoint: endpoints.LOCATION_SKETCHES,
	payload: {
		...initialMatsPayload,
		...payload,
		SketchStatusID: [statusData.complete.id],
	},
	method: 'POST',
});

// getMoreLocationSketches$network

export const getMoreLocationSketches$network = (payload) => ({
	type: types.GET_MORE_LOCATION_SKETCHES_$NETWORK,
	endpoint: endpoints.LOCATION_SKETCHES,
	payload: {
		...initialMatsPayload,
		...payload,
		SketchStatusID: [statusData.complete.id],
	},
	method: 'POST',
});

// getFavouriteSketches$network

export const getFavouriteSketches$network = ({ recordCount, pageNumber }) => ({
	type: types.GET_FAVOURITE_SKETCHES_$NETWORK,
	endpoint: `${endpoints.FAVOURITE_SKETCHES}/${recordCount}/${pageNumber}`,
});

// getMoreFavouriteSketches$network

export const getMoreFavouriteSketches$network = ({ recordCount, pageNumber }) => ({
	type: types.GET_MORE_FAVOURITE_SKETCHES_$NETWORK,
	endpoint: `${endpoints.FAVOURITE_SKETCHES}/${recordCount}/${pageNumber}`,
});

// getSearchSketches$network

export const getSearchSketches$network = (payload) => ({
	type: types.GET_SEARCH_MATS_$NETWORK,
	endpoint: endpoints.GET_SEARCH_MATS,
	payload: {
		...initialMatsPayload,
		...payload,
	},
	method: 'POST',
});

// getMoreSearchSketches$network

export const getMoreSearchSketches$network = (payload) => ({
	type: types.GET_MORE_SEARCH_MATS_$NETWORK,
	endpoint: endpoints.GET_SEARCH_MATS,
	payload: {
		...initialMatsPayload,
		...payload,
	},
	method: 'POST',
});

// getFavouriteSketchesQuantity$network

export const getFavouriteSketchesQuantity$network = () => ({
	type: types.GET_FAVOURITE_SKETCHES_QUANTITY_$NETWORK,
	endpoint: endpoints.FAVOURITE_SKETCHES_QUANTITY,
});

// addSketchToFavourite$network

export const addSketchToFavourite$network = (payload) => ({
	type: types.ADD_SKETCH_TO_FAVOURITE_$NETWORK,
	endpoint: endpoints.ADD_SKETCH_TO_FAVOURITE,
	payload: { sketchRequestID: payload.id },
	method: 'POST',
});

// deleteSketchFromFavourite$network

export const deleteSketchFromFavourite$network = (payload) => ({
	type: types.DELETE_SKETCH_FROM_FAVOURITE_$NETWORK,
	endpoint: `${endpoints.DELETE_SKETCH_FROM_FAVOURITE}/${payload.id}`,
	method: 'DELETE',
});
