import Vibrant from 'node-vibrant';

export default {
	/**
	 * @name isColorInRange
	 *
	 * @param {array}  originalСolorRGB
	 * @param {array}  testColorRGB
	 *
	 * @return {boolean} True, if the color is in the range
	 */

	isColorInRange(originalСolorRGB, testColorRGB) {
		let diffR, diffG, diffB;
		const originalСolor = this.rgbToLab(originalСolorRGB);
		const testColor = this.rgbToLab(testColorRGB);

		//	Delta E (ΔE) is the distance between two colors in CIELAB color space https://en.wikipedia.org/wiki/Color_difference
		const deltaE = 14;

		// Get distance to color
		diffR = Math.abs(originalСolor[0] - testColor[0]);
		diffG = Math.abs(originalСolor[1] - testColor[1]);
		diffB = Math.abs(originalСolor[2] - testColor[2]);

		if (
			Math.sqrt(Math.pow(diffR, 2) + Math.pow(diffG, 2) + Math.pow(diffB, 2)) < deltaE
		) {
			return true;
		}

		return false;
	},

	/**
	 * @name rgbToLab
	 *
	 * @param {array}  rgb
	 *
	 * @return {array} Returns color in CIELAB color space
	 */

	rgbToLab(rgb) {
		let r = rgb[0] / 255,
			g = rgb[1] / 255,
			b = rgb[2] / 255,
			x,
			y,
			z;

		r = r > 0.04045 ? Math.pow((r + 0.055) / 1.055, 2.4) : r / 12.92;
		g = g > 0.04045 ? Math.pow((g + 0.055) / 1.055, 2.4) : g / 12.92;
		b = b > 0.04045 ? Math.pow((b + 0.055) / 1.055, 2.4) : b / 12.92;

		x = (r * 0.4124 + g * 0.3576 + b * 0.1805) / 0.95047;
		y = (r * 0.2126 + g * 0.7152 + b * 0.0722) / 1.0;
		z = (r * 0.0193 + g * 0.1192 + b * 0.9505) / 1.08883;

		x = x > 0.008856 ? Math.pow(x, 1 / 3) : 7.787 * x + 16 / 116;
		y = y > 0.008856 ? Math.pow(y, 1 / 3) : 7.787 * y + 16 / 116;
		z = z > 0.008856 ? Math.pow(z, 1 / 3) : 7.787 * z + 16 / 116;

		return [116 * y - 16, 500 * (x - y), 200 * (y - z)];
	},

	/**
	 * @name getImageColors
	 *
	 * @param {string} url
	 * @param {number} count
	 *
	 * @return {Promise} Promise object represents list of image colors
	 */

	getImageColors(url) {
		return new Promise((resolve) => {
			const img = document.createElement('img');
			img.src = url;

			// We consider only colors that take not less than 5 percent of image
			const percentOfImage = 5;

			img.onload = () => {
				const initialPalette = [];

				Vibrant.from(img)
					.getPalette()
					.then((palette) => {
						for (let color of Object.values(palette)) {
							initialPalette.push(color);
						}

						let totalColorPopulation = initialPalette.reduce((prev, cur) => {
							return prev + cur._population;
						}, 0);

						let filteredPalette = initialPalette.filter(
							(color) =>
								(color._population / totalColorPopulation) * 100 >= percentOfImage
						);

						let resultPalette = filteredPalette.map((color) => color.getRgb());
						resolve(resultPalette);
					});
			};
		});
	},

	/**
	 * @name isImageTransparent
	 *
	 * @param {string}  url
	 *
	 * @return {boolean} True, if the image is transparent
	 */

	isImageTransparent(url) {
		return new Promise((resolve) => {
			const image = document.createElement('img');

			image.onload = () => {
				const canvas = document.createElement('canvas');
				const context = canvas.getContext('2d');
				canvas.width = image.width;
				canvas.height = image.height;
				context.drawImage(image, 0, 0);
				const data = context.getImageData(0, 0, canvas.width, canvas.height).data;
				let opacity = 0;

				for (let i = 0; i < data.length; i += 4) {
					opacity += data[i + 3];
				}

				// a number from 0 to 1, being 0 a fully transparent image, 1 a fully opaque one
				const opacityRatio = opacity / 255 / (data.length / 4);
				resolve(opacityRatio < 1);
			};

			image.crossOrigin = 'Anonymous';
			image.src = url;
		});
	},

	/**
	 * @name getColorsInRange
	 *
	 * @param {array}  colors
	 * @param {array}  list
	 * @param {number} threshold
	 *
	 * @return {array} Array of colors
	 */

	getColorsInRange(colors, list) {
		const colorsSet = new Set();

		colors.forEach((color) => {
			list.forEach((c) => {
				const testColor = [c.red, c.green, c.blue];

				if (this.isColorInRange(color, testColor)) {
					colorsSet.add(c.name);
				}
			});
		});

		return colorsSet;
	},
};
