import types from 'prop-types';
import React from 'react';

// Components

import { Modal, LogoMatTypeForm } from '../../../../components';
import Form from '../../../../modules/form-x';

// Static

import { matType } from '../../../../static/constants';

// Styles

import './styles.scss';

// ----------------

// Type of props

LogoMatTypeModal.propTypes = {
	onSubmit: types.func.isRequired,
	onClose: types.func.isRequired,
	open: types.bool.isRequired,
};

// ----------------

export default function LogoMatTypeModal(props) {
	const { onSubmit, open, onClose } = props;

	// Render

	return (
		<Modal onClose={onClose} title="Logo mat type" open={open} bodyAccent>
			<div className="logo-mat-type-modal">
				<p className="modal__subtitle logo-mat-type-modal__subtitle">
					Select your logo mat type
				</p>
				<Form
					validate={{ required: true }}
					onSubmit={(data) => {
						onSubmit(data.logoMatType);
					}}
					fields={{
						logoMatType: {
							value: matType.TRADITIONAL.name,
						},
					}}
					render={(formProps) => <LogoMatTypeForm onClose={onClose} {...formProps} />}
				/>
			</div>
		</Modal>
	);
}
