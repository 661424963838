import types from 'prop-types';
import React from 'react';

// Components

import CustomerApprovalForm from '../../../../components/block/forms/CustomerApprovalForm';
import Container from '../../../../components/rp/Container';
import Modal from '../../../../components/block/Modal';
import Form from '../../../../modules/form-x';

// Static

import { email } from '../../../../static/regExps';

// Action creators

import { toggle } from '../../../../redux/actionCreators/ui';

// Type of props

CustomerApprovalModal.propTypes = {
	onSubmitHandleClick: types.func.isRequired,
	onClose: types.func.isRequired,
	open: types.bool.isRequired,
};

// ----------------

export default function CustomerApprovalModal(props) {
	const { onSubmitHandleClick, onClose, onDownload, open } = props;

	return (
		<Modal
			withoutPadding
			bodyAccent
			maxWidth="content"
			onClose={onClose}
			title="Add Customer Approval"
			open={open}
		>
			<div className="customer-approval-modal">
				<Container
					mapDispatch={{ toggle }}
					render={(cProps) => (
						<Form
							validate={{ required: true }}
							onSubmit={(data) => {
								const { signature } = data;

								onSubmitHandleClick({ ...data, signature: signature.base64 });
								cProps.toggle('customerApprovalModal');
							}}
							fields={{
								customerName: {
									messages: { required: 'Please enter approved customer name' },
									validate: { min: 3 },
									value: '',
								},
								email: {
									messages: { required: 'Please enter correct email' },
									validate: { regExp: email },
									value: '',
								},

								signature: {
									messages: { required: 'Please place your signature' },
									value: null,
									handler: 'setter',
								},
							}}
							render={(formProps) => (
								<CustomerApprovalForm
									onDownload={onDownload}
									onClose={onClose}
									{...formProps}
								/>
							)}
						/>
					)}
				/>
			</div>
		</Modal>
	);
}
