import React from 'react';

// Components

import { ResetPasswordForm, Container } from '../../components';
import Form from '../../modules/form-x';

// Action creators

import { resetPassword } from '../../redux/actionCreators/auth';

// Action types

import { RESET_PASSWORD_$NETWORK } from '../../redux/types/auth';

// Static

import { email } from '../../static/regExps';

// ----------------

export default function ResetPassword(props) {
	return (
		<Container
			mapDispatch={{ resetPassword }}
			mapState={(state) => ({
				postNetworkProcess: state.network[RESET_PASSWORD_$NETWORK],
			})}
			render={(cProps) => (
				<Form
					extraState={{ isRequestSend: false, notValidData: false }}
					onSubmit={async (data, actions) => {
						try {
							const res = await cProps.resetPassword({
								userName: data.email.toLowerCase(),
							});

							if (!!res.Result.Data) {
								actions.setExtraState('isRequestSend', true);
							} else {
								actions.setExtraState('notValidData', true);
							}
						} catch (err) {
							actions.setExtraState('notValidData', true);
						}
					}}
					validate={{ required: true }}
					fields={{
						email: {
							value: '',
							validate: { regExp: email },
							messages: { regExp: 'Please, enter a valid email address' },
						},
					}}
					render={(formProps) => (
						<ResetPasswordForm
							{...formProps}
							networkProcess={cProps.postNetworkProcess}
						/>
					)}
				/>
			)}
		/>
	);
}
