import Notifications from 'react-notification-system-redux';
import upperFirst from 'lodash/upperFirst';

// ----------------

/**
 * Notification common action creator
 */

export const notify = (params, type) => (store) => {
	store.dispatch(
		Notifications[type]({
			autoDismiss: 3,
			position: 'br',
			children: null,
			message: 'Some notify',
			action: undefined,
			title: upperFirst(type),
			...params,
		})
	);
};

/**
 * Success msgs
 */

export const successMsgs = (params) => (store) => {
	store.dispatch(notify(params, 'success'));
};

/**
 * Warning msgs
 */

export const warningMsgs = (params) => (store) => {
	store.dispatch(notify(params, 'warning'));
};

/**
 * Error msgs
 */

export const errorMsgs = (params) => (store) => {
	store.dispatch(notify(params, 'error'));
};

/**
 * Info msgs
 */

export const infoMsgs = (params) => (store) => {
	store.dispatch(notify(params, 'info'));
};
