import types from 'prop-types';
import React from 'react';

// Styles

import './styles.scss';

// ----------------

// Type of props

SketchImages.propTypes = {
	onImageClick: types.func.isRequired,
	imageList: types.arrayOf(types.object).isRequired,
};

// ----------------

export default function SketchImages(props) {
	const { onImageClick, imageList } = props;

	// Render

	return (
		<div className="sketch-images">
			<div className="sketch-images__list">
				{imageList.map((image, index) => (
					<div
						className="sketch-images__image-container"
						onClick={() => onImageClick(image)}
						key={index}
					>
						<img className="sketch-images__image" src={image.url} alt="Background" />
					</div>
				))}
			</div>
		</div>
	);
}
