import classNames from 'classnames';
import types from 'prop-types';
import React from 'react';

// Styles

import './styles.scss';

// ----------------

// Type of props

FullScreenLoader.propTypes = {
	opacity: types.bool,
};

// ----------------

export default function FullScreenLoader(props) {
	// Modify

	const modify = classNames({
		' full-screen-loader--opacity': props.opacity,
	});

	// Render

	return (
		<div className={`full-screen-loader${modify}`}>
			<div className="container">
				<div className="paint" />
				<div className="brush">
					<svg viewBox="0 0 29.55 115.73">
						<path
							d="M9.52,40.4C6.24,47,7.82,53.57,9.44,59.59c4.65,17.13,7.7,27.08,12.66,44.13a111.19,111.19,0,0,0,5.33,12C28.1,111.65,29,107.23,29,103c0-16.79-.38-25.07-.71-41.86,0-1.78-.78-12.08-1.25-13.81a52.14,52.14,0,0,0-3.24-7.82s-1.91,1.37-6.12,1.58A25.48,25.48,0,0,1,9.52,40.4Z"
							transform="translate(0 0)"
							fill="#fff"
						/>
						<path
							d="M25.08,16.1c-.33,2.28-22.79,2.35-21.81-2.71h0A16.57,16.57,0,0,0,2.35,0C9.7,1.46,14.9,4.18,22.66,10.41,22.66,10.41,25.4,13.83,25.08,16.1Z"
							transform="translate(0 0)"
							fill="orange"
						/>
						<path d="M2.64,15.06l-.05.1Z" transform="translate(0 0)" fill="orange" />
						<path
							d="M3.27,13.38h0c-1,5.06,21.48,5,21.81,2.71s-2.42-5.69-2.42-5.69c6.32,5.08,8.43,11.66,5.77,18-2.74,6.59-6.56,8.33-15.94,7.27C1.85,34.52-2.9,26.36,1.83,16.81A28.48,28.48,0,0,0,3.27,13.38Z"
							transform="translate(0 0)"
							fill="#fff"
						/>
					</svg>
				</div>
			</div>
		</div>
	);
}
