import types from 'prop-types';
import React from 'react';

// Components

import SvgIcon from '../../block/SvgIcon';

// Assets

import { visibilityOnIcon, visibilityOffIcon } from '../../../assets/icons';

// Styles

import './styles.scss';

// ----------------

// Type of props

VisibilityIcon.propTypes = {
	isVisible: types.bool.isRequired,
	onClick: types.func.isRequired,
};

// Default value for props

VisibilityIcon.defaultProps = {
	isVisible: false,
};

// ----------------

export default function VisibilityIcon(props) {
	const { isVisible, onClick } = props;

	return (
		<div className="visibility-icon" onClick={onClick}>
			<SvgIcon url={isVisible ? visibilityOnIcon : visibilityOffIcon} />
		</div>
	);
}
