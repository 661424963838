import React, { useState, useEffect } from 'react';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import types from 'prop-types';

// Components

import FontIcon from '../../../components/block/FontIcon';

// Styles

import './styles.scss';

// ----------------

// Type of props

CounterIconButton.propTypes = {
	iconSize: types.oneOf(['xs', 'sm', 'md', 'lg', 'xl', 'xxl', 'xxxl', 'xxxxl']),
	quantity: types.number,
	onClick: types.func,
	stretch: types.bool,
	link: types.string,
	icon: types.string.isRequired,
	id: types.string.isRequired,
};

// Default value for props

CounterIconButton.defaultProps = {
	iconSize: 'xl',
};

// ----------------

export default function CounterIconButton(props) {
	const { iconSize, quantity, onClick, stretch, link, icon, id } = props;

	// State

	const [countJump, setCountJump] = useState(false);

	// Preparation

	useEffect(() => {
		setCountJump(true);

		return () => {
			setCountJump(false);
		};
	}, [quantity]);

	// Modify styles

	const modify = classNames({
		' counter-icon-btn--stretch': stretch,
	});

	const modifyCounter = classNames({
		' counter-icon-btn__counter--jump': countJump,
	});

	// Customize render

	const renderButton = () => (
		<button
			className={`counter-icon-btn counter-icon-btn--asButton${modify}`}
			onClick={onClick}
			id={id}
		>
			<div className="counter-icon-btn__icon-block">
				<div className={`counter-icon-btn__counter${modifyCounter}`}>
					{quantity > 99 ? '99+' : quantity}
				</div>
				<FontIcon size={iconSize} icon={icon} />
			</div>
		</button>
	);

	const renderLink = () => (
		<Link className={`counter-icon-btn ${modify}`} to={link || ''} id={id}>
			<div className="counter-icon-btn__icon-block">
				<div className={`counter-icon-btn__counter ${modifyCounter}`}>
					{quantity > 99 ? '99+' : quantity}
				</div>
				<FontIcon size={iconSize} icon={icon} />
			</div>
		</Link>
	);

	// Render

	return link ? renderLink() : renderButton();
}
