import { NS } from "../../static/constants";

// ----------------

const TYPE_NAME = "AUTH";

export const RESET_PASSWORD_$NETWORK = `${NS}/${TYPE_NAME}/SIGNUP_$NETWORK`;
export const RESTORE_BRANCH_NAME     = `${NS}/${TYPE_NAME}/RESTORE_BRANCH_NAME`;
export const SIGNIN_$NETWORK         = `${NS}/${TYPE_NAME}/SIGNIN_$NETWORK`;
export const SIGNUP_$NETWORK         = `${NS}/${TYPE_NAME}/SIGNUP_$NETWORK`;
