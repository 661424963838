import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import React from 'react';
import types from 'prop-types';

// ----------------

export default class Container extends React.Component {
	// Type of props

	static propTypes = {
		mapDispatch: types.object,
		withRouter: types.bool,
		mapState: types.func,
		render: types.func.isRequired,
		extra: types.object,
	};

	// -------- Methods --------

	constructor(props) {
		super(props);

		this.container = connect(
			props.mapState    || null, // prettier-ignore
			props.mapDispatch || null
		)(props.render);

		if (props.withRouter) this.container = withRouter(this.container);
	}

	// Render

	render() {
		return <this.container {...this.props.extra} />;
	}
}
