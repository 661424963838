import * as types from '../types/matCanvas';

const defaultState = {};

export default function (state, action) {
	if (!state) return defaultState;

	switch (action.type) {
		case types.SET_CANVAS: {
			return { ...state, canvas: action.payload };
		}
		default:
			return state;
	}
}
