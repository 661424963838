import React from 'react';
import types from 'prop-types';

// Components

import { Container, FontIcon } from '../../../components';

// Action creators

import { toggleModal } from '../../../redux/actionCreators/mat';

// Static

import { matType as matTypeInfo } from '../../../static/constants';
import { limit } from '../../../static/constants';

// ----------------

// Type of props

ToolMobile.propTypes = {
	handleMobileHideClick: types.func,
	deviceSizeXS: types.bool,
	onAddClick: types.func,
	hideMode: types.bool,
	onClose: types.func,
	title: types.string.isRequired,
};

// ----------------

export default function ToolMobile(props) {
	const {
		handleMobileHideClick,
		deviceSizeXS,
		onAddClick,
		hideMode,
		onClose,
		title,
	} = props;

	// Render

	return (
		<div className="tool__mobile">
			<Container
				mapState={(state) => {
					const { colorsCount, imageCount, activeTool, matType } = state.mat;
					if (activeTool === 'image')
						return { counterText: `${imageCount} / ${limit.IMAGES}` };
					if (matType === matTypeInfo.TRADITIONAL.name && activeTool === 'colors')
						return { counterText: `${colorsCount} / ${limit.COLORS}` };
					return { counterText: '' };
				}}
				render={(cProps) => (
					<span className="tool__title">
						{title} <span className="tool__counter">{cProps.counterText}</span>
					</span>
				)}
			/>

			{onAddClick && (
				<button className="tool__add tool__add--mobile" onClick={onAddClick}>
					<FontIcon icon="add" size={deviceSizeXS ? 'lg' : 'md'} />
					{!deviceSizeXS && <span> Add </span>}
				</button>
			)}

			<div className="tool__buttons">
				<Container
					mapDispatch={{ toggleModal }}
					mapState={(state) => ({
						isColorsTool:
							state.mat.matType === matTypeInfo.TRADITIONAL.name &&
							state.mat.activeTool === 'colors',
						colorsCount: state.mat.colorsCount,
					})}
					render={(cProps) => (
						<>
							{cProps.isColorsTool && cProps.colorsCount ? (
								<div
									className="tool__clear"
									onClick={() => cProps.toggleModal('clear-colors')}
								>
									clear all
								</div>
							) : null}
						</>
					)}
				/>

				{hideMode && !deviceSizeXS && (
					<div
						className="tool__button tool__button--hide"
						onClick={handleMobileHideClick}
					>
						<FontIcon icon="keyboard_arrow_down" size="xxl" />
					</div>
				)}

				{onClose && (
					<div className="tool__button" onClick={onClose}>
						<FontIcon icon="close" size="lg" />
					</div>
				)}
			</div>
		</div>
	);
}
