import { NS } from "../../static/constants";

// ----------------

const TYPE_NAME = "USER";

export const SET_USER_SETTINGS           = `${NS}/${TYPE_NAME}/SET_USER_SETTINGS`;
export const RESTORE_BRANCH              = `${NS}/${TYPE_NAME}/RESTORE_BRANCH`;

// Network

export const POST_USER_SETTINGS_$NETWORK = `${NS}/${TYPE_NAME}/POST_USER_SETTINGS_$NETWORK`;
export const GET_USER_SETTINGS_$NETWORK  = `${NS}/${TYPE_NAME}/GET_USER_SETTINGS_$NETWORK`;
export const POST_FEEDBACK_$NETWORK      = `${NS}/${TYPE_NAME}/POST_FEEDBACK_$NETWORK`;
export const GET_USER_$NETWORK           = `${NS}/${TYPE_NAME}/GET_USER_$NETWORK`;