import types from 'prop-types';
import React from 'react';

// Components

import Color from '../../../components/block/Color';

// Styles

import './styles.scss';

// ----------------

// Type of props

SelectedTextPreview.propTypes = {
	fontFamily: types.string.isRequired,
	fontStyle: types.string,
	text: types.string.isRequired,
	color: types.object.isRequired,
	bg: types.oneOfType([types.object, types.bool]),
};

// ----------------

export default function SelectedTextPreview(props) {
	const { fontFamily, fontStyle, text, color, bg } = props;

	return (
		<div className="selected-text-preview">
			<div className="selected-text-preview__text">{text}</div>
			<div className="selected-text-preview__grid">
				{bg && (
					<div className="selected-text-preview__cell">
						<Color
							withBorderRadius
							label="BG"
							name={bg.name}
							rgb={{ r: bg.red, g: bg.green, b: bg.blue }}
						/>
					</div>
				)}
				<div className="selected-text-preview__cell">
					<Color
						withBorderRadius
						name={color.name}
						rgb={{ r: color.red, g: color.green, b: color.blue }}
					/>
				</div>
			</div>
			<p>{fontFamily}</p>
			<p>{fontStyle}</p>
		</div>
	);
}
