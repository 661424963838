import React from 'react';

// Components

import { MatStruct, Container } from '../../components';

// ----------------

export default function Mat(props) {
	return (
		<Container
			mapState={(state) => ({ matIsReady: state.mat.matIsReady })}
			render={({ matIsReady }) => {
				return matIsReady ? <MatStruct match={props.match} /> : null;
			}}
		/>
	);
}
