import colorConvert from 'color-convert';

// Selectors

import getById from '../redux/selectors/getById';

// Static

import { productGroupData } from '../static/constants';

// ----------------

export default function getCustomSketchData(sketch) {
	const {
		sketchAttributes,
		sketchRequestID,
		productGroupID,
		variationOf,
		designName,
		sketchRef,
		sketchURL,
		scanURL,
	} = sketch;

	// Sketch name

	const sketchName = designName;

	// Sketch preview image

	const image = sketchURL || scanURL;

	// Sketch orientation

	const orientationID = getById(sketchAttributes, 'entityID', 58).entitySubPropertyID;
	const orientation = getById(sketchAttributes, 'entityID', 58).attributeName;

	// Sketch mat type

	const matTypeID = productGroupID;

	// Sketch ID

	const sketchID = sketchRequestID;

	// Sketch size

	const size = getById(sketchAttributes, 'entityID', 16).attributeName;

	// Sketch mat details

	const matDetails =
		`${productGroupData[productGroupID] || ''} ${size || ''} ${orientation || ''} ` || '';

	// Sketch color list

	let colorListRgb;

	const colorList = sketchAttributes.filter((item) => item.entitySubPropertyID === 30);

	if (colorList) {
		colorListRgb = colorList.map((item) => ({
			name: item.attributeValue,
			red: colorConvert.hex.rgb(
				item.attributeName.substring(item.attributeName.indexOf('#') + 1)
			)[0],
			green: colorConvert.hex.rgb(
				item.attributeName.substring(item.attributeName.indexOf('#') + 1)
			)[1],
			blue: colorConvert.hex.rgb(
				item.attributeName.substring(item.attributeName.indexOf('#') + 1)
			)[2],
		}));
	} else {
		colorListRgb = [];
	}

	// Sketch background color

	let bgRgb;

	const bg = sketchAttributes.filter((item) => item.entitySubPropertyID === 29)[0];

	if (bg) {
		bgRgb = {
			name: bg.attributeValue,
			red: colorConvert.hex.rgb(
				bg.attributeName.substring(bg.attributeName.indexOf('#') + 1)
			)[0],
			green: colorConvert.hex.rgb(
				bg.attributeName.substring(bg.attributeName.indexOf('#') + 1)
			)[1],
			blue: colorConvert.hex.rgb(
				bg.attributeName.substring(bg.attributeName.indexOf('#') + 1)
			)[2],
		};
	}

	return {
		orientationID,
		colorListRgb,
		orientation,
		variationOf,
		sketchName,
		matDetails,
		sketchRef,
		matTypeID,
		sketchID,
		image,
		bgRgb,
		size,
	};
}
