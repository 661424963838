import * as base_types from '../types/base';
import * as types from '../types/meta';
import $ from '../../utils/createActionType';

// -------- Default state of the branch --------

const defaultState = {
	locations: [],
};

// -------- Reducer --------

export default function (state, action) {
	if (!state) return defaultState;

	switch (action.type) {
		case $(types.GET_LOCATIONS_$NETWORK): {
			return { ...state, locations: action.payload.result.data };
		}

		case types.RESTORE_BRANCH:
		case base_types.RESTORE_DEFAULT_STATE: {
			return defaultState;
		}

		default: {
			return state;
		}
	}
}
