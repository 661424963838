import React from 'react';

// Components

import { MarketingStruct, Container, VideoCard } from '../../components';

// Action types

import { GET_MARKETING_MATERIALS_$NETWORK } from '../../redux/types/marketing';

// ----------------

export default function MarketingVideos(props) {
	return (
		<Container
			mapState={(state) => ({
				getNetworkProcess: state.network[GET_MARKETING_MATERIALS_$NETWORK],
				marketingVideos: state.marketing.marketingVideos,
			})}
			render={(cProps) => (
				<MarketingStruct
					getNetworkProcess={cProps.getNetworkProcess}
					listItemKeyName="id"
					defaultGridMode="block"
					materials={cProps.marketingVideos}
					card={(cardProps) => (
						<VideoCard
							videoCode={cardProps.VideoEmbedCode}
							title={cardProps.VideoTitle}
							id={cardProps.VideoId}
							{...cardProps}
						/>
					)}
				/>
			)}
		/>
	);
}
