import React from 'react';

// Hooks

import { useWindowWidth } from '../../../../hooks';

// Components

import ToolStandardActions from '../../../../components/block/ToolStandardActions';
import TriggerButton from '../../../../components/ui/TriggerButton';
import Container from '../../../../components/rp/Container';

// Action creators

import { toggleModal } from '../../../../redux/actionCreators/mat';

// Static

import { limit } from '../../../../static/constants';

// Styles

import './styles.scss';

// ----------------

export default function ImageTool() {
	// Preparation

	const currentWindowWidth = useWindowWidth();
	const deviceSizeXS = currentWindowWidth <= 576;

	return (
		<div className="image-tool">
			{/* Image compound actions */}

			<Container
				mapDispatch={{ toggleModal }}
				mapState={(state) => ({ imageCount: state.mat.imageCount })}
				render={(cProps) => (
					<div className="image-tool__section">
						{!deviceSizeXS && (
							<div className="image-tool__count">
								{cProps.imageCount} / {limit.IMAGES}
							</div>
						)}
						<ul className="image-tool__list">
							<li>
								<TriggerButton
									mobileColorInverse
									onClick={() => cProps.toggleModal('replaceColor')}
									label="Replace color"
									size={deviceSizeXS ? 'md' : 'sm'}
								/>
							</li>
							<li>
								<TriggerButton
									mobileColorInverse
									onClick={() => cProps.toggleModal('removeColor')}
									label="Remove color"
									size={deviceSizeXS ? 'md' : 'sm'}
								/>
							</li>
							<li>
								<TriggerButton
									mobileColorInverse
									onClick={() => cProps.toggleModal('cropImage')}
									label="Crop image"
									size={deviceSizeXS ? 'md' : 'sm'}
								/>
							</li>
						</ul>
					</div>
				)}
			/>

			{/* Image standard actions */}

			<ToolStandardActions />
		</div>
	);
}
