import { NS } from "../../static/constants";

// ----------------

const TYPE_NAME = "MARKETING";

export const GET_MARKETING_MATERIALS_$NETWORK = `${NS}/${TYPE_NAME}/GET_MARKETING_MATERIALS_$NETWORK`;
export const SET_MARKETING_VIDEOS             = `${NS}/${TYPE_NAME}/SET_MARKETING_VIDEOS`;
export const SET_MARKETING_FLYERS             = `${NS}/${TYPE_NAME}/SET_MARKETING_FLYERS`;
export const SET_ACTIVE_FLYER_ID              = `${NS}/${TYPE_NAME}/SET_ACTIVE_FLYER_ID`;
export const RESTORE_BRANCH                   = `${NS}/${TYPE_NAME}/RESTORE_BRANCH`;
