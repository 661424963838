import { reducer as systemNotifications } from 'react-notification-system-redux';

export default {
	systemNotifications,
	marketing: require('./marketing').default,
	network: require('./network').default,
	system: require('./system').default,
	google: require('./google').default,
	sketch: require('./sketch').default,
	mats: require('./mats').default,
	meta: require('./meta').default,
	user: require('./user').default,
	mat: require('./mat').default,
	ui: require('./ui').default,
	matCanvas: require('./matCanvas').default,
};
