import types from 'prop-types';
import React from 'react';

// Hooks

import { useWindowWidth } from '../../../hooks';

// Components

import { SelectColor, Select, TriggerButton } from '../../../components';

// Selectors

import getById from '../../../redux/selectors/getById';

// Data

import { fontFamily as fontFamilyOptions } from '../../../static/data/mat';

// Styles

import './styles.scss';

// ----------------

// Type of props

FontParams.propTypes = {
	onChangeFontFamily: types.func.isRequired,
	onSelectBGColor: types.func.isRequired,
	onSelectColor: types.func.isRequired,
	onItalicClick: types.func.isRequired,
	onBoldClick: types.func.isRequired,
	fontObject: types.object.isRequired,
	onClearBG: types.func.isRequired,
	colors: types.object.isRequired,
};

// ----------------

export default function FontParams(props) {
	const {
		fontBGColor: fontBGColorName,
		fontFamily,
		fontColor: fontColorName,
		italic,
		bold,
	} = props.fontObject;

	const {
		onChangeFontFamily,
		onSelectBGColor,
		onSelectColor,
		onItalicClick,
		onBoldClick,
		onClearBG,
		colors,
	} = props;

	const allColors   = [...colors.standart, ...colors.pms]; // prettier-ignore
	const fontColor   = getById(allColors, 'name', fontColorName); // prettier-ignore
	const fontBGColor = fontBGColorName
		? getById(allColors, 'name', fontBGColorName)
		: null;

	// Preparation

	const currentWindowWidth = useWindowWidth();
	const deviceSizeXS = currentWindowWidth <= 576;

	// Render

	return (
		<div className="font-params">
			{/* Font family */}

			<div className="font-params__row font-params__row--font">
				<Select
					withFontPreviews
					selectedOption={fontFamilyOptions.find((font) => font.key === fontFamily)}
					optionsVisible={12}
					placeholder="Font"
					onSelect={(option) => onChangeFontFamily(option.key)}
					onFocus={() => null}
					options={fontFamilyOptions}
					theme="fourth"
					name="font"
					size={deviceSizeXS ? 'md' : 'xs'}
					fluid
				/>
			</div>

			{/* Font style */}

			<div className="font-params__row font-params__row--style">
				<div className="font-params__style-row">
					<div className="font-params__trigger">
						<TriggerButton
							fontSize={deviceSizeXS ? 'md' : 'sm'}
							onClick={onBoldClick}
							active={bold}
							label="B"
							size={deviceSizeXS ? 'md' : 'sm'}
							bold
						/>
					</div>
					<div className="font-params__trigger">
						<TriggerButton
							fontSize={deviceSizeXS ? 'md' : 'sm'}
							onClick={onItalicClick}
							active={italic}
							label="I"
							size={deviceSizeXS ? 'md' : 'sm'}
							italic
						/>
					</div>
				</div>
			</div>

			{/* Font BG color */}

			<div className="font-params__row">
				<SelectColor
					withBorderRadius
					sketchColors
					onSelect={onSelectBGColor}
					label={deviceSizeXS ? 'BG' : 'Background'}
					size={deviceSizeXS ? 'xl' : 'sm'}
					{...(fontBGColor && {
						onCrossClick: () => onClearBG(null),
						rgb: {
							r: fontBGColor.red,
							g: fontBGColor.green,
							b: fontBGColor.blue,
						},
						name: fontBGColor.name,
					})}
				/>
			</div>

			{/* Font color */}

			<div className="font-params__row">
				<SelectColor
					withBorderRadius
					sketchColors
					onSelect={onSelectColor}
					{...(!deviceSizeXS ? { label: 'Color' } : {})}
					size={deviceSizeXS ? 'xl' : 'sm'}
					rgb={{
						r: fontColor.red,
						g: fontColor.green,
						b: fontColor.blue,
					}}
					name={fontColor.name}
				/>
			</div>
		</div>
	);
}
