import types from 'prop-types';
import React, { useEffect } from 'react';

// Components

import InputField from '../../../../components/ui/InputField';
import Button from '../../../../components/ui/Button';

// Styles

import './styles.scss';

// ----------------

// Type of props

NewSketchRequestForm.propTypes = {
	onSubmit: types.func.isRequired,
	onClose: types.func.isRequired,
	fields: types.object.isRequired,
};

// Default value for props

NewSketchRequestForm.defaultProps = {
	onSubmit: () => null,
	onClose: () => null,
};

// ----------------

export default function NewSketchRequestForm(props) {
	const { onSubmit, fields, onClose } = props;

	useEffect(() => {
		document
			.getElementsByClassName('new-sketch-request-form')[0]
			.getElementsByTagName('input')[0]
			.focus();
	}, []);

	return (
		<form className="new-sketch-request-form" onSubmit={onSubmit}>
			<fieldset className="new-sketch-request-form__fields">
				<InputField
					{...fields.sketchName}
					placeholder="Enter your new sketch name"
					theme="onPrimaryDark"
					title="Sketch name"
					size="md"
					fluid
				/>
			</fieldset>
			<div className="form__two-buttons-grid">
				<Button
					reverseType
					onClick={onClose}
					fluid
					id="new-sketch-request-form-cancel-button"
				>
					Cancel
				</Button>
				<Button
					onClick={onSubmit}
					fluid
					type="submit"
					id="new-sketch-request-form-submit-button"
				>
					Start
				</Button>
			</div>
		</form>
	);
}
