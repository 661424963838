import { NS } from "../../static/constants";

// ----------------

const TYPE_NAME = "GOOGLE";

export const RESTORE_BRANCH           = `${NS}/${TYPE_NAME}/RESTORE_BRANCH`;
export const MUTATION_TYPE            = `${NS}/${TYPE_NAME}/MUTATION_TYPE`;
export const GET_IMAGES_$NETWORK      = `${NS}/${TYPE_NAME}/GET_IMAGES_$NETWORK`;
export const GET_MORE_IMAGES_$NETWORK = `${NS}/${TYPE_NAME}/GET_MORE_IMAGES_$NETWORK`;
