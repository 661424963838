import React from 'react';

// Hooks

import { useWindowWidth } from '../../../hooks';

// Components

import { Container, TriggerButton, ColorTool, SelectImage } from '../../../components';

// Action creators

import {
	addEditBGFromCurrentBg,
	addImageObjectFromBg,
	setModalType,
	setBGOption,
	toggleModal,
	delBGImage,
} from '../../../redux/actionCreators/mat';

// Styles

import './styles.scss';

// ----------------

export default function TriggerBackground() {
	// Preparation

	const currentWindowWidth = useWindowWidth();
	const deviceSizeXS = currentWindowWidth <= 576;

	return (
		<div className="trigger-background">
			<Container
				mapDispatch={{
					addEditBGFromCurrentBg,
					addImageObjectFromBg,
					setModalType,
					setBGOption,
					toggleModal,
					delBGImage,
				}}
				mapState={(state) => ({
					bgOption: state.mat.bgOption,
					bgImage: state.mat.bgImage,
				})}
				render={(cProps) => (
					<>
						<TriggerButton
							onClick={() => cProps.setBGOption('color')}
							active={cProps.bgOption === 'color'}
							label="Color"
							name="bgColor"
							size={deviceSizeXS ? 'md' : 'sm'}
							{...(deviceSizeXS ? { borderRadius: 'left' } : {})}
							id="photorealistic-bg-color"
						/>
						<TriggerButton
							onClick={() => cProps.setBGOption('image')}
							active={cProps.bgOption === 'image'}
							label="Image"
							name="bgImage"
							size={deviceSizeXS ? 'md' : 'sm'}
							{...(deviceSizeXS ? { borderRadius: 'right' } : {})}
							id="photorealistic-bg-image"
						/>

						<div className="trigger-background__body">
							{cProps.bgOption === 'color' ? (
								<ColorTool size={deviceSizeXS ? 'xl' : 'sm'} />
							) : (
								<SelectImage
									onCrossClick={() => cProps.addImageObjectFromBg()}
									imageUrl={cProps.bgImage}
									onClick={() => {
										if (cProps.bgImage) {
											cProps.addEditBGFromCurrentBg(cProps.bgImage);
										} else {
											cProps.setModalType('addImageToBackground');
											cProps.toggleModal('image');
										}
									}}
									label="Select image"
									size={deviceSizeXS ? 'xl' : 'sm'}
									id="select-bg-image"
								/>
							)}
						</div>
					</>
				)}
			/>
		</div>
	);
}
