import React, { useEffect } from 'react';
import types from 'prop-types';

// Components

import ImageCard from '../../../components/block/cards/ImageCard';
import SearchInput from '../../../components/ui/SearchInput';
import List from '../../../components/block/List';
import Button from '../../../components/ui/Button';
import Bool from '../../../components/rp/Bool';
import Form from '../../../modules/form-x';

// Styles

import './styles.scss';

// ----------------

// Type of props

ListImage.propTypes = {
	loadMoreNetworkProcess: types.bool,
	defaultSearchValue: types.string,
	networkProcess: types.bool,
	onImageClick: types.func.isRequired,
	showLoadMore: types.bool,
	onLoadMore: types.func.isRequired,
	onSearch: types.func.isRequired,
	list: types.array,
};

// ----------------

function BoundImageCard({ data, onClick }) {
	return (
		<Bool
			render={({ isTrue, handleChange }) => (
				<ImageCard
					disabled={isTrue}
					onClick={() => {
						handleChange();
						onClick(data.link);
					}}
					image={data.link}
					label={data.title}
					size={`${data.image.width}x${data.image.height}`}
				/>
			)}
		/>
	);
}

// ----------------

export default function ListImage(props) {
	const {
		loadMoreNetworkProcess,
		defaultSearchValue,
		networkProcess,
		onImageClick,
		showLoadMore,
		onLoadMore,
		onSearch,
		list,
	} = props;

	// Preparation

	useEffect(() => {
		document
			.getElementsByClassName('list-image__search')[0]
			.getElementsByTagName('input')[0]
			.focus();
	}, []);

	// Render

	return (
		<div className="list-image">
			<div className="list-image__search">
				<Form
					fields={{ search: { value: defaultSearchValue || '' } }}
					render={(formProps) => (
						<form onSubmit={formProps.onSubmit}>
							<SearchInput
								placeholder="Search images..."
								onChange={formProps.fields.search.onChange}
								onClear={() => formProps.onClear('search')}
								value={formProps.fields.search.value}
								name={formProps.fields.search.name}
							/>
						</form>
					)}
					onSubmit={(data) => {
						if (data.search) onSearch(data.search);
					}}
				/>
			</div>
			<div className="list-image__cards-grid-wrapper">
				<List
					listContentClassName="list-image__grid"
					networkProcess={networkProcess}
					staticProps={{ onClick: onImageClick }}
					listItem={BoundImageCard}
					list={list}
				/>
				{showLoadMore && list.length && !networkProcess ? (
					<div className="list-image__button">
						<Button
							loaderColor="white"
							isLoading={loadMoreNetworkProcess}
							onClick={onLoadMore}
							size="lg"
							fluid
							id="load-more-google-images"
						>
							Load more
						</Button>
					</div>
				) : null}
			</div>
		</div>
	);
}
