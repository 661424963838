import React, { useState, memo, useRef, useEffect } from 'react';
import useOnClickOutside from 'use-onclickoutside';
import classNames from 'classnames';
import types from 'prop-types';

// Hooks

import { useWindowWidth } from '../../../hooks';

// Components

import OptionsFieldMobile from '../../../components/ui/Select/OptionsFieldMobile';
import Loader from '../../../components/block/loaders/Loader';
import SvgIcon from '../../../components/block/SvgIcon';

// Assets

import { chevronRightIcon } from '../../../assets/icons';

// Styles

import './styles.scss';

// ----------------

// Type of props

Select.propTypes = {
	withFontPreviews: types.bool,
	selectedOption: types.shape({
		value: types.string,
		key: types.string,
	}),
	optionsVisible: types.number,
	placeholder: types.string,
	loaderSize: types.string,
	onSelect: types.func,
	options: types.array.isRequired,
	fluid: types.bool,
	theme: types.oneOf(['onPrimary', 'secondary', 'secondaryDark', 'fourth']),
	size: types.oneOf(['xs', 'sm', 'md', 'lg']),
	keys: types.shape({
		keyValue: types.string,
		keyName: types.string,
	}),
};

// Default value for props

Select.defaultProps = {
	optionsVisible: 5.45,
	theme: 'onPrimary',
	status: {},
	size: 'lg',
};

// ----------------

function Select(props) {
	const {
		withFontPreviews,
		selectedOption,
		optionsVisible,
		placeholder,
		loaderSize,
		isLoading,
		onSelect,
		options,
		status: { error },
		theme,
		fluid,
		size,
		keys,
	} = props;

	// State

	const [optionsFieldOpen, toggleOptionsField] = useState(false);
	const [optionsFieldOutside, toggleOptionsFieldOutside] = useState(false);

	// Preparation

	const currentWindowWidth = useWindowWidth();
	const selectRef = useRef(null);

	useOnClickOutside(selectRef, handleOptionsFieldClose);

	useEffect(() => {
		if (currentWindowWidth <= 768) {
			if (!optionsFieldOutside) {
				toggleOptionsFieldOutside(true);
			}
		} else if (optionsFieldOutside) {
			toggleOptionsFieldOutside(false);
		}
	}, [currentWindowWidth, optionsFieldOutside]);

	// Handlers

	function handleOptionsFieldToggle() {
		if (!optionsFieldOpen) props.onFocus();

		toggleOptionsField(!optionsFieldOpen);
	}

	function handleOptionsFieldClose() {
		toggleOptionsField(false);
	}

	function handleOptionSelect(selectedOption) {
		onSelect(selectedOption);
		handleOptionsFieldClose();
	}

	// Modify item key and value

	const value = keys ? keys.keyValue : 'value';
	const key = keys ? keys.keyName : 'key';

	// Modify styles

	const modify = classNames({
		[` select--theme-${theme}`]: theme,
		[` select--size-${size}`]: size,
		' select--fluid': fluid,
	});

	const modifyButton = classNames({
		' select__button--focus': optionsFieldOpen,
		' select__button--error': error,
	});

	const modifyOptionsField = classNames({
		' select__options-field--open': optionsFieldOpen,
	});

	const modifyOption = (item) =>
		classNames({
			' select__option--selected': item[key] === selectedOption[key],
		});

	const optionHeight = {
		xs: '29px',
		sm: '29px',
		md: '42px',
		lg: '44px',
	};

	// Render

	return (
		<div className={`select${modify}`} ref={optionsFieldOutside ? null : selectRef}>
			<div
				className={`select__button${modifyButton}`}
				onClick={handleOptionsFieldToggle}
				id="select-button"
			>
				<div className="select__button-icon">
					<SvgIcon size="xs" url={chevronRightIcon} />
				</div>
				{isLoading ? (
					<Loader color={theme} size={loaderSize} />
				) : !selectedOption ? (
					<span className="select__placeholder">{placeholder}</span>
				) : (
					<span className="select__selected-option">
						{withFontPreviews ? (
							<span style={{ fontFamily: `${selectedOption[value]}` }}>
								{selectedOption[value]}
							</span>
						) : (
							selectedOption[value]
						)}
					</span>
				)}
			</div>
			{optionsFieldOpen && (
				<>
					{optionsFieldOutside ? (
						<OptionsFieldMobile
							handleOptionsFieldClose={handleOptionsFieldClose}
							handleOptionSelect={handleOptionSelect}
							optionsFieldOpen={optionsFieldOpen}
							withFontPreviews={withFontPreviews}
							selectedOption={selectedOption}
							optionsVisible={optionsVisible}
							optionKey={key}
							options={options}
							theme={theme}
							value={value}
						/>
					) : (
						<div
							className="select__options-field-backdrop"
							onClick={handleOptionsFieldClose}
						>
							<div className={`select__options-field${modifyOptionsField}`}>
								<ul
									className="select__options-field-content"
									style={{
										maxHeight: `calc(${optionsVisible} * ${optionHeight[size]})`,
									}}
								>
									{!!options.length ? (
										options.map((item) => (
											<li
												className={`select__option${
													selectedOption ? modifyOption(item) : ''
												}`}
												onClick={() => handleOptionSelect(item)}
												key={item[key]}
											>
												{withFontPreviews ? (
													<span style={{ fontFamily: `${item[value]}` }}>
														{item[value]}
													</span>
												) : (
													item[value]
												)}
											</li>
										))
									) : (
										<p className="select__no-options">No options</p>
									)}
								</ul>
							</div>
						</div>
					)}
				</>
			)}
		</div>
	);
}

export default memo(Select);
