const getMatStatus = (mat) => {
	if (mat.mcSketchStatusID) {
		switch (mat.mcSketchStatusID) {
			case 205:
				return 'complete';

			case 226:
				return 'rejected';

			case 249:
				return 'inProcess';

			default: {
				console.log('Mat status is undefined');
				return undefined;
			}
		}
	} else {
		switch (true) {
			case mat.isPending === 1:
				return 'inProcessDraft';

			case mat.isPending === false:
				return 'draft';

			default:
		}
	}
};

export default getMatStatus;
