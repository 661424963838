import classNames from 'classnames';
import types from 'prop-types';
import React from 'react';

// Styles

import './styles.scss';

// ----------------

// Type of props

AnimatedIcon.propTypes = {
	icon: types.oneOf(['add', 'search']).isRequired,
};

// ----------------

export const AddIconRender = () => {
	return (
		<>
			<div className="plus ver" />
			<div className="plus hor" />
		</>
	);
};

export const SearchIconRender = () => {
	return (
		<>
			<div className="glass ring" />
			<div className="glass blink" />
			<div className="glass handle" />
		</>
	);
};

const iconRender = {
	search: <SearchIconRender />,
	add: <AddIconRender />,
};

// ----------------

export default function AnimatedIcon(props) {
	// Modify styles

	const modify = classNames({
		[` animated-icon--${props.icon}`]: props.icon,
	});

	// Render

	return <div className={`animated-icon${modify}`}>{iconRender[props.icon]}</div>;
}
