import initCenteringGuidelines from './centeringGuidelines';
import initAligningGuidelines from './aligningGuidelines';
import FontFaceObserver from 'fontfaceobserver';
import imageAspectRatio from 'image-aspect-ratio';
import { fabric } from 'fabric-with-gestures';
import React from 'react';
import 'fabric-customise-controls';
import { connect } from 'react-redux';

// Static

import { matType } from '../../../static/constants';

// Action types

import {
	RESTORE_MAT_HISTORY,
	SET_ORIENTATION_L,
	SET_ORIENTATION_P,
	TOGGLE_DRAG_MODE,
	SET_BORDER_COLOR,
	TOGGLE_BORDER,
	SET_BG_OPTION,
	SET_BG_COLOR,
	DEL_BG_COLOR,
	SELECT_COLOR,
	DESELECT_COLOR,
	CLEAR_COLORS,
	SET_BG_IMAGE,
	DEL_BG_IMAGE,
	SET_Z_INDEX,
	DEL_OBJECT,
	SET_SIZE,
	SET_TOOL,
	ZOOM,
} from '../../../redux/types/mat';

// Aсtions

import { setCanvas } from './../../../redux/actionCreators/matCanvas';

// Selectors

import getById from '../../../redux/selectors/getById';

// Images

import transparentBg from '../../../assets/images/transparent_bg.jpg';
import cropIcon from '../../../assets/icons/crop.svg';
import penIcon from '../../../assets/icons/pen.svg';

// Styles

import './styles.scss';

// ----------------

class Canvas extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			initGuidelines: true,
		};

		this.canvasHeight = null;
		this.canvasWidth = null;
		this.objects = {};
		this.canvas = null;
		this.mobile = this.props.mobile;

		this.props.dispatch(setCanvas(this));

		// Binds

		this.handleTextObjectDblClick = this.handleTextObjectDblClick.bind(this);
		this.handleDeselectedObject = this.handleDeselectedObject.bind(this);
		this.handleSelectedObject = this.handleSelectedObject.bind(this);
		this.handleModifiedObject = this.handleModifiedObject.bind(this);
		this.handleKeyPress = this.handleKeyPress.bind(this);

		// Refs

		this.canvasRef = React.createRef();
	}

	// -------- Life cycle --------

	/**
	 * Did mount
	 */

	componentDidMount() {
		this.canvas = new fabric.Canvas('canvas');

		this.config();

		if (this.props.restore) {
			this.restore();
		} else {
			this.newDraftConfig();
			this.canvas.renderAll();
		}
	}

	/**
	 * Did update
	 */

	componentDidUpdate() {
		this.update();
	}

	/**
	 * Will unmount
	 */

	componentWillUnmount() {
		document.removeEventListener('keydown', this.handleKeyPress);
	}

	// -------- CRUD --------

	/**
	 * Specifies the type of mutation.
	 */

	update() {
		const {
			syncObjectsParams,
			matSnapshots,
			tempBgImage,
			lastAction,
			objects,
			bgImage,
			mobile,
			zoom,
		} = this.props;

		if (mobile !== this.mobile) {
			this.mobile = mobile;
			this.setBorderForObjects();
			this.canvas.renderAll();
		}

		switch (lastAction.type) {
			case DEL_OBJECT: {
				this.delObject(lastAction.id);

				lastAction.save && matSnapshots();

				break;
			}

			case SET_BG_OPTION: {
				if (bgImage && !tempBgImage) {
					this.setBackgroundImage(bgImage);
				}

				if (!bgImage && tempBgImage) {
					this.setBackgroundImage(transparentBg, false);
					this.setBackgroundColor();
				}

				matSnapshots();

				break;
			}

			case SET_BG_COLOR: {
				this.setBackgroundColor();

				matSnapshots();

				break;
			}

			case SELECT_COLOR:
			case DESELECT_COLOR: {
				matSnapshots();

				break;
			}

			case DEL_BG_COLOR:
			case CLEAR_COLORS: {
				this.setBackgroundImage(transparentBg, false);

				matSnapshots();

				break;
			}

			case RESTORE_MAT_HISTORY: {
				this.restore();

				break;
			}

			case TOGGLE_BORDER: {
				this.toggleBorder();
				matSnapshots();

				break;
			}

			case SET_BORDER_COLOR: {
				this.setBorderColor();
				matSnapshots();

				break;
			}

			case ZOOM: {
				this.zoom(zoom);
				this.toggleBorder();

				break;
			}

			case TOGGLE_DRAG_MODE: {
				this.toggleDragMode();

				break;
			}

			case SET_Z_INDEX: {
				this.setZIndex(objects);
				matSnapshots();

				break;
			}

			case SET_TOOL: {
				this.canvas.discardActiveObject();

				this.canvas.renderAll();

				break;
			}

			case SET_ORIENTATION_L:
			case SET_ORIENTATION_P:
			case SET_SIZE: {
				this.setSize();

				if (bgImage) {
					this.setBackgroundImage(this.canvas.backgroundImage, true, true);
				}
				this.resizeObjects();

				syncObjectsParams(this.canvas.getObjects());

				break;
			}

			case SET_BG_IMAGE: {
				this.canvas.discardActiveObject();
				this.canvas.renderAll();

				this.setBackgroundImage(bgImage);

				break;
			}

			case DEL_BG_IMAGE: {
				this.setBackgroundImage(transparentBg, false);
				this.setBackgroundColor();

				break;
			}

			default:
				return;
		}
	}

	/**
	 * Create text fabric object
	 *
	 * @param {object}  params - Params of fabric object
	 * @param {boolean} restore
	 */

	async createTextFabricObject(params, restore) {
		let { text } = params;
		const { id } = params;

		if (!id) throw new Error('createTextFabricObject -> Object must have id parameter.');

		if (!text) text = ';)';

		// Create object

		const fabricTextObj = new fabric.Text(text);

		// Init

		this.initFabricObject(fabricTextObj, id, 'text', !restore);
		await this.setParamsForFabricObject(fabricTextObj, params);

		return fabricTextObj;
	}

	/**
	 * Create image fabric object
	 *
	 * @param {object}  params - Params of fabric object
	 * @param {boolean} restore
	 */

	async createImageFabricObject(params, restore) {
		const { id, url } = params;

		if (!id) throw new Error('createImageFabricObject -> Object must have id parameter.');

		const fabricImgObj = await new Promise((resolve) => {
			fabric.Image.fromURL(url, (fabricImgObj) => {
				this.initFabricObject(fabricImgObj, id, 'image', !restore);
				this.normalizeImageSize(fabricImgObj);
				this.setParamsForFabricObject(fabricImgObj, params);

				resolve(fabricImgObj);
			});
		});

		return fabricImgObj;
	}

	/**
	 * Delete fabric object
	 *
	 * @param {number} id
	 */

	delObject(id) {
		this.canvas.remove(this.objects[id]);

		delete this.objects[id];
	}

	// -------- Setters --------

	/**
	 * Toggle border
	 */

	toggleBorder() {
		const { isShow, color: colorName } = this.props.border;
		const { zoom } = this.props;
		const borderSize = 8 * zoom;

		// Added a little border gap to prevent issue on iOS when border didn't sit flush to the edge of canvas
		const borderGap = 0.5;

		if (isShow) {
			const color = this.getColorObjectByName(colorName);

			this.canvasRef.current.style.outlineOffset = `-${borderSize - borderGap}px`;
			this.canvasRef.current.style.outline = `${borderSize + borderGap}px solid rgb(${
				color.red
			}, ${color.green}, ${color.blue})`;
		} else {
			this.canvasRef.current.style.outline = 'none';
		}
	}

	/**
	 * Set border color
	 */

	setBorderColor() {
		const { color: colorName } = this.props.border;
		const color = this.getColorObjectByName(colorName);

		this.canvasRef.current.style.outlineColor = `rgb(${color.red}, ${color.green}, ${color.blue})`;
	}

	/**
	 * Zoom
	 */

	zoom(zoom) {
		const { canvas } = this;

		canvas.setHeight(this.canvasHeight * zoom);
		canvas.setWidth(this.canvasWidth * zoom);

		canvas.setZoom(zoom);
	}

	/**
	 * Toggle drag mode
	 */

	toggleDragMode() {
		const { dragMode } = this.props;
		const handle = document.getElementById('handleCanvas');

		if (dragMode) {
			handle.style.cursor = 'grab';
			this.canvas.defaultCursor = 'grab';
			this.canvas.discardActiveObject();

			for (let key in this.objects) {
				this.objects[key].hoverCursor = 'grab';
				this.objects[key].selectable = false;
			}
		} else {
			handle.style.cursor = 'default';
			this.canvas.defaultCursor = 'default';

			for (let key in this.objects) {
				this.objects[key].hoverCursor = 'move';
				this.objects[key].selectable = true;
			}
		}

		this.canvas.renderAll();
	}

	/**
	 * @param {object}  objects
	 * @param {boolean} render
	 */

	setZIndex(objects, render = false) {
		Object.keys(objects)
			.map((key) => objects[key])
			.sort((a, b) => (a.zIndex > b.zIndex ? 1 : -1))
			.forEach((obj) => {
				this.objects[obj.id].moveTo(obj.zIndex);
			});

		render && this.canvas.renderAll();
	}

	/**
	 * Set canvas size
	 */

	setSize() {
		const { matOrientation, matSize, zoom } = this.props;
		const { initGuidelines } = this.state;

		let matSizeArr = [];
		let x = 4;
		let y = 6;

		if (matSize) {
			matSizeArr = matSize.split('x');

			x = +matSizeArr[0];
			y = +matSizeArr[1];
		}

		const maxSize = 576;
		const maxCanvasWidth = 384;
		let ratio = y / x;

		let canvasHeight;
		let canvasWidth;

		if (matOrientation === 'Portrait') {
			canvasWidth = maxCanvasWidth;
			canvasHeight = canvasWidth * ratio;

			if (canvasHeight > maxSize) {
				canvasHeight = maxSize;
				canvasWidth = canvasHeight / ratio;
			}
		} else {
			canvasHeight = maxCanvasWidth;
			canvasWidth = canvasHeight * ratio;

			if (canvasWidth > maxSize) {
				canvasWidth = maxSize;
				canvasHeight = canvasWidth / ratio;
			}
		}

		this.canvas.setHeight(canvasHeight * zoom);
		this.canvas.setWidth(canvasWidth * zoom);

		this.canvasHeight = canvasHeight;
		this.canvasWidth = canvasWidth;

		// Init guidelines

		if (initGuidelines) {
			initCenteringGuidelines(this.canvas);

			this.setState({
				initGuidelines: false,
			});
		}
	}

	/**
	 * Resize objects
	 */

	resizeObjects() {
		const { canvas } = this;
		const { zoom } = this.props;
		const { width, height } = canvas;

		var sel = new fabric.ActiveSelection(canvas.getObjects(), {
			canvas: canvas,
		});

		canvas.setActiveObject(sel);

		if (width > height) {
			sel.set({
				scaleX: (canvas.height - 30) / (sel.height * zoom),
				scaleY: (canvas.height - 30) / (sel.height * zoom),
			});
		} else {
			sel.set({
				scaleX: (canvas.width - 30) / (sel.width * zoom),
				scaleY: (canvas.width - 30) / (sel.width * zoom),
			});
		}

		sel.viewportCenter();

		canvas.discardActiveObject();
		canvas.requestRenderAll();

		canvas.remove(sel);
	}

	/**
	 * Set background image
	 *
	 * @param {string}  image
	 * @param {boolean} [cover=true]
	 * @param {boolean} [update=false]
	 */

	setBackgroundImage(image, cover = true, update = false) {
		if (cover) {
			const setCoverParams = (fabricImgObj) => {
				let left = 0;
				let top = 0;

				const { canvasWidth, canvasHeight } = this;

				let { width, height } = imageAspectRatio.calculate(
					fabricImgObj.width,
					fabricImgObj.height,
					canvasWidth,
					canvasHeight
				);

				let coefficient = 1;

				if (canvasHeight > height) {
					coefficient = canvasHeight / height;
				} else if (canvasWidth > width) {
					coefficient = canvasWidth / width;
				}

				height = height * coefficient;
				width = width * coefficient;

				if (width > canvasWidth) {
					left = ((width - canvasWidth) / 2) * -1;
				}

				if (height > canvasHeight) {
					top = ((height - canvasHeight) / 2) * -1;
				}

				this.canvas.setBackgroundImage(
					fabricImgObj,
					this.canvas.renderAll.bind(this.canvas),
					{
						left,
						top,
						scaleY: height / fabricImgObj.height,
						scaleX: width / fabricImgObj.width,
					}
				);
			};

			if (update) {
				setCoverParams(image);
			} else {
				fabric.Image.fromURL(image, setCoverParams, {
					crossOrigin: 'Anonymous',
				});
			}
		} else {
			this.canvas.backgroundImage = null;
			this.canvas.backgroundColor = null;

			this.canvas.setBackgroundColor(
				{
					source: image,
					crossOrigin: 'Anonymous',
				},
				this.canvas.renderAll.bind(this.canvas)
			);
		}
	}

	/**
	 * Set background color
	 */

	setBackgroundColor() {
		const { bgColor: bgColorName } = this.props;

		if (bgColorName) {
			const bgColor = this.getColorObjectByName(bgColorName);

			this.canvas.setBackgroundColor(
				`rgb(${bgColor.red},${bgColor.green},${bgColor.blue})`,
				() => {
					this.canvas.renderAll();
				}
			);

			return true;
		}

		return false;
	}

	/**
	 * Set border for objects
	 */

	setBorderForObjects() {
		const objects = this.canvas.getObjects();

		objects.forEach((object) => {
			this.objectBorderConfig(object, this.mobile);
		});
	}

	// -------- Utils --------

	/**
	 * Setting global canvas сonfigurations.
	 */

	config() {
		const { zoom } = this.props;
		this.canvas.selection = false;

		// Set default zoom

		this.zoom(zoom);

		// Init guidelines
		initAligningGuidelines(this.canvas);

		// Add global events on canvas

		document.addEventListener('keydown', this.handleKeyPress);

		// Corner setting

		this.canvas.customiseControls(
			{
				tr: {
					cursor: 'pointer',
					action: (e, target) => {
						if (target.objectType === 'text') {
							this.props.setEditTextId(target.objectId);
						} else {
							this.props.toggleModal('cropImage');
						}
					},
				},
			},
			() => {
				this.canvas.renderAll();
			}
		);
	}

	/**
	 * Setting canvas сonfigurations for new draft.
	 */

	newDraftConfig() {
		// Set canvas size

		this.setSize();

		// Set transparent background

		this.setBackgroundImage(transparentBg, false);
	}

	/**
	 * Restore canvas
	 */

	async restore() {
		const {
			updateExtractedColors,
			bgColor: bgColorName,
			matType: currentMatType,
			bgImage,
			objects,
			zoom,
		} = this.props;

		const fabricObjects = this.objects;
		const newObjects = [];

		// Border

		this.toggleBorder();

		// Size

		this.setSize();

		// Zoom

		this.zoom(zoom);

		// Objects

		const fabricObjectsKeys = Object.keys(fabricObjects);
		const objectsKeys = Object.keys(objects);

		for (let i = 0; i < objectsKeys.length; i++) {
			const fabricObject = fabricObjects[objectsKeys[i]];
			const object = objects[objectsKeys[i]];

			if (fabricObject) {
				await this.props.editObject(object, false);
			} else {
				let fabricObj;

				if (object.objectType === 'text') {
					fabricObj = await this.createTextFabricObject(object, true);
				} else {
					fabricObj = await this.createImageFabricObject(object, true);
				}
				newObjects.push(fabricObj);
			}
		}

		for (let i = 0; i < fabricObjectsKeys.length; i++) {
			const fabricObject = fabricObjects[fabricObjectsKeys[i]];
			const object = objects[fabricObjectsKeys[i]];

			if (!object) {
				this.canvas.remove(fabricObject);
				delete fabricObjects[fabricObjectsKeys[i]];
			}
		}

		this.canvas.add(...newObjects);

		// BG

		if (this.canvas.backgroundImage && !bgImage) this.canvas.backgroundImage = null;

		switch (true) {
			case !!bgColorName: {
				const colorObj = this.getColorObjectByName(bgColorName);
				const bgColor = `rgb(${colorObj.red},${colorObj.green},${colorObj.blue})`;

				if (bgColor !== this.canvas.backgroundColor) {
					this.canvas.setBackgroundColor(bgColor);
				}
			}
			// falls through

			case !!bgImage: {
				if (!bgImage) break;

				if (
					!this.canvas.backgroundImage ||
					this.canvas.backgroundImage._element.currentSrc !== bgImage
				) {
					this.setBackgroundImage(bgImage);
				} else {
					this.setBackgroundImage(this.canvas.backgroundImage, true, true);
				}

				break;
			}

			default: {
				if (
					typeof this.canvas.backgroundColor === 'string' ||
					this.canvas.backgroundImage
				) {
					this.setBackgroundImage(transparentBg, false);
				}
			}
		}

		// Z-index

		this.setZIndex(objects, false);

		// Render

		this.canvas.requestRenderAll();

		// Extracted Colors

		if (currentMatType === matType.TRADITIONAL.name) updateExtractedColors();
	}

	/**
	 * Init fabric object
	 *
	 * @param {object}  fabricObject
	 * @param {number}  id
	 * @param {string}  type
	 * @param {boolean} active
	 */

	initFabricObject(fabricObject, id, type, active = true) {
		fabricObject.objectType = type;
		fabricObject.objectId = id;
		this.objects[id] = fabricObject;

		// Set originX and originY

		fabricObject.originX = 'center';
		fabricObject.originY = 'center';

		// ---- Events ----

		// Add handler on modified event

		fabricObject.on('modified', this.handleModifiedObject);

		// Add handler on selected

		fabricObject.on('selected', this.handleSelectedObject);

		// Add handler on deselected

		fabricObject.on('deselected', this.handleDeselectedObject);

		if (type === 'text') {
			// Add handler on double click

			fabricObject.on('mousedblclick', this.handleTextObjectDblClick);
		}

		// ---- Rest ----

		// Set object as active

		active && this.canvas.setActiveObject(fabricObject);

		// Set border style

		this.objectBorderConfig(fabricObject, this.mobile);

		// Set object on center

		this.canvas.viewportCenterObject(fabricObject);
	}

	/**
	 * Set params for fabric object
	 *
	 * @param {object} fabricObject
	 * @param {object} params
	 */

	async setParamsForFabricObject(fabricObject, params) {
		const type = fabricObject.objectType;

		// Set params by object type

		if (type === 'text') {
			// Text

			await this.setParamsForTextFabricObject(fabricObject, params);
		} else {
			// Image

			await this.setParamsForImageFabricObject(fabricObject, params);
		}

		return fabricObject;
	}

	/**
	 * Set params for text fabric object
	 *
	 * @param {object} fabricObject
	 * @param {object} params
	 */

	async setParamsForTextFabricObject(fabricObject, params) {
		const {
			fontBGColor: fontBGColorName,
			fontFamily,
			fontColor: fontColorName,
			height,
			italic,
			angle,
			width,
			bold,
			text,
			left,
			top,
		} = params;

		// Text

		fabricObject.set({
			text,
		});

		// Сolors

		const fontBGColor = fontBGColorName
			? this.getColorObjectByName(fontBGColorName)
			: null;
		const fontColor = fontColorName ? this.getColorObjectByName(fontColorName) : null;

		fabricObject.fontBGColor = fontBGColorName || null;
		fabricObject.fontColor = fontColorName || null;

		if (fontBGColor) {
			fabricObject.set(
				'backgroundColor',
				`rgb(${fontBGColor.red}, ${fontBGColor.green}, ${fontBGColor.blue})`
			);
		} else {
			fabricObject.set('backgroundColor', 'transparent');
		}

		if (fontColor) {
			fabricObject.set({
				fill: `rgb(${fontColor.red}, ${fontColor.green}, ${fontColor.blue})`,
			});
		}

		// Font

		await this.setFontFamily(fontFamily, fabricObject);

		// Font style

		fabricObject.set({
			fontWeight: bold ? 'bold' : 'normal',
			fontStyle: italic ? 'italic' : 'normal',
		});

		// Sizes

		if (width) {
			fabricObject.set({
				scaleX: width / fabricObject.width,
			});
		}

		if (height) {
			fabricObject.set({
				scaleY: height / fabricObject.height,
			});
		}

		// Angle

		if (angle || angle === 0)
			fabricObject.set({
				angle,
			});

		// Positions

		if (left || left === 0) {
			fabricObject.set({
				left: left + width / 2,
			});
		}

		if (top || top === 0) {
			fabricObject.set({
				top: top + height / 2,
			});
		}

		return fabricObject;
	}

	/**
	 * Set params for image fabric object
	 *
	 * @param {object} fabricObject
	 * @param {object} params
	 */

	async setParamsForImageFabricObject(fabricObject, params) {
		const { height, width, angle, left, top, url, visible } = params;

		if (fabricObject._element && fabricObject._element.src !== url) {
			await new Promise((resolve) => {
				fabricObject.setSrc(url, () => {
					resolve();
				});
			});
		}

		//fabricObject._element.src = url;

		// Sizes

		if (width) {
			fabricObject.set({
				scaleX: width / fabricObject.width,
			});
		}

		if (height) {
			fabricObject.set({
				scaleY: height / fabricObject.height,
			});
		}

		// Angle

		if (angle || angle === 0)
			fabricObject.set({
				angle,
			});

		// Positions

		if (left || left === 0) {
			fabricObject.set({
				left: left + width / 2,
			});
		}

		if (top || top === 0) {
			fabricObject.set({
				top: top + height / 2,
			});
		}

		// Visibility

		fabricObject.set({
			visible,
		});

		return fabricObject;
	}

	/**
	 * Create fabric border
	 */

	createFabricBorder() {
		const {
			colors,
			border: { color: borderColor },
		} = this.props;

		const color = getById([...colors.standart, ...colors.pms], 'name', borderColor);

		return new fabric.Rect({
			strokeWidth: 8,
			height: this.canvasHeight - 8,
			stroke: `rgb(${color.red},${color.green},${color.blue})`,
			width: this.canvasWidth - 8,
			fill: 'transparent',
		});
	}

	/**
	 * Get color object by name
	 *
	 * @param {string} colorName
	 */

	getColorObjectByName(colorName) {
		const { colors } = this.props;

		return getById([...colors.standart, ...colors.pms], 'name', colorName);
	}

	/**
	 * Normalize image size
	 *
	 * @param {object} fabricImgObj
	 */

	normalizeImageSize(fabricImgObj) {
		const { matOrientation } = this.props;
		const canvasWidth = this.canvasWidth;
		const canvasHeight = this.canvasHeight;
		const offset = 100;

		if (matOrientation === 'Portrait' && fabricImgObj.width > canvasWidth) {
			fabricImgObj.set({
				scaleX: (canvasWidth - offset) / fabricImgObj.width,
				scaleY: (canvasWidth - offset) / fabricImgObj.width,
			});

			fabricImgObj.setCoords();
			return true;
		}

		if (matOrientation === 'Landscape' && fabricImgObj.height > canvasHeight) {
			fabricImgObj.set({
				scaleX: (canvasHeight - offset) / fabricImgObj.height,
				scaleY: (canvasHeight - offset) / fabricImgObj.height,
			});

			fabricImgObj.setCoords();
			return true;
		}

		return false;
	}

	/**
	 * Set fabric object border config
	 *
	 * @param {object}  fabricObj
	 * @param {boolean} isMobile
	 */

	objectBorderConfig(fabricObj, isMobile) {
		fabricObj.customiseCornerIcons(
			{
				settings: {
					cornerBackgroundColor: 'rgba(29, 160, 255, 1)',
					borderColor: '1da0ff',
					cornerShape: 'circle',
					cornerSize: isMobile ? 28 : 16,
				},
				tr: {
					settings: {
						cornerPadding: 16,
						cornerSize: isMobile ? 38 : 30,
					},
					icon: fabricObj.objectType === 'text' ? penIcon : cropIcon,
				},
			},
			() => {
				this.canvas.renderAll();
			}
		);

		fabricObj.set({
			transparentCorners: false,
			borderDashArray: isMobile ? [10, 10] : [5, 5],
			borderColor: '#1da0ff',
			padding: 0,
		});

		const showTextControl = !(fabricObj.objectType === 'text' && isMobile);

		fabricObj.setControlsVisibility({
			ml: showTextControl,
			mr: showTextControl,
			mt: showTextControl,
			mb: showTextControl,
		});
	}

	/**
	 * Set font family
	 *
	 * @param {string} fontFamily
	 * @param {object} fabricObj
	 */

	setFontFamily(fontFamily, fabricObj) {
		return new Promise((resolve) => {
			const fontName = fontFamily.replace(/_/g, ' ');

			if (fontName && fabricObj.fontFamily !== fontName) {
				const font = new FontFaceObserver(fontName);

				font.load().then(() => {
					fabricObj.set('fontFamily', font.family);
					resolve(true);
				});
			} else resolve(false);
		});
	}

	// -------- Handlers --------

	/**
	 * Handle key press
	 */

	handleKeyPress(e) {
		if (this.props.activeObjectId) {
			switch (e.keyCode) {
				case 40: {
					this.props.editObject({
						top: this.props.objects[this.props.activeObjectId].top + 1,
						id: this.props.activeObjectId,
					});

					break;
				}

				case 38: {
					this.props.editObject({
						top: this.props.objects[this.props.activeObjectId].top - 1,
						id: this.props.activeObjectId,
					});

					break;
				}

				case 37: {
					this.props.editObject({
						left: this.props.objects[this.props.activeObjectId].left - 1,
						id: this.props.activeObjectId,
					});

					break;
				}

				case 39: {
					this.props.editObject({
						left: this.props.objects[this.props.activeObjectId].left + 1,
						id: this.props.activeObjectId,
					});

					break;
				}

				default:
			}
		}
	}

	/**
	 * Handle modified object
	 */

	handleModifiedObject(e) {
		this.props.syncObjectParams(e.target);
	}

	/**
	 * Handle selected object
	 */

	handleSelectedObject(e) {
		const { lastAction } = this.props;

		const skipActions = [SET_SIZE, SET_ORIENTATION_L, SET_ORIENTATION_P];
		if (skipActions.includes(lastAction.type)) return;

		const activeObject = this.canvas.getActiveObject();
		// Forced to call this action twice, in order to cancel the active tool first
		this.props.setActiveObject(activeObject.objectId, null);
		this.props.setActiveObject(activeObject.objectId, activeObject.objectType);
	}

	/**
	 * Handle deselected object
	 */

	handleDeselectedObject(e) {
		const { lastAction } = this.props;

		const skipActions = [SET_TOOL, SET_SIZE, SET_ORIENTATION_L, SET_ORIENTATION_P];
		if (skipActions.includes(lastAction.type)) return;

		this.props.setActiveObject(null);
	}

	/**
	 * Handle text object double click
	 */

	handleTextObjectDblClick(e) {
		this.props.setEditTextId(e.target.objectId);
	}

	// -------- Render --------

	render() {
		return (
			<div className="canvas-wrapper">
				<div className="canvas">
					<canvas ref={this.canvasRef} id="canvas" />
				</div>
			</div>
		);
	}
}

export default connect((state) => ({ matCanvas: state.matCanvas }))(Canvas);
