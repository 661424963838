import React, { memo } from 'react';
import classNames from 'classnames';
import types from 'prop-types';

// Styles

import './styles.scss';

// ----------------

// Type of props

Switch.propTypes = {
	onChange: types.func.isRequired,
	active: types.bool.isRequired,
	theme: types.oneOf([
		'onPrimary',
		'onPrimaryDark',
		'secondaryDark',
		'onSecondary',
		'onFourth',
	]),
	size: types.oneOf(['sm', 'md']),
	id: types.string,
};

// Default value for props

Switch.defaultProps = {
	theme: 'onPrimary',
	size: 'md',
};

// ----------------

function Switch(props) {
	const { onChange, active, theme, size, id } = props;

	// Modify styles

	const modify = classNames({
		[` switch--theme-${theme}`]: theme,
		[` switch--size-${size}`]: size,
		' switch--active': active,
	});

	// Render

	return (
		<div className={`switch${modify}`} onClick={onChange} id={id}>
			<span />
		</div>
	);
}

export default memo(Switch);
