import * as types from '../types/marketing';

// Static

import endpoints from '../../static/endpoints/marketing';

//Selectors
import { getUserData } from '../selectors/user';

// -------- Classic action creator --------

export const setActiveFlyerId = (id) => ({
	type: types.SET_ACTIVE_FLYER_ID,
	payload: { id },
});

export const setMarketingFlyers = (payload) => ({
	type: types.SET_MARKETING_FLYERS,
	payload: payload,
});

export const setMarketingVideos = (payload) => ({
	type: types.SET_MARKETING_VIDEOS,
	payload: payload,
});

// -------- Async action creator --------

// getMarketingMaterials

export const getMarketingMaterials = (payload) => async (store) => {
	// const { CatalogID, LanguageID } = await store.getState().user.userData;
	const { CatalogID, LanguageID } = await getUserData(store.getState());

	const data = await store.dispatch(
		getMarketingMaterials$network({ catalogID: CatalogID, languageID: LanguageID })
	);

	// Marketing flyers

	const flyers = data.result.data
		.map((post) => {
			const flyerObj = post.postMediaInfolist[0];
			let isPathExist;
			let pathError;

			if (!!flyerObj) {
				isPathExist = 'imagePath' in flyerObj;
			}

			if (isPathExist) {
				pathError = /\/\/Image\/\/$/.test(flyerObj.imagePath);
			}

			if (!pathError && isPathExist) {
				return {
					fileExtension: flyerObj.extension ? flyerObj.extension : 'pdf',
					title: post.postTitle ? post.postTitle : undefined,
					size: flyerObj.size ? flyerObj.size : '',
					url: flyerObj.imagePath,
					id: flyerObj.documentID
						? flyerObj.documentID
						: `${post.entityID}${post.mcSectionID}${post.postID}`,
				};
			}

			return null;
		})
		.filter((item) => !!item && item);

	store.dispatch(setMarketingFlyers({ flyers }));

	// Marketing videos

	const videos = data.result.data
		.map((post) => {
			const videoObj = post.postMediaInfolist[1];
			let isPathExist;

			if (!!videoObj) {
				isPathExist = 'postUrl' in videoObj;
			}

			if (isPathExist) {
				return {
					title: post.postTitle ? post.postTitle : undefined,
					url: videoObj.postUrl,
					id: videoObj.postMediaID ? videoObj.postMediaID : post.postID,
				};
			}

			return null;
		})
		.filter((item) => !!item && item);

	store.dispatch(setMarketingVideos({ videos }));
};

// -------- Async network action creator --------

// getMarketingMaterials$network

export const getMarketingMaterials$network = ({ catalogID, languageID }) => ({
	type: types.GET_MARKETING_MATERIALS_$NETWORK,
	endpoint: `${endpoints.MARKETING_MATERIALS}/${catalogID}/${languageID}`,
});
