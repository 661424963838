import * as base_types from '../types/base';
import * as types from '../types/sketch';

// -------- Default state of the branch --------

const defaultState = {
	activeSketchPreview: null,
	sketchData: {
		sketchRequestList: [{ sketchAttributes: [{}] }],
	},
};

// -------- Reducer --------

export default function (state, action) {
	if (!state) return defaultState;

	switch (action.type) {
		case types.SET_ACTIVE_SKETCH_PREVIEW: {
			return { ...state, activeSketchPreview: { ...action.payload } };
		}

		case types.SET_SKETCH_DATA_BY_ID: {
			return {
				...state,
				sketchData: { ...action.payload },
			};
		}

		case types.RESTORE_BRANCH:
		case base_types.RESTORE_DEFAULT_STATE: {
			return defaultState;
		}

		default: {
			return state;
		}
	}
}
