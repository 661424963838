import env from '../env';

export default {
	DELETE_SKETCH_FROM_FAVOURITE: `${env.API_INVENTORY}Virtuoso/Favourite`,
	FAVOURITE_SKETCHES_QUANTITY: `${env.API_VIEWER}Virtuoso/FavouriteSketcheCount`,
	ADD_SKETCH_TO_FAVOURITE: `${env.API_INVENTORY}Virtuoso/Favourite`,
	FAVOURITE_SKETCHES: `${env.API_VIEWER}Virtuoso/Favourite`,
	LOCATION_SKETCHES: `${env.API_VIEWER}SketchView/SearchSketchRequest`,
	GET_SEARCH_MATS: `${env.API_VIEWER}SketchView/SearchSketchRequest`,
	SKETCHES: `${env.API_VIEWER}SketchView/SearchSketchRequest`,
	DRAFTS: `${env.API_INVENTORY}Virtuoso/Drafts`,
};
