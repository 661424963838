import classNames from 'classnames';
import types from 'prop-types';
import React from 'react';

// Components

import UIFieldWrapper from '../../../../components/block/UIFieldWrapper';

import VisibilityIcon from '../../../../components/ui/VisibilityIcon';

import InputField from '../../../../components/ui/InputField';

import SvgIcon from '../../../../components/block/SvgIcon';

import Button from '../../../../components/ui/Button';

import Select from '../../../../components/ui/Select';

import Bool from '../../../../components/rp/Bool';

// Assets

import { letterSendIcon } from '../../../../assets/icons';

// Styles

import './styles.scss';

// ----------------

// Type of props

SignupForm.propTypes = {
	networkProcess: types.bool,
	setExtraState: types.func.isRequired,
	extraState: types.object.isRequired,
	locations: types.array.isRequired,
	onSubmit: types.func.isRequired,
	fields: types.object.isRequired,
};

// Default value for props

SignupForm.defaultProps = {
	locations: [],
	onSubmit: () => null,
};

// ----------------

export default function SignupForm(props) {
	const {
		postNetworkProcess,
		getNetworkProcess,
		// setExtraState,
		extraState,
		locations,
		onSubmit,
		fields,
	} = props;

	// Modify styles

	const modify = classNames({
		' sign-up-form--isRequestSend': extraState.isRequestSend,
	});

	// Render

	return (
		<form className={`sign-up-form${modify}`} onSubmit={onSubmit}>
			{!extraState.isRequestSend ? (
				<>
					<fieldset className="sign-up-form__fields">
						<InputField
							{...fields.FirstName}
							placeholder="First name"
							theme="secondaryDark"
							fluid
						/>
						<InputField
							{...fields.LastName}
							placeholder="Last name"
							theme="secondaryDark"
							fluid
						/>
						<UIFieldWrapper status={fields.CustomerID.status} theme="secondaryDark" fluid>
							<Select
								selectedOption={fields.CustomerID.value}
								placeholder="Location"
								isLoading={getNetworkProcess}
								onSelect={fields.CustomerID.onChange}
								onFocus={fields.CustomerID.onFocus}
								options={locations}
								status={fields.CustomerID.status}
								theme="secondaryDark"
								name={fields.CustomerID.name}
								keys={{ keyName: 'id', keyValue: 'value' }}
								fluid
							/>
						</UIFieldWrapper>
						<InputField
							{...fields.Email}
							placeholder="Email address"
							theme="secondaryDark"
							fluid
						/>
						<Bool
							render={(props) => (
								<InputField
									{...fields.Password}
									customSideBlock={
										<VisibilityIcon
											onClick={props.handleChange}
											isVisible={props.isTrue}
										/>
									}
									placeholder="Desired password"
									theme="secondaryDark"
									type={props.isTrue ? 'text' : 'password'}
									fluid
								/>
							)}
						/>
						<Bool
							render={(props) => (
								<InputField
									{...fields.RetypePassword}
									customSideBlock={
										<VisibilityIcon
											onClick={props.handleChange}
											isVisible={props.isTrue}
										/>
									}
									placeholder="Confirm password"
									theme="secondaryDark"
									type={props.isTrue ? 'text' : 'password'}
									fluid
								/>
							)}
						/>
					</fieldset>
				</>
			) : (
				<div className="sign-up-form__success-block">
					<h2 className="success-block__title">New Account Request</h2>
					<div className="success-block__icon-wrapper">
						<SvgIcon size="auto" url={letterSendIcon} />
					</div>
					<h2 className="success-block__title">Thank you!</h2>
					<span className="success-block__subtitle">
						A confirmation email has been sent to the provided inbox. Please click the
						link in the email to complete your registration.
					</span>
				</div>
			)}
			<div className="sign-up-form__buttons">
				{!extraState.isRequestSend && (
					<Button
						borderWeight="bold"
						loaderColor="white"
						isLoading={postNetworkProcess}
						onSubmit={onSubmit}
						fluid
						theme="onPrimary"
						type="submit"
						size="lg"
						id="signup-form-submit-button"
					>
						Sign Up
					</Button>
				)}

				<Button
					borderWeight="bold"
					reverseType
					fluid
					theme="onPrimary"
					link="/signin"
					size="lg"
					id="signup-form-back-button"
				>
					Back to Sign In
				</Button>
			</div>
		</form>
	);
}
