import types from 'prop-types';
import React from 'react';

// Components

import Color from '../../../components/block/Color';

// Styles

import './styles.scss';

// ----------------

// Type of props

SelectedColorsListPreview.propTypes = {
	list: types.array.isRequired,
	bg: types.oneOfType([types.object, types.bool]),
};

// ----------------

export default function SelectedColorsListPreview(props) {
	const { list, bg } = props;

	return (
		<div className="selected-colors-list-preview">
			{bg && (
				<div className="selected-colors-list-preview__bg">
					<Color
						withBorderRadius
						label="Background"
						name={bg.name}
						rgb={{ r: bg.red, g: bg.green, b: bg.blue }}
					/>
				</div>
			)}
			<div className="selected-colors-list-preview__grid">
				{list.map((color) => (
					<div className="selected-colors-list-preview__cell" key={color.name}>
						<Color
							withBorderRadius
							name={color.name}
							rgb={{ r: color.red, g: color.green, b: color.blue }}
						/>
					</div>
				))}
			</div>
		</div>
	);
}
