import React from 'react';
import types from 'prop-types';

// Styles

import './styles.scss';

// ----------------

// Type of props

ImagePreview.propTypes = {
	imageUrl: types.string,
};

// ----------------

export default function ImagePreview(props) {
	const { imageUrl } = props;

	// Render

	return (
		<div className="image-preview" style={{ backgroundImage: `url(${imageUrl})` }} />
	);
}
