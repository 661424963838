import React, { useState } from 'react';
import classNames from 'classnames';
import types from 'prop-types';

// Components

import MatsPagesHeader from '../../../../components/block/headers/MatsPagesHeader';
import ButtonUp from '../../../../components/ui/ButtonUp';
import List from '../../../../components/block/List';
import Button from '../../../../components/ui/Button';

// Styles

import './styles.scss';

// Formating card view mode

const matViewFormat = {
	block: 'block',
	list: 'row',
};

// ----------------

// Type of props

MatsPagesStruct.propTypes = {
	headerAdditionalActions: types.array,
	loadMoreNetworkProcess: types.bool,
	getNetworkProcess: types.bool,
	showLoadMore: types.bool,
	onLoadMore: types.func.isRequired,
	titleIcon: types.string,
	title: types.string.isRequired,
	mats: types.array.isRequired,
	card: types.oneOfType([types.node, types.func]),
};

// Default value for props

MatsPagesStruct.defaultProps = {};

// ----------------

export default function MatsPagesStruct(props) {
	const {
		loadMoreNetworkProcess,
		getNetworkProcess,
		headerSortActions,
		showLoadMore,
		onLoadMore,
		titleIcon,
		title,
		mats,
		card: Card,
	} = props;

	// State

	const [activeGridMode, setMode] = useState('block');

	// Modify styles

	const modifyViewGrid = classNames({
		[` mats-pages-struct__mats-grid--${activeGridMode}`]: activeGridMode,
	});

	// Render

	return (
		<div className="mats-pages-struct">
			<div className="mats-pages-struct__header-wrapper">
				<MatsPagesHeader
					handleGridModeToggle={setMode}
					activeGridMode={activeGridMode}
					matsQuantity={mats.length}
					sortActions={headerSortActions}
					titleIcon={titleIcon}
					title={title}
				/>
			</div>
			<div className="mats-pages-struct__mats-grid-wrapper">
				<List
					listContentClassName={`mats-pages-struct__mats-grid${modifyViewGrid} show-info`}
					networkProcess={getNetworkProcess}
					listItem={(props) => {
						return <Card viewMode={matViewFormat[activeGridMode]} {...props.data} />;
					}}
					list={mats}
				/>
				{showLoadMore && !!mats.length && mats.length >= 12 && !getNetworkProcess && (
					<div className="mats-pages-struct__load-more-button">
						<Button
							loaderColor="white"
							isLoading={loadMoreNetworkProcess}
							onClick={onLoadMore}
							size="xl"
							fluid
							id="load-more-mats-list"
						>
							Load More
						</Button>
					</div>
				)}
			</div>
			<div className="mats-pages-struct__button-up-wrapper">
				<ButtonUp />
			</div>
		</div>
	);
}
