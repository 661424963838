import ReactJoyride from 'react-joyride';
import types from 'prop-types';
import React from 'react';

// ----------------

Joyride.propTypes = {
	tooltip: types.func.isRequired,
	onSkip: types.func.isRequired,
	steps: types.array.isRequired,
	run: types.bool,
};

// Default value for props

Joyride.defaultProps = {
	run: false,
};

// ----------------

export default function Joyride(props) {
	const { tooltip, onSkip, steps, run } = props;

	// Handlers

	function handleJoyrideCallback(data) {
		const { action } = data;

		if (action === 'skip' || action === 'reset') {
			onSkip();
		}
	}

	// Render

	return (
		<ReactJoyride
			disableScrollParentFix
			disableOverlayClose
			spotlightPadding={0}
			tooltipComponent={tooltip}
			showSkipButton
			showProgress
			continuous
			callback={handleJoyrideCallback}
			steps={steps}
			run={run}
		/>
	);
}
