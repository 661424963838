import * as base_types from '../types/base';
import * as types from '../types/marketing';

// -------- Default state of the branch --------

const defaultState = {
	marketingVideos: [],
	marketingFlyers: [],
	activeFlyerId: null,
};

// -------- Reducer --------

export default function (state, action) {
	if (!state) return defaultState;

	switch (action.type) {
		case types.SET_MARKETING_FLYERS: {
			return { ...state, marketingFlyers: action.payload.flyers };
		}

		case types.SET_MARKETING_VIDEOS: {
			return { ...state, marketingVideos: action.payload.videos };
		}

		case types.SET_ACTIVE_FLYER_ID: {
			return { ...state, activeFlyerId: action.payload.id };
		}

		case types.RESTORE_BRANCH:
		case base_types.RESTORE_DEFAULT_STATE: {
			return defaultState;
		}

		default: {
			return state;
		}
	}
}
