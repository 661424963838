import React, { memo } from 'react';

// Components

import UIFieldWrapper from '../../../components/block/UIFieldWrapper';
import Textarea from '../../../components/ui/Textarea';

// ----------------

function TextareaField(props) {
	const { withPadding, titleSize, title, ...inputProps } = props;

	return (
		<UIFieldWrapper
			withPadding={withPadding}
			titleSize={titleSize}
			status={inputProps.status}
			title={title}
			theme={inputProps.theme}
			fluid={inputProps.fluid}
		>
			<Textarea {...inputProps} />
		</UIFieldWrapper>
	);
}

export default memo(TextareaField);
