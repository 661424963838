import classNames from 'classnames';
import types from 'prop-types';
import React from 'react';

// Components

import FontIcon from '../../../components/block/FontIcon';

// Assets

import transparent from '../../../assets/icons/transparent-color.svg';

// Styles

import './styles.scss';

// ----------------

// Type of props

Color.propTypes = {
	withBorderRadius: types.bool,
	onCrossClick: types.func,
	extracted: types.bool,
	required: types.bool,
	selected: types.bool,
	onClick: types.func,
	pointer: types.bool,
	active: types.bool,
	label: types.string,
	name: types.string,
	size: types.oneOf(['sm', 'md', 'lg', 'xl']),
	rgb: types.exact({ r: types.number, g: types.number, b: types.number }),
};

// Default value for props

Color.defaultProps = {
	size: 'sm',
};

// ----------------

export default function Color(props) {
	const {
		withBorderRadius,
		onCrossClick,
		onlyPreview,
		extracted,
		required,
		selected,
		onClick,
		pointer,
		active,
		label,
		name,
		size,
		rgb,
	} = props;

	// Handlers

	function handleCrossClick(e) {
		e.stopPropagation();
		onCrossClick(name);
	}

	// Utils

	function isLightColor({ r, g, b }) {
		const hsp = Math.sqrt(0.299 * (r * r) + 0.587 * (g * g) + 0.114 * (b * b));

		// Using the HSP value, determine whether the color is light or dark

		return hsp > 215;
	}

	function isColorCloseToWhite({ r, g, b }) {
		const Y = 0.2126 * r + 0.7152 * g + 0.0722 * b;

		// Convert the color to gray scale and then check if it's close to white

		return Y > 250;
	}

	// Modify styles

	const modify = classNames({
		' color--with-border-radius': withBorderRadius,
		[` color--size-${size}`]: size,
		' color--with-border': rgb ? isColorCloseToWhite(rgb) : false,
		' color--selected': selected,
		' color--pointer': pointer,
		' color--active': active,
	});

	const modifyName = classNames({
		' color__name--selected': rgb,
		' color__name--inverse': rgb ? isLightColor(rgb) : false,
	});

	// Render

	return (
		<div
			className={`color${modify}`}
			onClick={
				!onlyPreview
					? () => {
							onClick && onClick(name);
					  }
					: null
			}
			style={{
				background: rgb ? `rgb(${rgb.r}, ${rgb.g}, ${rgb.b} )` : `url(${transparent})`,
			}}
		>
			{!onlyPreview && onCrossClick && (
				<div className="color__icon-btn" onClick={handleCrossClick}>
					<FontIcon size="xs" icon="close" />
				</div>
			)}

			{required && (
				<div className="color__required-icon">
					<FontIcon size="xs" icon="priority_high" />
				</div>
			)}

			{extracted && (
				<div className="color__extracted-icon">
					<FontIcon size="xs" icon="star" />
				</div>
			)}

			<p className={`color__name${modifyName}`}>
				{rgb
					? label
						? `${label} ${name}`
						: name
					: label || <FontIcon icon="add" size="xl" />}
			</p>
		</div>
	);
}
