import types from 'prop-types';
import React from 'react';

// Components

import Button from '../../../../components/ui/Button';
import Switch from '../../../../components/ui/Switch';

// Styles

import './styles.scss';

// ----------------

// Type of props

WelcomeToMatForm.propTypes = {
	onSubmit: types.func,
	fields: types.object.isRequired,
};

// Default value for props

WelcomeToMatForm.defaultProps = {
	onSubmit: () => null,
};

// ----------------

export default function WelcomeToMatForm(props) {
	const { onSubmit, fields } = props;

	return (
		<form className="welcome-to-mat-form" onSubmit={onSubmit}>
			<div className="form__two-buttons-grid">
				<Button
					reverseType
					onClick={() => null}
					fluid
					id="welcome-to-mat-form-skip-button"
				>
					Skip
				</Button>
				<Button
					onClick={onSubmit}
					fluid
					theme="secondary"
					type="submit"
					id="welcome-to-mat-form-submit-button"
				>
					Start
				</Button>
			</div>
			<fieldset className="welcome-to-mat-form__fields">
				<div className="welcome-to-mat-form__switch">
					<span className="welcome-to-mat-form__switch-title">
						Don't show me this message
					</span>
					<Switch
						onChange={fields.dontShowMessage.onChange}
						active={fields.dontShowMessage.value}
					/>
				</div>
			</fieldset>
		</form>
	);
}
