import React, { memo, Suspense } from 'react';
import classNames from 'classnames';
import types from 'prop-types';

// Components

import FontIcon from '../../../../components/block/FontIcon';
import Button from '../../../../components/ui/Button';
import Modal from '../../../../components/block/Modal';

// Styles

import './styles.scss';

// Lazy import components

const MatPreview = React.lazy(() => import('../../../block/cards/MatPreview'));

// ----------------

// Type of props

MatPreviewModal.propTypes = {
	onVariation: types.func.isRequired,
	onPreview: types.func.isRequired,
	onApprove: types.func.isRequired,
	onDelete: types.func.isRequired,
	onClose: types.func.isRequired,
	onEdit: types.func,
	open: types.bool.isRequired,
	data: types.object,
};

// Default value for props

MatPreviewModal.defaultProps = {
	onVariation: () => null,
	onPreview: () => null,
	onApprove: () => null,
	onDelete: () => null,
	onClose: () => null,
	onEdit: () => null,
};

// ----------------

function MatPreviewModal(props) {
	const {
		onVariation,
		onPreview,
		onApprove,
		onDelete,
		onClose,
		onEdit,
		open,
		data,
	} = props;

	// Modify styles

	const modifyButtonsBlock = classNames({
		[` mat-preview-modal__buttons-grid--for-${data.status}`]: data.status,
	});

	// Render

	return (
		<Modal withoutPadding onClose={onClose} open={open}>
			<div className="mat-preview-modal">
				<Suspense fallback={<span>Loading...</span>}>
					<MatPreview withBorder={false} largeView forModal maxWidth="auto" {...data} />
				</Suspense>

				{/* Buttons */}

				<div className={`mat-preview-modal__buttons-grid${modifyButtonsBlock}`}>
					{(data.status === 'draft' || data.status === 'inProcessDraft') && (
						<>
							<Button
								reverseType
								onClick={onDelete}
								theme="error"
								id="mat-modal-delete-button"
								fluid
							>
								<FontIcon size="xxl" icon="delete_forever" />
							</Button>
							<Button textUppercase onClick={onEdit} id="mat-modal-edit-button" fluid>
								Edit
							</Button>
						</>
					)}
					{data.status === 'inProcess' && (
						<Button
							textUppercase
							onClick={onVariation}
							id="mat-modal-variation-button"
							fluid
						>
							Variation
						</Button>
					)}
					{data.status === 'complete' && (
						<>
							<Button
								reverseType
								textUppercase
								onClick={onPreview}
								id="mat-modal-preview-button"
								fluid
							>
								Preview
							</Button>
							<Button
								reverseType
								textUppercase
								onClick={onApprove}
								id="mat-modal-approve-button"
								fluid
							>
								Approve Now
							</Button>
							<Button
								textUppercase
								onClick={onVariation}
								id="mat-modal-variation-button"
								fluid
							>
								Variation
							</Button>
						</>
					)}
				</div>
			</div>
		</Modal>
	);
}

export default memo(MatPreviewModal);
