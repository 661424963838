import * as types from '../types/meta';

// Static

import endpoints from '../../static/endpoints/meta';

// -------- Async network action creator --------

// getLocations$network

export const getLocations$network = () => ({
	type: types.GET_LOCATIONS_$NETWORK,
	endpoint: endpoints.GET_LOCATIONS,
	payload: { description: '', url: 'https://clarity-cintas.millmats.com' },
	method: 'POST',
});
