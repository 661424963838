import React, { memo } from 'react';
import classNames from 'classnames';
import types from 'prop-types';

/* Utils */

import { formatDateService } from '../../../../utils';

// Components

import PreloaderCustomStub from '../../../../components/block/PreloaderCustomStub';
import Preloader from '../../../../components/rp/Preloader';
import FontIcon from '../../../../components/block/FontIcon';

// Styles

import './styles.scss';

// Static

import { productGroupData, statusData } from '../../../../static/constants';

// ----------------

// Type of props

MatPreview.propTypes = {
	onFavouriteClick: types.func,
	withFavouriteIcon: types.bool,
	withHoverEffect: types.bool,
	companyName: types.string,
	withMatType: types.bool,
	withBorder: types.bool,
	imageName: types.string,
	sketchRef: types.oneOfType([types.number, types.string]),
	largeView: types.bool,
	favourite: types.number,
	forModal: types.bool,
	viewMode: types.oneOf(['block', 'row']),
	maxWidth: types.oneOf(['auto', 'default']),
	onClick: types.func,
	status: types.oneOf(['complete', 'draft', 'rejected', 'inProcess']),
	image: types.string,
	name: types.string,
	size: types.string,
	date: types.string,
	type: types.oneOfType([types.oneOf([24, 101, 114, 115, 116, 205]), types.number])
		.isRequired,
	id: types.oneOfType([types.number, types.string]),
};

// Default value for props

MatPreview.defaultProps = {
	onFavouriteClick: () => null,
	withFavouriteIcon: false,
	withHoverEffect: false,
	companyName: '-',
	withMatType: true,
	withBorder: true,
	imageName: 'Preview image',
	sketchRef: '-',
	largeView: false,
	favourite: 0,
	forModal: false,
	maxWidth: 'default',
	viewMode: 'block',
	status: 'draft',
	name: '-',
	size: '-',
	date: '-',
	id: '-',
};

// ----------------

function MatPreview(props) {
	const {
		onFavouriteClick,
		withFavouriteIcon,
		withHoverEffect,
		companyName,
		withMatType,
		withBorder,
		largeView,
		imageName,
		sketchRef,
		favourite,
		forModal,
		viewMode,
		maxWidth,
		onClick,
		status,
		image,
		name,
		size,
		date,
		type,
		id,
	} = props;

	// Handlers

	function handleFavouriteClick(e) {
		e.stopPropagation();
		onFavouriteClick(id, favourite);
	}

	// Modify styles

	const modify = classNames({
		[` mat-preview--max-width-${maxWidth}`]: maxWidth,
		[` mat-preview--view-mode-${viewMode}`]: viewMode,
		' mat-preview--withHoverEffect': withHoverEffect,
		' mat-preview--withBorder': withBorder,
		' mat-preview--largeView': largeView,
	});

	const modifyFavouriteBtn = classNames({
		' mat-preview__favourite-btn--isFavourite': !!favourite,
	});

	const modifyFooter = classNames({
		' mat-preview__footer-block--with-mat-type': withMatType,
	});

	const modifyMatStatus = classNames({
		[` mat-preview__mat-status--${statusData[status].color}`]: statusData[status].color,
	});

	// Render

	return (
		<div className={`mat-preview${modify}`} onClick={onClick}>
			<div className="mat-preview__image-block">
				<div>
					{image ? (
						<div className="mat-preview__image-wrapper">
							<Preloader
								loader={(lProps) => (
									<PreloaderCustomStub text="Image is loading" {...lProps} />
								)}
								render={({ handleLoad, isLoaded }) => (
									<img
										className="img-contain"
										onLoad={handleLoad}
										alt={imageName}
										src={image}
									/>
								)}
							/>
						</div>
					) : (
						<div className="mat-preview__image-empty-wrapper">
							<span className="text-small">No image</span>
						</div>
					)}
				</div>
			</div>
			<div className="mat-preview__title-block">
				<p className="mat-preview__mat-label" title={name}>
					{name}
				</p>
				<p className="text-small mat-preview__mat-ref" title={`#${sketchRef}`}>
					#{sketchRef}
				</p>
				{forModal && (
					<p className="mat-preview__company-name" title={companyName}>
						{companyName}
					</p>
				)}
			</div>
			<div className={`mat-preview__footer-block${modifyFooter}`}>
				<div className={`text-mini mat-preview__mat-status${modifyMatStatus}`}>
					<p>{statusData[status].label}</p>
					<span />
				</div>
				{withMatType && (
					<div className="text-mini mat-preview__mat-size">
						<p title={`${productGroupData[type] || '-'}, ${size}`}>{`${
							productGroupData[type] || '-'
						}, ${size}`}</p>
					</div>
				)}
				<div className="text-mini mat-preview__mat-create">
					<p>{formatDateService.defaultDateFormat(date)}</p>
					{(favourite === 0 || favourite === 1) &&
						['complete', 'inProcess'].includes(status) &&
						withFavouriteIcon && (
							<div
								className={`mat-preview__favourite-btn${modifyFavouriteBtn}`}
								onClick={(e) => handleFavouriteClick(e)}
								id="mat-preview-favourite-btn"
							>
								<FontIcon
									icon={favourite === 1 ? 'favorite' : 'favorite_border'}
									size="lg"
								/>
							</div>
						)}
				</div>
			</div>
		</div>
	);
}

export default memo(MatPreview);
