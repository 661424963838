import types from 'prop-types';
import React from 'react';

// Hooks

import { useWindowWidth } from '../../../../hooks';

// Components

import { SelectedColorsList, Container } from '../../../../components';

// Static

import { limit } from '../../../../static/constants';

// Action creators

import {
	deselectColor,
	toggleModal,
	delBGColor,
} from '../../../../redux/actionCreators/mat';

// Selectors

import getObjArrayByIds from '../../../../redux/selectors/getObjArrayByIds';
import getById from '../../../../redux/selectors/getById';

// Styles

import './styles.scss';

// ----------------

// Type of props

ColorTool.propTypes = {
	withClearAllColors: types.bool,
	palette: types.bool,
	size: types.string,
};

// ----------------

export default function ColorTool(props) {
	const { withClearAllColors, palette, size } = props;

	// Preparation

	const currentWindowWidth = useWindowWidth();
	const deviceSizeXS = currentWindowWidth <= 576;

	return (
		<div className="color-tool">
			<Container
				mapDispatch={{
					onDeleteColor: deselectColor,
					onDeleteBG: delBGColor,
					toggleModal,
				}}
				extra={{ deviceSizeXS }}
				mapState={(state) => {
					const allColors = [...state.mat.colors.standart, ...state.mat.colors.pms];

					return {
						colorsCount: state.mat.colorsCount,
						matType: state.mat.matType,
						list: getObjArrayByIds(allColors, state.mat.selectedColors, 'name'),
						bg: getById(allColors, 'name', state.mat.bgColor),
					};
				}}
				render={(cProps) => (
					<>
						{!cProps.deviceSizeXS && (
							<div className="color-tool__header">
								{palette && (
									<div className="color-tool__count">
										{cProps.colorsCount} / {limit.COLORS}
									</div>
								)}

								{withClearAllColors && cProps.colorsCount ? (
									<div
										className="color-tool__clear"
										onClick={() => cProps.toggleModal('clear-colors')}
									>
										clear all
									</div>
								) : null}
							</div>
						)}
						<SelectedColorsList
							requiredOneColor={!cProps.list.length}
							requiredBg={!cProps.bg}
							addButton={
								cProps.colorsCount < (cProps.bg ? limit.COLORS : limit.COLORS - 1)
							}
							palette={palette}
							size={size}
							{...cProps}
						/>
					</>
				)}
			/>
		</div>
	);
}
