import React from 'react';

// Components

import Modal from '../../../../components/block/Modal';
import MatLayers from '../../../../components/block/MatLayers';
import Container from '../../../../components/rp/Container';

// ----------------

export default function MatLayersModal(props) {
	return (
		<Modal
			bodyAccent
			maxWidth="full"
			onClose={props.onClose}
			title="Arrange layers"
			open={props.open}
		>
			<Container
				mapState={(state) => ({ objects: state.mat.objects })}
				render={(cProps) => <MatLayers onChange={props.onChange} {...cProps} />}
			/>
		</Modal>
	);
}
