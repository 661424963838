export default function getMatDataForSave(mat) {
	return {
		selectedSizeId: mat.selectedSizeId,
		matOrientation: mat.matOrientation,
		selectedColors: mat.selectedColors,
		bgImageJSON: mat.bgImageJSON,
		tempBgImage: mat.tempBgImage,
		nextZIndex: mat.nextZIndex,
		bgOption: mat.bgOption,
		objects: mat.objects,
		matInfo: mat.matInfo,
		matType: mat.matType,
		bgColor: mat.bgColor,
		bgImage: mat.bgImage,
		border: mat.border,
		nextId: mat.nextId,
	};
}
