import types from 'prop-types';
import React from 'react';

// Components

import FontIcon from '../../../components/block/FontIcon';

// Styles

import './styles.scss';

// ----------------

// Type of props

SearchInput.propTypes = {
	placeholder: types.string,
	onIconClick: types.func,
	onChange: types.func.isRequired,
	onClear: types.func.isRequired,
	value: types.string.isRequired,
	name: types.string,
};

// Default value for props

SearchInput.defaultProps = {
	placeholder: 'Search',
};

// ----------------

export default function SearchInput(props) {
	const { placeholder, onIconClick, onChange, onClear, value, name } = props;

	// Render

	return (
		<div className="search-input">
			<div
				className="search-input__icon-wrapper"
				onClick={onIconClick}
				style={{ ...(onIconClick && { cursor: 'pointer' }) }}
			>
				<FontIcon size="xxl" icon="search" />
			</div>
			<input
				autoComplete="off"
				placeholder={placeholder}
				onChange={onChange}
				value={value}
				type="search"
				name={name}
				id="search-input"
			/>
			{!!value && (
				<div className="search-input__clear-icon-wrapper" onClick={onClear}>
					<FontIcon size="md" icon="close" />
				</div>
			)}
		</div>
	);
}
