import React, { useEffect } from 'react';
import classNames from 'classnames';
import types from 'prop-types';

// Components

import { FontIcon, SubmitSketchForm, Container } from '../../../../components';
import Form from '../../../../modules/form-x';

// Action creators

import { setMatInfo, submit } from '../../../../redux/actionCreators/mat';

// Static

import { matType } from '../../../../static/constants';
import { email } from '../../../../static/regExps';

// Selectors

import getDesignAdditions from '../../../../redux/selectors/getDesignAdditions';
import getTextDetails from '../../../../redux/selectors/getTextDetails';
import getById from './../../../../redux/selectors/getById';

// Styles

import './styles.scss';

// ----------------

// Type of props

SubmitSketch.propTypes = {
	onClose: types.func.isRequired,
	isOpen: types.bool.isRequired,
};

// ----------------

export default function SubmitSketch(props) {
	const { onClose, isOpen } = props;

	useEffect(() => {
		if (isOpen) {
			document.body.style.overflow = 'hidden';
		} else {
			document.body.style.overflow = 'auto';
		}
	}, [isOpen]);

	// Modify styles

	const modify = classNames({
		' submit-sketch--open': isOpen,
	});

	// Render

	return (
		<>
			<div className={`submit-sketch${modify}`}>
				<div className="submit-sketch__inner">
					<header className="submit-sketch__header">
						<span className="text-middle submit-sketch__title">
							Submit sketch request
						</span>
						<div className="submit-sketch__close-button" onClick={onClose}>
							<FontIcon icon="close" />
						</div>
					</header>
					<div className="submit-sketch__body">
						{isOpen && (
							<Container
								mapDispatch={{ onSaveData: setMatInfo, onSubmit: submit }}
								mapState={(state) => ({
									designAdditions: getDesignAdditions(state.mat),
									matOrientation: state.mat.matOrientation,
									textDetails: getTextDetails(state.mat),
									matType: matType[state.mat.matType].displayName,
									matSize: getById(state.mat.matSizes, 'id', state.mat.selectedSizeId)
										.value,
									mobile: state.system.mobile,
									data: state.mat.matInfo,
								})}
								render={(cProps) => (
									<Form
										ignoreDidUpdate
										extraState={{ signatureError: false }}
										validate={{ max: 255 }}
										onSubmit={(data, actions) => {
											const { signature } = cProps.data;

											if (signature) {
												cProps.onSubmit({ ...data, signature: signature.base64 });
											} else {
												actions.setExtraState('signatureError', true);
											}
										}}
										data={cProps.data}
										fields={{
											instructions: { value: '' },
											companyName: { value: '' },
											conditions: { value: false, handler: 'boolean' },
											sketchName: { value: '', validate: { required: true, max: 30 } },
											email: { value: '', validate: { regExp: email } },
										}}
										render={(formProps) => (
											<SubmitSketchForm onClose={onClose} {...cProps} {...formProps} />
										)}
									/>
								)}
							/>
						)}
					</div>
				</div>
			</div>
			{isOpen && <div className="submit-sketch__backdrop" onClick={onClose} />}
		</>
	);
}
