import classNames from 'classnames';
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';

//Actions
import { deleteActiveObject } from '../../../redux/actionCreators/mat';

// Components

import { FontIcon } from '../../../components';

// Data

import data from './data';

// Styles

import './styles.scss';

// ----------------

export default function ToolStandardActions(props) {
	const dispatch = useDispatch();

	useEffect(() => {
		document.addEventListener('keydown', handleKeyPress);
		return () => document.removeEventListener('keydown', handleKeyPress);
	});

	const handleKeyPress = (event) => {
		if (event.keyCode === 46) {
			dispatch(deleteActiveObject());
		}
	};

	return (
		<div className="tool-standard-actions">
			<div className="tool-standard-actions__buttons">
				{data().map((item) => {
					const modify = classNames({
						' tool-standard-actions__icon--danger': item.danger,
					});

					return (
						<div
							className={`tool-standard-actions__icon${modify}`}
							onClick={item.onClick}
							key={item.id}
							id={item.id}
						>
							<FontIcon
								rotate45={item.rotate45}
								rotate90={item.rotate90}
								icon={item.icon}
								size="lg"
							/>
						</div>
					);
				})}
			</div>
		</div>
	);
}
