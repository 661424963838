export default {
	updateSketchDocumentSuccess: 'Sketch approved successfully.',
	updateSketchDocumentError: 'Could not approve sketch.',
	deleteToFavouriteSuccess: 'Sketch deleted from favorites successfully.',
	addToFavouriteSuccess: 'Sketch added to favorites successfully.',
	saveDraftSuccess: 'Draft saved successfully.',
	saveDraftError: 'Could not create variation of this sketch.',
	setTransparentBgImageError:
		'You cannot use this image because it has a transparent background. Please define a color from the palette in order to submit your sketch.',
	selectTransparentImageAsBgError:
		'You cannot use images with transparent areas. Please try another image',
	saveTransparentImageAsBgError: 'Background image should cover all mat area.',
	addImageFromGoogleSearchError:
		'An error occurred while adding image to the mat. Please try another image from Google Search.',
	addImageFromPdfError:
		'An error occurred while converting PDF to image. Please try another one.',
	addConvertedImageToCanvasError:
		'Could not add converted image to the mat. Please check your VPN connection.',
};
