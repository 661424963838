import * as base_types from '../types/base';
import * as types from '../types/mats';
import $ from '../../utils/createActionType';

// -------- Default state of the branch --------

const defaultState = {
	favouriteSketchesQty: 0,
	showLoadMoreButton: true,
	favouriteSketches: [],
	locationSketches: [],
	searchSketches: [],
	sketches: [],
	drafts: [],
};

// -------- Reducer --------

export default function (state, action) {
	if (!state) return defaultState;

	switch (action.type) {
		case $(types.GET_DRAFTS_$NETWORK): {
			return {
				...state,
				drafts: action.payload.result.data ? action.payload.result.data : [],
			};
		}

		case $(types.GET_MORE_DRAFTS_$NETWORK): {
			return {
				...state,
				drafts: [
					...state.drafts,
					...(action.payload.result.data ? action.payload.result.data : []),
				],
			};
		}

		case $(types.GET_SKETCHES_$NETWORK): {
			return {
				...state,
				sketches: action.payload.result.data ? action.payload.result.data : [],
			};
		}

		case $(types.GET_MORE_SKETCHES_$NETWORK): {
			return {
				...state,
				sketches: [
					...state.sketches,
					...(action.payload.result.data ? action.payload.result.data : []),
				],
			};
		}

		case $(types.GET_LOCATION_SKETCHES_$NETWORK): {
			return {
				...state,
				locationSketches: action.payload.result.data ? action.payload.result.data : [],
			};
		}

		case $(types.GET_MORE_LOCATION_SKETCHES_$NETWORK): {
			return {
				...state,
				locationSketches: [
					...state.locationSketches,
					...(action.payload.result.data ? action.payload.result.data : []),
				],
			};
		}

		case $(types.GET_FAVOURITE_SKETCHES_$NETWORK): {
			return {
				...state,
				favouriteSketches: action.payload.result.data ? action.payload.result.data : [],
			};
		}

		case $(types.GET_MORE_FAVOURITE_SKETCHES_$NETWORK): {
			return {
				...state,
				favouriteSketches: [
					...state.favouriteSketches,
					...(action.payload.result.data ? action.payload.result.data : []),
				],
			};
		}

		case $(types.GET_SEARCH_MATS_$NETWORK): {
			return {
				...state,
				searchSketches: action.payload.result.data ? action.payload.result.data : [],
			};
		}

		case $(types.GET_MORE_SEARCH_MATS_$NETWORK): {
			return {
				...state,
				searchSketches: [
					...state.searchSketches,
					...(action.payload.result.data ? action.payload.result.data : []),
				],
			};
		}

		case types.DELETE_DRAFT_BY_ID: {
			return {
				...state,
				drafts: state.drafts.filter(({ sketchRef }) => sketchRef !== action.payload.id),
			};
		}

		case types.ADD_SKETCH_FAVOURITE_FLAG_BY_ID: {
			const {
				payload: { list, id },
			} = action;

			return {
				...state,
				[list]: state[list].map((item) =>
					item.sketchRequestID === id ? { ...item, isFavourite: 1 } : item
				),
			};
		}

		case types.REMOVE_SKETCH_FAVOURITE_FLAG_BY_ID: {
			const {
				payload: { list, id },
			} = action;

			return {
				...state,
				[list]: state[list].map((item) =>
					item.sketchRequestID === id ? { ...item, isFavourite: false } : item
				),
			};
		}

		case types.DELETE_SKETCH_FROM_FAVOURITE_BY_ID: {
			return {
				...state,
				favouriteSketches: state.favouriteSketches.filter(
					({ sketchRequestID }) => sketchRequestID !== action.payload.id
				),
			};
		}

		case $(types.GET_FAVOURITE_SKETCHES_QUANTITY_$NETWORK): {
			return {
				...state,
				favouriteSketchesQty: action.payload.result.data ? action.payload.result.data : 0,
			};
		}

		case types.SHOW_LOAD_MORE_BUTTON: {
			return { ...state, showLoadMoreButton: action.payload };
		}

		case types.INCREMENT_FAVOURITE_QUANTITY: {
			return { ...state, favouriteSketchesQty: state.favouriteSketchesQty + 1 };
		}

		case types.DECREMENT_FAVOURITE_QUANTITY: {
			return { ...state, favouriteSketchesQty: state.favouriteSketchesQty - 1 };
		}

		case types.RESTORE_BRANCH:
		case base_types.RESTORE_DEFAULT_STATE: {
			return defaultState;
		}

		default: {
			return state;
		}
	}
}
