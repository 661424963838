import types from 'prop-types';
import React from 'react';

// Components

import Loader from '../../../components/block/loaders/Loader';

// Styles

import './styles.scss';

// ----------------

// Type of props

PreloaderCustomStub.propTypes = {
	isLoaded: types.bool,
	children: types.node,
	text: types.string,
};

// Default value for props

PreloaderCustomStub.defaultProps = {
	isLoaded: false,
	text: 'Loading',
};

// ----------------

export default function PreloaderCustomStub(props) {
	const { isLoaded, children, text } = props;

	return (
		<>
			{!isLoaded && (
				<div className="preloader-custom-stub">
					<span className="preloader-custom-stub__text">{text}</span>
					<Loader color="onPrimaryDark" size="sm" />
				</div>
			)}
			{children}
		</>
	);
}
