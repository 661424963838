import * as base_types from '../types/base';
import * as types from '../types/ui';

// -------- Default state of the branch --------

const defaultState = {
	active: {},
};

// -------- Reducer --------

export default function (state, action) {
	if (!state) return defaultState;

	switch (action.type) {
		case types.TOGGLE: {
			if (action.payload in state.active) {
				delete state.active[action.payload];
				return { ...state, active: { ...state.active } };
			} else {
				return { ...state, active: { ...state.active, [action.payload]: true } };
			}
		}

		case types.RESTORE_BRANCH:
		case base_types.RESTORE_DEFAULT_STATE: {
			return defaultState;
		}

		default: {
			return state;
		}
	}
}
