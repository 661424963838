export const getMat = (state) => {
	return state.mat;
};

export const getMatObjects = (state) => {
	return state.mat.objects;
};

export const getMatObjectsById = (state, id) => {
	return state.mat.objects[id];
};

export const getEditTextId = (state) => {
	return state.mat.editTextId;
};
