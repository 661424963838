import classNames from 'classnames';
import types from 'prop-types';
import React from 'react';

// Styles

import './styles.scss';

// ----------------

// Type of props

TriggerButton.propTypes = {
	mobileColorInverse: types.bool,
	borderRadius: types.oneOf(['left', 'right']),
	fontSize: types.oneOf(['sm', 'md']),
	onClick: types.func,
	active: types.bool,
	italic: types.bool,
	theme: types.oneOf(['fourth']),
	label: types.string.isRequired,
	size: types.oneOf(['sm', 'md']),
	bold: types.bool,
	id: types.string,
};

// Default value for props

TriggerButton.defaultProps = {
	fontSize: 'sm',
	active: false,
	theme: 'fourth',
	size: 'sm',
};

// ----------------

export default function TriggerButton(props) {
	const {
		mobileColorInverse,
		borderRadius,
		disabled,
		fontSize,
		onClick,
		active,
		italic,
		theme,
		label,
		size,
		bold,
		id,
	} = props;

	// Modify styles

	const modify = classNames({
		[` trigger-button--border-radius-${borderRadius}`]: borderRadius,
		[` trigger-button--theme-${theme}`]: theme,
		[` trigger-button--font-size-${fontSize}`]: fontSize,
		[` trigger-button--size-${size}`]: size,
		' trigger-button--color-inverse': mobileColorInverse,
		' trigger-button--disabled': disabled,
		' trigger-button--active': active,
		' trigger-button--italic': italic,
		' trigger-button--bold': bold,
	});

	// Render

	return (
		<div className={`trigger-button${modify}`} onClick={onClick} id={id}>
			{label}
		</div>
	);
}
