import React, { useState } from 'react';
import { useDropzone } from 'react-dropzone';
import Prettysize from 'prettysize';
import classNames from 'classnames';
import types from 'prop-types';

// Components

import FontIcon from '../../../components/block/FontIcon';

// Styles

import './styles.scss';

// ----------------

// Type of props

UploadImage.propTypes = {
	onLoadImage: types.func.isRequired,
	maxSize: types.number,
	minSize: types.number,
	accept: types.arrayOf(types.shape({ format: types.string, displayName: types.string })),
};

// Default value for props

UploadImage.defaultProps = {
	maxSize: 1024 * 1024 * 10, // 10MB in bytes
	minSize: 1,
};

// ----------------

export default function UploadImage(props) {
	const { onLoadImage, maxSize, minSize, accept } = props;

	// State

	const [isError, setError] = useState(false);

	// Preparation

	const { getRootProps, getInputProps, isDragActive } = useDropzone({
		maxSize,
		minSize,
		onDrop: (acceptedFiles, notAcceptedFiles) => {
			if (acceptedFiles.length) {
				onLoadImage(acceptedFiles[0]);
			} else if (notAcceptedFiles.length) {
				setError(true);
			}
		},
		...(accept && { accept: accept.map((item) => item.format).join(', ') }),
	});

	// Modify styles

	const modifyDropzone = classNames({
		' upload-image__dropzone--active': isDragActive,
	});

	const modifyNotation = classNames({
		' upload-image__notation--error': isError,
	});

	// Render

	return (
		<div className="upload-image" {...getRootProps()}>
			<input {...getInputProps()} />

			<div className={`upload-image__dropzone${modifyDropzone}`}>
				<div className="upload-image__content">
					<div className="upload-image__icon">
						<FontIcon icon="system_update" size="max" rotate180 />
					</div>

					<div className="upload-image__text text-middle">
						Drag and drop files,{' '}
						<span className="upload-image__accent">
							<nobr> or click on drag zone </nobr>
						</span>
						<br />
					</div>

					{accept && (
						<div className={`upload-image__notation${modifyNotation} text-small`}>
							(Only {accept.map((item) => item.displayName).join(', ')} file types)
						</div>
					)}

					<div className={`upload-image__notation${modifyNotation} text-small`}>
						(Max size {Prettysize(maxSize)})
					</div>
				</div>
			</div>
		</div>
	);
}
