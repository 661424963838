import { useState, useEffect } from 'react';
import { setDesktopVersion, setMobileVersion } from './../redux/actionCreators/system';
import { useDispatch } from 'react-redux';

export const useMediaQuery = (maxMobileSize) => {
	const dispatch = useDispatch();
	const [isMatches, setIsMatches] = useState(false);

	useEffect(() => {
		const mediaQueryList = window.matchMedia(`(max-width: ${maxMobileSize}px)`);
		const changeEventHandler = (e) => setIsMatches(e.matches);

		setIsMatches(mediaQueryList.matches);
		mediaQueryList.addListener(changeEventHandler);

		return () => {
			mediaQueryList.removeListener(changeEventHandler);
		};
	}, [maxMobileSize]);

	if (isMatches) {
		dispatch(setMobileVersion(true));
	} else {
		dispatch(setDesktopVersion(true));
	}

	return isMatches;
};
