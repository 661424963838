import * as types from '../types/user';

// Static

import { userSettings as userSettingsInitialPayload } from '../../static/payloads/user';
import endpoints from '../../static/endpoints/user';

// Selectors

import { getUser } from '../selectors/user';

// -------- Classic action creator --------

// setUserSettings

export const setUserSettings = (payload) => ({
	type: types.SET_USER_SETTINGS,
	payload,
});

// -------- Async action creator --------

// getUserSettings

export const getUserSettings = (payload) => async (store) => {
	const data = await store.dispatch(getUserSettings$network());

	if (data.result.data) {
		const parseData = JSON.parse(data.result.data);

		store.dispatch(setUserSettings(parseData));
	} else {
		store.dispatch(setUserSettings(userSettingsInitialPayload));
	}
};

// postUserSettings

export const postUserSettings = (payload, key) => async (store) => {
	const { userSettings } = getUser(store.getState());
	const newSettings = {
		...userSettings,
		[key]: payload,
	};

	const json = JSON.stringify(newSettings);
	const sendPayload = {
		jsonData: `${json}`,
	};

	const data = await store.dispatch(postUserSettings$network(sendPayload));
	const parseData = JSON.parse(data.result.data.jsonData);

	store.dispatch(setUserSettings(parseData));
};

// -------- Async network action creator --------

// getUser$network

export const getUser$network = () => ({
	type: types.GET_USER_$NETWORK,
	endpoint: endpoints.USER,
	payload: { url: 'https://clarity-cintas.millmats.com' },
	method: 'POST',
});

// getUserSettings$network

export const getUserSettings$network = () => ({
	type: types.GET_USER_SETTINGS_$NETWORK,
	endpoint: endpoints.GET_USER_SETTINGS,
});

// postUserSettings$network

export const postUserSettings$network = (payload) => ({
	type: types.POST_USER_SETTINGS_$NETWORK,
	endpoint: endpoints.POST_USER_SETTINGS,
	payload,
	method: 'POST',
});

// postFeedback$network

export const postFeedback$network = (payload) => ({
	type: types.POST_FEEDBACK_$NETWORK,
	endpoint: endpoints.POST_FEEDBACK,
	payload,
	method: 'POST',
});
