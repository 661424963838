import React from 'react';

// Components

import { MarketingStruct, Container, FlyerCard } from '../../components';

// Action types

import { GET_MARKETING_MATERIALS_$NETWORK } from '../../redux/types/marketing';

// Action creators

import { setActiveFlyerId } from '../../redux/actionCreators/marketing';
import { toggle } from '../../redux/actionCreators/ui';

// ----------------

export default function MarketingFlyers(props) {
	return (
		<Container
			mapDispatch={{ setActiveFlyerId, toggle }}
			mapState={(state) => ({
				getNetworkProcess: state.network[GET_MARKETING_MATERIALS_$NETWORK],
				marketingFlyers: state.marketing.marketingFlyers,
			})}
			render={(cProps) => (
				<MarketingStruct
					getNetworkProcess={cProps.getNetworkProcess}
					listItemKeyName="id"
					defaultGridMode="list"
					materials={cProps.marketingFlyers}
					card={(cardProps) => (
						<FlyerCard
							fileExtension={cardProps.fileExtension}
							onClick={(id) => {
								cProps.setActiveFlyerId(id);
								cProps.toggle('flyerCardModal');
							}}
							title={cardProps.title}
							size={cardProps.size}
							url={cProps.url}
							id={cardProps.id}
							{...cardProps}
						/>
					)}
				/>
			)}
		/>
	);
}
