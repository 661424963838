import types from 'prop-types';
import React from 'react';

// Components

import { ManageDashboardForm, Container, Modal } from '../../../../components';
import Form from '../../../../modules/form-x';

// Action creators

import { postUserSettings } from '../../../../redux/actionCreators/user';

// ----------------

// Type of props

ManageDashboardModal.propTypes = {
	onClose: types.func.isRequired,
	open: types.bool.isRequired,
};

// ----------------

export default function ManageDashboardModal(props) {
	const { onClose, ...rest } = props;

	// Render

	return (
		<Modal onClose={onClose} title="Manage My Dashboard" bodyAccent {...rest}>
			<div>
				<Container
					mapDispatch={{ postUserSettings }}
					mapState={(state) => ({
						dashboardFilters: state.user.userSettings.dashboardFilters,
					})}
					render={({
						dashboardFilters: {
							myLocationSketches,
							createSketch,
							searchLogo,
							mySketches,
							myDrafts,
						},
						...cProps
					}) => (
						<Form
							validate={{ required: false }}
							onSubmit={async (data, actions) => {
								try {
									await cProps.postUserSettings(data, 'dashboardFilters');
									await onClose();
								} catch (err) {
									console.log('Submit error');
								}
							}}
							fields={{
								myLocationSketches: {
									value: myLocationSketches,
									handler: 'boolean',
									validate: { required: false },
								},
								createSketch: {
									value: createSketch,
									handler: 'boolean',
									validate: { required: false },
								},
								searchLogo: {
									value: searchLogo,
									handler: 'boolean',
									validate: { required: false },
								},
								mySketches: {
									value: mySketches,
									handler: 'boolean',
									validate: { required: false },
								},
								myDrafts: {
									value: myDrafts,
									handler: 'boolean',
									validate: { required: false },
								},
							}}
							render={(formProps) => (
								<ManageDashboardForm {...formProps} onClose={onClose} />
							)}
						/>
					)}
				/>
			</div>
		</Modal>
	);
}
