import types from 'prop-types';
import React from 'react';

// Components

import Tooltip from '../../../components/block/Tooltip';

// Styles

import './styles.scss';

// ----------------

// Type of props

TooltipDefaultView.propTypes = {
	children: types.node.isRequired,
	maxWidth: types.string,
	render: types.node.isRequired,
	title: types.string.isRequired,
};

// ----------------

export default function TooltipDefaultView(props) {
	const { children, maxWidth, render, title } = props;

	return (
		<Tooltip
			placement="auto-start"
			maxWidth={maxWidth || '500px'}
			trigger="click"
			render={() => (
				<div className="tooltip-default-view">
					<p className="text-small tooltip-default-view__title">{title}</p>
					<div className="tooltip-default-view__content">{render}</div>
				</div>
			)}
		>
			{children}
		</Tooltip>
	);
}
