import classNames from 'classnames';
import types from 'prop-types';
import React from 'react';

// Styles

import './styles.scss';

// ----------------

// Type of props

UIFiltersStruct.propTypes = {
	children: types.node.isRequired,
	theme: types.oneOf(['onFourth']),
	title: types.string,
	fluid: types.bool,
};

// Default value for props

UIFiltersStruct.defaultProps = {
	theme: 'onFourth',
};

// ----------------

export default function UIFiltersStruct(props) {
	const { children, theme, title, fluid } = props;

	// Modify styles

	const modify = classNames({
		[` ui-filters-struct--theme-${theme}`]: theme,
		' ui-filters-struct--fluid': fluid,
	});

	// Render

	return (
		<div className={`ui-filters-struct${modify}`}>
			{title && <p className="ui-filters-struct__title">{title}</p>}
			<div className="ui-filters-struct__grid">{children}</div>
		</div>
	);
}
