import React, { useState, useEffect } from 'react';
import types from 'prop-types';

// Components

import Modal from '../../../../components/block/Modal';
import Paint from '../../../../components/block/Paint';
import Button from '../../../../components/ui/Button';

// Styles

import './styles.scss';

// ----------------

// Type of props

AddSignatureModal.propTypes = {
	onSaveData: types.func.isRequired,
	onClose: types.func.isRequired,
	open: types.bool.isRequired,
	isSubmitForm: types.bool,
	data: types.object,
};

// ----------------

export default function AddSignatureModal(props) {
	const { onClose, onSaveData, data, open, isSubmitForm } = props;

	// State

	const [signatureValue, setSignature] = useState(data || null);

	// Set initial signature to null to prevent incorrect behavior when resizing canvas

	useEffect(() => {
		if (open) {
			setSignature(null);
		}
	}, [open]);

	// Handlers

	function onCancel() {
		if (!!signatureValue && !data) {
			setSignature(null);
		} else {
			setSignature(data);
		}

		onClose();
	}

	function onSave() {
		const saveData = isSubmitForm ? { signature: signatureValue } : signatureValue;

		onSaveData(saveData);
		onClose();
	}

	// Render

	return (
		<Modal
			onClose={() => {
				onCancel();
			}}
			title="Approval Signature"
			open={open}
			withoutBorderRadius
			withoutPadding
			maxWidth="fullscreen"
			bodyHeight="full"
			bodyAccent
		>
			<div className="add-signature-modal">
				<div className="add-signature-modal__paint">
					<Paint
						onButtonClick={() => {
							setSignature(null);
						}}
						onMouseUp={(signature) => {
							setSignature(signature);
						}}
						onMouseDown={() => null}
						data={signatureValue && signatureValue.json}
					/>
				</div>
				<div className="modal__two-buttons-grid add-signature-modal__buttons">
					<Button
						reverseType
						onClick={() => {
							onCancel();
						}}
						fluid
						id="add-signature-cancel-button"
					>
						Cancel
					</Button>
					<Button
						onClick={() => {
							onSave();
						}}
						id="add-signature-submit-button"
						disabled={!signatureValue}
						fluid
					>
						Save
					</Button>
				</div>
			</div>
		</Modal>
	);
}
