import { Route, Router } from 'react-router-dom';
import React from 'react';

// ----------------

export default function TopWrapper(component, history) {
	return () => (
		<Router history={history}>
			<Route component={component} />
		</Router>
	);
}
