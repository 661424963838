// Hooks
import { useDispatch, useSelector } from 'react-redux';

// Action creators

import {
	setDragDefPosition,
	toggleLayersModal,
	toggleTutorial,
	toggleDragMode,
	zoomDefault,
	undoRedo,
	setTool,
	zoomOut,
	zoomIn,
} from '../../../../redux/actionCreators/mat';

// ----------------

export default (props) => {
	const dispatch = useDispatch();
	const state = useSelector((state) => state);

	return [
		// UNDO - REDO

		[
			{
				onClick: () => {
					dispatch(undoRedo('undo'));
				},
				disabled: props.unduDisabled,
				tooltip: 'Undo',
				icon: 'undo',
				id: 'additional-tool-undo',
			},
			{
				onClick: () => {
					dispatch(undoRedo('redo'));
				},
				disabled: props.reduDisabled,
				tooltip: 'Redo',
				icon: 'redo',
				id: 'additional-tool-redo',
			},
		],

		// Zoom

		[
			{
				onClick: () => {
					dispatch(zoomIn());
				},
				tooltip: 'Zoom in',
				icon: 'zoom_in',
				id: 'additional-tool-zoom-in',
			},
			{
				onClick: () => {
					dispatch(zoomOut());
				},
				tooltip: 'Zoom out',
				icon: 'zoom_out',
				id: 'additional-tool-zoom-out',
			},
			{
				onClick: () => {
					dispatch(zoomDefault());
				},
				tooltip: 'Reset zoom',
				icon: 'find_replace',
				id: 'additional-tool-zoom-default',
			},
		],

		[
			{
				onClick: () => {
					dispatch(toggleDragMode());
				},
				mobileTop: true,
				tooltip: 'Drag',
				icon: 'touch_app',
				drag: true,
				id: 'additional-tool-drag',
			},
			{
				onClick: () => {
					dispatch(setDragDefPosition());
				},
				mobileTop: true,
				bigMargin: true,
				tooltip: 'Reset drag',
				icon: 'filter_center_focus',
				id: 'additional-tool-drag-center',
			},
		],

		// Layer

		[
			{
				onClick: () => {
					dispatch(toggleLayersModal());
				},
				mobileTop: true,
				tooltip: 'Layers',
				icon: 'layers',
				id: 'additional-tool-layers',
			},
		],

		// Tutorial

		[
			{
				onClick: () => {
					if (state.mat.activeTool) {
						dispatch(setTool(null));
					}
					dispatch(toggleTutorial());
				},
				mobileTop: true,
				tooltip: 'Tutorial',
				icon: 'school',
				id: 'additional-tool-tutorial',
			},
		],
	];
};
