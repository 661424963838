import * as base_types from '../types/base';
import * as types from '../types/user';
import $ from '../../utils/createActionType';

// -------- Default state of the branch --------

const defaultState = {
	userSettings: {
		dashboardFilters: {},
	},
	userData: {},
};

// -------- Reducer --------

export default function (state, action) {
	if (!state) return defaultState;

	switch (action.type) {
		case $(types.GET_USER_$NETWORK): {
			return { ...state, userData: { ...action.payload.Result.Data } };
		}

		case types.SET_USER_SETTINGS: {
			return { ...state, userSettings: action.payload };
		}

		case types.RESTORE_BRANCH:
		case base_types.RESTORE_DEFAULT_STATE: {
			return defaultState;
		}

		default: {
			return state;
		}
	}
}
