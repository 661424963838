import { fabric } from 'fabric-with-gestures';
import types from 'prop-types';
import React from 'react';

// Components

import FontIcon from '../../../../components/block/FontIcon';
import Button from '../../../../components/ui/Button';
import Modal from '../../../../components/block/Modal';

// Images

import transparentBg from '../../../../assets/images/transparent_bg.jpg';

// Styles

import './styles.scss';

// ----------------

export default class EditBackgroundImageModal extends React.Component {
	// Type of props

	static propTypes = {
		onClose: types.func.isRequired,
		onSave: types.func.isRequired,
	};

	// -------- Constructor --------

	constructor(props) {
		super(props);

		this.state = {
			buttonSize: 'xl',
			windowWidth: 0,
			initialScaleX: 1,
			initialScaleY: 1,
			historyPosition: -1,
			history: [],
		};

		this.canvasHeight = null;
		this.canvasWidth = null;
		this.canvas = null;

		// Binds

		this.handleModifiedObject = this.handleModifiedObject.bind(this);
		this.handleButtonSize = this.handleButtonSize.bind(this);
		this.handleKeyPress = this.handleKeyPress.bind(this);
		this.pushToHistory = this.pushToHistory.bind(this);
		this.handleRotate = this.handleRotate.bind(this);
		this.handleAlign = this.handleAlign.bind(this);
		this.onImageSave = this.onImageSave.bind(this);
		this.handleUndo = this.handleUndo.bind(this);
		this.handleRedo = this.handleRedo.bind(this);

		// Refs

		this.canvasRef = React.createRef();
	}

	// -------- Life cycle --------

	/**
	 * Did mount
	 */

	componentDidMount() {
		this.canvas = new fabric.Canvas('bg-canvas');

		this.config();

		if (this.props.restoreEditBg) {
			this.restoreImage();
		} else {
			this.addImage();
		}

		// Get toolbar button size

		this.handleButtonSize();
		window.addEventListener('resize', this.handleButtonSize);

		// Add global events on canvas

		document.addEventListener('keydown', this.handleKeyPress);
	}

	/**
	 * Will unmount
	 */

	componentWillUnmount() {
		window.removeEventListener('resize', this.handleButtonSize);
		document.removeEventListener('keydown', this.handleKeyPress);
	}

	// -------- Setters --------

	/**
	 * Set canvas size
	 */

	setSize() {
		const { matOrientation, matSize } = this.props;

		let matSizeArr = [];
		let x = 4;
		let y = 6;

		if (matSize) {
			matSizeArr = matSize.split('x');

			x = +matSizeArr[0];
			y = +matSizeArr[1];
		}

		let maxSize, maxCanvasWidth;

		if (window.innerHeight > 720 && window.innerWidth > 576) {
			maxSize = 576;
			maxCanvasWidth = 384;
		} else {
			maxSize = 340;
			maxCanvasWidth = 227;
		}

		let ratio = y / x;

		let canvasHeight;
		let canvasWidth;

		if (matOrientation === 'Portrait') {
			canvasWidth = maxCanvasWidth;
			canvasHeight = canvasWidth * ratio;

			if (canvasHeight > maxSize) {
				canvasHeight = maxSize;
				canvasWidth = canvasHeight / ratio;
			}
		} else {
			canvasHeight = maxCanvasWidth;
			canvasWidth = canvasHeight * ratio;

			if (canvasWidth > maxSize) {
				canvasWidth = maxSize;
				canvasHeight = canvasWidth / ratio;
			}
		}

		this.canvas.setHeight(canvasHeight);
		this.canvas.setWidth(canvasWidth);

		this.canvasHeight = canvasHeight;
		this.canvasWidth = canvasWidth;
	}

	/**
	 * Scale image to fit the canvas
	 */

	scaleImage(fabricObject) {
		const { canvas } = this;
		const canvasAspect = canvas.width / canvas.height;
		const objectAspect = fabricObject.width / fabricObject.height;

		if (canvasAspect > objectAspect) {
			fabricObject.scaleToWidth(canvas.width);
		} else {
			fabricObject.scaleToHeight(canvas.height);
		}

		fabricObject.set({
			originX: 'center',
			originY: 'center',
		});
	}

	/**
	 * Add new image to modal for editing
	 */

	addImage() {
		const { editBg } = this.props;
		const { canvas } = this;

		// Set canvas background placeholder

		canvas.setBackgroundColor(
			{ source: transparentBg, crossOrigin: 'Anonymous' },
			canvas.renderAll.bind(canvas)
		);

		// Add image to canvas

		fabric.Image.fromURL(editBg, (fabricObject) => {
			this.initFabricObject(fabricObject);
			this.normalizeImageSize(fabricObject);
			canvas.add(fabricObject);
			this.pushToHistory(fabricObject);
		});
	}

	/**
	 * Restore existing background image from JSON
	 */

	restoreImage() {
		const { bgImageJSON } = this.props;
		const { canvas } = this;

		canvas.loadFromJSON(bgImageJSON, () => {
			canvas.setBackgroundColor(
				{ source: transparentBg, crossOrigin: 'Anonymous' },
				canvas.renderAll.bind(canvas)
			);

			const fabricObject = canvas.getObjects()[0];
			this.initFabricObject(fabricObject, true);
			this.pushToHistory(fabricObject);
		});
	}

	// -------- Utils --------

	/**
	 * Setting global canvas сonfiguration
	 */

	config() {
		this.canvas.selection = false;

		// Set canvas size

		this.setSize();
	}

	/**
	 * Init fabric object
	 *
	 * @param {object}  fabricObject
	 */

	initFabricObject(fabricObject, restore = false) {
		const { mobile } = this.props;

		// Set fabric object properties

		fabricObject.objectType = 'image';
		fabricObject.originX = 'center';
		fabricObject.originY = 'center';

		// ---- Rest ----

		// Set object as active

		this.canvas.setActiveObject(fabricObject);

		// ---- Events ----

		// Add handler on modified event

		fabricObject.on('modified', this.handleModifiedObject);

		// Set border style

		this.objectBorderConfig(fabricObject, mobile);

		// Set object on center

		if (!restore) {
			this.canvas.viewportCenterObject(fabricObject);
		}
	}

	/**
	 * Normalize image size
	 *
	 * @param {object} fabricObject
	 */

	normalizeImageSize(fabricObject) {
		const { matOrientation } = this.props;
		const canvasWidth = this.canvasWidth;
		const canvasHeight = this.canvasHeight;
		const offset = window.innerWidth > 576 ? 100 : 60;

		if (matOrientation === 'Portrait' && fabricObject.width > canvasWidth) {
			const scaleXFactor = (canvasWidth - offset) / fabricObject.width;

			fabricObject.set({
				scaleX: scaleXFactor,
				scaleY: scaleXFactor,
			});

			this.setState({
				initialScaleX: scaleXFactor,
				initialScaleY: scaleXFactor,
			});

			fabricObject.setCoords();
			return true;
		}

		if (matOrientation === 'Landscape' && fabricObject.height > canvasHeight) {
			const scaleYFactor = (canvasHeight - offset) / fabricObject.height;

			fabricObject.set({
				scaleX: scaleYFactor,
				scaleY: scaleYFactor,
			});

			this.setState({
				initialScaleX: scaleYFactor,
				initialScaleY: scaleYFactor,
			});

			fabricObject.setCoords();
			return true;
		}

		return false;
	}

	/**
	 * Set fabric object border config
	 *
	 * @param {object}  fabricObject
	 * @param {boolean} isMobile
	 */

	objectBorderConfig(fabricObject, isMobile) {
		fabricObject.customiseCornerIcons(
			{
				settings: {
					cornerBackgroundColor: 'rgba(29, 160, 255, 1)',
					borderColor: '1da0ff',
					cornerShape: 'circle',
					cornerSize: isMobile ? 28 : 16,
				},
			},
			() => {
				this.canvas.renderAll();
			}
		);

		fabricObject.set({
			transparentCorners: false,
			borderDashArray: isMobile ? [10, 10] : [5, 5],
			borderColor: '#1da0ff',
			padding: 0,
		});
	}

	// -------- Handlers --------

	/**
	 * Handle key press
	 */

	handleKeyPress(e) {
		const fabricObject = this.canvas.getActiveObject();

		if (fabricObject) {
			const initialTop = fabricObject.get('top');
			const initialLeft = fabricObject.get('left');

			switch (e.keyCode) {
				case 40: {
					fabricObject.set('top', initialTop + 1);
					this.pushToHistory(fabricObject);

					break;
				}

				case 38: {
					fabricObject.set('top', initialTop - 1);
					this.pushToHistory(fabricObject);

					break;
				}

				case 37: {
					fabricObject.set('left', initialLeft - 1);
					this.pushToHistory(fabricObject);

					break;
				}

				case 39: {
					fabricObject.set('left', initialLeft + 1);
					this.pushToHistory(fabricObject);

					break;
				}

				default:
					break;
			}
		}
	}

	/**
	 * Handle modified object
	 */

	handleModifiedObject(e) {
		const { initialScaleX, initialScaleY } = this.state;

		if (initialScaleX !== e.target.scaleX || initialScaleY !== e.target.scaleY) {
			this.setState({
				initialScaleX: e.target.scaleX,
				initialScaleY: e.target.scaleY,
			});
		}

		this.pushToHistory(e.target);
	}

	/**
	 * Rotate image
	 */

	handleRotate(direction = 'left') {
		const fabricObject = this.canvas.getActiveObject();

		if (fabricObject) {
			const initialAngle = fabricObject.get('angle');
			const angle = direction === 'left' ? initialAngle - 90 : initialAngle + 90;
			fabricObject.set('angle', angle);

			this.pushToHistory(fabricObject);
		}
	}

	/**
	 * Align image
	 */

	handleAlign(to = 'center') {
		const fabricObject = this.canvas.getActiveObject();

		if (fabricObject) {
			switch (to) {
				case 'center': {
					this.canvas.viewportCenterObject(fabricObject);
					break;
				}

				case 'centerV': {
					this.canvas.viewportCenterObjectV(fabricObject);
					break;
				}

				case 'centerH': {
					this.canvas.viewportCenterObjectH(fabricObject);
					break;
				}

				default:
					break;
			}

			this.pushToHistory(fabricObject);
		}
	}

	/**
	 * Undo
	 */

	handleUndo() {
		if (this.state.historyPosition > 0) {
			const imageData = this.state.history[this.state.historyPosition - 1];

			const objects = this.canvas.getObjects();
			objects[0].set({ ...imageData });
			this.canvas.renderAll();

			this.setState((prevState) => ({
				historyPosition: prevState.historyPosition - 1,
			}));
		}
	}

	/**
	 * Redo
	 */

	handleRedo() {
		if (this.state.historyPosition + 1 !== this.state.history.length) {
			const imageData = this.state.history[this.state.historyPosition + 1];

			const objects = this.canvas.getObjects();
			objects[0].set({ ...imageData });

			this.canvas.renderAll();

			this.setState((prevState) => ({
				historyPosition: prevState.historyPosition + 1,
			}));
		}
	}

	/**
	 * Push image data to history
	 */

	pushToHistory(fabricObject) {
		const imageData = {
			height: fabricObject.height,
			width: fabricObject.width,
			scaleX: fabricObject.scaleX,
			scaleY: fabricObject.scaleY,
			angle: fabricObject.angle,
			left: fabricObject.left,
			top: fabricObject.top,
			oCoords: fabricObject.oCoords,
			aCoords: fabricObject.aCoords,
		};

		this.setState(
			(prevState) => ({
				historyPosition: prevState.historyPosition + 1,
				history: [
					...prevState.history.splice(0, prevState.historyPosition + 1),
					imageData,
				],
			}),
			() => {
				this.canvas.renderAll();
			}
		);
	}

	/**
	 * Handle toolbar button size
	 */

	handleButtonSize() {
		this.setState(
			{
				windowWidth: window.innerWidth,
			},
			() => {
				if (this.state.windowWidth < 576) {
					this.setState({ buttonSize: 'lg' });
				} else {
					this.setState({ buttonSize: 'xl' });
				}
			}
		);
	}

	/**
	 * Save background image
	 */

	onImageSave() {
		const { onSave } = this.props;
		const { canvas } = this;

		// Remove background placeholder

		canvas.setBackgroundColor(null);

		/**
		 * Convert canvas object to PNG image and check it's transparency
		 */

		onSave(canvas.toDataURL('png'), canvas.toJSON());

		// Move back background placeholder

		canvas.setBackgroundColor(
			{ source: transparentBg, crossOrigin: 'Anonymous' },
			canvas.renderAll.bind(canvas)
		);
	}

	// -------- Render --------

	render() {
		const { onClose } = this.props;
		const { buttonSize } = this.state;

		return (
			<Modal
				withoutPadding
				bodyHeight="full"
				maxWidth={window.innerHeight < 980 ? 'maxHeight' : 'maxFull'}
				onClose={onClose}
				title="Edit Background Image"
				bodyAccent
				open
			>
				<div className="edit-bg-image-modal">
					{/* Header */}
					<div className="edit-bg-image-modal__header">
						<div className="edit-bg-image-modal__header-actions-wrapper">
							<div
								onClick={() => this.handleRotate('left')}
								className="edit-bg-image-modal__header-action"
							>
								<FontIcon icon="rotate_left" size={buttonSize} />
							</div>
							<div
								onClick={() => this.handleRotate('right')}
								className="edit-bg-image-modal__header-action"
							>
								<FontIcon icon="rotate_right" size={buttonSize} />
							</div>
							<div
								onClick={() => this.handleAlign('centerV')}
								className="edit-bg-image-modal__header-action"
							>
								<FontIcon icon="vertical_align_center" size={buttonSize} />
							</div>
							<div
								onClick={() => this.handleAlign('centerH')}
								className="edit-bg-image-modal__header-action"
							>
								<FontIcon icon="vertical_align_center" rotate90 size={buttonSize} />
							</div>
							<div
								onClick={() => this.handleAlign()}
								className="edit-bg-image-modal__header-action"
							>
								<FontIcon icon="fullscreen_exit" rotate45 size={buttonSize} />
							</div>
							<div
								onClick={() => this.handleUndo()}
								className="edit-bg-image-modal__header-action"
							>
								<FontIcon icon="undo" size={buttonSize} />
							</div>
							<div
								onClick={() => this.handleRedo()}
								className="edit-bg-image-modal__header-action"
							>
								<FontIcon icon="redo" size={buttonSize} />
							</div>
						</div>
					</div>

					{/* Body */}

					<div className="edit-bg-image-modal__body">
						<div className="bg-canvas edit-bg-image-canvas">
							<canvas ref={this.canvasRef} id="bg-canvas" />
						</div>
					</div>

					{/* Buttons */}

					<div className="edit-bg-image-modal__buttons">
						<div className="modal__two-buttons-grid edit-bg-image-modal__buttons-grid">
							<Button
								reverseType
								onClick={onClose}
								fluid
								id="edit-bg-image-modal-cancel-button"
							>
								Cancel
							</Button>
							<Button
								fluid
								onClick={() => this.onImageSave()}
								id="edit-bg-image-modal-submit-button"
							>
								Save
							</Button>
						</div>
					</div>
				</div>
			</Modal>
		);
	}
}
