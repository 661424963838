const sidebarNavLinks = (handleModalToggle) => {
	return [
		{
			label: 'Dashboard',
			icon: 'dashboard',
			to: '/dashboard',
			id: 'dashboard',
		},
		{
			label: 'My Drafts',
			icon: 'burst_mode',
			to: '/my-drafts',
			id: 'my-drafts',
		},
		{
			label: 'My Sketches',
			icon: 'image',
			to: '/my-sketches',
			id: 'my-sketches',
		},
		{
			label: ' My Location Sketches',
			icon: 'my_location',
			to: '/my-location-sketches',
			id: 'my-location-sketches',
		},
		{
			label: 'Search Database',
			icon: 'collections',
			to: '/search',
			id: '/search',
		},
		{
			label: 'Marketing Materials',
			icon: 'store',
			to: '/marketing',
			id: 'marketing-materials',
		},
		{
			onClick: () => handleModalToggle('feedbackModal'),
			label: 'Send Us Your Feedback',
			icon: 'mail_outline',
			id: 'send-feedback',
		},
	];
};

export default sidebarNavLinks;
