import { NS } from "../../static/constants";

// ----------------

const TYPE_NAME = "MAT";

export const RESTORE_BRANCH          = `${NS}/${TYPE_NAME}/RESTORE_BRANCH`;
export const MAT_READY               = `${NS}/${TYPE_NAME}/MAT_READY`;
export const ADD_OBJECT              = `${NS}/${TYPE_NAME}/ADD_OBJECT`;
export const EDIT_OBJECT             = `${NS}/${TYPE_NAME}/EDIT_OBJECT`;
export const SYNC_OBJECT             = `${NS}/${TYPE_NAME}/SYNC_OBJECT`;
export const SYNC_OBJECTS            = `${NS}/${TYPE_NAME}/SYNC_OBJECTS`;
export const DEL_OBJECT              = `${NS}/${TYPE_NAME}/DEL_OBJECT`;
export const SET_BG_OPTION           = `${NS}/${TYPE_NAME}/SET_BG_OPTION`;
export const SET_BG_COLOR            = `${NS}/${TYPE_NAME}/SET_BG_COLOR`;
export const DEL_BG_COLOR            = `${NS}/${TYPE_NAME}/DEL_BG_COLOR`;
export const TOGGLE_ADDITIONAL_TOOLS = `${NS}/${TYPE_NAME}/TOGGLE_ADDITIONAL_TOOLS`;
export const SET_TOOL_HEIGHT         = `${NS}/${TYPE_NAME}/SET_TOOL_HEIGHT`;
export const SET_TOOL                = `${NS}/${TYPE_NAME}/SET_TOOL`;
export const SET_ACTIVE_OBJECT       = `${NS}/${TYPE_NAME}/SET_ACTIVE_OBJECT`;
export const TOGGLE_MODAL            = `${NS}/${TYPE_NAME}/TOGGLE_MODAL`;
export const SET_MODAL_TYPE          = `${NS}/${TYPE_NAME}/SET_MODAL_TYPE`;
export const SET_EDIT_BG             = `${NS}/${TYPE_NAME}/SET_EDIT_BG`;
export const DEL_EDIT_BG             = `${NS}/${TYPE_NAME}/DEL_EDIT_BG`;
export const SET_EDIT_TEXT_ID        = `${NS}/${TYPE_NAME}/SET_EDIT_TEXT_ID`;
export const TOGGLE_BORDER           = `${NS}/${TYPE_NAME}/TOGGLE_BORDER`;
export const SET_BORDER_COLOR        = `${NS}/${TYPE_NAME}/SET_BORDER_COLOR`;
export const TOGGLE_DRAG_MODE        = `${NS}/${TYPE_NAME}/TOGGLE_DRAG_MODE`;
export const SET_Z_INDEX             = `${NS}/${TYPE_NAME}/SET_Z_INDEX`;
export const SET_COLORS              = `${NS}/${TYPE_NAME}/SET_COLORS`;
export const SELECT_COLOR            = `${NS}/${TYPE_NAME}/SELECT_COLOR`;
export const SELECT_MULTIPLY_COLORS  = `${NS}/${TYPE_NAME}/SELECT_MULTIPLY_COLORS`;
export const DESELECT_COLOR          = `${NS}/${TYPE_NAME}/DESELECT_COLOR`;
export const CLEAR_COLORS            = `${NS}/${TYPE_NAME}/CLEAR_COLORS`;
export const OPEN_SUBMIT             = `${NS}/${TYPE_NAME}/OPEN_SUBMIT`;
export const CLOSE_SUBMIT            = `${NS}/${TYPE_NAME}/CLOSE_SUBMIT`;
export const SAVE_SIGNATURE          = `${NS}/${TYPE_NAME}/SAVE_SIGNATURE`;
export const CLEAR_SIGNATURE         = `${NS}/${TYPE_NAME}/CLEAR_SIGNATURE`;
export const SET_MAT_INFO            = `${NS}/${TYPE_NAME}/SET_MAT_INFO`;
export const SAVE_DRAFT              = `${NS}/${TYPE_NAME}/SAVE_DRAFT`;
export const RESTORE_MAT             = `${NS}/${TYPE_NAME}/RESTORE_MAT`;
export const RESTORE_MAT_HISTORY     = `${NS}/${TYPE_NAME}/RESTORE_MAT_HISTORY`;
export const START_LOADING           = `${NS}/${TYPE_NAME}/START_LOADING`;
export const END_LOADING             = `${NS}/${TYPE_NAME}/END_LOADING`;
export const TOGGLE_IMAGE_FILTERS    = `${NS}/${TYPE_NAME}/TOGGLE_IMAGE_FILTERS`;
export const SHOW_TUTORIAL           = `${NS}/${TYPE_NAME}/SHOW_TUTORIAL`;
export const SET_SIZE                = `${NS}/${TYPE_NAME}/SET_SIZE`;
export const SET_ORIENTATION_L       = `${NS}/${TYPE_NAME}/SET_ORIENTATION_L`;
export const SET_ORIENTATION_P       = `${NS}/${TYPE_NAME}/SET_ORIENTATION_P`;
export const SET_MAT_NAME            = `${NS}/${TYPE_NAME}/SET_MAT_NAME`;
export const SET_MAT_TYPE            = `${NS}/${TYPE_NAME}/SET_MAT_TYPE`;
export const ADD_SNAPSHOTS_TO_HIS    = `${NS}/${TYPE_NAME}/ADD_SNAPSHOTS_TO_HIS`;
export const RELOAD_HIS_AFTER_SAVE   = `${NS}/${TYPE_NAME}/RELOAD_HIS_AFTER_SAVE`;
export const UNDO                    = `${NS}/${TYPE_NAME}/UNDO`;
export const REDO                    = `${NS}/${TYPE_NAME}/REDO`;
export const SET_SIZES               = `${NS}/${TYPE_NAME}/SET_SIZES `;
export const SET_LYL_IMAGES          = `${NS}/${TYPE_NAME}/SET_LYL_IMAGES`;
export const SET_BG_IMAGE            = `${NS}/${TYPE_NAME}/SET_BG_IMAGE`;
export const DEL_BG_IMAGE            = `${NS}/${TYPE_NAME}/DEL_BG_IMAGE`;
export const ZOOM                    = `${NS}/${TYPE_NAME}/ZOOM`;
export const SET_DRAG_POSITION       = `${NS}/${TYPE_NAME}/SET_DRAG_POSITION`;
export const SET_DRAG_DEF_POSITION   = `${NS}/${TYPE_NAME}/SET_DRAG_DEF_POSITION`;
export const SET_EXTRACTED_COLORS    = `${NS}/${TYPE_NAME}/SET_EXTRACTED_COLORS`;
export const TOGGLE_SUGGESTED_COLORS = `${NS}/${TYPE_NAME}/TOGGLE_SUGGESTED_COLORS`;


// Network

export const GENERATE_SKETCH_PDF_$NETWORK = `${NS}/${TYPE_NAME}/GENERATE_SKETCH_PDF_$NETWORK`;
export const GET_LYL_IMAGES_$NETWORK      = `${NS}/${TYPE_NAME}/GET_LYL_IMAGES_$NETWORK`;
export const CONVERT_IMAGE_$NETWORK       = `${NS}/${TYPE_NAME}/CONVERT_IMAGE_$NETWORK`;
export const GET_COLORS_$NETWORK          = `${NS}/${TYPE_NAME}/GET_COLORS_$NETWORK`;
export const GET_SIZES_$NETWORK           = `${NS}/${TYPE_NAME}/GET_SIZES_$NETWORK`;
export const UPDATE_DRAFT_$NETWORK        = `${NS}/${TYPE_NAME}/UPDATE_DRAFT_$NETWORK`;
export const SAVE_DRAFT_$NETWORK          = `${NS}/${TYPE_NAME}/SAVE_DRAFT_$NETWORK`;
export const GET_DRAFT_$NETWORK           = `${NS}/${TYPE_NAME}/GET_DRAFT_$NETWORK`;
export const DEL_DRAFT_$NETWORK           = `${NS}/${TYPE_NAME}/DEL_DRAFT_$NETWORK`;
export const SUBMIT_$NETWORK              = `${NS}/${TYPE_NAME}/SUBMIT_$NETWORK`;
