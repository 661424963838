import { SortableContainer, SortableElement } from 'react-sortable-hoc';
import arrayMove from 'array-move';
import React from 'react';
import types from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

// Components

import Layer from '../../../components/block/Layer';

// Action creators

import { toggleModal, setActiveObject } from '../../../redux/actionCreators/mat';

// Styles

import './styles.scss';

// ----------------

// Type of props

MatLayers.propTypes = {
	onChange: types.func.isRequired,
	objects: types.object.isRequired,
};

// ----------------

export default function MatLayers(props) {
	const { onChange, objects } = props;

	// Preparation

	const data = Object.keys(objects)
		.map((key) => ({
			visible: objects[key].visible,
			zIndex: objects[key].zIndex,
			value: objects[key].text || objects[key].url,
			type: objects[key].objectType,
			id: objects[key].id,
		}))
		.sort((a, b) => (a.zIndex > b.zIndex ? -1 : 1));

	// Handlers

	function onSortEnd({ oldIndex, newIndex }) {
		onChange(arrayMove(data, oldIndex, newIndex).map((obj) => obj.id));
	}

	// Render

	return (
		<div className="mat-layers">
			{data.length ? (
				<>
					<p className="mat-layers__title">Drag and drop to arrange layers</p>
					<SortableList
						transitionDuration={300}
						helperClass="layer--active"
						onSortEnd={onSortEnd}
						distance={2}
						data={data}
					/>
				</>
			) : (
				<h2 className="mat-layers__empty">Canvas is empty</h2>
			)}
		</div>
	);
}

const SortableItem = SortableElement(({ obj }) => {
	const dispatch = useDispatch();
	const { canvas } = useSelector((state) => state.matCanvas.canvas);
	// Handlers

	function onSetActiveObject() {
		canvas.getObjects().forEach((fabricObj) => {
			if (fabricObj.objectId === obj.id) {
				canvas.setActiveObject(fabricObj);
			}
		});

		canvas.requestRenderAll();

		dispatch(setActiveObject(obj.id, obj.type));
		dispatch(toggleModal('layers'));
	}

	return (
		<Layer
			onSetActiveObject={() => onSetActiveObject()}
			value={obj.value}
			type={obj.type}
		/>
	);
});

const SortableList = SortableContainer(({ data }) => {
	return (
		<div>
			{data.map(
				(obj, index) =>
					obj.visible !== false && (
						<SortableItem key={`item-${index}`} index={index} obj={obj} />
					)
			)}
		</div>
	);
});
