/**
 * @param {any} obj The object to inspect.
 *
 * @returns {boolean} True if the argument appears to be a plain object.
 */

export default function isPlainObject(obj) {
	if (typeof obj !== 'object' || obj === null) return false;

	return !(obj instanceof Array);
}
