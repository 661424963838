import React from 'react';

// Components

import { MainStruct, MainHeader, Container } from '../../components';

// Action creators

import { toggle } from '../../redux/actionCreators/ui';

// ----------------

export default function Marketing(props) {
	return (
		<MainStruct
			header={
				<Container
					mapDispatch={{ toggle }}
					mapState={(state) => ({
						favouriteQuantity: state.mats.favouriteSketchesQty,
						sidebarOpen: state.ui.active.sidebar,
					})}
					render={MainHeader}
				/>
			}
		>
			<props.subRouter />
		</MainStruct>
	);
}
