import React, { useEffect } from 'react';
import types from 'prop-types';

// Components

import SketchImages from '../../../../components/block/tabsContent/SketchImages';

import UploadImage from '../../../../components/block/UploadImage';

import ListImage from '../../../../components/block/ListImage';

import Container from '../../../../components/rp/Container';

import Modal from '../../../../components/block/Modal';

import Tabs from '../../../../components/block/Tabs';

// Action creators

import { addImageObject, addEditBG } from '../../../../redux/actionCreators/mat';
import {
	getImages$network,
	getMoreImages,
} from '../../../../redux/actionCreators/google';

// Action types

import {
	GET_IMAGES_$NETWORK,
	GET_MORE_IMAGES_$NETWORK,
} from '../../../../redux/types/google';

// Static

import { matType as matTypeInfo, limit } from '../../../../static/constants';

// ----------------

// Type of props

AddNewImageModal.propTypes = {
	onUnmount: types.func,
	onClose: types.func.isRequired,
	open: types.bool.isRequired,
};

// ----------------

const tabs = {
	sketch: (props) => (
		<Container
			mapDispatch={{ addEditBG }}
			extra={{ sketchImages: props.sketchImages }}
			render={(cProps) => (
				<SketchImages
					imageList={cProps.sketchImages}
					onImageClick={(image) => cProps.addEditBG(image)}
				/>
			)}
		/>
	),
	device: (props) => (
		<Container
			mapDispatch={{
				onLoadImage:
					props.modalType === 'addImageToBackground' ? addEditBG : addImageObject,
			}}
			render={(cProps) => (
				<UploadImage maxSize={limit.IMAGE_SIZE} accept={limit.IMAGE_ACCEPT} {...cProps} />
			)}
		/>
	),
	web: (props) => (
		<Container
			mapDispatch={{
				onImageClick:
					props.modalType === 'addImageToBackground' ? addEditBG : addImageObject,
				onLoadMore: getMoreImages,
				onSearch: getImages$network,
			}}
			mapState={(state) => ({
				loadMoreNetworkProcess: state.network[GET_MORE_IMAGES_$NETWORK],
				defaultSearchValue: state.google.imagesNextPage.searchValue,
				networkProcess: state.network[GET_IMAGES_$NETWORK],
				showLoadMore:
					state.google.imagesNextPage.start && state.google.imagesNextPage.start < 100,
				list: state.google.images,
			})}
			render={ListImage}
		/>
	),
};

// ----------------

export default function AddNewImageModal(props) {
	const { onUnmount, onClose, open, modalType, matType, sketchImages } = props;

	// Preparation

	useEffect(() => {
		if (!open && onUnmount) {
			onUnmount();
		}
	}, [open, onUnmount]);

	function getTabLinks() {
		let tabLinks, tabContent;
		const bgModal =
			matType === matTypeInfo.PHOTO.name && modalType === 'addImageToBackground';

		const sketchLinks = [{ name: 'sketch', label: 'Sketch image' }];
		const sketchContent = [
			{ content: tabs.sketch, name: 'sketch', props: { modalType, sketchImages } },
		];

		const defaultLinks = [
			{ name: 'device', label: 'From the device' },
			{ name: 'web', label: 'Search the web' },
		];
		const defaultContent = [
			{ content: tabs.device, name: 'device', props: { modalType } },
			{ content: tabs.web, name: 'web', props: { modalType } },
		];

		if (bgModal && sketchImages.length > 1) {
			tabLinks = sketchLinks;
			tabContent = sketchContent;
		} else if (bgModal && sketchImages.length === 1) {
			tabLinks = [...sketchLinks, ...defaultLinks];
			tabContent = [...sketchContent, ...defaultContent];
		} else {
			tabLinks = defaultLinks;
			tabContent = defaultContent;
		}

		return {
			tabs: tabLinks,
			content: tabContent,
		};
	}

	const links = getTabLinks();

	// Render

	return (
		<Modal
			withoutPadding
			bodyAccent
			bodyHeight="full"
			maxWidth="maxFull"
			onClose={onClose}
			title="Add new image"
			open={open}
		>
			<Tabs
				tabLinksWidth="initial"
				tabCount={links.tabs.length}
				tabLinks={links.tabs}
				tabContent={links.content}
			/>
		</Modal>
	);
}
