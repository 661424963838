import React, { useEffect } from 'react';
import classNames from 'classnames';
import types from 'prop-types';

// Components

import { SearchFiltersForm, FontIcon } from '../../../../components';

// Styles

import './styles.scss';

// ----------------

// Type of props

SearchAside.propTypes = {
	getSizesNetworkProcess: types.bool,
	filterSizes: types.array.isRequired,
	onSearch: types.func.isRequired,
	toggle: types.func.isRequired,
	open: types.bool,
};

// Default value for props

SearchAside.defaultProps = {
	filterSizes: [],
	onSearch: () => null,
	open: false,
};

// ----------------

export default function SearchAside(props) {
	const { getSizesNetworkProcess, filterSizes, onSearch, toggle, open } = props;

	// Preparation

	useEffect(() => {
		if (open) {
			document.body.setAttribute('class', 'overflow-hidden');
		} else {
			document.body.removeAttribute('class');
		}
	}, [open]);

	// Modify styles

	const modify = classNames({
		' search-aside--open': open,
	});

	// Render

	return (
		<aside className={`search-aside${modify}`}>
			<header className="search-aside__header">
				<FontIcon size="xl" icon="tune" />
				<span className="search-aside__header-title">Filter Results by</span>
			</header>
			<SearchFiltersForm
				getSizesNetworkProcess={getSizesNetworkProcess}
				onSubmit={onSearch}
				toggle={toggle}
				sizes={filterSizes}
			/>
		</aside>
	);
}
