import React, { memo } from 'react';

// Components

import Input from '../../../components/ui/Input';
import UIFieldWrapper from '../../../components/block/UIFieldWrapper';

// ----------------

function InputField(props) {
	const { withPadding, titleSize, title, ...inputProps } = props;

	return (
		<UIFieldWrapper
			withPadding={withPadding}
			titleSize={titleSize}
			status={inputProps.status}
			title={title}
			theme={inputProps.theme}
			fluid={inputProps.fluid}
		>
			<Input {...inputProps} />
		</UIFieldWrapper>
	);
}

export default memo(InputField);
