import types from 'prop-types';
import React from 'react';

// Components

import { Modal, NewSketchRequestForm } from '../../../../components';
import Form from '../../../../modules/form-x';

// ----------------

// Type of props

NewSketchRequestModal.propTypes = {
	sketchName: types.string,
	onSubmit: types.func.isRequired,
	onClose: types.func.isRequired,
	title: types.string.isRequired,
	open: types.bool.isRequired,
};

// ----------------

export default function NewSketchRequestModal(props) {
	const { sketchName, onSubmit, onClose, title, open } = props;

	return (
		<Modal onClose={onClose} title={title} open={open} bodyAccent>
			<div className="new-sketch-request-modal">
				<Form
					validate={{ required: true }}
					onSubmit={(data) => {
						onSubmit(data.sketchName);
						onClose();
					}}
					fields={{
						sketchName: {
							messages: { required: 'Please enter sketch name' },
							validate: { min: 2, max: 60 },
							value: sketchName ? `${sketchName} copy` : '',
						},
					}}
					render={(formProps) => (
						<NewSketchRequestForm {...formProps} onClose={onClose} />
					)}
				/>
			</div>
		</Modal>
	);
}
