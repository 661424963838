// Static

import { matType } from '../../../../static/constants';

export const logoMatTypeDes = {
	[matType.TRADITIONAL.name]: {
		label: 'Create your sketch by selecting your colors from the standard logo palette',
	},
	[matType.PHOTO.name]: {
		label: 'Photorealistic printing capabilities without color selection',
	},
	[matType.LYL.name]: {
		label: 'Choose from a library of photorealistic background images',
	},
};
