import classNames from 'classnames';
import types from 'prop-types';
import React from 'react';

// Hooks

import { useWindowWidth } from '../../../hooks';

// Components

import { SelectColor, ToolSlider, Color } from '../../../components';

// Action creators

import { selectColorByLimit, setBGColorByLimit } from '../../../redux/actionCreators/mat';

// Styles

import './styles.scss';

// ----------------

// Type of props

SelectedColorsList.propTypes = {
	requiredOneColor: types.bool,
	onDeleteColor: types.func.isRequired,
	onlyPreview: types.bool,
	onDeleteBG: types.func.isRequired,
	requiredBg: types.bool,
	addButton: types.bool,
	palette: types.bool,
	list: types.array.isRequired,
	bg: types.oneOfType([types.object, types.bool]),
};

// Default props

SelectedColorsList.defaultProps = {
	onDeleteColor: () => null,
	onDeleteBG: () => null,
};

// ----------------

export default function SelectedColorsList(props) {
	const {
		requiredOneColor,
		onDeleteColor,
		onlyPreview,
		onDeleteBG,
		requiredBg,
		addButton,
		palette,
		list,
		size,
		bg,
	} = props;

	// Preparation

	const currentWindowWidth = useWindowWidth();
	const deviceSizeXS = currentWindowWidth <= 576;

	// Modify

	const modify = classNames({
		' selected-colors-list__cell--hidden': !addButton,
	});

	return (
		<div className="selected-colors-list">
			{deviceSizeXS && palette ? (
				<div className="selected-colors-list__slider">
					<ToolSlider list={list} withColorSelect>
						<div className="selected-colors-list__cell">
							<SelectColor
								withBorderRadius
								onlyPreview={onlyPreview}
								onSelect={setBGColorByLimit}
								required={requiredBg}
								label="Background"
								size="xl"
								{...(bg && {
									onCrossClick: onDeleteBG,
									name: bg.name,
									rgb: { r: bg.red, g: bg.green, b: bg.blue },
								})}
							/>
						</div>
						{list.map(({ name, red: r, green: g, blue: b }) => (
							<div className="selected-colors-list__cell" key={name}>
								<Color
									withBorderRadius
									onCrossClick={() => onDeleteColor(name)}
									onlyPreview={onlyPreview}
									size="xl"
									name={name}
									rgb={{ r, g, b }}
								/>
							</div>
						))}
						<div className={`selected-colors-list__cell ${modify}`}>
							{addButton && (
								<SelectColor
									withBorderRadius
									required={requiredOneColor}
									onSelect={selectColorByLimit}
									size="xl"
								/>
							)}
						</div>
					</ToolSlider>
				</div>
			) : (
				<>
					<SelectColor
						withBorderRadius
						onlyPreview={onlyPreview}
						onSelect={setBGColorByLimit}
						required={requiredBg}
						label="Background"
						size={size ? size : 'sm'}
						{...(bg && {
							onCrossClick: onDeleteBG,
							name: bg.name,
							rgb: { r: bg.red, g: bg.green, b: bg.blue },
						})}
					/>
					{palette && (
						<div className="selected-colors-list__grid">
							{list.map(({ name, red: r, green: g, blue: b }) => (
								<div className="selected-colors-list__cell" key={name}>
									<Color
										withBorderRadius
										onCrossClick={() => onDeleteColor(name)}
										onlyPreview={onlyPreview}
										name={name}
										rgb={{ r, g, b }}
									/>
								</div>
							))}
							{addButton && (
								<div className="selected-colors-list__cell">
									<SelectColor
										withBorderRadius
										required={requiredOneColor}
										onSelect={selectColorByLimit}
									/>
								</div>
							)}
						</div>
					)}
				</>
			)}
		</div>
	);
}
