import * as base_types from '../types/base';
import * as types from '../types/system';

// -------- Default state of the branch --------

const defaultState = {
	loading: false,
	mobile: window.innerWidth > 1024 ? false : true,
};

// -------- Reducer --------

export default function (state, action) {
	if (!state) return defaultState;

	switch (action.type) {
		// SET_DESKTOP_VERSION

		case types.SET_DESKTOP_VERSION: {
			return { ...state, mobile: false };
		}

		// SET_MOBILE_VERSION

		case types.SET_MOBILE_VERSION: {
			return { ...state, mobile: true };
		}

		// START_LOADING

		case types.START_LOADING: {
			return {
				...state,
				loading: { opacity: action.payload.opacity },
			};
		}

		// END_LOADING

		case types.END_LOADING: {
			return {
				...state,
				loading: false,
			};
		}

		case types.RESTORE_BRANCH:
		case base_types.RESTORE_DEFAULT_STATE: {
			return defaultState;
		}

		default: {
			return state;
		}
	}
}
