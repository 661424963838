import * as Sentry from '@sentry/browser';
import env from '../static/env';

if (env.SENTRY_ENABLE) {
	Sentry.init({
		dsn: env.SENTRY_DSN,
		debug: env.SENTRY_DEBUG,
		environment: env.SENTRY_ENVIRONMENT,
	});
}
