import types from 'prop-types';
import React from 'react';

// Components

import Button from '../../../../components/ui/Button';
import Switch from '../../../../components/ui/Switch';

// Styles

import './styles.scss';

// ----------------

// Type of props

ManageDashboardForm.propTypes = {
	onSubmit: types.func.isRequired,
	onClose: types.func.isRequired,
	fields: types.object.isRequired,
};

// Default value for props

ManageDashboardForm.defaultProps = {
	onSubmit: () => null,
	onClose: () => null,
};

// ----------------

export default function ManageDashboardForm(props) {
	const { fields, onSubmit, onClose } = props;

	return (
		<form className="manage-dashboard-form" onSubmit={onSubmit}>
			<fieldset className="manage-dashboard-form__fields">
				<div className="manage-dashboard-form__switch">
					<span className="manage-dashboard-form__switch-title">New Sketch Request</span>
					<Switch
						onChange={fields.createSketch.onChange}
						active={fields.createSketch.value}
						theme="onPrimaryDark"
					/>
				</div>
				<div className="manage-dashboard-form__switch">
					<span className="manage-dashboard-form__switch-title">Search Database</span>
					<Switch
						onChange={fields.searchLogo.onChange}
						active={fields.searchLogo.value}
						theme="onPrimaryDark"
					/>
				</div>
				<div className="manage-dashboard-form__switch">
					<span className="manage-dashboard-form__switch-title">My Drafts</span>
					<Switch
						onChange={fields.myDrafts.onChange}
						active={fields.myDrafts.value}
						theme="onPrimaryDark"
					/>
				</div>
				<div className="manage-dashboard-form__switch">
					<span className="manage-dashboard-form__switch-title">My Sketches</span>
					<Switch
						onChange={fields.mySketches.onChange}
						active={fields.mySketches.value}
						theme="onPrimaryDark"
					/>
				</div>
				<div className="manage-dashboard-form__switch">
					<span className="manage-dashboard-form__switch-title">
						Sketches From My Location
					</span>
					<Switch
						onChange={fields.myLocationSketches.onChange}
						active={fields.myLocationSketches.value}
						theme="onPrimaryDark"
					/>
				</div>
			</fieldset>
			<div className="form__two-buttons-grid">
				<Button
					reverseType
					onClick={onClose}
					fluid
					id="manage-dashboard-form-cancel-button"
				>
					Cancel
				</Button>
				<Button
					onClick={onSubmit}
					fluid
					type="submit"
					id="manage-dashboard-form-submit-button"
				>
					Save
				</Button>
			</div>
		</form>
	);
}
