import React from 'react';

// Components

import { MatPreviewStruct } from '../../components';

// ----------------

export default function MatPreview(props) {
	return <MatPreviewStruct />;
}
