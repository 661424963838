import React, { memo } from 'react';
import classNames from 'classnames';
import types from 'prop-types';

// Components

import PreloaderCustomStub from '../../../../components/block/PreloaderCustomStub';
import Preloader from '../../../../components/rp/Preloader';

// Styles

import './styles.scss';

// ----------------

// Type of props

VideoCard.propTypes = {
	viewMode: types.string.isRequired,
	title: types.string,
	url: types.string,
	id: types.number,
};

// Default value for props

VideoCard.defaultProps = {
	title: '-',
	id: '-',
};

// ----------------

function VideoCard(props) {
	const { viewMode, title, url, id } = props;

	// Modify styles

	const modify = classNames({
		[` video-card--view-${viewMode}`]: viewMode,
	});

	// Render

	return (
		<div className={`video-card${modify}`}>
			<div className="video-card__video-wrapper">
				<Preloader
					loader={(lProps) => <PreloaderCustomStub text="Video is loading" {...lProps} />}
					render={({ handleLoad, isLoaded }) => (
						<iframe
							allowFullScreen="allowfullscreen"
							frameBorder="0"
							onLoad={handleLoad}
							title={title}
							type="text/html"
							src={url}
						/>
					)}
				/>
			</div>
			<div className="video-card__info-wrapper">
				<p className="video-card__title">{title}</p>
				<p className="text-small video-card__id">{`#${id}`}</p>
			</div>
		</div>
	);
}

export default memo(VideoCard);
