import { createStore, combineReducers, applyMiddleware } from 'redux';
import { createLogger } from 'redux-logger';
import { composeWithDevTools } from 'redux-devtools-extension';

// Static

import { ENV } from '../static/constants';

// Middlewares

import async from './middleware/async';

// Reducers

import reducers from './reducers';

// ----------------

/**
 * Create redux store with middlewares
 *
 * @param {object} [preloadedState]
 *
 * @returns {object} redux store
 */

export default function createReduxStore(preloadedState) {
	const composeEnhancers = composeWithDevTools({});
	const middlewares = [async];

	if (ENV === 'development') {
		middlewares.unshift(
			createLogger({
				predicate: (getState, action) => {
					if (typeof action === 'function') return false;
					return true;
				},
				collapsed: true,
			})
		);
	}

	return createStore(
		combineReducers(reducers),
		preloadedState,
		composeEnhancers(applyMiddleware(...middlewares))
	);
}
