import classNames from 'classnames';
import types from 'prop-types';
import React from 'react';

// Components

import SvgIcon from '../../../components/block/SvgIcon';

// Assets

import { warningIcon } from '../../../assets/icons';

// Styles

import './styles.scss';

// ----------------

// Type of props

ErrorField.propTypes = {
	error: types.string,
	fluid: types.bool,
};

// ----------------

export default function ErrorField(props) {
	const { error, fluid } = props;

	// Modify styles

	const modify = classNames({
		' error-field--shows': !!error,
		' error-field--fluid': fluid,
	});

	// Render

	return (
		<div className={`error-field${modify}`}>
			{!!error && (
				<>
					<div className="error-field__icon-wrapper">
						<SvgIcon url={warningIcon} />
					</div>
					<span className="error-field__text">{error}</span>
				</>
			)}
		</div>
	);
}
