import React from 'react';

// Components

import { SignupForm, Container } from '../../components';
import Form from '../../modules/form-x';

// Action creators

import { signup } from '../../redux/actionCreators/auth';

// Action types

import { SIGNUP_$NETWORK } from '../../redux/types/auth';
import { GET_LOCATIONS_$NETWORK } from '../../redux/types/meta';

// Static

import { email } from '../../static/regExps';

// ----------------

export default function Signup(props) {
	return (
		<Container
			mapDispatch={{ signup }}
			mapState={(state) => ({
				postNetworkProcess: state.network[SIGNUP_$NETWORK],
				getNetworkProcess: state.network[GET_LOCATIONS_$NETWORK],
				locations: state.meta.locations,
			})}
			render={(cProps) => (
				<Form
					extraState={{ isCaptchaVerified: false, isRequestSend: false }}
					validate={{ required: true }}
					onSubmit={async (data, actions) => {
						const modifiedData = {
							...data,
							TempUserName: data.Email.toLowerCase(),
							TempPassword: data.Password,
							TempEmailID: data.Email.toLowerCase(),
							CustomerID: data.CustomerID.id,
							FullName: `${data.FirstName} ${data.LastName}`,
							Email: data.Email.toLowerCase(),
						};

						try {
							await cProps.signup({
								...modifiedData,
							});
							actions.setExtraState('isRequestSend', true);
						} catch (err) {}
					}}
					fields={{
						FirstName: {
							value: '',
						},
						LastName: {
							value: '',
						},
						CustomerID: {
							value: null,
							handler: 'setter',
						},
						Email: {
							value: '',
							validate: { regExp: email },
							messages: { regExp: 'Please, enter a valid email address' },
						},
						Password: {
							value: '',
							validate: { min: 8 },
							messages: { min: 'Length is short, minimum 8 required' },
						},
						RetypePassword: {
							value: '',
							validate: { confirm: 'Password' },
							ignoreOnSubmit: true,
						},
					}}
					render={(formProps) => (
						<SignupForm
							{...formProps}
							postNetworkProcess={cProps.postNetworkProcess}
							getNetworkProcess={cProps.getNetworkProcess}
							locations={cProps.locations}
						/>
					)}
				/>
			)}
		/>
	);
}
