import types from 'prop-types';
import React from 'react';

// Components

import Container from '../../../../components/rp/Container';
import Sidebar from '../../../../components/block/sidePanels/Sidebar';

// Action creators

import { logout } from '../../../../redux/actionCreators/auth';
import { toggle } from '../../../../redux/actionCreators/ui';

// Styles

import './styles.scss';

// ----------------

// Type of props

MainStruct.propTypes = {
	children: types.node.isRequired,
	header: types.node.isRequired,
};

// ----------------

export default function MainStruct(props) {
	const { children, header } = props;

	return (
		<div className="main-struct">
			{header}
			<Container
				mapDispatch={{ toggle, logout }}
				mapState={(state) => ({
					userData: state.user.userData,
					open: state.ui.active.sidebar,
				})}
				render={(cProps) => (
					<Sidebar
						{...cProps}
						userEmail={cProps.userData.UserName}
						userName={cProps.userData.FullName}
					/>
				)}
			/>
			<main className="main-struct__main-section">{children}</main>
		</div>
	);
}
