import { NavLink } from 'react-router-dom';
import types from 'prop-types';
import React from 'react';

// Components

import FontIcon from '../../../../components/block/FontIcon';

// Styles

import './styles.scss';

// ----------------

// Type of props

SidebarNavLink.propTypes = {
	data: types.exact({
		onClick: types.func,
		label: types.string.isRequired,
		icon: types.string.isRequired,
		id: types.string.isRequired,
		to: types.string,
	}),
};

// Default value for props

SidebarNavLink.defaultProps = {
	data: types.exact({
		to: '',
	}),
};

// ----------------

export default function SidebarNavLink(props) {
	const { data, onClose } = props;

	// Preparation

	const renderButton = () => (
		<div
			className="sidebar-nav-link"
			onClick={data.onClick}
			id={`${data.id}-sidebar-button`}
		>
			<FontIcon size="xl" icon={data.icon} />
			<p className="sidebar-nav-link__label">{data.label}</p>
		</div>
	);

	const renderLink = () => (
		<NavLink
			activeClassName="sidebar-nav-link--active"
			className="sidebar-nav-link"
			onClick={onClose}
			id={`${data.id}-sidebar-link`}
			to={data.to}
		>
			<FontIcon size="xl" icon={data.icon} />
			<p className="sidebar-nav-link__label">{data.label}</p>
		</NavLink>
	);

	// Render

	return data.onClick ? renderButton() : renderLink();
}
