import React, { useState } from 'react';
import classNames from 'classnames';
import types from 'prop-types';

// Hooks

import { useWindowWidth } from '../../../../hooks';

// Components

import {
	MatPreviewInfoCard,
	SelectedColorsListPreview,
	FontIcon,
} from '../../../../components';

// Styles

import './styles.scss';

// ----------------

MatPreviewAside.propTypes = {
	sketchReference: types.string.isRequired,
	sketchName: types.string.isRequired,
	colorsList: types.array,
	variation: types.oneOfType([types.string, types.number]),
	className: types.string,
	details: types.string,
	bgColor: types.object,
};

// Default value for props

MatPreviewAside.defaultProps = {
	sketchReference: '-',
	sketchName: '-',
	variation: '-',
	details: '',
};

// ----------------

export default function MatPreviewAside(props) {
	const {
		sketchReference,
		sketchName,
		colorsList,
		variation,
		className,
		details,
		bgColor,
	} = props;

	// State

	const [asideMobileShow, setSidebarShow] = useState(false);

	// Preparation

	const currentWindowWidth = useWindowWidth();

	// Handlers

	function handleSidebarToggle() {
		setSidebarShow(!asideMobileShow);
	}

	// Modify styles

	const modify = classNames({
		' mat-preview-aside--open': asideMobileShow && currentWindowWidth <= 1024,
	});

	// Render

	return (
		<aside className={`${className} mat-preview-aside${modify}`}>
			<header
				className="mat-preview-aside__header"
				onClick={!asideMobileShow ? handleSidebarToggle : null}
			>
				<div className="mat-preview-aside__header-content">
					<div className="mat-preview-aside__back-icon">
						<FontIcon icon="info" size="xl" />
					</div>
					<p className="mat-preview-aside__name text-middle">Sketch details</p>
					{asideMobileShow && (
						<div className="mat-preview-aside__close-btn" onClick={handleSidebarToggle}>
							<FontIcon icon="close" size="lg" />
						</div>
					)}
				</div>
			</header>

			{/* Preview info list */}

			<div className="mat-preview-aside__body">
				<MatPreviewInfoCard title="Sketch Name" fontWeight="bold" value={sketchName} />
				<MatPreviewInfoCard
					title="Sketch Reference"
					fontWeight="bold"
					value={sketchReference}
				/>
				<MatPreviewInfoCard title="Variation of" value={variation} />
				<MatPreviewInfoCard title="Mat Details" value={details} />
				<MatPreviewInfoCard title="Sketch Colors">
					{(!!colorsList || !!bgColor) && (
						<SelectedColorsListPreview list={colorsList} bg={bgColor} />
					)}
				</MatPreviewInfoCard>
			</div>
		</aside>
	);
}
