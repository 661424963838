import types from 'prop-types';
import React from 'react';

// Components

import Tooltip from '../../../components/block/Tooltip';

// Styles

import './styles.scss';

// ----------------

// Type of props

TooltipHoverView.propTypes = {
	placement: types.string,
	children: types.node.isRequired,
	render: types.node.isRequired,
};

// ----------------

export default function TooltipHoverView(props) {
	const { placement, mobileTop, children, render } = props;

	return (
		<Tooltip
			placement={placement}
			mobileTop={mobileTop}
			delayShow={800}
			maxWidth="200px"
			trigger="hover"
			render={() => <div className="tooltip-hover-view">{render}</div>}
			width="auto"
			hideArrow
		>
			{children}
		</Tooltip>
	);
}
