import Notifications from 'react-notification-system-redux';
import types from 'prop-types';
import React from 'react';

// Styles

import './styles.scss';

// Data

import customStyles from './data';

// ----------------

// Type of props

SystemNotification.propTypes = {
	notifications: types.array.isRequired,
};

// ----------------

export default function SystemNotification(props) {
	return <Notifications notifications={props.notifications} style={customStyles} />;
}
