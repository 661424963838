import React, { useEffect } from 'react';
import classNames from 'classnames';
import types from 'prop-types';

// Hooks

import { useModal } from '../../../../hooks';

// Components

import SidebarNavLinkCard from '../../../../components/block/cards/SidebarNavLinkCard';

import FeedbackModal from '../../../../components/block/modals/FeedbackModal';

import LogoutModal from '../../../../components/block/modals/LogoutModal';

import FontIcon from '../../../../components/block/FontIcon';

import Button from '../../../../components/ui/Button';

// Styles

import './styles.scss';

// Data

import sidebarNavLinks from './data';

// ----------------

// Type of props

Sidebar.propTypes = {
	userEmail: types.string,
	userName: types.string,
	toggle: types.func,
	logout: types.func,
	open: types.bool,
};

// Default value for props

Sidebar.defaultProps = {
	userEmail: '-',
	userName: '-',
};

// ----------------

export default function Sidebar(props) {
	const { userName, userEmail, toggle, logout, open } = props;

	// State

	const { modal, handleModalToggle } = useModal();

	// Preparation

	useEffect(() => {
		if (open) {
			document.body.setAttribute('class', 'overflow-hidden');
		} else {
			document.body.removeAttribute('class');
		}
	}, [open]);

	// Modify styles

	const modify = classNames({
		' sidebar--open': open,
	});

	const modifyBackdrop = classNames({
		' sidebar__backdrop--open': open,
	});

	// Render

	return (
		<>
			<aside className={`sidebar${modify}`}>
				<div className="sidebar__links-block">
					{sidebarNavLinks(handleModalToggle).map((item) => (
						<SidebarNavLinkCard
							onClose={() => toggle('sidebar')}
							data={item}
							key={item.label}
						/>
					))}
				</div>
				<div className="sidebar__bottom-block">
					<div>
						<div className="sidebar__info">
							<p className="text-mini sidebar__info-title">You are logged in as</p>
							<p className="sidebar__info-user-name">{userName}</p>
							<p className="sidebar__info-user-email">{userEmail}</p>
						</div>

						<div className="sidebar__buttons">
							<Button
								onClick={() => handleModalToggle('logoutModal')}
								fluid
								icon={<FontIcon size="xl" icon="power_settings_new" />}
								id="logout-sidebar-button"
							>
								Log out
							</Button>
						</div>
					</div>
				</div>
			</aside>
			<div
				className={`sidebar__backdrop${modifyBackdrop}`}
				onClick={() => toggle('sidebar')}
			/>

			{/* Modals */}

			<FeedbackModal
				onClose={() => {
					handleModalToggle(null);
				}}
				open={modal === 'feedbackModal'}
			/>
			<LogoutModal
				onSubmit={() => logout()}
				onClose={() => {
					handleModalToggle(null);
				}}
				open={modal === 'logoutModal'}
			/>
		</>
	);
}
