import classNames from 'classnames';
import types from 'prop-types';
import React from 'react';

// Styles

import './styles.scss';

// ----------------

// Type of props

HamburgerButton.propTypes = {
	handleClick: types.func,
	isOpen: types.bool.isRequired,
	mirror: types.bool,
};

// Default value for props

HamburgerButton.defaultProps = {
	isOpen: false,
	mirror: false,
};

// ----------------

export default function HamburgerButton(props) {
	const { handleClick, isOpen, mirror } = props;

	// Modify styles

	const modify = classNames({
		' hamburger-btn--mirror-open': mirror && isOpen,
		' hamburger-btn--mirror': mirror,
		' hamburger-btn--open': isOpen,
	});

	// Render

	return (
		<button className={`hamburger-btn${modify}`} onClick={handleClick}>
			<p />
			<p />
			<p />
		</button>
	);
}
