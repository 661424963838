import PopperTooltip from 'react-popper-tooltip';
import classNames from 'classnames';
import types from 'prop-types';
import React from 'react';

// Styles

import 'react-popper-tooltip/dist/styles.css';
import './styles.scss';

// ----------------

// Type of props

Tooltip.propTypes = {
	mobileTop: types.bool,
	hideArrow: types.bool,
	maxWidth: types.string,
	children: types.node.isRequired,
	render: types.func.isRequired,
	width: types.string,
};

// Default value for props

Tooltip.defaultProps = {
	maxWidth: '400px',
	width: '100%',
};

// ----------------

export default function Tooltip(props) {
	const { mobileTop, hideArrow, maxWidth, children, render, width, ...rest } = props;

	// Preparation

	let ref = null;

	// Modify styles

	const modify = classNames({
		[` tooltip-container--mobile-top`]: mobileTop,
	});

	// Handlers

	function handleClose() {
		ref.click();
	}

	// Render

	return (
		<PopperTooltip
			getTriggerRef={(HTMLElement) => {
				ref = HTMLElement;
			}}
			tooltip={({ getTooltipProps, getArrowProps, tooltipRef, placement, arrowRef }) => (
				<div
					{...getTooltipProps({
						className: `tooltip-container${modify}`,
						style: { maxWidth, width },
						ref: tooltipRef,
					})}
				>
					{!hideArrow && (
						<div
							{...getArrowProps({
								'data-placement': placement,
								className: 'tooltip-arrow',
								ref: arrowRef,
							})}
						/>
					)}

					{render({ handleClose })}
				</div>
			)}
			{...rest}
		>
			{({ getTriggerProps, triggerRef }) => (
				<div
					{...getTriggerProps({
						ref: triggerRef,
					})}
				>
					{children}
				</div>
			)}
		</PopperTooltip>
	);
}
