import React from 'react';

// Components

import { SigninForm, Container } from '../../components';
import Form from '../../modules/form-x';

// Action creators

import { signin } from '../../redux/actionCreators/auth';

// Action types

import { SIGNIN_$NETWORK } from '../../redux/types/auth';

// ----------------

export default function Signin() {
	return (
		<Container
			mapDispatch={{ signin }}
			mapState={(state) => ({ networkProcess: state.network[SIGNIN_$NETWORK] })}
			render={(cProps) => (
				<Form
					extraState={{ notValidData: false }}
					validate={{ required: true }}
					onSubmit={async (data, actions) => {
						try {
							await cProps.signin(data);
						} catch (err) {
							actions.setExtraState('notValidData', true);
						}
					}}
					fields={{
						rememberMe: {
							value: false,
							handler: 'boolean',
							validate: { required: false },
						},
						password: { value: '', messages: { required: 'Please enter your password' } },
						userName: {
							value: '',
							messages: { required: 'Please enter your user name' },
						},
					}}
					render={(formProps) => (
						<SigninForm {...formProps} networkProcess={cProps.networkProcess} />
					)}
				/>
			)}
		/>
	);
}
