import types from 'prop-types';
import React from 'react';

// Components

import Modal from '../../../../components/block/Modal';

// ----------------

// Type of props

SelectColorModal.propTypes = {
	tabsComponent: types.func.isRequired,
	onClose: types.func.isRequired,
	open: types.bool.isRequired,
};

// ----------------

export default function SelectColorModal(props) {
	const { tabsComponent, onClose, open } = props;

	return (
		<Modal
			withoutPadding
			bodyHeight="full"
			maxWidth="maxFull"
			onClose={onClose}
			title="Select color"
			bodyBgColor="light"
			bodyAccent
			open={open}
		>
			{tabsComponent()}
		</Modal>
	);
}
