// Notifications colors

const defaultColors = {
	success: {
		rgb: '94, 164, 0',
		hex: '#5ea400',
	},
	error: {
		rgb: '236, 61, 61',
		hex: '#ec3d3d',
	},
	warning: {
		rgb: '235, 173, 23',
		hex: '#ebad1a',
	},
	info: {
		rgb: '54, 156, 199',
		hex: '#369cc7',
	},
};

// Custom Styles

const customStyles = {
	Wrapper: {},
	Containers: {
		DefaultStyle: {
			width: '375px',
		},
	},

	NotificationItem: {
		DefaultStyle: {
			borderRadius: '0',
			transition: '0.3s ease',
			fontSize: '1rem',
			padding: '1rem',
		},

		success: {
			borderTop: 'none',
		},
	},

	Title: {
		DefaultStyle: {
			fontSize: '0.9375rem',
			padding: '0.75rem 1rem',
			margin: '0',
		},

		success: {
			backgroundColor: defaultColors.success.hex,
		},

		error: {
			backgroundColor: defaultColors.error.hex,
		},

		warning: {
			backgroundColor: defaultColors.warning.hex,
		},

		info: {
			backgroundColor: defaultColors.info.hex,
		},
	},

	MessageWrapper: {
		DefaultStyle: {
			padding: '1rem',
			margin: 0,
		},
	},

	Dismiss: {
		DefaultStyle: {
			backgroundColor: 'transparent',
			fontWeight: 'thin',
			lineHeight: '20px',
			fontSize: '24px',
			height: '20px',
			right: '10px',
			width: '20px',
			top: '13px',
		},
	},
};

export default customStyles;
