import React, { memo } from 'react';
import classNames from 'classnames';
import types from 'prop-types';

// Styles

import './styles.scss';

// ----------------

MatPreviewInfoCard.propTypes = {
	fontWeight: types.oneOf(['normal', 'bold']),
	children: types.node,
	value: types.oneOfType([types.string, types.number]),
	title: types.string.isRequired,
};

// Default value for props

MatPreviewInfoCard.defaultProps = {
	fontWeight: 'normal',
};

// ----------------

function MatPreviewInfoCard(props) {
	const { children, title, fontWeight, value } = props;

	// Modify styles

	const modify = classNames({
		[` preview-info__value--font-${fontWeight}`]: fontWeight,
	});

	// Render

	return (
		<div className="preview-info">
			<span className="preview-info__title">{title}</span>
			{!!value ? (
				<span className={`preview-info__children preview-info__value${modify}`}>
					{value}
				</span>
			) : !!children ? (
				<div className="preview-info__children">{children}</div>
			) : (
				'-'
			)}
		</div>
	);
}

export default memo(MatPreviewInfoCard);
