import classNames from 'classnames';
import types from 'prop-types';
import React from 'react';

// Components

import DashboardSlider from '../../../../components/block/DashboardSlider';

import AnimatedIcon from '../../../../components/block/AnimatedIcon';

import MatPreview from '../../../../components/block/cards/MatPreview';

import FontIcon from '../../../../components/block/FontIcon';

import Loader from '../../../../components/block/loaders/Loader';

import Button from '../../../../components/ui/Button';

// Utils

import { getMatFavouriteStatus, getMatStatus } from '../../../../utils';

// Styles

import './styles.scss';

// ----------------

// Type of props

DashboardPageStruct.propTypes = {
	myLocationSketches: types.array.isRequired,
	manageMyDashboard: types.object.isRequired,
	mySketchesData: types.array.isRequired,
	myDraftsData: types.array.isRequired,
};

// Default value for props

DashboardPageStruct.defaultProps = {
	myLocationSketches: [],
	mySketchesData: [],
	myDraftsData: [],
};

// ----------------

export default function DashboardPageStruct(props) {
	const {
		locationSketchesNetworkProcess,
		deleteSketchFromFavourite,
		sketchesNetworkProcess,
		setActiveSketchPreview,
		addSketchToFavourite,
		draftsNetworkProcess,
		myLocationSketches,
		manageMyDashboard: {
			viewMyLocationSketches,
			viewNewSketchRequest,
			viewSearchDataBase,
			viewMySketches,
			viewMyDrafts,
		},
		mySketchesData,
		myDraftsData,
		toggle,
	} = props;

	// Preparation

	const slidersLength = Object.values({
		viewMyLocationSketches,
		viewMySketches,
		viewMyDrafts,
	}).filter((item) => item).length;

	function isDashboardEmpty() {
		const settings = [
			viewMyLocationSketches,
			viewNewSketchRequest,
			viewSearchDataBase,
			viewMySketches,
			viewMyDrafts,
		];

		return settings.every((elem) => elem === false);
	}

	// Modify styles

	const modifyGrid = classNames({
		[` dashboard-page-struct__sliders-grid--${slidersLength}sliders`]: slidersLength,
	});

	// Render

	return (
		<div className="dashboard-page-struct show-info">
			{(viewNewSketchRequest || viewSearchDataBase) && (
				<div className="dashboard-page-struct__buttons">
					{viewNewSketchRequest && (
						<Button
							className="dashboard-page-struct__custom-btn"
							onClick={() => toggle('newSketchRequestModal')}
							theme="onPrimary"
							fluid
							icon={<AnimatedIcon icon="add" />}
							size="xxl"
							id="dashboard-create-sketch-button"
						>
							Create New Sketch
						</Button>
					)}
					{viewSearchDataBase && (
						<Button
							className="dashboard-page-struct__custom-btn"
							fluid
							theme="onPrimary"
							link="/search"
							icon={<AnimatedIcon icon="search" />}
							size="xxl"
							id="dashboard-search-sketch-button"
						>
							Search for Sketch
						</Button>
					)}
				</div>
			)}
			{(viewMyDrafts || viewMySketches || viewMyLocationSketches) && (
				<div className={`dashboard-page-struct__sliders-grid${modifyGrid}`}>
					{viewMyDrafts && (
						<div className="dashboard-page-struct__slider-wrapper">
							<p className="dashboard-page-struct__title">My Drafts</p>
							{draftsNetworkProcess ? (
								<div className="slider__loader">
									<Loader />
								</div>
							) : (
								<DashboardSlider keyName="draftId" linkTo="/my-drafts" title="My Drafts">
									{myDraftsData.map(
										({
											productGroupID,
											attributeName,
											companyName,
											designName,
											imageName,
											sketchRef,
											sketchURL,
											draftId,
											created,
											...cardProps
										}) => (
											<MatPreview
												withPreviewModal
												companyName={companyName}
												withBorder={false}
												imageName={imageName || undefined}
												favourite={0}
												maxWidth="auto"
												onClick={async () => {
													await setActiveSketchPreview(draftId, 'draft', 'drafts');
													await toggle('matPreviewModal');
												}}
												sketchRef={sketchRef}
												status={getMatStatus(cardProps)}
												image={sketchURL}
												size={attributeName}
												name={designName}
												date={created}
												type={productGroupID || undefined}
												key={draftId}
												id={draftId}
											/>
										)
									)}
								</DashboardSlider>
							)}
						</div>
					)}
					{viewMySketches && (
						<div className="dashboard-page-struct__slider-wrapper">
							<p className="dashboard-page-struct__title">My Sketches</p>
							{sketchesNetworkProcess ? (
								<div className="slider__loader">
									<Loader />
								</div>
							) : (
								<DashboardSlider
									keyName="sketchRequestID"
									linkTo="/my-sketches"
									title="My Sketches"
								>
									{mySketchesData.map(
										({
											sketchRequestID,
											productGroupID,
											attributeName,
											companyName,
											requestDate,
											designName,
											imageName,
											sketchRef,
											sketchURL,
											scanURL,
											...cardProps
										}) => (
											<MatPreview
												onFavouriteClick={(id, favourite) =>
													favourite === 1
														? deleteSketchFromFavourite({ id })
														: addSketchToFavourite({ id })
												}
												withPreviewModal
												companyName={companyName}
												withBorder={false}
												imageName={imageName}
												favourite={getMatFavouriteStatus(cardProps)}
												maxWidth="auto"
												onClick={async () => {
													await setActiveSketchPreview(
														sketchRequestID,
														'sketch',
														'sketches'
													);
													await toggle('matPreviewModal');
												}}
												sketchRef={sketchRef}
												status={getMatStatus(cardProps)}
												image={
													getMatStatus(cardProps) === 'complete' ? sketchURL : scanURL
												}
												size={attributeName}
												name={designName}
												date={requestDate}
												type={productGroupID || undefined}
												key={sketchRequestID}
												id={sketchRequestID}
											/>
										)
									)}
								</DashboardSlider>
							)}
						</div>
					)}
					{viewMyLocationSketches && (
						<div className="dashboard-page-struct__slider-wrapper">
							<p className="dashboard-page-struct__title">Sketches from My Location</p>
							{locationSketchesNetworkProcess ? (
								<div className="slider__loader">
									<Loader />
								</div>
							) : (
								<DashboardSlider
									keyName="sketchRequestID"
									linkTo="/my-location-sketches"
									title="Sketches from My Location"
								>
									{myLocationSketches.map(
										({
											sketchRequestID,
											productGroupID,
											attributeName,
											companyName,
											requestDate,
											designName,
											imageName,
											sketchRef,
											sketchURL,
											scanURL,
											...cardProps
										}) => (
											<MatPreview
												onFavouriteClick={(id, favourite) =>
													favourite === 1
														? deleteSketchFromFavourite({ id })
														: addSketchToFavourite({ id })
												}
												withPreviewModal
												companyName={companyName}
												withBorder={false}
												imageName={imageName}
												favourite={getMatFavouriteStatus(cardProps)}
												maxWidth="auto"
												onClick={async () => {
													await setActiveSketchPreview(
														sketchRequestID,
														'sketch',
														'locationSketches'
													);
													await toggle('matPreviewModal');
												}}
												sketchRef={sketchRef}
												status={getMatStatus(cardProps)}
												image={
													getMatStatus(cardProps) === 'complete' ? sketchURL : scanURL
												}
												size={attributeName}
												name={designName}
												date={requestDate}
												type={productGroupID || undefined}
												key={sketchRequestID}
												id={sketchRequestID}
											/>
										)
									)}
								</DashboardSlider>
							)}
						</div>
					)}
				</div>
			)}
			{isDashboardEmpty() && (
				<div className="dashboard-page-struct__placeholder">
					<div className="dashboard-page-struct__icon">
						<FontIcon icon="view_compact" size="xlarge" />
					</div>
					<div>Your Dashboard is empty</div>
					<div
						className="dashboard-page-struct__button"
						onClick={() => toggle('manageDashboardModal')}
					>
						Customize it now
					</div>
				</div>
			)}
		</div>
	);
}
