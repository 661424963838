import classNames from 'classnames';
import types from 'prop-types';
import React from 'react';

// Components

import FontIcon from '../../../components/block/FontIcon';
import SvgIcon from '../../../components/block/SvgIcon';

// Styles

import './styles.scss';

// ----------------

// Type of props

IconButton.propTypes = {
	handleClick: types.func.isRequired,
	buttonSize: types.oneOf(['sm', 'md', 'lg', 'xl', 'stretch']),
	iconSize: types.oneOf(['xs', 'sm', 'md', 'lg', 'xl', 'xxl', 'auto']),
	iconType: types.oneOf(['fontIcon', 'svgIcon']).isRequired,
	icon: types.string.isRequired,
	id: types.string.isRequired,
};

// Default value for props

IconButton.defaultProps = {
	buttonSize: 'xl',
	iconSize: 'md',
};

// ----------------

export default function IconButton(props) {
	const { handleClick, buttonSize, iconSize, iconType, icon, id } = props;

	// Modify styles

	const modify = classNames({
		[` icon-btn--${buttonSize}`]: buttonSize,
	});

	// Render

	return (
		<div className={`icon-btn${modify}`} onClick={handleClick} id={id}>
			{iconType === 'svgIcon' ? (
				<SvgIcon size={iconSize} url={icon} />
			) : (
				<FontIcon size={iconSize} icon={icon} />
			)}
		</div>
	);
}
