/**
 * Create success or error action type
 *
 * @param {string} currentType
 * @param {boolean} [newType=true] success if true, error if false.
 *
 * @return {string} new action type
 **/

export default function createActionType(currentType, newType = true) {
	return newType ? `${currentType}_SUCCESS` : `${currentType}_ERROR`;
}
