import formurlencoded from 'form-urlencoded';
import NetworkError from '../utils/errors/NetworkError';
import Cookies from 'js-cookie';

// Static

import { cookie } from '../static/constants';

// ----------------

/**
 * Makes the http request and handle the result
 *
 * @param {string}  endpoint
 * @param {object}  [options]
 * @param {boolean} [URLE=false]
 * @param {boolean} [withoutToken=false]
 *
 * @return {object} Result
 **/

export default async function (endpoint, options, URLE = false, withoutToken = false) {
	const token = Cookies.get(cookie.TOKEN);

	let allOptions = {};

	// Set default headers

	const headers = {
		...(!URLE
			? { 'Content-Type': 'application/json' }
			: { 'Content-Type': 'application/x-www-form-urlencoded' }),
		Accept: 'application/json',
		...(token && !withoutToken ? { Authorization: `Bearer ${token}` } : {}),
	};

	// Merging default headers with custom headers

	if (options.headers) {
		allOptions.headers = { ...headers, ...options.headers };

		delete options.headers;
	} else allOptions.headers = headers;

	// Preparations body for the request

	if (options.body) {
		if (URLE) allOptions.body = formurlencoded(options.body);
		else allOptions.body = JSON.stringify(options.body);

		delete options.body;
	}

	allOptions = {
		...options,
		...allOptions,
	};

	// Request

	const res = await window.fetch(endpoint, allOptions);
	let data;

	try {
		data = await res.json();
	} catch (err) {}

	if (res.status < 200 || res.status >= 300) throw new NetworkError(res.status, data);
	else if (res.status === 200 && data.Result && data.Result.IsError)
		throw new NetworkError(res.status, data);

	return data;
}
