import { Link } from 'react-router-dom';
import types from 'prop-types';
import React from 'react';

// Components

import CounterIconButton from '../../../../components/ui/CounterIconButton';
import HamburgerButton from '../../../../components/ui/HamburgerButton';

// Assets

import { virtuosoLogo } from '../../../../assets/images';

// Styles

import './styles.scss';

// ----------------

// Type of props

MainHeader.propTypes = {
	headerCustomButtons: types.oneOfType([types.node, types.func]),
	favouriteQuantity: types.number,
	sidebarOpen: types.bool,
	toggle: types.func,
};

// ----------------

export default function MainHeader(props) {
	const { headerCustomButtons, favouriteQuantity, sidebarOpen, toggle } = props;
	return (
		<header className="main-header">
			<div
				className="main-header__menu-btn"
				onClick={() => toggle('sidebar')}
				id="main-header-hamburger-button"
			>
				<HamburgerButton isOpen={sidebarOpen} mirror />
			</div>
			<div className="main-header__logo-wrapper">
				<Link
					onClick={sidebarOpen ? () => toggle('sidebar') : null}
					className="main-header__logo"
					id="dashboard-home-link"
					to="/dashboard"
				>
					<img className="img-contain" src={virtuosoLogo} alt="Virtuoso Logo" />
				</Link>
			</div>
			<div className="main-header__buttons-block">
				{!!headerCustomButtons &&
					headerCustomButtons.map((item, index) => (
						<div className="buttons-block__btn" key={index}>
							{item}
						</div>
					))}
				<div className="buttons-block__btn">
					<CounterIconButton
						quantity={favouriteQuantity}
						stretch
						link="/my-favourite-sketches"
						icon="favorite_border"
						id="main-header-favourite-button"
					/>
				</div>
			</div>
		</header>
	);
}
