import React from 'react';

/**
 * Define static data for mat.
 */

// Mat Designer fonts

export const fontFamily = [
	{ value: 'American Typewriter', key: 'American_Typewriter' },
	{ value: 'Arial', key: 'Arial' }, // test
	{ value: 'Arvo', key: 'Arvo' },
	{ value: 'Asap', key: 'Asap' },
	{ value: 'Avenir', key: 'Avenir' },
	{ value: 'Berlin Sans', key: 'Berlin_Sans' },
	{ value: 'Bitter', key: 'Bitter' },
	{ value: 'Brush Script', key: 'Brush_Script' },
	{ value: 'Cairo', key: 'Cairo' },
	{ value: 'Cantarell', key: 'Cantarell' },
	{ value: 'Chalkboard', key: 'Chalkboard' },
	{ value: 'Cinzel Decorative', key: 'Cinzel_Decorative' },
	{ value: 'Corbel', key: 'Corbel' },
	{ value: 'Cuprum', key: 'Cuprum' },
	{ value: 'Dosis', key: 'Dosis' },
	{ value: 'Droid Serif', key: 'Droid_Serif' },
	{ value: 'Exo', key: 'Exo' },
	{ value: 'Fira Sans', key: 'Fira_Sans' },
	{ value: 'Franklin Gothic', key: 'Franklin_Gothic' },
	{ value: 'Gentium Basic', key: 'Gentium_Basic' },
	{ value: 'Gill Sans', key: 'Gill_Sans' },
	{ value: 'Halant', key: 'Halant' },
	{ value: 'Heebo', key: 'Heebo' },
	{ value: 'Impact', key: 'Impact' },
	{ value: 'Istok Web', key: 'Istok_Web' },
	{ value: 'Josefin Sans', key: 'Josefin_Sans' },
	{ value: 'Jura', key: 'Jura' },
	{ value: 'Kanit', key: 'Kanit' },
	{ value: 'Lato', key: 'Lato' },
	{ value: 'Libre Baskerville', key: 'Libre_Baskerville' },
	{ value: 'Lobster Two', key: 'Lobster_Two' },
	{ value: 'Lucida Sans', key: 'Lucida_Sans' },
	{ value: 'Marker Felt', key: 'Marker_Felt' },
	{ value: 'Maven Pro', key: 'Maven_Pro' },
	{ value: 'Neuton', key: 'Neuton' },
	{ value: 'Nobile', key: 'Nobile' },
	{ value: 'Nunito', key: 'Nunito' },
	{ value: 'Open Sans', key: 'Open_Sans' },
	{ value: 'Oswald', key: 'Oswald' },
	{ value: 'Papyrus', key: 'Papyrus' },
	{ value: 'Passion One', key: 'Passion_One' },
	{ value: 'PT Sans', key: 'PT_Sans' },
	{ value: 'PT Serif', key: 'PT_Serif' },
	{ value: 'Quicksand', key: 'Quicksand' },
	{ value: 'Roboto', key: 'Roboto' },
	{ value: 'Roboto Condensed', key: 'Roboto_Condensed' },
	{ value: 'Roboto Mono', key: 'Roboto_Mono' },
	{ value: 'Roboto Slab', key: 'Roboto_Slab' },
	{ value: 'Signika', key: 'Signika' },
	{ value: 'Songer', key: 'Songer' },
	{ value: 'Source Sans Pro', key: 'Source_Sans_Pro' },
	{ value: 'Source Serif Pro', key: 'Source_Serif_Pro' },
	{ value: 'Teko', key: 'Teko' },
	{ value: 'Titillium Web', key: 'Titillium_Web' },
	{ value: 'Turret Road', key: 'Turret_Road' },
	{ value: 'Ubuntu', key: 'Ubuntu' },
	{ value: 'Ubuntu Mono', key: 'Ubuntu_Mono' },
	{ value: 'Vibes', key: 'Vibes' },
	{ value: 'Yanone Kaffeesatz', key: 'Yanone_Kaffeesatz' },
];

/**
 * Steps for Mat tutorial
 */

const commonTutorialOptions = {
	disableBeacon: true,
	event: 'click',
};

export const getMatTutorial = (isMobile, state) => {
	return {
		steps: [
			{
				...commonTutorialOptions,
				placement:
					state.mat.matType !== 'LYL' && isMobile
						? 'left'
						: state.mat.matType === 'LYL' && isMobile
						? 'top'
						: 'right',

				content: (
					<span>
						Enter <strong>text</strong> here that you would like to display on your
						design.
						<br />
						<br />
						You will also have options to customize the look of your text (i.e. Font,
						color, position).
					</span>
				),
				target: '#mat-text-tool',
				styles: {
					spotlight: {
						borderRadius: 0,
					},
				},
				title: 'Text Tool',
			},
			{
				...commonTutorialOptions,
				placement:
					state.mat.matType !== 'LYL' && isMobile
						? 'left'
						: state.mat.matType === 'LYL' && isMobile
						? 'top'
						: 'right',

				content: (
					<span>
						Insert your <strong>logo</strong> or <strong>image</strong> file from your
						device or web search. Once you add the file, you can EDIT like normal (i.e.
						Crop, Remove/Replace Color).
						<br />
						<br />
						You can also REMOVE the updated logo and add a new file.
					</span>
				),
				target: '#mat-image-tool',
				styles: {
					spotlight: {
						borderRadius: 0,
					},
				},
				title: 'Image Tool',
			},
			{
				...commonTutorialOptions,
				placement: isMobile ? 'top' : 'right',
				content: (
					<span>
						Insert a <strong>border</strong> around your design
					</span>
				),
				target: '#mat-border-tool',
				styles: {
					spotlight: {
						borderRadius: 0,
					},
				},
				title: 'Border Tool',
				skipForLYLMatType: true,
			},
			{
				...commonTutorialOptions,
				placement: 'right',
				content: (
					<span>
						The color selection for your sketch has been modified to help ensure all the
						necessary colors are chosen.
						<br />
						<br />
						Depending on <strong>Mat Type</strong>, you will be notified of any missing
						colors/design instructions.
					</span>
				),
				target: '#mat-colors-tool',
				styles: {
					spotlight: {
						borderRadius: 0,
					},
				},
				title: 'Colors Tool',
				skipForLYLMatType: true,
			},
			{
				...commonTutorialOptions,
				placement: 'right',
				content: (
					<span>
						Click here to quickly change <strong>size</strong> and{' '}
						<strong>orientation</strong> of your mat.
					</span>
				),
				target: '#mat-size-tool',
				styles: {
					spotlight: {
						borderRadius: 0,
					},
				},
				title: 'Size Tool',
				skipForLYLMatType: true,
			},
			{
				...commonTutorialOptions,
				content: (
					<span>
						You can <strong>Delete</strong> the design at any time convenient for you.
					</span>
				),
				target: '#mat-delete-button',
				styles: {
					spotlight: {
						borderRadius: 0,
					},
				},
				title: 'Delete Button',
			},
			{
				...commonTutorialOptions,
				content: (
					<span>
						You can <strong>Save</strong> the design and later return to work on it.
					</span>
				),
				target: '#mat-save-button',
				styles: {
					spotlight: {
						borderRadius: 0,
					},
				},
				title: 'Save Button',
			},
			{
				...commonTutorialOptions,
				content: (
					<span>
						Click here to <strong>Submit</strong> your design for sketch when you are
						ready.
					</span>
				),
				target: '#mat-submit-button',
				styles: {
					spotlight: {
						borderRadius: 0,
					},
				},
				title: 'Submit Button',
			},
			{
				...commonTutorialOptions,
				placement: 'left',
				content: (
					<span>
						Use the <strong>Undo</strong> button to navigate through changes while in the{' '}
						<strong>Mat Designer</strong>.
					</span>
				),
				target: '#additional-tool-undo',
				styles: {
					spotlight: {
						borderRadius: 3,
					},
				},
				title: 'Undo Option',
			},
			{
				...commonTutorialOptions,
				placement: 'left',
				content: (
					<span>
						Use the <strong>Redo</strong> button to navigate through changes while in the{' '}
						<strong>Mat Designer</strong>.
					</span>
				),
				target: '#additional-tool-redo',
				styles: {
					spotlight: {
						borderRadius: 3,
					},
				},
				title: 'Redo Option',
			},
			{
				...commonTutorialOptions,
				placement: 'left',
				content: (
					<span>
						Use the <strong>Zoom In</strong> button to view on the mat something in
						greater detail.
					</span>
				),
				target: '#additional-tool-zoom-in',
				styles: {
					spotlight: {
						borderRadius: 3,
					},
				},
				title: 'Zoom In Option',
			},
			{
				...commonTutorialOptions,
				placement: 'left',
				content: (
					<span>
						Use the <strong>Zoom Out</strong> button to view the whole design.
					</span>
				),
				target: '#additional-tool-zoom-out',
				styles: {
					spotlight: {
						borderRadius: 3,
					},
				},
				title: 'Zoom Out Option',
			},
			{
				...commonTutorialOptions,
				placement: 'left',
				content: (
					<span>
						Use the <strong>Zoom Reset</strong> button to return to the default view of
						your mat.
					</span>
				),
				target: '#additional-tool-zoom-default',
				styles: {
					spotlight: {
						borderRadius: 3,
					},
				},
				title: 'Zoom Reset',
			},
			{
				...commonTutorialOptions,
				placement: 'left',
				content: (
					<span>
						Use the <strong>Drag</strong> button to move mat to the desired location on
						the screen
					</span>
				),
				target: '#additional-tool-drag',
				styles: {
					spotlight: {
						borderRadius: 3,
					},
				},
				title: 'Drag Option',
			},
			{
				...commonTutorialOptions,
				placement: 'left',
				content: (
					<span>
						Use the <strong>Reset Drag</strong> button to reset mat location on the screen
						to default
					</span>
				),
				target: '#additional-tool-drag-center',
				styles: {
					spotlight: {
						borderRadius: 3,
					},
				},
				title: 'Reset Drag Option',
			},
			{
				...commonTutorialOptions,
				placement: 'left',
				content: (
					<span>
						Use the <strong>Layers</strong> button to arrange layers in the sketch
					</span>
				),
				target: '#additional-tool-layers',
				styles: {
					spotlight: {
						borderRadius: 3,
					},
				},
				title: 'Layers Option',
			},
			{
				...commonTutorialOptions,
				placement: 'left',
				content: (
					<span>
						Click the Tutorial button if you want to re-visit the{' '}
						<strong>Mat Designer Tutorial</strong>
					</span>
				),
				target: '#additional-tool-tutorial',
				styles: {
					spotlight: {
						borderRadius: 3,
					},
				},
				title: 'Tutorial option',
			},
			{
				...commonTutorialOptions,
				placement: 'bottom',
				content: (
					<span>
						Your current sketch <strong>Design Name</strong> and <strong> Size </strong>{' '}
						are always visible while you are in the Mat Designer.
					</span>
				),
				target: '#mat-reference-title',
				styles: {
					spotlight: {
						borderRadius: 3,
					},
				},
				title: 'Quick Reference',
			},
			{
				...commonTutorialOptions,
				placement: 'bottom',
				content: (
					<span>
						Click here to <strong>Exit</strong> the Mat Designer. Upon leaving, all of
						your design changes are saved to My Drafts
					</span>
				),
				target: '#mat-back-button',
				styles: {
					spotlight: {
						borderRadius: 3,
					},
				},
				title: 'Exit Mat Designer',
			},
		],
		run: true,
	};
};
