import React, { memo, useEffect } from 'react';
import classNames from 'classnames';
import ReactDOM from 'react-dom';
import types from 'prop-types';

// Components

import FontIcon from '../../../components/block/FontIcon';

// Styles

import './styles.scss';

// ----------------

// Type of props

Modal.propTypes = {
	withoutBorderRadius: types.bool,
	withoutPadding: types.bool,
	shadowAccent: types.bool,
	bodyBgColor: types.string,
	bodyAccent: types.bool,
	bodyHeight: types.oneOf(['default', 'full']),
	closeIcon: types.bool,
	children: types.node.isRequired,
	maxWidth: types.oneOf([
		'default',
		'long',
		'content',
		'full',
		'maxFull',
		'maxHeight',
		'fullscreen',
	]),
	onClose: types.func,
	title: types.string,
	open: types.bool.isRequired,
};

// Default props

Modal.defaultProps = {
	shadowAccent: false,
	closeIcon: true,
	maxWidth: 'default',
};

// ----------------

function Modal(props) {
	const {
		withoutBorderRadius,
		withoutPadding,
		shadowAccent,
		bodyBgColor,
		bodyAccent,
		bodyHeight,
		closeIcon,
		children,
		maxWidth,
		onClose,
		title,
		open,
	} = props;

	// Preparation

	useEffect(() => {
		if (open) {
			setTimeout(() => {
				document.body.setAttribute('class', 'overflow-hidden');
			}, 250);
		} else {
			document.body.removeAttribute('class');
		}
	}, [open]);

	// Modify styles

	const modify = classNames({
		' modal--open': open,
	});

	const modifyModalContent = classNames({
		[` modal__content--maxWidth-${maxWidth}`]: maxWidth,
		' modal__content--withoutPadding': withoutPadding,
		' modal__content--withoutBorderRadius': withoutBorderRadius,
		' modal__content--shadow-accent': shadowAccent,
		' modal__content--body-accent': bodyAccent,
		[` modal__content--body-bg-${bodyBgColor}`]: bodyBgColor,
		[` modal__content--body-height-${bodyHeight}`]: bodyHeight,
	});

	// Render

	return ReactDOM.createPortal(
		open ? (
			<div className={`modal${modify}`}>
				<div className={`modal__content${modifyModalContent}`}>
					{title && <p className="modal__title">{title}</p>}
					{closeIcon && (
						<div className="modal__close-btn" onClick={onClose}>
							<FontIcon icon="close" />
						</div>
					)}
					<div className="modal__body">{children}</div>
				</div>
				<div className="modal__backdrop	" />
			</div>
		) : null,
		document.body
	);
}

export default memo(Modal);
