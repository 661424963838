import classNames from 'classnames';
import types from 'prop-types';
import React from 'react';

// Hooks

import { useModal } from '../../../../hooks';

// Components

import SelectedColorsListPreview from '../../../../components/block/SelectedColorsListPreview';

import SelectedTextPreview from '../../../../components/block/SelectedTextPreview';

import AddSignatureModal from '../../../../components/block/modals/AddSignatureModal';

import TextareaField from '../../../../components/ui/Textarea';

import ImagePreview from '../../../../components/block/ImagePreview';

import InputField from '../../../../components/ui/InputField';

import Container from '../../../../components/rp/Container';

import Switch from '../../../../components/ui/Switch';

import Button from '../../../../components/ui/Button';

import Label from '../../../../components/block/Label';

// Selectors

import getObjArrayByIds from '../../../../redux/selectors/getObjArrayByIds';
import getById from '../../../../redux/selectors/getById';

// Static

import { matType as matTypeInfo } from '../../../../static/constants';
import browserHistory from '../../../../static/global';

// Styles

import './styles.scss';

// ----------------

// Type of props

SubmitSketchForm.propTypes = {
	matOrientation: types.string.isRequired,
	textDetails: types.array.isRequired,
	onSaveData: types.func.isRequired,
	onSubmit: types.func.isRequired,
	onClose: types.func.isRequired,
	matType: types.string.isRequired,
	matSize: types.string.isRequired,
	fields: types.object.isRequired,
	mobile: types.bool.isRequired,
	data: types.object,
};

// Default value for props

SubmitSketchForm.defaultProps = {
	onSaveData: () => null,
	onSubmit: () => null,
};

// ----------------

export default function SubmitSketchForm(props) {
	const {
		matOrientation,
		setExtraState,
		textDetails,
		extraState,
		onSaveData,
		onSubmit,
		onClose,
		matType,
		matSize,
		fields,
		mobile,
		data,
	} = props;

	// State

	const { modal, handleModalToggle } = useModal();

	const labelPosition = mobile ? 'top' : 'left';
	const sketchId = browserHistory.history.location.pathname.split('/')[2];

	// Modify styles

	const error = classNames({
		' submit-sketch-form__signature--error': extraState.signatureError,
	});

	// Handlers

	const handleBlur = (e) => {
		const { name } = e.target;
		const res = fields[name].onBlur(e);

		if (res.isValid !== false) {
			onSaveData({ [name]: res.value });
		}
	};

	// Render

	return (
		<div className="submit-sketch-form">
			{/* Sketch ID */}

			<div className="submit-sketch-form__row">
				<Label
					title="Sketch #"
					position={labelPosition}
					theme="onPrimaryDark"
					fullWidthMobile
				>
					<div className="submit-sketch-form__row-content">{sketchId || '-'}</div>
				</Label>
			</div>

			{/* Sketch Name */}

			<div className="submit-sketch-form__row">
				<Label
					title="Sketch Name"
					position={labelPosition}
					theme="onPrimaryDark"
					fullWidthMobile
				>
					<div className="submit-sketch-form__row-content">
						<InputField
							{...fields.sketchName}
							placeholder="Sketch Name"
							withPadding={false}
							onBlur={handleBlur}
							theme="onPrimaryDark"
							size="sm"
							fluid
							id="submit-sketch-form-sketch-name"
						/>
					</div>
				</Label>
			</div>

			{/* Alternate Email */}

			<div className="submit-sketch-form__row">
				<Label
					title="Alternate Email"
					position={labelPosition}
					theme="onPrimaryDark"
					fullWidthMobile
				>
					<div className="submit-sketch-form__row-content">
						<InputField
							{...fields.email}
							placeholder="Alternate Email"
							withPadding={false}
							onBlur={handleBlur}
							theme="onPrimaryDark"
							size="sm"
							fluid
							id="submit-sketch-form-alternate-email"
						/>
					</div>
				</Label>
			</div>

			{/* Company/Customer Name */}

			<div className="submit-sketch-form__row">
				<Label
					title="Company/Customer Name"
					position={labelPosition}
					theme="onPrimaryDark"
					fullWidthMobile
				>
					<div className="submit-sketch-form__row-content">
						<InputField
							{...fields.companyName}
							placeholder="Company/Customer Name"
							withPadding={false}
							onBlur={handleBlur}
							theme="onPrimaryDark"
							size="sm"
							fluid
							id="submit-sketch-form-company-name"
						/>
					</div>
				</Label>
			</div>

			{/* Additional Instructions for Designer */}

			<div className="submit-sketch-form__row">
				<Label
					position={labelPosition}
					title="Additional Instructions for Designer"
					theme="onPrimaryDark"
					alignTop
					fullWidthMobile
				>
					<div className="submit-sketch-form__row-content">
						<TextareaField
							{...fields.instructions}
							placeholder="Additional Instructions for Designer"
							withPadding={false}
							onBlur={handleBlur}
							theme="onPrimaryDark"
							size="sm"
							fluid
							id="submit-sketch-form-instructions"
						/>
					</div>
				</Label>
			</div>

			{/* Approval Signature */}

			<div className="submit-sketch-form__row">
				<Label
					title="Approval Signature"
					position={labelPosition}
					theme="onPrimaryDark"
					alignTop
					fullWidthMobile
				>
					<div className="submit-sketch-form__row-content">
						<div
							className={`submit-sketch-form__signature${error}`}
							onClick={() => {
								if (extraState.signatureError) setExtraState('signatureError', false);
								handleModalToggle('addSignatureModal');
							}}
							id="submit-sketch-form-signature"
						>
							{data.signature && data.signature.base64Url ? (
								<img src={data.signature.base64Url} alt="" />
							) : (
								<p>Place Approval Signature in here</p>
							)}
						</div>
					</div>
				</Label>
			</div>

			{/* Mat Details */}

			<div className="submit-sketch-form__row">
				<Label
					title="Mat Details"
					position={labelPosition}
					theme="onPrimaryDark"
					fullWidthMobile
				>
					<div className="submit-sketch-form__row-content">{`${matType} | ${matSize} | ${matOrientation}`}</div>
				</Label>
			</div>

			{/* Sketch Colors */}

			<Container
				mapState={(state) => {
					const allColors = [...state.mat.colors.standart, ...state.mat.colors.pms];

					return {
						bgImage: state.mat.bgImage,
						isImagePreview: state.mat.matType !== matTypeInfo.TRADITIONAL.name,
						list: getObjArrayByIds(allColors, state.mat.selectedColors, 'name'),
						bg: state.mat.bgImage ? null : getById(allColors, 'name', state.mat.bgColor),
					};
				}}
				extra={{ labelPosition }}
				render={(cProps) => (
					<div className="submit-sketch-form__row">
						<Label
							title={cProps.isImagePreview ? 'Background' : 'Sketch Colors'}
							position={cProps.labelPosition}
							theme="onPrimaryDark"
							alignTop
							fullWidthMobile
						>
							<div className="submit-sketch-form__row-content">
								{cProps.bgImage ? (
									<ImagePreview imageUrl={cProps.bgImage} />
								) : (
									(cProps.bg || cProps.list.length) && (
										<SelectedColorsListPreview {...cProps} />
									)
								)}
							</div>
						</Label>
					</div>
				)}
			/>

			{/* Border Color */}

			<Container
				mapState={(state) => {
					const allColors = [...state.mat.colors.standart, ...state.mat.colors.pms];

					return {
						list: [getById(allColors, 'name', state.mat.border.color)],
						isShow: state.mat.border.isShow,
					};
				}}
				extra={{ labelPosition }}
				render={(cProps) => (
					<>
						{cProps.isShow && cProps.list.length && (
							<div className="submit-sketch-form__row">
								<Label
									title="Border Color"
									position={cProps.labelPosition}
									theme="onPrimaryDark"
									alignTop
									fullWidthMobile
								>
									<div className="submit-sketch-form__row-content">
										<SelectedColorsListPreview {...cProps} />
									</div>
								</Label>
							</div>
						)}
					</>
				)}
			/>

			{/* Text details */}

			{textDetails.length ? (
				<div className="submit-sketch-form__row">
					<Label
						title="Text Details"
						position={labelPosition}
						theme="onPrimaryDark"
						alignTop
						fullWidthMobile
					>
						<div className="submit-sketch-form__row-content">
							{textDetails.map((item, index) => (
								<div className="submit-sketch-form__text-preview" key={index}>
									<Container
										mapState={(state) => {
											const allColors = [
												...state.mat.colors.standart,
												...state.mat.colors.pms,
											];

											return {
												bg: getById(allColors, 'name', item.fontBGColor),
												color: getById(allColors, 'name', item.fontColor),
											};
										}}
										render={(cProps) => <SelectedTextPreview {...cProps} {...item} />}
									/>
								</div>
							))}
						</div>
					</Label>
				</div>
			) : null}

			{/* Conditions */}

			<div>
				<div className="submit-sketch-form__conditions">
					<div className="submit-sketch-form__conditions-switch">
						<Switch
							onChange={() => {
								const res = fields.conditions.onChange();
								onSaveData({ conditions: res });
							}}
							active={fields.conditions.value}
							theme="onPrimaryDark"
							id="submit-sketch-form-apply-conditions"
						/>
					</div>
					<span className="submit-sketch-form__conditions-text">
						I understand that my design is subject to color variance and clarity once
						printed on carpet. Overall appearance of the printed image is established by
						the quality, detail, focus and composition of the uploaded file. I agree for
						the designer to use best judgement when selecting colors and re-creating
						graphic details.
					</span>
				</div>
			</div>

			{/* Buttons block */}

			<div className="submit-sketch-form__buttons">
				<div className="form__two-buttons-grid">
					<Button id="cancel-submit-sketch-button" reverseType onClick={onClose}>
						Cancel
					</Button>
					<Button
						onClick={onSubmit}
						theme="secondary"
						type="submit"
						id="submit-sketch-button"
						disabled={!fields.conditions.value}
						fluid
					>
						Submit
					</Button>
				</div>
			</div>

			<AddSignatureModal
				onClose={() => {
					handleModalToggle(null);
				}}
				onSaveData={onSaveData}
				data={data.signature}
				open={modal === 'addSignatureModal'}
				isSubmitForm
			/>
		</div>
	);
}
