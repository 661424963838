export default function getTextDetails(mat) {
	const { objects } = mat;
	const info = [];

	const objectKeys = Object.keys(objects);

	if (objectKeys.length) {
		objectKeys.forEach((key) => {
			const object = objects[key];

			if (object.objectType === 'text') {
				let fontStyle = null;
				if (object.bold || object.italic) {
					fontStyle = `Font style: ${object.bold ? 'Bold' : ''}${
						object.bold && object.italic ? ' Italic' : object.italic ? 'Italic' : ''
					}`;
				}

				info.push({
					text: object.text,
					fontBGColor: object.fontBGColor,
					fontColor: object.fontColor,
					fontFamily: `Font family: ${object.fontFamily.replace(/_/g, ' ')}`,
					fontStyle: fontStyle,
				});
			}
		});
	}

	return info;
}
