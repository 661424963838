import React, { useEffect } from 'react';
import classNames from 'classnames';
import types from 'prop-types';

// Components

import InputField from '../../../../components/ui/InputField';
import Button from '../../../../components/ui/Button';

// Styles

import './styles.scss';

// ----------------

// Type of props

AddTextForm.propTypes = {
	primaryBtnText: types.string,
	maxLength: types.number.isRequired,
	onSubmit: types.func.isRequired,
	onClose: types.func.isRequired,
	fields: types.object.isRequired,
};

// Default value for props

AddTextForm.defaultProps = {
	primaryBtnText: 'Save',
};

// ----------------

export default function AddTextForm(props) {
	const { primaryBtnText, maxLength, onSubmit, onClose, fields } = props;

	// Preparation

	useEffect(() => {
		document
			.getElementsByClassName('add-text-form')[0]
			.getElementsByTagName('input')[0]
			.focus();
	}, []);

	const valueLength = fields.text.value.length;

	// Modify styles

	const modifyCounter = classNames({
		' add-text-form__counter--error': valueLength >= maxLength,
	});

	// Render

	return (
		<form className="add-text-form" onSubmit={onSubmit}>
			<fieldset className="add-text-form__fields">
				<InputField
					{...fields.text}
					placeholder="Enter your text here..."
					theme="onPrimary"
					title="Text"
					fluid
				/>
			</fieldset>
			<p className={`add-text-form__counter${modifyCounter}`}>
				Available characters:{' '}
				<span>{valueLength >= maxLength ? 0 : maxLength - valueLength}</span>
			</p>
			<div className="form__two-buttons-grid">
				<Button reverseType fluid onClick={onClose} id="add-text-form-cancel-button">
					Cancel
				</Button>
				<Button fluid theme="secondary" type="submit" id="add-text-form-submit-button">
					{primaryBtnText}
				</Button>
			</div>
		</form>
	);
}
