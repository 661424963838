import types from 'prop-types';
import React from 'react';

// Components

import Size from '../../../components/block/Size';

// Styles

import './styles.scss';

// ----------------

// Type of props

SizeList.propTypes = {
	onSizeClick: types.func.isRequired,
	selectedId: types.number,
	list: types.array,
};

// ----------------

export default function SizeList(props) {
	const { onSizeClick, selectedId, list } = props;

	return (
		<div className="size-list">
			<div className="size-list__grid">
				{list.map(({ id, value }) => (
					<div className="size-list__cell" key={id}>
						<Size
							{...(selectedId === id
								? { active: true }
								: {
										onClick: () => {
											onSizeClick(id);
										},
								  })}
						>
							{value}
						</Size>
					</div>
				))}
			</div>
		</div>
	);
}
