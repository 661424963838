import React, { useState } from 'react';
import types from 'prop-types';

// ----------------

// Type of props

Search.propTypes = {
	listComponentProps: types.object,
	searchComponent: types.func.isRequired,
	switchComponent: types.func,
	listComponent: types.func.isRequired,
	valueKey: types.string.isRequired,
	list: types.array.isRequired,
};

// ----------------

export default function Search(props) {
	const {
		listComponentProps,
		searchComponent,
		switchComponent,
		listComponent,
		valueKey,
		list,
	} = props;

	// State

	const [value, setValue] = useState('');

	// Handlers

	function handleChange(e) {
		setValue(e.target.value);
	}

	function handleClear(e) {
		setValue('');
	}

	// Preparation

	const regExp = new RegExp(`${value}`, 'i');
	const filteredList = list.filter((item) => item && regExp.test(item[valueKey]));

	// Render

	return (
		<>
			{searchComponent({
				value,
				onChange: handleChange,
				onClear: handleClear,
			})}
			{listComponent({
				list: filteredList,
				...listComponentProps,
			})}
			{switchComponent && switchComponent()}
		</>
	);
}
